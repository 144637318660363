import React from 'react'
import Select from 'react-select'

const customStyles = hasError => ({
  indicatorSeparator: () => ({ display: '' }),
  menuList: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  control: provided => ({
    ...provided,
    border: hasError ? '1px solid #dc3545' : '',
    boxShadow: 'none !important',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    cursor: 'pointer',
    minHeight: '33.5px',
    height: '33.5px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '33.5px',
    padding: '0 8px',
    display: 'flex',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '33.5px',
  }),
})

const theme = current => ({
  ...current,
  colors: {
    ...current.colors,
    primary: 'black',
  },
})

const CustomOption = props => {
  const { innerProps, data } = props
  return (
    <div style={{ padding: 10 }} {...innerProps}>
      <span style={{ display: 'block', color: '#000' }}>{`${data.label}`}</span>
    </div>
  )
}

const CustomSingleValue = props => {
  const { innerProps, data } = props
  return (
    <div style={{ padding: '0 4px' }} {...innerProps}>
      <span style={{ display: 'block' }}>{`${data.label}`}</span>
    </div>
  )
}
const Dropdown = ({
  id,
  onChange,
  value,
  onBlur,
  options,
  placeholder,
  components,
  isDisabled = false,
  styles = null,
}) => (
  <Select
    id={id}
    styles={styles || customStyles(false)}
    theme={theme}
    isSearchable={false}
    isClearable={false}
    onChange={onChange}
    value={value}
    onBlur={onBlur}
    options={options}
    autofocus={true}
    placeholder={placeholder}
    escapeClearsValue={true}
    isDisabled={isDisabled}
    classNamePrefix="custom-dropdown"
    components={
      components || {
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }
    }
  />
)

export const DropdownOne = ({
  fieldName,
  placeHolder,
  options,
  value,
  hasError,
  handleChange,
  handleBlur,
  loading,
  inputClassname,
}) => {
  if (options.length === 1) {
    const option = options[0]
    return (
      <input
        disabled={true}
        type="text"
        className={inputClassname}
        value={option.label}
      />
    )
  }

  return (
    <Dropdown
      id={fieldName}
      placeholder={placeHolder}
      isDisabled={loading}
      styles={customStyles(hasError)}
      options={options}
      onChange={selectedOption => {
        handleChange(fieldName)(selectedOption.value)
      }}
      onBlur={() => {
        handleBlur({
          target: { name: fieldName },
        })
      }}
      value={value}
    />
  )
}

export default Dropdown
