import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { parse } from 'qs'

import * as commonCreators from '../redux/ducks/screens/signup/commons/creators'
import * as mealStepPageCreators from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as DeliveryPageCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import { setAddresses } from 'src/redux/ducks/screens/signup/pages/Checkout/creators'

import { removeParamOfUrl } from 'src/utils/utils'

import * as auth0Util from 'src/utils/auth0'
import * as sessionStorageUtil from 'src/utils/sessionStorage'
import { getPersistedState } from 'src/utils/sessionStorage'

import { SESSION_KEY } from 'src/constants'
import { useDispatch } from 'react-redux'
import { fetchUser } from 'src/features/user/userSlice'
import { setAuthUser, setToken } from 'src/features/auth/authSlice'
import { logError } from 'src/utils/logError'
import { validatePersistedStateData } from 'src/utils/dataValidation'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'

const Context = props => {
  const dispatch = useDispatch()
  
  const {
    getAccessTokenSilently,
    isAuthenticated,
    user: authUser,
  } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      try {
        dispatch(setAuthUser(authUser))
        const asyncFunction = async () => {
          props.setAuthenticated({
            isAuthenticated,
            user: authUser,
          })
          const accessToken = await getAccessTokenSilently({})
          sessionStorageUtil.setAuthAccessToken(accessToken)
          dispatch(setToken(accessToken))

          if (
            authUser &&
            auth0Util.getCustomAttributeValue(authUser, 'isSubscribed')
          ) {
            const { payload } = await dispatch(fetchUser())
            const user = payload?.users[0]
            if (user?.status !== 'Inactive') {
              window.location.replace(process.env.REACT_APP_SUBSCRIPTION_URL)
            }
          }
        }
        asyncFunction()
      } catch (error) {
        logError(error)
      }
    }
  }, [isAuthenticated, authUser]) // eslint-disable-line

  return props.children
}

export const withAuth0App = WrappedComponent => {
  const HOCComponent = props => {
    const location = useLocation()
    const navigate = useNavigate()

    const { zipcode } = parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
      const setPersistedState = async (persistedState, zipcode) => {
        if (persistedState) {
          const data = await validatePersistedStateData(persistedState, zipcode)
          props.actions.setUserSelectedData(data.userSelectedData)
          props.actions.setCartData(data.cartData)
          props.actions.setDeliveryOptionsData(data.deliveryOptionsData)
          props.actions.setPreferencesQuizData(data.preferencesQuizData)
          props.actions.setAddresses(data.addressData)
          props.actions.setForcedSkipQuiz(data.forceSkipQuiz)
          props.actions.persistState({ sessionId: SESSION_KEY })
        }
      }

      const sessionId = SESSION_KEY
      const persistedState = getPersistedState(sessionId)

      if (
        persistedState &&
        persistedState.userSelectedData.zipcode === zipcode
      ) {
        props.actions.setUserSelectedData({
          selectedPlan: persistedState.userSelectedData.selectedPlan,
        })
        setPersistedState(persistedState, zipcode)
      }
    }, [zipcode, props.actions])

    return (
      <Auth0Provider
        cacheLocation="localstorage"
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={appState => {
          if (appState?.returnTo) {
            navigate(appState.returnTo, {
              state: { couponCode: appState.couponCode },
            })
          } else {
            //@TODO this error solved a issue in firefox versions
            window.location.hash = window.location.hash // eslint-disable-line

            const url = `${location.pathname}${location.search}`
            const newUrl = removeParamOfUrl(url, 'code')

            window.history.replaceState(
              {},
              document.title,
              (appState && appState.returnTo) || newUrl,
            )
          }
        }}
      >
        <Context setAuthenticated={props.actions.setAuthenticated}>
          <WrappedComponent {...props} />
        </Context>
      </Auth0Provider>
    )
  }

  const SneakPeekCheckComp = props => {
    const { isTreatment: isSneakPeekTreatment } = useExperiment(
      EXPERIMENTS.sneakPeek,
    )
    if (isSneakPeekTreatment) return <WrappedComponent {...props} />

    return <HOCComponent {...props} />
  }

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        setUserSelectedData: data =>
          commonCreators.setUserSelectedDataStart(data),
        setCartData: data => mealStepPageCreators.setCartData(data),
        setDeliveryOptionsData: data =>
          DeliveryPageCreators.deliveryOptionCreators.start(data),
        setPreferencesQuizData: data =>
          commonCreators.preferencesQuizSetResponse(data),
        setAuthenticated: data => commonCreators.setAuthenticated(data),
        setAddresses: data => setAddresses(data),
        setForcedSkipQuiz: commonCreators.forcedSkipQuizStart,
        persistState: signUpCommonActionCreators.persistState,
      },
      dispatch,
    ),
  })
  return connect(null, mapDispatchToProps)(SneakPeekCheckComp)
}
