import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { parse } from 'qs'

import * as signUpCommonSelectors from '../../../../redux/ducks/screens/signup/commons/selectors'
import * as preOrderPageSelectors from '../../../../redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as signUpCommonActionCreators from '../../../../redux/ducks/screens/signup/commons/creators'
import * as creatorCommons from '../../../../redux/ducks/commons/creators'
import * as creatorMealsStep from '../../../../redux/ducks/screens/signup/pages/MealsStep/creators'
import * as deliverySettingCreators from '../../../../redux/ducks/screens/signup/pages/DeliverySettings/creators'
import { setAddresses } from '../../../../redux/ducks/screens/signup/pages/Checkout/creators'
import LostSelectionStepsWarningPopup from '../../../../components/Popups/LostSelectionStepsWarning'
import Analytics from 'src/utils/analytics'
import { autoSelectDeliveryDate } from '../../../../utils/utils'
import { DEFAULT_COUPON, SESSION_KEY } from '../../../../constants'
import { POP_REDUCING_TEXT, POP_INCREASING_TEXT } from './strings'
import SelectPlanPageCui from './SelectPlanPageCui'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSearchParam, removeUrlParameter } from 'src/utils/urlUtils'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import { getCUCookie } from 'src/utils/cookieUtils'
import { useAuth0 } from '@auth0/auth0-react'
import { PATHS, pageNames } from '../../constants'
import { logInfo } from 'src/utils/logError'

function SelectPlanPageContainer({
  userSelectedData,
  coupon,
  signUpInitData,
  cartTotalProductQty,
  validDates,
  actions,
  govx,
  isEconomicFlow,
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [temporalSelectedPlan, setTemporalSelectedPlan] = useState(null)
  const [showLostSelectionPoUp, setShowLostSelectionPoUp] = useState(false)
  const [warningPopUpText, setWarningPopUpText] = useState(POP_REDUCING_TEXT)
  const [clearCartAction, setClearCartAction] = useState(false)
  const [userSelectedPlan, setUserSelectedPlan] = useState(null)
  const { loginWithRedirect } = useAuth0()

  const MEAL_PLAN_8 = 8;

  const {
    isTreatment: isSneakPeekTreatment,
    isEnabled: isSneakPeekEnabled,
    trackExperimentViewed: trackSneakPeekViewed,
  } = useExperiment(EXPERIMENTS.sneakPeek)

  /**
   * @description This useEffect is used to set the default plan to 8 meals per delivery
   */
  useEffect(() => {
    if (!userSelectedData.selectedPlan) {
      const plan = signUpInitData.mealPlans?.find(
        ({ mealsPerDelivery }) =>
          mealsPerDelivery === MEAL_PLAN_8,
      )
      planHandler(plan)
      setUserSelectedPlan(plan)
    }
  
    actions.sendIdentify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Analytics.trackRealLoad('Plan Page Load')

    const search = location.search
    const { address, addressState, city } = parse(search, {
      ignoreQueryPrefix: true,
    })

    if (address && addressState && city) {
      actions.setAddresses({
        address: address,
        city: city,
        state: addressState,
        zipcode: userSelectedData.zipcode,
      })
    }
    actions.sendLogging({
      event: 'mealPlansPage',
      warning: false,
      data: {
        category: 'new-funnel',
        label: 'test-new-funnel',
        action: 'plan-page',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof actions.addSeoData === 'function')
      actions.addSeoData({
        title: 'A Chef Collective | CookUnity | Plan Selection',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const search = location.search
    const { planSize, noSkip } = parse(search, {
      ignoreQueryPrefix: true,
    })
    if (planSize && !noSkip) {
      if (userSelectedData.selectedPlan) {
        actions.setLoading(true)
        onGoNext()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const planHandler = newSelectedPlan => {
    actions.setUserSelectedDataStart({
      selectedPlan: newSelectedPlan,
    })
  }

  const selectPlanHandler = newSelectedPlan => {
    if (newSelectedPlan === undefined) {
      return;
    }
    const isAvailableForProtectCurrentSelection =
        userSelectedData.selectedPlan &&
        !showLostSelectionPoUp &&
        userSelectedData.selectedPlan.mealsPerDelivery >
          newSelectedPlan.mealsPerDelivery &&
        cartTotalProductQty > 0,
      isAvailableForChangeWarningSelection =
        userSelectedData.selectedPlan &&
        !showLostSelectionPoUp &&
        userSelectedData.selectedPlan.mealsPerDelivery <
          newSelectedPlan.mealsPerDelivery &&
        cartTotalProductQty > 0

    if (
      isAvailableForProtectCurrentSelection ||
      isAvailableForChangeWarningSelection
    ) {
      const text = isAvailableForProtectCurrentSelection
        ? POP_REDUCING_TEXT
        : POP_INCREASING_TEXT
      setWarningPopUpText(text)
      setTemporalSelectedPlan(newSelectedPlan)
      setShowLostSelectionPoUp(true)
      setClearCartAction(!!isAvailableForProtectCurrentSelection)
    } else {
      planHandler(newSelectedPlan)
    }
  }

  const nextPageWithResetSelectedMealPage = () => {
    actions.setUserSelectedDataStart({ selectedPlan: temporalSelectedPlan })

    if (clearCartAction) actions.resetProductCart()

    setShowLostSelectionPoUp(false)
    actions.setForcedSkipQuiz(true)
  }

  const onGoNext = () => {
    if (userSelectedData?.selectedPlan === null) {
      logInfo('Attempted to go next but no plan selected')
      return
    }
      const autoSelectedDate = autoSelectDeliveryDate(validDates)

      actions.setDeliveryOptions({
        validDates,
        originalSelectedDate: {
          startDay: autoSelectedDate,
          days: [autoSelectedDate.day],
        },
        autoSelectedDate: autoSelectedDate,
        startDay: autoSelectedDate,
        firstSelectionStartDay: true,
      })


      actions.setUserSelectedDataStart({
        startDay: autoSelectedDate,
        days: [autoSelectedDate.day],
      })

      actions.sendTracking({
        eventName: 'Sign Up - Delivery Day Assigned',
        eventData: {
          first_delivery_date: autoSelectedDate.date,
          user_delivery_day: autoSelectedDate.day,
        },
      })

    actions.persistState({ sessionId: SESSION_KEY })

    const selectedPlanData = {
      plan_id: userSelectedData.selectedPlan.id,
      plan_name: userSelectedData.selectedPlan.title,
      plan_billing_amount: userSelectedData.selectedPlan.price,
      plan_meals: userSelectedData.selectedPlan.mealsPerDelivery,
    }

    actions.sendTracking({
      eventName: 'Sign Up - Meal Plan Selected',
      eventData: selectedPlanData,
    })

    logInfo('Sign Up - Meal Plan Selected', { payload: { selectedPlan: selectedPlanData, couponData: coupon } })

    if (isSneakPeekEnabled) trackSneakPeekViewed()

    const search = removeUrlParameter(location.search, [
      'planSize',
      'meals',
      'noSkip',
    ])

    if (!isSneakPeekTreatment) {
      const { email, cuSession, utm_source } = parse(search, {
        ignoreQueryPrefix: true,
      })

      const searchForAuth0 = !cuSession ? `&cuSession=${SESSION_KEY}` : ''


      loginWithRedirect({
        action: 'signup',
        redirectUri: `${window.location.origin}${
          PATHS[pageNames.MEALS]
        }${search}${searchForAuth0}`,
        initialEmail: email,
        search: `${search}${searchForAuth0}`,
        env: process.env.NODE_ENV,
        isDemo: utm_source === 'demo',
        exp: 'treatment-1',
        rebexp: 'TREATMENT',
      })

      return
    }

    navigate(PATHS[pageNames.MEALS] + search)
  }

  const plansWithPromotion = signUpInitData.mealPlans

  const plansWithDefault = plansWithPromotion.map(plan => {
    if (plan.mealsPerDelivery === MEAL_PLAN_8) {
      return {
        ...plan,
        highlight: true,
      }
    }
    return plan
  })

  const onClickPlan = clickedPlanId => {
    const selectedPlan = plansWithPromotion?.find(
      ({ id }) => id === clickedPlanId,
    )
    selectPlanHandler(selectedPlan)
    if (
      (selectedPlan.couponCode &&
        coupon.hiddenCouponCode !== selectedPlan.couponCode) ||
      (!selectedPlan.couponCode &&
        coupon.couponCode !== coupon.hiddenCouponCode)
    ) {
      actions.applyCoupon(
        getSearchParam('coupon') ||
          getCUCookie('discountCode') ||
          DEFAULT_COUPON,
      )
    }
  }

  const mainSectionText = 'You can skip, pause or change the plan size anytime.'

  return (
    <>
      <LostSelectionStepsWarningPopup
        text={warningPopUpText}
        isOpen={showLostSelectionPoUp}
        onClose={() => setShowLostSelectionPoUp(false)}
        onActionClick={nextPageWithResetSelectedMealPage}
      />

      <SelectPlanPageCui
        plans={plansWithDefault}
        userSelectedData={userSelectedData}
        signUpInitData={signUpInitData}
        selectedPlan={userSelectedData.selectedPlan ?? userSelectedPlan}
        onClickPlan={onClickPlan}
        coupon={coupon}
        onGoNext={onGoNext}
        disabledNextStepButton={
          !userSelectedData.selectedPlan || showLostSelectionPoUp
        }
        sendTracking={actions.sendTracking}
        applyCoupon={actions.applyCoupon}
        govx={govx}
        isEconomicFlow={isEconomicFlow}
        defaultPlan={MEAL_PLAN_8}
        mainSectionText={mainSectionText}
      />
    </>
  )
}

const mapStateToProps = state => ({
  signUpInitData: signUpCommonSelectors.getSignUpInitData(state),
  coupon: signUpCommonSelectors.getCoupon(state),
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
  cartTotalProductQty: preOrderPageSelectors.getCart(state).totalProductQty,
  validDates: signUpCommonSelectors.getDeliveryDays(state),
  isEconomicFlow: signUpCommonSelectors.getIsEconomicFlow(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      applyCoupon: signUpCommonActionCreators.applyCouponStart,
      sendLogging: creatorCommons.loggingStart,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
      addSeoData: signUpCommonActionCreators.startAddSeoData,
      resetProductCart: creatorMealsStep.resetCart,

      setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
      sendTracking: creatorCommons.trackingStart,
      setDeliveryOptions: deliverySettingCreators.deliveryOptionCreators.start,
      persistState: signUpCommonActionCreators.persistState,
      setAddresses: setAddresses,
      sendIdentify: creatorCommons.identifyStart,
      setLoading: signUpCommonActionCreators.setForceLoading,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPlanPageContainer)
