const PATHS = {
  DASHBOARD: 'welcome-back',
  MEAL_SELECTION: 'meal-selection',
  DELIVERY_DAY_SELECTION: 'delivery-day-selection',
  CONFIRM_ORDER: 'confirm-order',
  THANK_YOU: 'thank-you',
}

export const SIGN_UP_PATHS = {
  HOME: '/',
  PLAN_SELECTION: '/eat-everything/page-1',
  DELIVERY_DAY_SELECTION: '/eat-everything/page-2',
  PREFERENCE_QUIZ: `/quiz`,
  MEAL_SELECTION: `/eat-everything/page-3`,
  CHECKOUT: `/eat-everything/page-4`,
  THANK_YOU: `/thankyou`,
}

export const PATHS_NAME = {
  [SIGN_UP_PATHS.HOME]: 'Home',
  [SIGN_UP_PATHS.PLAN_SELECTION]: 'Plan Selection',
  [SIGN_UP_PATHS.DELIVERY_DAY_SELECTION]: 'Delivery Day Selection',
  [SIGN_UP_PATHS.PREFERENCE_QUIZ]: 'Quiz',
  [SIGN_UP_PATHS.MEAL_SELECTION]: 'Meal Selection',
  [SIGN_UP_PATHS.CHECKOUT]: 'Checkout',
  [SIGN_UP_PATHS.THANK_YOU]: 'Thank You',
}

export const RESURRECTION_PATHS = {
  DASHBOARD: `/${PATHS.DASHBOARD}`,
  MEAL_SELECTION: `/${PATHS.DASHBOARD}/${PATHS.MEAL_SELECTION}`,
  DELIVERY_DAY_SELECTION: `/${PATHS.DASHBOARD}/${PATHS.DELIVERY_DAY_SELECTION}`,
  CONFIRM_ORDER: `/${PATHS.DASHBOARD}/${PATHS.CONFIRM_ORDER}`,
  THANK_YOU: `/${PATHS.DASHBOARD}/${PATHS.THANK_YOU}`,
}

export const ROUTES_CONFIGURATION = {
  [RESURRECTION_PATHS.DASHBOARD]: {
    previousPage: '/',
    nextPage: RESURRECTION_PATHS.MEAL_SELECTION,
  },
  [RESURRECTION_PATHS.MEAL_SELECTION]: {
    previousPage: RESURRECTION_PATHS.DASHBOARD,
    nextPage: RESURRECTION_PATHS.CONFIRM_ORDER,
  },
  [RESURRECTION_PATHS.DELIVERY_DAY_SELECTION]: {
    previousPage: RESURRECTION_PATHS.DASHBOARD,
    nextPage: RESURRECTION_PATHS.MEAL_SELECTION,
  },
  [RESURRECTION_PATHS.CONFIRM_ORDER]: {
    previousPage: RESURRECTION_PATHS.MEAL_SELECTION,
    nextPage: RESURRECTION_PATHS.THANK_YOU,
  },
  [RESURRECTION_PATHS.THANK_YOU]: {
    previousPage: RESURRECTION_PATHS.CONFIRM_ORDER,
    nextPage: RESURRECTION_PATHS.THANK_YOU,
  },
}

export const REDIRECT_PATHS = {
  REDIRECT_TO_STORE: '/open-store',
}
