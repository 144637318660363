import { useState, useEffect } from 'react'
import { getUserOs } from 'src/utils/events'
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'src/constants'

const useOnlineStore = () => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const userOs = getUserOs()
    if (userOs === 'iOS') {
      setUrl(APP_STORE_URL)
    } else if (userOs === 'Android OS') {
      setUrl(GOOGLE_PLAY_URL)
    }
  }, [])

  return {
    url,
  }
}

export default useOnlineStore
