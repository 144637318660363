/**
 * @description Makes simple reduce flow and do not repetitive
 * @param configReducer {{
 * simpleActions: Object,
 * stateName: String,
 * initialState: Immutable iRecord
 * },
 * }
 */
export function createSimpleReducerFlow(configReducer) {
  const {simpleActions, stateNames = [], initialState} = configReducer;
  return {
    [simpleActions.start]: (state) => {
      return state.setIn([...stateNames, 'start'], true);
    },
    [simpleActions.success]: (state, {payload}) => {
      return state
        .setIn([...stateNames, 'start'], false)
        .setIn([...stateNames, 'success'], true)
        .setIn([...stateNames, 'data'], payload);
    },

    [simpleActions.failed]: (state, {payload}) => {
      return state
        .setIn([...stateNames, 'start'], false)
        .setIn([...stateNames, 'success'], false)
        .setIn([...stateNames, 'data'], initialState.getIn([...stateNames, 'data']))
        .setIn([...stateNames, 'error'], payload);
    },

    [simpleActions.reset]: (state) => {
      return state.setIn([...stateNames], initialState.getIn([...stateNames]));
    },
  };
}

export function createSimpleActionsFlow(duck, name) {
  return {
    start: duck.defineType(`${name}_START`),
    success: duck.defineType(`${name}_SUCCESS`),
    failed: duck.defineType(`${name}_FAILED`),
    reset: duck.defineType(`${name}_RESET`),
  };
}

export function createSimpleActionCreatorsFlow(duck, action) {
  return {
    start: duck.createAction(action.start),
    success: duck.createAction(action.success),
    failed: duck.createAction(action.failed),
    reset: duck.createAction(action.reset),
  };
}
