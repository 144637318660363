import './GoTopButtonCui.scss'

const GoTopButtonCui = ({ showGoTop, scrollUp }) => {
  return (
    <div className={showGoTop} onClick={scrollUp}>
      <button>
        <i className="goTop__text fas fa-arrow-up" />
        <p>Back to top</p>
      </button>
    </div>
  )
}
export default GoTopButtonCui
