import React from 'react'
import classnames from 'classnames'
import './CrossedOutLabel.scss'

export const CROSSED_OUT_VARIANTS = {
  PREMIUM: 'premium',
  FREE: 'free',
}

const CrossedOutLabel = ({
  shouldCross,
  variant = CROSSED_OUT_VARIANTS.FREE,
  className,
  children,
}) => {
  return (
    <div className={classnames('crossed-out-wrapper', variant, className)}>
      {shouldCross && <span className='line-through' />}
      <span className='crossed-out-text'>{children}</span>
    </div>
  )
}

export default CrossedOutLabel
