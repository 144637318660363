import { CuiIcon } from 'front-lib'

import { DEFAULT_MEAL_PLAN } from 'src/constants/index'

const isDefaultPlan = plan => plan.mealsPerDelivery === DEFAULT_MEAL_PLAN

export const getMealPlansWithDefault = plans =>
  plans.map(plan => {
    if (isDefaultPlan(plan)) {
      return {
        ...plan,
        highlight: true,
      }
    }
    return plan
  })

export const mapPlansToOptions = plans =>
  plans.map(
    ({ id, mealsPerDelivery, highlight, couponCode, topText, topIcon }) => ({
      id,
      label: mealsPerDelivery,
      topIcon: couponCode && !topText && topIcon && <CuiIcon name={topIcon} />,
      bottomIcon: highlight && <CuiIcon className="starIcon" name="starFull" />,
      topText: couponCode && topText,
    }),
  )

export const trackDeliveryDayAssigned = (
  sendTracking,
  { autoSelectedDate },
) => {
  sendTracking({
    eventName: 'Sign Up - Delivery Day Assigned',
    eventData: {
      first_delivery_date: autoSelectedDate.date,
      user_delivery_day: autoSelectedDate.day,
    },
  })
}

export const trackMealPlanUpdated = (
  sendTracking,
  { oldPlan, newPlan },
) => {
  sendTracking({
    eventName: 'Meal Plan Updated',
    eventData: {
      old_plan_id: oldPlan?.id,
      old_plan_name: oldPlan?.title,
      old_plan_meals: oldPlan?.mealsPerDelivery,
      new_plan_id: newPlan?.id,
      new_plan_name: newPlan?.title,
      new_plan_meals: newPlan?.mealsPerDelivery,
    },
  })
}

export const OVERLAY_VIEWED = 'overlay_viewed'
export const OVERLAY_CLOSED = 'overlay_closed'
export const OVERLAY_SUBMITTED = 'overlay_submitted'

export const getChangePlanOverlayTrackData = ({ event }) => {
  const overlayTrackData = {
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'Custom',
    overlay_action: 'change plan',
  }

  switch (event) {
    case OVERLAY_VIEWED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Viewed',
        overlay_name: 'Sign Up - Change Plan - Overlay Viewed',
        overlay_trigger: 'manual',
      }
    case OVERLAY_CLOSED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Closed',
        overlay_name: 'Sign Up - Change Plan - Overlay Closed',
        overlay_trigger: 'manual',
      }

    case OVERLAY_SUBMITTED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Submitted',
        overlay_name: 'Sign Up - Change Plan - Overlay Submitted',
        overlay_trigger: 'manual',
      }

    default:
      break
  }
}
