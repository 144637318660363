import React from 'react'
import { useSelector } from 'react-redux'
import { addressSelector } from 'src/features/address/addressSlice'

const ConfirmAddress = () => {
    const address = useSelector(addressSelector)

    return (
        <div className="confirm-address__container-cui">
            <h3 className="rebrand">Delivery Address</h3>
            <p className='rebrand'>{`${address?.street}, ${address?.zipCode}`}</p>
        </div>
    )
}


export default ConfirmAddress