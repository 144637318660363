import { isEmpty } from 'ramda'
import { applicationConstants } from 'src/constants'

export const getCustomKey = key => {
  if (!key) return null

  return applicationConstants.AUTH0_NAMESPACE + key
}

export const getCustomAttributeValue = (data, key) => {
  if (isEmpty(data) || !key) return null

  return data[getCustomKey(key)]
}
