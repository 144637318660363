import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useVariant } from '@unleash/proxy-client-react'
import React from 'react'
import { EXPERIMENTS } from 'src/constants/experiments'

const STRIPE_PUBLISHABLE_TOKEN = process.env.REACT_APP_STRIPE_PUBLISHABLE_TOKEN
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_TOKEN)

const paymentElementOpts = {
  mode: 'setup',
  currency: 'usd',
  appearance: {
    theme: 'flat',
    variables: {
      fontFamily: '"TT Norms Pro", sans-serif',
      fontLineHeight: '20px',
      fontSize: '14px',
      fontSizeSm: '12px',
      colorText: '#000',
      backgroundColor: '#fff',
      colorPrimary: '#231f20',
      accessibleColorOnColorPrimary: '#262626',
      colorIconTabSelected: '#fff',
      colorIcon: '#97235a',
      colorIconCheckmark: '#97235a',
      colorDanger: '#9e2146',
      colorIconCardError: '#9e2146',
      colorIconCardCvcError: '#9e2146',
    },
    rules: {
      '.Input': {
        border: '1px solid #707070',
        borderRadius: '200px',
        backgroundColor: '#fff',
        padding: '14px 12px',
        fontSize: '14px',
        fontLineHeight: '20px',
        fontWeight: '500',
        fontFamily: '"TT Norms Pro", sans-serif',
      },
      '.Input::placeholder': {
        fontFamily: '"TT Norms Pro italic", sans-serif',
      },
      '.Input:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      },
      '.Label': {
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: '"TT Norms Pro", sans-serif',
      },
      '.AccordionItem': {
        fontFamily: '"TT Norms Pro bold", sans-serif',
        fontSize: '16px',
        fontWeight: 700,
        borderColor: '#ddd',
      },
      '.AccordionItem--selected': {
        color: '#97235a',
      },
    }
  },
  fonts: [
    {
      family: 'TT Norms Pro',
      src: 'url(https://statics.cookunity.com/fonts/tt-norms-pro/tt-norms-pro-normal.woff)',
      weight: 500
    },
    {
      family: 'TT Norms Pro bold',
      src: 'url(https://statics.cookunity.com/fonts/tt-norms-pro/tt-norms-pro-bold.woff)',
      weight: 700,
    },
    {
      family: 'TT Norms Pro italic',
      src: 'url(https://statics.cookunity.com/fonts/tt-norms-pro/tt-norms-pro-normal-italic.woff)',
      weight: 500,
      style: 'italic'
    }
  ],
};



const StripeProvider = ({ children }) => {
  const variant = useVariant(EXPERIMENTS.stripePaymentElement);
  const isPaymentElementEnabled = (variant && variant.enabled && variant.name === 'TREATMENT');
  return (isPaymentElementEnabled ? <Elements stripe={stripePromise} options={paymentElementOpts}>{children}</Elements> : <Elements stripe={stripePromise}>{children}</Elements>)
}

export default StripeProvider
