import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { CuiHeader, /* CuiBreadcrumbs, CuiBreadcrumb */ } from 'front-lib'

import Loader from '../../components/Loader'

import './SignupPage.scss'
// import classNames from 'classnames'
import { openCustomerServiceChat } from '../../utils/customerServiceChat'
import useTrackEvent from 'src/hooks/useTrackEvent'

// const FunnelBreadcrumbs = ({ data, stepHandler }) => (
//   <CuiBreadcrumbs color="light">
//     {data.map(item => (
//       <CuiBreadcrumb
//         active={!item.disabled}
//         key={item.id}
//         onClick={() => !item.disabled && stepHandler(item.step)}
//         className={classNames({ 'funnel-breadcrumb--hover': !item.disabled })}
//       >
//         {item.label}
//       </CuiBreadcrumb>
//     ))}
//   </CuiBreadcrumbs>
// )

export const SignUpScreen = ({
  isLoading,
  // stepHandler,
  // breadPathData,
  seoData,
  showTopBar,
  pathname,
  setBreadPathStart,
  children,
}) => {
  const helpRequestedCallback = useTrackEvent({
    eventName: 'CTA Button Clicked',
  });
  
  useEffect(() => {
    setBreadPathStart(pathname)
  }, [pathname, setBreadPathStart])

  const handleHelpClick = () => {
    openCustomerServiceChat(helpRequestedCallback);
  };  

  return isLoading ? (
    <Loader />
  ) : (
    <div className="signUpFlowContainer">
      <Helmet>
        <title>{seoData.title}</title>
      </Helmet>

      {showTopBar && (
        <CuiHeader
          showNeedHelpTitle
          color="dark"
          showNeedHelp
          onClickHelp={handleHelpClick}
          // center={
          //   <FunnelBreadcrumbs data={breadPathData} stepHandler={stepHandler} />
          // }
          className="sign-up-screen__cui-header"
        />
      )}

      {children}
    </div>
  )
}
