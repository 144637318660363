import React, { useState, useEffect } from 'react'

function ImageWithDefaultPlaceholder(props) {
  const [errorLoaded, setErrorLoaded] = useState(false)
  const { src, srcDefault, alt, className, onSetDefaultSource } = props

  useEffect(() => {
    setErrorLoaded(false)
  }, [src])

  const handleOnError = () => {
    if (typeof onSetDefaultSource === 'function') onSetDefaultSource()
    setErrorLoaded(true)
  }

  const image = errorLoaded ? srcDefault : src ? src : srcDefault

  return (
    <img
      className={`${className} ${errorLoaded ? 'no-image' : ''}`}
      src={image}
      onError={handleOnError}
      alt={alt}
    />
  )
}

export default ImageWithDefaultPlaceholder
