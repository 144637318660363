import hash from 'hash.js'

export const sanitize = value =>
  typeof value === 'string' ? value.replace(/ /g, '').toLowerCase() : value

export const sha256 = value => {
  return hash
    .sha256()
    .update(value)
    .digest('hex')
}
