import {
  promotionTypeOfUseLabels,
  zeroInvoicesReferralType,
  SEATTLE_STORE_ID,
  COUPON_SIX_WEEK_PROMO,
  COUPON_DISCLAIMERS,
} from '../constants'

export function transformCoupon(couponCode) {
  if (couponCode) {
    const upperCasedCoupon = couponCode.toUpperCase();

    if (upperCasedCoupon === 'GET50OFF' || upperCasedCoupon === 'GET5OOFF') {
      return 'GET50OFF4J';
    }

    if (upperCasedCoupon === 'GET30OFF'){
      return 'GET30OFF4J';
    }
  }

  return couponCode;
}

export const isExpiredCoupon = coupon => !!coupon.expiry && new Date().getTime() > new Date(coupon.expiry).getTime()

export const hasRestrictions = (coupon, selectedPlanId) => {
  return !!selectedPlanId &&
    !!coupon.restrictions &&
    Array.isArray(coupon.restrictions.notAvailablePlans) &&
    coupon.restrictions.notAvailablePlans.includes(selectedPlanId);
}

/**
 *
 * @param {boolean} onlyEconomicPlans - Is Economic Flow enabled
 * @param {string} coupon - New coupon to be applied
 * @returns {boolean} - True if the user is in an economic flow or the coupon is an economic plan, false otherwise
 */
export const hasEconomicData = ({ coupon, onlyEconomicPlans }) =>
  onlyEconomicPlans || coupon === 'TRY_ECONOMIC_PLAN'

/**
 * Checks if a coupon is unavailable for a specific store on the user selected date.
 * @param {Object} coupon - The coupon object containing restrictions.
 * @param {Object} startDay - The startDay object containing the user selected date.
 * @param {Object} store - The store object containing the user store id.
 * @returns {boolean} True if the day is unavailable, false otherwise.
 */
export const hasUnavailableDay = ({ coupon, startDay, store }) => {
  const unavailableDays = coupon?.restrictions?.notAvailableDays.find(
    restriction => restriction?.store === store?.id,
  )

  if (unavailableDays?.days && Array.isArray(unavailableDays?.days)) {
    return unavailableDays.days.includes(startDay?.day)
  }

  return false
}
  

export const isEligibleCoupon = coupon => !coupon.isEligible || coupon.isEligible.isEligible

export const isEligibleZeroInvoices = coupon => coupon.isEligible && coupon.isEligible.type === zeroInvoicesReferralType

export const getPromotionTypeOfUseLabel = coupon => {
  if (coupon?.promotion?.discount) {
    return promotionTypeOfUseLabels[coupon.promotion.discount.typeOfUse] || promotionTypeOfUseLabels.default
  }

  return promotionTypeOfUseLabels.default
}

export const getRemainingDaysForExpiration = ({ expiry }) => {
  const currentDate = new Date();
  const expiryDate = new Date(expiry);

  const timeDifference = expiryDate.getTime() - currentDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

  return daysDifference;

}

export const changeCouponOnSearch = (search, newCoupon) => {
  const urlSearchParam = new URLSearchParams(search)

  urlSearchParam.set('coupon', newCoupon)

  return '?' + decodeURI(urlSearchParam.toString())
}

export const validateCoupon = newCoupon => {
  if (!newCoupon || typeof newCoupon !== 'string' || newCoupon.trim() === '') {
    return 'Empty code is not valid'
  }
}

export const getCouponsPerPlanByCouponCode = ({ couponCode, storeId }) => {
  if (couponCode?.toUpperCase()?.includes(COUPON_SIX_WEEK_PROMO)) {
    if (storeId === SEATTLE_STORE_ID) {
      return {
        '4': 'SIXWEEKPROMO4S',
        '6': 'SIXWEEKPROMO6S',
        '8': 'SIXWEEKPROMO8S',
        '12': 'SIXWEEKPROMO12S',
        '16': 'SIXWEEKPROMO16S',
      }
    }
    return {
      '4': 'SIXWEEKPROMO4',
      '6': 'SIXWEEKPROMO6',
      '8': 'SIXWEEKPROMO8',
      '12': 'SIXWEEKPROMO12',
      '16': 'SIXWEEKPROMO16',
    }
  }

  return null
}

export const isNewYorkStore = ({ storeId }) => storeId === 1

export const isFreeDessertCoupon = ({ couponCode }) =>
  couponCode?.toUpperCase().includes('FREEDESSERT')

export const transformFreeDessertCoupon = ({ couponCode }) => {
  switch (couponCode) {
    case 'FREEDESSERT40':
      return 'GET40OFF'
    case 'FREEDESSERT50':
      return 'GET50OFF'
    case 'FREEDESSERT60':
      return 'GET60OFF'
    default:
      return ''
  }
}

export const getCouponDisclaimer = ({ couponCode = '' }) => {
  if (isFreeDessertCoupon({ couponCode })) {
    return COUPON_DISCLAIMERS.FREE_DESSERT
  }

  return ''
}
