import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { logError } from 'src/utils/logError'

import * as sessionStorageUtil from 'src/utils/sessionStorage'

const getAccessToken = () => {
  return sessionStorageUtil.getAuthAccessToken()
}

const URL = process.env.REACT_APP_GRAPH_API_URL
const PUBLIC_URL = process.env.REACT_APP_GRAPH_API_PUBLIC_URL
const MENU_SERVICE_URL = process.env.REACT_APP_MENU_SERVICE_PUBLIC_URL
const MEALS_SERVICE_URL = process.env.REACT_APP_MEALS_SERVICE_URL

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path }) =>
        logError(`[GraphQL error]: Message: ${message}, Path: ${path}`, {
          operation,
          response,
        }),
      )
    }
    if (networkError) {
      logError(`[Network error]: ${networkError}`, { operation, response })
    }
  },
)

const httpLink = createHttpLink({
  uri: URL,
  credentials: 'include',
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const publicClient = new ApolloClient({
  link: from([
    errorLink,
    createHttpLink({
      uri: PUBLIC_URL,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const menuServiceClient = new ApolloClient({
  link: from([
    errorLink,
    createHttpLink({
      uri: MENU_SERVICE_URL,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const mealsServiceClient = new ApolloClient({
  link: from([
    errorLink,
    createHttpLink({
      uri: MEALS_SERVICE_URL,
      credentials: 'include',
      fetchOptions: {
        mode: 'no-cors',
      },
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

const gqlClientMap = {
  query: (query, variables = {}) =>
    client.query({
      query: gql(query),
      variables,
    }),
  menuServiceQuery: (query, variables = {}) =>
    menuServiceClient.query({
      query: gql(query),
      variables,
    }),
  mealsServiceQuery: (query, variables = {}) =>
    mealsServiceClient.query({
      query: gql(query),
      variables,
    }),
  publicQuery: (query, variables = {}) =>
    publicClient.query({
      query: gql(query),
      variables,
    }),
  publicMutation: (mutation, variables = {}) =>
    publicClient.mutate({
      mutation: gql(mutation),
      variables,
    }),
  mutation: (mutation, variables = {}) =>
    client.mutate({
      mutation: gql(mutation),
      variables,
    }),
}

export default gqlClientMap
