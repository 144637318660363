import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { orderCreatedSelector } from 'src/features/cart/cartSlice'
import { subscriptionReactivatedSelector } from 'src/features/user/userSlice'
import useRouter from 'src/hooks/useRouter'

const ThankYouGuard = ({ children }) => {
  const { previousRoute } = useRouter()
  const subscriptionReactivated = useSelector(subscriptionReactivatedSelector)
  const orderCreated = useSelector(orderCreatedSelector)

  const isValid = subscriptionReactivated && orderCreated

  useEffect(() => {
    if (!isValid) {
      previousRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isValid && (children || <Outlet />)
}

export default React.memo(ThankYouGuard)
