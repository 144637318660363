import classNames from 'classnames'
import { useState } from 'react'

import MealCard from './MealCard'
import { isMobile } from 'src/utils/events'

const MealsDetail = ({ meals }) => {
  const [isOpen, setIsOpen] = useState(!isMobile())

  const classes = classNames('meal-cards', {
    hide: !isOpen,
  })

  const chevronClasses = classNames('fas fa-xs', {
    'fa-chevron-down': !isOpen,
    'fa-chevron-up': isOpen,
  })

  return (
    <div className={'meals-detail'}>
      <div
        className={'only-mobile meals-detail--dropdown-button'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className={'rebrand bold'}>
          {isOpen ? 'Hide all items' : 'Show all items'}
          <span className={chevronClasses} />
        </p>
      </div>
      <p className={'rebrand meals-detail--text'}>All Items</p>
      <div className={classes}>
        <div className={'meal-cards--inner'}>
          {meals.map(({ qty, data }) => {
            return <MealCard image={data.primaryImageUrl} name={data.name} quantity={qty} />
          })}
        </div>
      </div>
    </div>
  )
}

export default MealsDetail
