import { CuiImage } from "front-lib"
import DiamondIcon from '../../../../assets/svg/diamond.svg';
import './ChefSpecialRow.scss';

const ChefSpecialRow = () => (
    <div className='chef-special-row'>
        <CuiImage
            src={DiamondIcon}
            title="Chef Specials"
        />
        <p>
            Chef Specials
        </p>
    </div>
)

export default ChefSpecialRow