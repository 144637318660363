import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getPaymentsMethods,
  getStripeClientSecret,
  removePaymentMethod,
  setDefaultUserCard,
} from './paymentAPI'

const initialState = {
  paymentMethods: null,
  paymentMethodsStatus: 'idle',
  setDefaultUserCardStatus: 'idle',
  addCardStatus: 'idle',
  addCardError: null,
}

export const fetchPaymentsMethods = createAsyncThunk(
  'payment/fetch',
  async ({ forceFetch } = {}) => {
    const response = await getPaymentsMethods({ forceFetch })
    return response
  },
)

export const updateDefaultUserCard = createAsyncThunk(
  'payment/updateDefaultUserCard',
  async ({ cardId }) => {
    const response = await setDefaultUserCard({ cardId })
    return response
  },
)

export const addCard = createAsyncThunk(
  'payment/addCard',
  async ({ stripe, cardInfo }, { dispatch }) => {
    const {
      stripeClientSecret: {
        client_secret: stripeclientSecret,
        customer_id: stripeCustomerId,
      },
    } = await getStripeClientSecret()

    const stripeConfirmationResult = await stripe.confirmCardSetup(
      stripeclientSecret,
      {
        payment_method: {
          card: cardInfo.element,
          billing_details: { name: cardInfo.name },
        },
      },
    )

    if (stripeConfirmationResult.error) {
      removePaymentMethod({ cardId: stripeCustomerId })
      throw new Error(stripeConfirmationResult.error.message)
    }

    await dispatch(updateDefaultUserCard({ cardId: stripeCustomerId }))
    await dispatch(fetchPaymentsMethods({ forceFetch: true }))

    return null
  },
)

export const paymentSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearAddCardError: state => {
      state.addCardStatus = 'idle'
      state.addCardError = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPaymentsMethods.pending, state => {
        state.paymentMethodsStatus = 'loading'
      })
      .addCase(fetchPaymentsMethods.fulfilled, (state, { payload }) => {
        state.paymentMethodsStatus = 'idle'
        state.paymentMethods = payload.paymentMethods
      })
      .addCase(updateDefaultUserCard.pending, state => {
        state.setDefaultUserCardStatus = 'loading'
      })
      .addCase(updateDefaultUserCard.fulfilled, state => {
        state.setDefaultUserCardStatus = 'idle'
      })
      .addCase(addCard.pending, state => {
        state.addCardStatus = 'loading'
      })
      .addCase(addCard.fulfilled, state => {
        state.addCardStatus = 'idle'
      })
      .addCase(addCard.rejected, (state, { error }) => {
        state.addCardStatus = 'failed'
        state.addCardError = error.message
      })
  },
})

export const { clearAddCardError } = paymentSlice.actions

export const cardSelector = state =>
  state.get('payment').paymentMethods?.find(el => el.isDefault).card
export const isLoadingAddCardSelector = state =>
  state.get('payment').addCardStatus === 'loading'
export const errorAddCardSelector = state => state.get('payment').addCardError
export const paymentMethodsStatusSelector = state => state.get('payment').paymentMethodsStatus === 'loading'

export default paymentSlice.reducer
