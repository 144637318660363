import { useState } from 'react'
import { CuiCard, CuiLink } from 'front-lib'
import useOnlineStore from 'src/hooks/useOnlineStore'

import './DownloadApp.scss'

export function DownloadApp({ onClick = () => {} }) {
  const [buttonFill, setButtonFill] = useState('solid')
  const { url } = useOnlineStore()

  const handleClick = () => {
    onClick()
    setButtonFill('outline')
  }

  return (
    <div className={'download-app__container'}>
      <CuiCard className={'download-app'}>
        <div className={'download-app__text'}>
          <p className="download-app__title">
            ⭐ Make your life easier with our app!
          </p>
          <p className="download-app__description">
            Effortlessly pick meals, manage your order, and track delivery with
            our app available on iOS and Android.
          </p>
          {url && (
            <CuiLink
              className={'bottom-banner--button'}
              fill={buttonFill}
              onClick={handleClick}
              href={url}
            >
              Let's go!
            </CuiLink>
          )}
        </div>
        {!url && <div className={'download-app__qr'} />}
      </CuiCard>
    </div>
  )
}
