import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import * as qs from 'qs'
import { connect } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  useFlag,
  useFlagsStatus,
  useUnleashClient,
} from '@unleash/proxy-client-react'

import { SignUpScreen } from './SignUpScreen'
import SelectPlanPage from './pages/SelectPlans'
import DeliverySettingPage from './pages/DeliverySettings'
import MealsStep from './pages/MealsStep'
import CheckoutPage from './pages/Checkout'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import {
  getSignUpInitData,
  getSignUpInitDataError,
} from 'src/redux/ducks/screens/signup/commons/selectors'
import { getCookie } from '../../utils/cookieUtils'
import { getCouponsPerPlanByCouponCode } from '../../utils/coupons'
import { sanitizeZipCode } from 'front-lib'
import { getData as getDeliveryData } from '../../redux/ducks/screens/signup/pages/DeliverySettings/selectors/deliveryOptions'
import {
  getUTMValues,
  getLPCookie,
  autoSelectDeliveryDate,
} from '../../utils/utils'
import { SESSION_KEY, DEFAULT_MEAL_PLAN, COUPON_SIX_WEEK_PROMO } from '../../constants'
import { pageNames, PATHS } from './constants'
import { removeUrlParameter } from 'src/utils/urlUtils'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { logError, logInfo, logView } from 'src/utils/logError'

import { useGovXUser } from 'src/app/providers/GovXOAuthProvider'
import FullScreenError from '../../components/FullScreenError'
import { useSelector } from 'react-redux'
import useAppSearchParams from 'src/hooks/useAppSearchParams'
import { userSelector } from 'src/features/user/userSlice'
import { authUserSelector } from 'src/features/auth/authSlice'
import { ringSelector } from 'src/features/address/addressSlice'
import { getCustomAttributeValue } from 'src/utils/auth0'
import { PATHS_NAME } from 'src/constants/routes'

function SignUpPageContainer({
  userSelectedData,
  deliveryData,
  isLoading,
  breadPathData,
  seoData,
  showTopBar,
  createUserSuccess,
  createUserData,
  signupInitData,
  couponData,
  actions,
  validDates,
  initDataError,
}) {
  const { loginWithRedirect } = useAuth0()
  const { getAllSearchParams } = useAppSearchParams()
  const [currentPath, setCurrentPath] = useState('');
  const zipCode = useSelector(signUpCommonSelectors.getUserSelectedZipcode)
  const user = useSelector(userSelector)
  const authUser = useSelector(authUserSelector)
  const ring = useSelector(ringSelector)
  const previousStoreRingInformation = useSelector(
    signUpCommonSelectors.getRing,
  )
  const selectedPlan = useSelector(signUpCommonSelectors.getUserSelectedPlan)
  const coupon = useSelector(signUpCommonSelectors.getCoupon)

  const govx = useGovXUser()

  const { flagsReady } = useFlagsStatus()
  const [flagsError, setFlagsError] = useState(false)
  const unleashClient = useUnleashClient()
  unleashClient.on('error', error => {
    setFlagsError(true)
    logInfo(error, {unleashError: true})
  })

  const location = useLocation()
  const navigate = useNavigate()

  const trackEconomicFlow = useTrackEvent({
    eventName: 'Sign Up - Economic Flow Started',
  })

  const economicFlag = useFlag('gro-economic-flow')
  const isEconomicPlanFlowEnabled = economicFlag || govx?.isVerified || false

  const allowOverbook = useFlag('allow-overbook')

  const isMultiWeekPromotionEnabled = useFlag('gro-gtt-4271-multi-week-plan-price')

  useEffect(() => {
    if (isEconomicPlanFlowEnabled) {
      trackEconomicFlow({
        is_govx_user: govx?.isVerified ? true : false,
        govx_user_id: govx?.user?.userProfile?.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEconomicPlanFlowEnabled])

  useEffect(() => {
    if (location.pathname !== PATHS[pageNames.MEALS]) {
      logView(PATHS_NAME[location.pathname], getContextLog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    actions.sendLogging({
      event: 'pageVisited',
      warning: false,
      data: {
        page: 'trackNewFunnel',
      },
    })

    window.onload = function () {
      const loadTime =
        window.performance.timing.domContentLoadedEventEnd -
        window.performance.timing.navigationStart

      actions.sendLogging({
        event: 'loadTime',
        warning: false,
        data: {
          loadTime,
        },
      })
    }

    window.onunload = () => {
      actions.sendLogging({
        event: 'funnelExit',
        warning: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const {
      coupon,
      diet,
      plan,
      zipcode,
      email,
      meal,
      exp: experimentParameter,
      planSize,
      meals,
    } = qs.parse(location.search.slice(1))

    if (!flagsReady && !flagsError) return

    const UTMQuery = getCookie('UTMQuery')

    const dataStart = {
      zipcode: sanitizeZipCode(zipcode),
      coupon,
      userCoupon: coupon,
      diet,
      exp: experimentParameter,
      plan,
      utm: UTMQuery,
      email,
      sharedMealId: parseInt(meal),
      planSize,
      searchMealsIds: meals && meals.split(','),
      onlyEconomicPlans: isEconomicPlanFlowEnabled,
      allowOverbook,
    }

    if (isMultiWeekPromotionEnabled && coupon?.toUpperCase() === COUPON_SIX_WEEK_PROMO) {
      const userPlan = userSelectedData?.selectedPlan?.mealsPerDelivery || DEFAULT_MEAL_PLAN;
      const couponString = getCouponsPerPlanByCouponCode({ couponCode: coupon, storeId: signupInitData?.ring?.id})[userPlan] ?? coupon;
      dataStart.coupon = couponString
    }

    actions.initDataStart(dataStart)

    actions.sendLogging({
      event: 'initDataStart',
      warning: false,
      data: dataStart,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagsReady, flagsError])

  // REPLACEMENT OF FIRST RENDER IN RENDER METHOD
  useEffect(() => {
    if (
      !isLoading &&
      location.pathname === PATHS[pageNames.MEALS]
    ) {
      const { zipcode, email } = qs.parse(location.search.slice(1))

      const utmValues = getUTMValues()

      actions.sendTracking({
        eventName: 'Sign Up - Sign Up Started',
        eventData: {
          user_email: email || null,
          user_zipcode: zipcode,
          user_ring_id: signupInitData?.ring.id,
          user_address_region: signupInitData?.store?.name,
          store_id: signupInitData?.store?.id,
          user_utm_campaign: utmValues.utm_campaign,
          user_utm_source: utmValues.utm_source,
          user_utm_medium: utmValues.utm_medium,
          user_utm_content: utmValues.utm_content,
          promotion_code_description: couponData.couponCode,
          landing_page: getLPCookie(),
        },
      })
    }

    if (!isLoading && location.pathname === '/') {
      const { selectedPlan, searchMealsIds } = userSelectedData
      const mealsPerDelivery = selectedPlan && selectedPlan.mealsPerDelivery

      if (
        !!mealsPerDelivery &&
        Array.isArray(searchMealsIds) &&
        searchMealsIds.length > 0
      ) {
        actions.setLoading(true)

        const autoSelectedDate = autoSelectDeliveryDate(validDates)

        actions.setUserSelectedDataStart({
          startDay: autoSelectedDate,
          days: [autoSelectedDate.day],
        })

        actions.persistState({ sessionId: SESSION_KEY })

        const search = removeUrlParameter(location.search, [
          'planSize',
          'meals',
          'noSkip',
        ])

        loginWithRedirect({
          action: 'signup',
          redirectUri: `${window.location.origin}${PATHS[pageNames.MEALS]}${search}`,
          search: `${search}`,
          env: process.env.NODE_ENV,
          exp: 'treatment-1',
          rebexp: 'TREATMENT',
        })

        return
      }

      stepHandler(PATHS[pageNames.MEALS])
    }

    if (createUserSuccess) {
      navigate(
        `${PATHS[pageNames.THANK_YOU]}${location.search}&email=${
          createUserData.email
        }`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, location.pathname, createUserSuccess])

  const getContextLog = (viewName = '') => {
    let previousPath;
    if (location.pathname !== currentPath) {
      previousPath = currentPath;
      setCurrentPath(location.pathname);
    }

    const paramsObj = getAllSearchParams();

    return {
      userId: user?.magento_id ||
        (authUser && getCustomAttributeValue(authUser, 'magento_id')) ||
        undefined,
      subscriberId: user?.id ||
        (authUser && getCustomAttributeValue(authUser, 'subscription_id')) || undefined,
      ringId: ring?.storeId || previousStoreRingInformation?.id,
      storeId: ring?.storeId || previousStoreRingInformation?.storeId,
      isLocal: ring?.isLocal || previousStoreRingInformation?.isLocal,
      planId: selectedPlan?.id,
      coupon: coupon?.couponCode,
      email: authUser?.email || paramsObj?.email,
      zipCode: zipCode || paramsObj?.zipcode,
      params: paramsObj,
      viewName: `${PATHS_NAME[location.pathname]} ${viewName}`.trim(),
      currentPath: location.pathname,
      previousPath
    };
  }

  const stepHandler = stepKey => {
    actions.persistState({ sessionId: SESSION_KEY })
    navigate(`${stepKey}${location.search}`)
  }

  const onConfirmDeliveryDay = stepKey => {
    stepHandler(stepKey)

    actions.sendTracking({
      eventName: 'Sign Up - Delivery Day Selected',
      eventData: {
        first_delivery_date: userSelectedData?.startDay?.date,
        //autoSelectedDate is defined in plan selection step and is not available here
        assigned_delivery_date: deliveryData?.autoSelectedDate?.date,
        assigned_delivery_day: deliveryData?.autoSelectedDate?.day,
        user_delivery_day: userSelectedData?.startDay?.day,
        delivery_date_options: deliveryData?.validDates?.map(
          validDate => validDate.date,
        ),
      },
    })
  }

  useEffect(() => {
    if (isLoading) return
    try {
      if (userSelectedData?.selectedPlan) {
        const isValidPlan = signupInitData?.mealPlans?.find(
          plan => plan.id === userSelectedData?.selectedPlan?.id,
        )

        if (!isValidPlan) {
          actions.setUserSelectedDataStart({ selectedPlan: null })
        }
      }
    } catch (error) {
      logError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, signupInitData?.mealPlans])

  const handleLogViewMealStep = value => {
    if (value) {
      logView(`${PATHS_NAME[location.pathname]} Quiz`, getContextLog('Quiz'));
    } else {
      logView(PATHS_NAME[location.pathname], getContextLog());
    }
  }

  if (initDataError) {
    const errorPage = 'Generic error'
    logView(errorPage, getContextLog());
    logError(`Error: ${errorPage}`, getContextLog());

    return <FullScreenError />
  }

  return (
    <SignUpScreen
      isLoading={isLoading}
      breadPathData={breadPathData}
      seoData={seoData}
      showTopBar={showTopBar}
      pathname={location.pathname}
      setBreadPathStart={actions.setBreadPathStart}
    >
      <Routes>
        <Route
          path={PATHS[pageNames.PLANS]}
          element={<SelectPlanPage govx={govx} />}
        />

        <Route
          path={PATHS[pageNames.DELIVERY]}
          element={<DeliverySettingPage stepHandler={onConfirmDeliveryDay} />}
        />

        <Route
          path={PATHS[pageNames.MEALS]}
          element={<MealsStep stepHandler={stepHandler} logView={handleLogViewMealStep} />}
        />

        <Route
          path={PATHS[pageNames.CHECKOUT]}
          element={<CheckoutPage stepHandler={stepHandler} />}
        />
      </Routes>
    </SignUpScreen>
  )
}

const mapStateToProps = state => ({
  isLoading: signUpCommonSelectors.getSignUpInitForceLoading(state),
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
  breadPathData: signUpCommonSelectors.getBreadPathData(state),
  seoData: signUpCommonSelectors.getSeoData(state),
  deliveryData: getDeliveryData(state),
  initDataError: getSignUpInitDataError(state),
  signupInitData: getSignUpInitData(state),
  couponData: signUpCommonSelectors.getCoupon(state),
  createUserSuccess: signUpCommonSelectors.getCreateUserSuccess(state),
  createUserData: signUpCommonSelectors.getCreateUserData(state),

  showTopBar: signUpCommonSelectors.getShowTopBar(state),
  validDates: signUpCommonSelectors.getDeliveryDays(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initDataStart: signUpCommonActionCreators.initDataStart,
      setBreadPathStart: signUpCommonActionCreators.setBreadPathStart,
      sendLogging: creatorCommons.loggingStart,
      persistState: signUpCommonActionCreators.persistState,
      sendTracking: creatorCommons.trackingStart,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
      setLoading: signUpCommonActionCreators.setForceLoading,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPageContainer)
