import { createSelector } from 'reselect'

/* CHECK_VALID_ZIP_CODE */
const checkValidZipState = state => state.get('commons').get('zipcode')

export const checkValidZipCodeLoading = createSelector(
  checkValidZipState,
  state => state.get('start'),
)

export const checkValidZipCode = createSelector(checkValidZipState, state =>
  state.get('isValid'),
)

export const getValidZipCodeError = createSelector(checkValidZipState, state =>
  state.get('error'),
)

export const getEmail = createSelector(checkValidZipState, state =>
  state.get('email'),
)

export const getZipCode = createSelector(checkValidZipState, state =>
  state.get('data'),
)
