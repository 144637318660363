import { CuiImage } from "front-lib"
import { useSelector } from "react-redux"
import { cardSelector } from "src/features/payment/paymentSlice"


const CreditCard = ({cardData}) => (
    <div>
        <p>
        <b>Credit Card</b> <br />
        <b>•••• •••• •••• {cardData.last4}</b>
        </p>
    </div>
)
const PayPal = () => (
    <div className="paypal">
        <b>Payment Method</b>
        <CuiImage src='src/assets/img/paypalLogoFull.png' alt="PayPal" />
    </ div>
)

const PaymentMethod = () => {
    const card = useSelector(cardSelector)

    return (
        <>
            {card?.provider === 'stripe' && <CreditCard cardData={card} />}
            {card?.provider === 'paypal' && <PayPal />}
        </>
    )
}

export default PaymentMethod