import React from 'react'
import Numeral from 'numeral'
import classnames from 'classnames'

import styles from './MealCard.module.scss'
import images from './images'
import { hasProductDiscount } from 'src/utils/promotionUtils'

export const getProteinTag = proteinType => {
  if (!proteinType) return

  const proteinsMap = {
    glutenfree: { icon: '', label: 'Gluten Free' },
    keto: { icon: '', label: 'Keto Diet' },
    meat: { icon: '', label: 'Meat' },
    paleo: { icon: '', label: 'Paleo' },
    poultry: { icon: '', label: 'Poultry' },
    seafood: { icon: '', label: 'Seafood' },
    vegan: { icon: images.veganIcon, label: 'Vegan' },
    vegetarian: { icon: images.vegetarianIcon, label: 'Vegetarian' }
  }
  return proteinsMap[proteinType.toLowerCase()]
}

export const formatFee = (premium_fee, fixed_price) =>
  `${!fixed_price ? '+' : ''} ${Numeral(premium_fee).format('$0,0.00')}`

export const formatChefName = (firstName, lastName) =>
  `${firstName} ${lastName}`

export const formatMealRating = stars => stars && Numeral(stars).format('0.0')

export const formatMealReviews = reviews =>
  reviews && (reviews > 999 ? '999+' : `${reviews}`)

export const formatFeature = (feature = null) => {
  if (feature && feature.description)
    feature.description = feature.description.toUpperCase()
  return feature || {}
}

export const findSpecificationDetail = (details, tag) =>
  Array.isArray(details) &&
  details.find(
    d =>
      d &&
      typeof d.label === 'string' &&
      d.label.toLowerCase() === tag.toLowerCase()
  )


// New util for MealCards to get the meal type after the refactor, move to meal utils
export const getMealCategory = meal => {
  const MEAT_TYPES = [
    'Beef',
    'Chicken',
    'Other fish',
    'Other meat',
    'Pork',
    'Salmon',
    'Seafood',
    'Vegan',
    'Vegetarian',
    'Poultry',
    'Sea food',
    'Meat',
    'Fish',
  ]

  const specificationsDetail = meal?.specifications_detail || meal?.specificationsDetails || []
  const meatType = meal?.meat_type || meal?.meatType

  const meatTag = specificationsDetail
    .filter(tag => tag && tag.label.trim())
    .find(tag => MEAT_TYPES.indexOf(tag) !== -1)

  const meatLabel =
    meatType || (typeof meatTag === 'undefined' ? meatTag : null)

  // [TODO] this is a similar hardcoded list that is in acquisition-web/src/redux/ducks/modules/MenuList/utils/index.js
  const meats = ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork', 'Meat']
  const seafood = [
    'Catfish',
    'Cod',
    'Crab',
    'Other fish',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'fish',
    'Sea food',
    'Fish',
  ]
  const poultry = ['Chicken', 'Turkey', 'Poultry']
  const vegan = ['Vegan']
  const vegetarian = ['Vegetarian']

  if (meats.includes(meatLabel)) {
    return 'meat'
  } else if (seafood.includes(meatLabel)) {
    return 'seafood'
  } else if (poultry.includes(meatLabel)) {
    return 'poultry'
  } else if (vegan.includes(meatLabel)) {
    return 'vegan'
  } else if (vegetarian.includes(meatLabel)) {
    return 'vegetarian'
  }

  return null
}

export const getLoadingContainer = () => (
  <div
    className={classnames(styles.meal_card, styles.loading)}
    data-testid="loading-container"
  >
    <div className={classnames(styles.meal_image_container, styles.loading)}>
      <div className={classnames(styles.meal_image, styles.loading)} />
    </div>
    <div className={styles.bottom_info}>
      <div className={styles.meal_name}>
        <div className={classnames(styles.title, styles.loading)} />
        <div className={classnames(styles.subtitle, styles.loading)} />
      </div>
    </div>
  </div>
)

export const getPromotionTag = product => {
  if (!hasProductDiscount(product)) return

  return {
    label: `+ $${product?.promotions[0]?.amount?.value} OFF`,
    color: 'blue',
  }
}
