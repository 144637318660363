import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { trackingStart } from 'src/redux/ducks/commons/creators'
import { getProductDetail } from 'src/redux/ducks/modules/ProductDetail/selectors'
import { getSignUpInitData, getUserSelectedData } from 'src/redux/ducks/screens/signup/commons/selectors'
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'

import 'front-lib/dist/index.css'
import { ProductPage } from 'front-lib'
import { bindActionCreators } from 'redux'
import { getCart } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import { isMobile } from 'src/utils/events'

const ProductDetail = ({
  product,
  addProduct,
  removeProduct,
  totalOrderedProducts,
  maxProductToCart,
  resetProductDetail,
  cartData,
  userStartDay,
  storeData,
  actions,
  menuFilters
}) => {
  const [productDetail, setProductDetail] = useState(null);

  useEffect(() => {
    if (product) {
      const url = `${window.location.origin}/meals/${product.id}`;
      setProductDetail({
        ...product,
        url,
      })
    }
  }, [product])

  const getOrderedMealQty = product => {
    if(!product) return 0
    const selectedProducts = cartData.products
    const productId = product.entity_id || product.mealExternalId
    if (selectedProducts && productId) {
      const product =
        selectedProducts.length > 0
          ? selectedProducts.find(p => p.id === productId)
          : null

      return product ? product.qty : 0
    }
    return 0
  }

  const resetProduct = () => {
    resetProductDetail()
  }

  const handleAddItem = () => {
    addProduct(productDetail)

    const filterValue =
      menuFilters?.meatTypeFilter ||
      menuFilters?.specificationsDetailFilter ||
      'all'
    const filterType = menuFilters?.meatTypeFilter ? 'meatType' : 'specificationsDetail'

    const filtersApplied = [{
      filter_group: 'All',
      filter_type: filterType,
      filter_value: filterValue
    }]

    if (menuFilters?.searchInput) {
      filtersApplied.push({
        filter_group: '',
        filter_type: 'Search',
        filter_value: menuFilters.searchInput,
      })
    }

    sendTracking(product, 'Product Added', {
      cart_quantity_added: 1,
      cart_product_quantity: getOrderedMealQty(productDetail) + 1,
      cart_total_quantity: cartData.totalProductQty + 1,
      product_add_source: 'Meals sets product detail',
      filters_applied: filtersApplied
    });

  }

  const handleRemoveItem = () => {
    removeProduct(productDetail)
    sendTracking(productDetail, 'Product Removed', {
      cart_quantity_removed: 1,
      cart_product_quantity: getOrderedMealQty(productDetail) - 1,
      cart_total_quantity: cartData.totalProductQty - 1,
      product_remove_source: 'Meals sets product detail',
    });

  }

  const sendTracking = (meal, eventName, params = {}) => {
    const stock = meal.stock ? meal.stock : 0;
    const specificationsDetails = meal.specificationsDetails || meal.specifications_detail || []
    const chefName = `${meal.chef_firstname || meal?.chef?.firstName || ''} ${meal.chef_lastname || meal?.chef?.lastName || ''}`
    const premiumFee = meal.premium_fee || meal.premiumFee || 0
    const ingredients = meal.ingredients_data || meal.ingredients || []
    const nutritionalFacts = meal.nutritional_facts || meal.nutritionalFacts || {}
    const trackEvent = {
      eventName,
      eventData: {
        menu_store: storeData.name,
        delivery_date: userStartDay.date,
        list_name: 'PreOrder Product Detail',
        list_type: 'Meals Selection',
        product: {
          id: meal.entity_id || meal.mealExternalId,
          chef_name: chefName,
          category: meal.category_id || meal.categoryId,
          heat_instructions_microwave: meal.cooking_steps?.microwave_steps || meal.cookingSteps?.microwaveSteps,
          heat_instructions_oven: meal.cooking_steps?.oven_steps || meal.cookingSteps?.ovenSteps,
          image_url: meal.image_full_url || meal.primaryImageUrl,
          ingredients: ingredients.map(specification => specification.name).join(', '),
          name: meal.name,
          nutritional_info: null,
          price: meal.price,
          rating: meal.stars,
          rating_count: meal.reviews,
          ratio_carb: nutritionalFacts.carbs,
          ratio_fat: nutritionalFacts.fat,
          ratio_protein: nutritionalFacts.protein,
          sku: meal.sku,
          specification: specificationsDetails.map(specification => specification.label).join(', '),
          premium: premiumFee > 0,
        },
        product_initial_capacity: stock,
        product_remaining_capacity: stock - params.cart_product_quantity,
        screen_name: isMobile() ? 'Meals Selection product detail' : null,
        page_name: isMobile() ? null : 'Meals Selection product detail',
        ...params
      }
    };

    actions.sendTracking(trackEvent)
  }


  const ordered = getOrderedMealQty(productDetail)
  const total = maxProductToCart - (totalOrderedProducts - ordered)

  if (!productDetail) return null
  return (
    <ProductPage
      openInModal={true}
      productData={productDetail}
      isLoading={productDetail === null}
      onClose={() => resetProduct()}
      isOrdering={{
        ordered,
        total,
        onAdd: () => handleAddItem(),
        onRemove: () => handleRemoveItem(),
      }}
      withDetails={false}
    />
  )
}

const mapStateToProps = state => ({
  cartData: getCart(state),
  product: getProductDetail(state),
  storeData: getSignUpInitData(state).store,
  userStartDay: getUserSelectedData(state).startDay,
  menuFilters: getMenuFilter(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: trackingStart,
    },
    dispatch,
  ),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
