import { CuiIcon, CuiImage, CuiTag, fullImagePath } from 'front-lib'
import { findSpecificationDetail, formatMealRating, formatMealReviews, getMealCategory, getProteinTag } from 'src/components/MealCardPremiumFee/utils'
import './MealTags.scss'



const MealTags = ({ meal }) => {
    const { reviews, stars, nutritionalFacts, specificationsDetails } = meal
    const { calories } = nutritionalFacts
    const mealReviews = formatMealReviews(reviews)
    const mealRating = formatMealRating(stars)
    const proteinTag = getProteinTag(getMealCategory(meal))
    const isSpicy = findSpecificationDetail(specificationsDetails, 'spicy')

    return (
        <div className="cui-meal-card-tags">
            {
                parseInt(mealReviews) > 0 && parseInt(mealRating) > 0 && (
                    <CuiTag color="white" fill="solid" size="small" type="tag">
                        <CuiIcon name="starFull" />
                        {mealRating} <span className="reviews">({mealReviews})</span>
                    </CuiTag>
                )
            }
            <CuiTag
                color="white"
                fill="solid"
                size="small"
                type="tag"
            >
                {calories} cal
            </CuiTag>
            {proteinTag && proteinTag.icon && (
                <CuiTag className='cui-meal-tag-icon' color="white" fill="solid" size="small" type="tag">
                    <CuiImage src={proteinTag.icon} alt={proteinTag.label} />
                    <div className='tooltip'>{`${proteinTag.label}`}</div>
                </CuiTag>
            )}

            {isSpicy && (
                <CuiTag className='cui-meal-tag-icon' color="white" fill="solid" size="small" type="tag">
                    <CuiImage src={fullImagePath('icon-spicy.png')} alt={isSpicy.label} />
                    <div className='tooltip'>{`${isSpicy.label}`}</div>
                </CuiTag>
            )}
        </div>
    )
}

export default MealTags