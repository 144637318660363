const ringByZipcodeQuery = `
ring (zipcode: $zipcode) {
  id,
  is_local,
  packaging,
  store_id
}
`

export const signupData = `
query initSignup($zipcode: String, $couponCode: String) {
    timeslots (zipcode: $zipcode) {
        start
        end
        label
    }
    upcomingDays (zipcode: $zipcode, weeks: 3, couponCode: $couponCode) {
      available
      date
      displayDate
      cutoff {
        time
        utcOffset
        userTimeZone
        tzShortName
      }
    }

    deliveryDays (zipcode: $zipcode) {
      deliveryDays
    }

    stores {
      id
      name
      shortName
      taxrate
      timezone
      latitude
      longitude
    }
    ${ringByZipcodeQuery}
}`

export const mealPlans = `
  query mealPlans($zipCode: String, $couponCode: String, $onlyEconomicPlans: Boolean) {
    plans: availablePlans(zipcode: $zipCode, couponCode: $couponCode, onlyEconomicPlans: $onlyEconomicPlans) {
      id
      title
      price
      mealsPerDelivery
      deliveries
      deliveryFee
      segmentId
    }
  }
`

export const ringByZipcode = `
query ring($zipcode: String) {
  ${ringByZipcodeQuery}
}
`

export const sampleMenu = `
query sampleMenu {
  sampleMenu {
          entity_id
          premium_fee
          name
          short_description
          calories
          image
          image_path
          price
          sku
          category_id
          chef_id
          chef_firstname
          chef_lastname
          logopic
          bannerpic
          stock
          inStock
          meat_type
          stars
          reviews
          is_celebrity_chef
          sidedish {
            id
          }
          specifications_detail {
            label
          }
    }
  }
`

export const publicAddAddress = `
  mutation publicAddAddress($email: String!, $password: String!, $addressInfo: ChangeUserAddressInput!) {
    publicAddAddress(email: $email, password: $password, addressInfo: $addressInfo){
      ... on AddressType {
            id
            city
            isActive,
            country,
            region,
            regionId,
            postcode,
            telephone,
            street
        }
      ... on userAddressChangeErrorType {
          error
      }
    }
  }
`

export const checkCoupon = `
query coupon ($coupon: String!){
  coupon(coupon: $coupon) {
    source
    expiry
    promotion {
      id
      title
      promotionType
      discount {
        rate
        duration
        type
        typeOfUse
      }
      credit {
        amount
      }
      compoundPromotions {
        orderCount
        rate
        type
      }
    }
    restrictions {
      notAvailablePlans
      notAvailableDays {
        store
        days
      }
    }
    isEligible {
      isEligible
      message
      type
    }
    productDiscounts {
      withProductDiscounts
    }
  }
}
`

export const setSetting = `
  mutation setSetting($key: String!, $value: String!) {
    setSetting(key: $key, value: $value) {
        ... on SetSetting {
            key
            value
        }
        ... on GenericError {
            error
        }
    }
  }
`

export const getQuizData = `
query {
  categoryTaste {
      id
      name
      image
      tastes {
          id
          name
          magento_id
      }
  }
  restrictionsForSignUp {
    id
    name
  }
  goals {
    id
    name
    image
    useInSignUp
  }
  diets {
    id
    name
    image
    label
  }
  dietaryPreferences {
    id
    label
    name
    inverse
  }
}
`

export const signUpMenu = `
query signUpMenu(
  $date: String!,
  $zipcode: String!,
  $tastes: [tasteInputType],
  $ingredientsAvoid: [ingredientAvoidInputType],
  $goals: [goalsInputType],
  ) {
  signUpMenu(
      date: $date,
      zipcode: $zipcode,
      tastes: $tastes,
      ingredientsAvoid: $ingredientsAvoid,
      goals: $goals,
      ){
          batch_id
          entity_id
          premium_fee
          name
          short_description
          calories
          image
          image_path
          price
          sku
          category_id
          chef_id
          chef_firstname
          chef_lastname
          feature {
            name
            description
            icon
            color
            background
          }
          bannerpic
          stock
          inStock
          meat_type
          stars
          reviews
          weight
          warning
          is_celebrity_chef
          sidedish {
              id
              __typename
          }
          specifications_detail {
              label
              __typename
          },
          __typename
  }
}
`

export const publicAddPaypal = `
mutation publicAddPaypal($email: String!, $password: String!, $token: String!, $device: String!) {
  publicAddPaypal(email: $email, password: $password, token: $token, device: $device) {
    ... on PaypalCustomer {
      id
      __typename
    }
    ... on PaymentError {
      error
    }
    __typename
  }
}
`

const mealFields = `
      batchId
      inventoryId
      id
      name
      shortDescription
      image
      imagePath
      price
      isPremium
      primaryImageUrl
      premiumFee
      premiumSpecial
      isIncludedInEconomicPlan
      sku
      stock
      reviews
      stars
      warning
      categoryId
      category {
        id
      }
      chef {
        id
        firstName
        lastName
        bannerPic
        isCelebrityChef
      }
      ingredients {
        name
      }
      meatType
      nutritionalFacts {
        calories
        carbs
        fat
        protein
      }
      specificationsDetails {
        label
      }
      characteristics {
          id
          label
          name
      }
      sideDish {
        id
        name
      }
      weight
      filter_by
      cuisines
      feature {
        background
        color
        description
        icon
        name
      }
      promotions {
          type,
          amount {
              value,
              type
          }
      }`

export const menu = `
      query getMenu($date: String!, $zipCode: String!, $filters: MenuFilters, $coupon: String) {
        menu(date: $date, zipCode: $zipCode, filters: $filters, coupon: $coupon) {
          meals {
            ${mealFields}
          }
        }
      }
      `

export const menuWithAllergens = `
      query getMenu($date: String!, $zipCode: String!, $filters: MenuFilters, $coupon: String) {
        menu(date: $date, zipCode: $zipCode, filters: $filters , coupon: $coupon) {
          meals {
            ${mealFields}
            allergens {
              id
              label
              name
            }
          }
        }
      }
      `

export const menuSortBy = `
          query getMenu($date: String!, $sortBy: MenuSortBy, $zipCode: String!, $filters: MenuFilters) {
            menu(date: $date, sortBy: $sortBy, zipCode: $zipCode, filters: $filters) {
              meals {
                ${mealFields}
              }
            }
          }`

export const getTaxRateFromZipCode = `
query getTaxRateFromZipCode($zipcode: Int) {
  taxesAmount: taxrateFromZipcode(zipcode: $zipcode)
}
`
export const validateZipCode = `
query validateZipCode($zipCode: String) {
  zipcodeValidation(zipcode: $zipCode)
}
`
export const validateGiftcard = `
  query redeemGiftCard(
    $giftCardCode: String!,
  ) {
    response: RedeemGiftCard(
      giftCardCode: $giftCardCode,
    ) {
      validated
      amount
    }
  }`
