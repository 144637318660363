function track(eventName, data = null) {
  if (window && window.analytics) window.analytics.track(eventName, data)
}

function trackRealLoad(eventName = null, trackingData = {}, timeout = 500) {
  if (!eventName) return false

  var checkGTM = setInterval(() => {
    if (window.google_tag_manager) {
      clearInterval(checkGTM)
      var dataLayer = window.dataLayer || []
      dataLayer.push({
        event: eventName,
        ...trackingData,
      })
    }
  }, timeout)
}

const analytics = {
  track,
  trackRealLoad,
}

export default analytics
