import * as qs from 'qs'
import { logError } from 'src/utils/logError'

export const formatQuestionsMap = (
  preferencesQuizData = {},
  questions = [],
  simplifiedDietsQuizExperiment = false,
  eatEverythingAtTopOfQuizExperiment = false,
) => {
  let questionsMapped = []

  questionsMapped = questions.map(question => {
    if (question.name === 'goals') {
      return {
        ...question,
        options: [
          ...preferencesQuizData.goals.map(goal => ({
            ...goal,
            label: goal.name,
            value: goal.id,
          })),
          ...question.options,
        ],
      }
    } else if (question.name === 'specificationsAvoid') {
      return {
        ...question,
        options: question.options
          .map(option => {
            const dp = preferencesQuizData.dietaryPreferences.find(
              dp => dp.label === option.label,
            )
            return dp ? { ...option, id: dp.id, inverse: dp.inverse } : option
          })
          .map(allergen => ({
            ...allergen,
            label: allergen.label,
            value: allergen.id,
          })),
      }
    } else if (question.name === 'allergens') {
      return {
        ...question,
        options: (preferencesQuizData.allergens || question.options).map(
          preference => ({
            ...preference,
            label: preference.label,
            value: preference.id,
          }),
        ),
      }
    } else if (question.name === 'proteins') {
      return {
        ...question,
        options: preferencesQuizData.categoryTaste.map(category => {
          if (category.name === 'Meat') {
            category = { ...category, name: 'Beef' }
          }
          return {
            ...category,
            label: category.name,
            value: category.id,
          }
        }),
      }
    } else if (question.name === 'diets') {
      if (!preferencesQuizData.diets.find(diet => diet.id === -2)) {
        const eatEverythingOption = {
          id: -2,
          name: 'None',
          label: 'I eat everything!',
        }
        if (eatEverythingAtTopOfQuizExperiment) {
          preferencesQuizData.diets.unshift(eatEverythingOption)
        } else {
          preferencesQuizData.diets.push(eatEverythingOption)
        }
      }

      const dietsToRemove = simplifiedDietsQuizExperiment
        ? ['Paleo', 'Keto Diet', 'Mediterranean Diet']
        : []

      const diets = preferencesQuizData.diets.filter(
        diet => !dietsToRemove.includes(diet.name),
      )

      return {
        ...question,
        options: diets.map(diet => ({
          ...diet,
          label: diet.label,
          value: diet.id,
        })),
      }
    } else {
      return {
        ...question,
      }
    }
  })

  return questionsMapped
}

const getQuestionsMaps = (questions, key) =>
  questions
    .find(val => val.name === key)
    ?.options.reduce((acc, opt) => {
      const optName = opt.name.toLowerCase().replaceAll(' ', '_')
      acc[optName] = opt.value
      return acc
    }, {}) ?? {}

export const formatResponseByQueryString = (queryString, questions) => {
  let response = null
  try {
    const queries = qs.parse(queryString, { ignoreQueryPrefix: true })
    const mappingAnswers = {
      goals: {
        'gain muscle': 1,
        'lose weight': 2,
        'overall wellness': 3,
        'eat lighter meals': 8,
        'just eating delicious food!': null,
      },
      diets: getQuestionsMaps(questions, 'diets'),
      proteins: getQuestionsMaps(questions, 'proteins'),
      allergens: getQuestionsMaps(questions, 'allergens'),
      specificationsAvoid: getQuestionsMaps(questions, 'allergens'),
    }

    for (let [query, value] of Object.entries(queries)) {
      const name = query.toLocaleLowerCase()
      const answerString = value.toLocaleLowerCase().replaceAll(' ', '_')
      if (Object.keys(mappingAnswers).includes(name)) {
        let count = 0
        const question = questions.find(val => val.name === name)
        const answers = answerString.split(',')
        for (let answer of answers) {
          if (answer && count < question.max) {
            count++
            answer = answer.trim().toLocaleLowerCase()
            response = response || {}
            response[name] = response[name] || []
            const option = question.options.filter(
              option => option.id === mappingAnswers[name][answer],
            )
            response[name] = [...response[name], ...option]
          }
        }
      }
    }
  } catch (err) {
    logError(err)
  }

  return response
}

export const reduceQuestionsToPreferences = (
  questions,
  preferencesQuizResponses,
) =>
  questions.reduce((preferences, question, index) => {
    if (!preferences[question.name]) {
      preferences[question.name] = {
        question_number: index + 1,
        question_title: question.title,
        preference_description: question.name,
        preferences_selected: Array.isArray(
          preferencesQuizResponses[question.name],
        )
          ? preferencesQuizResponses[question.name].map(
              preference => preference.name,
            )
          : [],
      }
    }

    return preferences
  }, {})
