export const doormanOptions = [
  { value: 'In my lobby', label: 'In my lobby' },
  { value: 'Front door of my house / apartment', label: 'Front door of my house / apartment' },
  { value: 'Hand it to me', label: 'Hand it to me' }
];

export const days = [0, 1, 2, 3, 4, 5, 6]
export const dayNames = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

export const pageNames = {
  PLANS: 'Sign Up - Plan Selection',
  DELIVERY: 'Sign Up - Delivery Date Selection',
  QUIZ: 'Sign Up - Preference Selection',
  MEALS: 'Sign Up - Meal Selection',
  CHECKOUT: 'Sign Up - Checkout',
  THANK_YOU: 'Sign Up - Thank you',
}

export const PATHS = {
  [pageNames.PLANS]: '/eat-everything/page-1',
  [pageNames.DELIVERY]: '/eat-everything/page-2',
  [pageNames.QUIZ]: '/quiz',
  [pageNames.MEALS]: '/eat-everything/page-3',
  [pageNames.CHECKOUT]: '/eat-everything/page-4',
  [pageNames.THANK_YOU]: '/thankyou',
}

export const WElCOME_PAGE = PATHS[pageNames.WELCOME]

export const promotionTypes = {
  PERCENTAGE: 'percent',
  FIXED_AMOUNT: 'fixed-amount',
}
