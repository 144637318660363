import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deliveryDaysSelector,
  deliveryDaySelectedSelector,
  fetchDeliveryDays,
  setDeliveryDaySelected,
  setConfirmationNeeded,
  setUnconfirmedDeliveryDay,
} from './deliverySlice'
import ToggleButtonGroup from '../../components/ToggleButtonGroup/ToggleButtonGroup'
import ToggleButton from '../../components/ToggleButton/ToggleButton'
import { CuiIcon } from 'front-lib'
import { zipCodeSelector } from '../address/addressSlice'
import './DeliveryDaySelect.scss'
import { mealsSelectedSelector } from '../meals/mealsSlice'
import useTrackEvent from 'src/hooks/useTrackEvent'

const DeliveryDaySelect = () => {
  const dispatch = useDispatch()
  const deliveryDays = useSelector(deliveryDaysSelector)
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const zipCode = useSelector(zipCodeSelector)
  const mealsSelected = useSelector(mealsSelectedSelector)

  const trackChangeDateSelected = useTrackEvent({
    eventName: 'Change delivery date clicked',
  })

  useEffect(() => {
    if (!deliveryDays) {
      dispatch(fetchDeliveryDays({ zipCode }))
    }
  })

  const handleChange = (e, value) => {

    const deliveryDay = deliveryDays.find(
      deliveryDayObj => deliveryDayObj.label === value,
    )

    trackChangeDateSelected({
      delivery_day: {
        label: deliveryDay?.label,
        date: deliveryDay?.date,
        day: deliveryDay?.day,
        cutoff: deliveryDay?.cutoff?.time,
      },
    })

    if (mealsSelected.length) {
      dispatch(setUnconfirmedDeliveryDay(deliveryDay))
      dispatch(setConfirmationNeeded(true))
    } else {
      dispatch(setDeliveryDaySelected(deliveryDay))
    }
  }

  return (
    <ToggleButtonGroup
      value={deliveryDaySelected?.label}
      onChange={handleChange}
      className="delivery-day-select"
      orientation="column"
      fullWidth
    >
      {deliveryDays?.map(deliveryDay => {
        const [day, date] = deliveryDay.label.split(',')

        return (
          <ToggleButton
            key={date}
            value={deliveryDay.label}
            disabled={deliveryDay.soldout}
            className="delivery-day-select__option"
          >
            <div className="delivery-day-select__option__content">
              <span className="delivery-day-select__option__content--day">
                {day},
              </span>
              <span className="delivery-day-select__option__content--date">
                {date}
              </span>
            </div>
            {!deliveryDay.soldout && deliveryDay.recommended && (
              <div className="delivery-day-select__option__label">
                <CuiIcon name="star" />
                <span className="delivery-day-select__option__label--text">
                  Recommended
                </span>
              </div>
            )}
            {deliveryDay.soldout && (
              <div className="delivery-day-select__option__label">
                <CuiIcon name="alertTriangle" />
                <span className="delivery-day-select__option__label--text">
                  Sold out
                </span>
              </div>
            )}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

export default DeliveryDaySelect
