import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as creatorCommons from '../../../../redux/ducks/commons/creators'

import PreOrder from './components/PreOrder'
import PreferencesQuiz from './components/PreferencesQuiz'

import { useLocation, useNavigate } from 'react-router-dom'
import { PATHS, pageNames } from '../../constants'
import { SESSION_KEY } from 'src/constants'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'

const MealsStep = props => {
  const { actions, searchMealsIds, userSelectedData, logView } = props
  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    actions.sendIdentify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isTreatment: quizHoldoutIsTreatment } = useExperiment(
    EXPERIMENTS.quizHoldout,
    {
      trackOnMount: true,
    },
  )

  const defaultShowQuizState =
    Array.isArray(searchMealsIds) && searchMealsIds.length > 0 ? false : true

  const [showQuiz, setShowQuiz] = useState(defaultShowQuizState)

  const handleSetShowQuiz = useCallback(
    val => {
      actions.setForcedSkipQuiz(!val)
      actions.persistState({ sessionId: SESSION_KEY })

      if (!userSelectedData.selectedPlan) {
        navigate(PATHS[pageNames.PLANS] + search)
      } else {
        setShowQuiz(val)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSelectedData.selectedPlan],
  )

  const shouldShowQuiz = !props.forcedSkipQuiz && showQuiz && !quizHoldoutIsTreatment


  useEffect(() => {
    if (logView) {
      logView(shouldShowQuiz)
    }
  // We don't want to run this effect when logView changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowQuiz])

  const Component = useMemo(
    () => {
      return shouldShowQuiz ? PreferencesQuiz : PreOrder
    },
    [shouldShowQuiz],
  )

  if (!shouldShowQuiz && !userSelectedData.selectedPlan?.mealsPerDelivery) {
    navigate(PATHS[pageNames.PLANS] + search)
    return
  }

  return <Component {...props} setShowQuiz={handleSetShowQuiz} />
}

const mapStateToProps = state => ({
  forcedSkipQuiz: signUpCommonSelectors.getForcedSkipQuizStateData(state),
  searchMealsIds: signUpCommonSelectors.getSearchMealsIds(state),
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
      persistState: signUpCommonActionCreators.persistState,
      sendIdentify: creatorCommons.identifyStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MealsStep)
