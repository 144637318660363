import Step from './components/Step'

import './Stepper.scss'

const Stepper = ({ steps, className = '' }) => {
  return (
    <div className={`stepper ${className}`}>
      {steps.map((step, idx) => {
        return <Step key={idx} {...step} />
      })}
    </div>
  )
}

export default Stepper
