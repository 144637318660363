import { getCookie } from './cookieUtils'
import { logError } from './logError'

export const trackEvent = (titleEvent, event) => {
  if (window.analytics) {
    window.analytics.track(titleEvent, event)
  }
}

export const removeParamOfUrl = (url, parameter) => {
  let urlparts = url.split('?')

  if (urlparts.length >= 2) {
    let urlBase = urlparts.shift()
    let queryString = urlparts.join('?')

    let prefix = encodeURIComponent(parameter) + '='
    let pars = queryString.split(/[&;]/g)
    for (let i = pars.length; i-- > 0; )
      if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1)
    url = urlBase + '?' + pars.join('&')
  }
  return url
}

export const getUTMValues = () => {
  const UTMQuery = getCookie('UTMQuery')
  const defaultValues = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
  }

  if (!UTMQuery) {
    return defaultValues
  }

  const splitUTMQuery = UTMQuery.split('&')

  return splitUTMQuery.reduce((acc, currentValue) => {
    const [key, value] = currentValue.split('=')

    acc[key] = value

    return acc
  }, defaultValues)
}

export const getLPCookie = () => {
  try {
    const LPCookie = getCookie('cu_lp')
    return LPCookie ? decodeURIComponent(LPCookie) : null
  } catch (e) {
    logError(e)
    return null
  }
}

export const getUtmSource = (utmString = '') =>
  utmString
    .split('&')
    .find(param => param.split('=')[0] === 'utm_source')
    ?.split('=')[1]

export const autoSelectDeliveryDate = validDates => {
  const filteredDates = validDates.filter(
    date => !date.soldout && date.available,
  )
  const recommendedDay = validDates.find(date => date.recommended)
  const deliveryDay = recommendedDay || filteredDates[0]

  return deliveryDay
}

export const formatPrice = num => {
  if (isNaN(num)) {
    return Number(num.replace(/[^0-9.-]+/g, ''))
  }
  return Math.round(num * 100) / 100
}

export const sanitizeGA4EventName = (str, suffix) => {
  var _suffix = typeof suffix === 'string' ? suffix : ''
  try {
    var sanitized = str.trim().replace(/[\s-]/g, '_')
    sanitized += _suffix
    return sanitized.toLowerCase()
  } catch (error) {
    return str
  }
}

export const getTimeZoneShortName = (tzShortName = '') => {
  if (typeof tzShortName !== 'string') {
    return ''
  }

  if (tzShortName.length <= 2) {
    return tzShortName
  }

  const [zone = '', , time = ''] = tzShortName.split('')
  return `${zone}${time}`
}
