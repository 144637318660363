import { parse } from 'qs'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { SESSION_KEY } from 'src/constants'
import { validatePersistedStateData } from 'src/utils/dataValidation'
import { getPersistedState } from 'src/utils/sessionStorage'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'

const withStateManagement = (WrappedComponent: any) => {
  const HOCComponent = (props: any) => {
    const location = useLocation()

    const { zipcode } = parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
      const persistedState = getPersistedState(SESSION_KEY)

      if (persistedState?.userSelectedData.zipcode === zipcode) {
        (async () => {
          const validatedData = await validatePersistedStateData(
            persistedState,
            zipcode,
          )

          props.actions.recoverState({
            sessionId: SESSION_KEY,
            data: validatedData,
          })
        })()
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    return <WrappedComponent {...props} />
  }

  const SneakPeekCheckComp = (props: any) => {
    const { isTreatment: isSneakPeekTreatment } = useExperiment(
      EXPERIMENTS.sneakPeek,
    )
    if (!isSneakPeekTreatment) return <WrappedComponent {...props} />

    return <HOCComponent {...props} />
  }

  const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(
      {
        recoverState: signUpCommonActionCreators.recoverState,
      },
      dispatch,
    ),
  })

  return connect(null, mapDispatchToProps)(SneakPeekCheckComp)
}

export default withStateManagement
