import { DEFAULT_DISCOUNTS, DISCOUNT_TYPES } from '../constants/promotions'
import { roundToTwo } from './mathUtils'
import { getPromotionDiscounts, hasProductDiscount } from './promotionUtils'
import { getMealPlanSize, getMealsQuantity } from './mealUtils'
import { isArray, isNumber, isObject } from './typeUtils'
import { logError } from './logError'

export const formatNumberToCurrency = value => {
  if (!isNumber(value)) {
    throw new TypeError('The parameter must be a number.')
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatArrayElementsToCurrency = value => {
  if (!isArray(value)) {
    throw new TypeError('The parameter must be an array.')
  }

  return value.map(val => formatNumberToCurrency(val))
}

export const formatObjectValuesToCurrency = value => {
  if (!isObject(value)) {
    throw new TypeError('The parameter must be an object.')
  }

  return Object.entries(value).reduce((acc, [key, val]) => {
    if (!isNumber(val)) {
      throw new TypeError('The object values must be numbers.')
    }

    acc[key] = formatNumberToCurrency(val)

    return acc
  }, {})
}

export const calculatePercentageDiscount = (percentageAmount, price) => {
  return price * percentageAmount
}

export const calculateFixedDiscount = (fixedAmount, price) => {
  if (fixedAmount >= price) {
    return price
  } else {
    return fixedAmount
  }
}

export const getDiscountAmount = (discount, amount) => {
  if (discount.type === DISCOUNT_TYPES.percent) {
    return calculatePercentageDiscount(discount.rate, amount)
  } else {
    return calculateFixedDiscount(discount.rate, amount)
  }
}

export const getFixedPricePerMeal = (planPrice, planMealSize, fixedAmount) => {
  return planPrice - planMealSize * fixedAmount
}

export const getPricePerMeal = ({ plan, promotion }) => {
  if (promotion) {
    const { planDiscount, credit } = getPromotionDiscounts({ plan, promotion })

    return Math.max(
      roundToTwo((plan.price - planDiscount - credit) / getMealPlanSize(plan)),
      0,
    )
  } else {
    return roundToTwo(plan.price / getMealPlanSize(plan))
  }
}

export const getOrdersDetailed = ({ plan, promotion }) => {
  const planPrice = plan?.price
  const compoundPromotions = promotion?.compoundPromotions

  const ordersWithPrices = compoundPromotions?.map(promotion => ({
      count: promotion.orderCount,
      ordinalText: getOrdinalText(promotion.orderCount),
      price: formatNumberToCurrency(planPrice),
      priceWithPromotion: formatNumberToCurrency(planPrice - (planPrice * promotion.rate)),
      percentage: formatPercentage(promotion.rate),
  }))

  return ordersWithPrices;
}

const formatPercentage = rate => `${rate * 100}% OFF`

const getOrdinalText = number => {
  const suffixes = ['st', 'nd', 'rd'];

  // Handling special cases for 11, 12, and 13
  if (number % 100 >= 11 && number % 100 <= 13) {
      return number + 'th order';
  }

  const lastDigit = number % 10;
  const suffix = suffixes[lastDigit - 1] || 'th';

  return number + suffix + ' order';
}

export const getPlanPriceDiscount = ({ plan, promotion }) => {
  if (promotion) {
    const { planDiscount, credit } = getPromotionDiscounts({ plan, promotion })

    return Math.min(planDiscount + credit, plan.price)
  } else {
    return 0
  }
}

export const calculatePremiumMealsFee = meals =>
  meals
    .map(
      meal =>
        (meal.qty || meal.quantity) * (meal.premiumFee ?? meal.premium_fee),
    )
    .reduce((acc, curr) => acc + curr, 0)

export const calculateExtraMealsFee = (meals, plan) => {
  const totalMealsQuantity = getMealsQuantity(meals)
  const mealPlanSize = getMealPlanSize(plan)
  const planPrice = plan.price

  return totalMealsQuantity > mealPlanSize
    ? roundToTwo(
        (totalMealsQuantity - mealPlanSize) * (planPrice / mealPlanSize),
      )
    : 0
}

export const getOrderPrices = ({ plan, meals }) => {
  const planPrice = plan.price
  const deliveryFee = plan.deliveryFee
  const premiumMealsFee = calculatePremiumMealsFee(meals)
  const extraMealsFee = calculateExtraMealsFee(meals, plan)

  return {
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
  }
}

export const calculateDiscounts = ({
  planPrice = 0,
  deliveryFee = 0,
  premiumMealsFee = 0,
  extraMealsFee = 0,
  discount,
  limit = Infinity,
}) => {
  let planDiscount
  let deliveryDiscount
  let premiumMealsDiscount
  let extraMealsDiscount

  if (discount.type === DISCOUNT_TYPES.percent) {
    planDiscount = getDiscountAmount(discount, planPrice)
    deliveryDiscount = getDiscountAmount(discount, deliveryFee)
    premiumMealsDiscount = getDiscountAmount(discount, premiumMealsFee)
    extraMealsDiscount = getDiscountAmount(discount, extraMealsFee)
  } else {
    const totalPrice = planPrice + deliveryFee + premiumMealsFee + extraMealsFee
    const grossDiscountAmount = getDiscountAmount(discount, totalPrice)

    const appliedDiscount = Math.min(grossDiscountAmount, limit)

    planDiscount = (planPrice * appliedDiscount) / totalPrice
    deliveryDiscount = (deliveryFee * appliedDiscount) / totalPrice
    premiumMealsDiscount = (premiumMealsFee * appliedDiscount) / totalPrice
    extraMealsDiscount = (extraMealsFee * appliedDiscount) / totalPrice
  }

  return {
    planDiscount,
    premiumMealsDiscount,
    deliveryDiscount,
    extraMealsDiscount,
  }
}

export const calculateSummary = ({
  plan,
  meals,
  promotion,
  taxRate,
  giftcardCredit = 0,
}) => {
  try {
    const planPrice = plan.price
    const deliveryFee = plan.deliveryFee
    const premiumMealsFee = calculatePremiumMealsFee(meals)
    const extraMealsFee = calculateExtraMealsFee(meals, plan)
    const productDiscount = calculateProductDiscount(meals)
    let discounts = DEFAULT_DISCOUNTS

    let totalDiscount = 0
    if (promotion) {
      discounts = getPromotionDiscounts({ plan, meals, promotion })
      totalDiscount = discounts.totalDiscount
    }

    const preTotal = roundToTwo(
      planPrice + deliveryFee + premiumMealsFee + extraMealsFee - totalDiscount - productDiscount,
    )
    const tax = roundToTwo(preTotal * taxRate)
    const totalWithoutDiscount = roundToTwo(preTotal + tax)

    const giftcardCreditRounded =
      typeof giftcardCredit === 'number' ? roundToTwo(giftcardCredit) : 0.00

    const total = Math.max(
      roundToTwo(totalWithoutDiscount - discounts.credit - giftcardCreditRounded),
      0,
    )

    return {
      planPrice,
      deliveryFee,
      premiumMealsFee,
      extraMealsFee,
      discount:
        totalDiscount || Math.min(totalWithoutDiscount, discounts.credit),
      preTotal,
      tax,
      total,
      giftcardCredit: giftcardCreditRounded,
      productDiscount,
    }
  } catch (error) {
    logError(error, 'calculateSummary error')
  }
}

export const getSymbolAmount = discount => {
  if (discount.type === DISCOUNT_TYPES.percent) {
    return `${discount.rate * 100}%`
  } else {
    return `$${discount.rate}`
  }
}

export const calculateProductDiscount = (products = []) => {
  const elegibleProducts = products.filter(hasProductDiscount)
  return elegibleProducts.reduce(
    (acc, product) => acc + product.promotions[0].amount.value * product.qty,
    0,
  )
}
