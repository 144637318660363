import { useSelector } from 'react-redux'
import { mealsSelectedQuantitySelector } from 'src/features/meals/mealsSlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import GutterCui from 'src/components/GutterCui/GutterCui'
import { CuiLink } from 'front-lib'
import './Footer.scss'

const Footer = ({ onGoNext, onViewSelection }) => {
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector)
  const planSelected = useSelector(planSelectedSelector)

  return (
    <GutterCui
      ctaText="Continue"
      nextDisabled={
        mealsSelectedQuantity > planSelected?.mealsPerDelivery ||
        mealsSelectedQuantity < planSelected?.mealsPerDelivery
      }
      onGoNext={onGoNext}
    >
      <div className="meals-selection-footer">
        <div className="meals-selection-footer__description">
          <span className="meals-selection-footer__description--text">
            Meal selected <span />
          </span>
          <span className="meals-selection-footer__description--amount">{`${mealsSelectedQuantity ?? 0}/${planSelected?.mealsPerDelivery}`}</span>
        </div>
        <CuiLink
          fill="clear"
          showUnderline
          onClick={onViewSelection}
          className="meals-selection-footer__button"
        >
          View Selection
        </CuiLink>
      </div>
    </GutterCui>
  )
}

export default Footer
