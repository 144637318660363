import React from 'react'
import DiamondIcon from '../../../../../../assets/svg/diamond.svg'
import './ChefSpecialLabel.scss'

const ChefSpecialLabel = () => (
  <div className="chef-special">
    <img src={DiamondIcon} alt="diamond-icon" />
    <p>Chef Specials</p>
  </div>
)

export default ChefSpecialLabel
