import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'
import { CuiButton } from 'front-lib'
import { CONNECTIONS } from '../../utils'
import styles from './SignInPanel.module.scss'

type Network = {
  icon: string
  label: string
}

type Networks = {
  apple: Network
  google: Network
  facebook: Network
}

const networks: Networks = {
  apple: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M21.2808 18.424C20.933 19.2275 20.5213 19.9672 20.0442 20.6472C19.394 21.5743 18.8616 22.216 18.4513 22.5724C17.8153 23.1573 17.1338 23.4568 16.4041 23.4739C15.8802 23.4739 15.2485 23.3248 14.513 23.0224C13.7752 22.7214 13.0972 22.5724 12.4772 22.5724C11.827 22.5724 11.1296 22.7214 10.3837 23.0224C9.63662 23.3248 9.03481 23.4824 8.57468 23.498C7.87491 23.5278 7.1774 23.2197 6.48118 22.5724C6.03681 22.1848 5.48099 21.5204 4.81515 20.5791C4.10075 19.5739 3.51342 18.4084 3.05329 17.0795C2.56051 15.6442 2.31348 14.2543 2.31348 12.9087C2.31348 11.3673 2.64654 10.0379 3.31366 8.92385C3.83796 8.029 4.53546 7.32312 5.40844 6.80493C6.28142 6.28674 7.22468 6.02267 8.24048 6.00578C8.7963 6.00578 9.52518 6.1777 10.431 6.51559C11.3342 6.85462 11.9141 7.02655 12.1684 7.02655C12.3585 7.02655 13.0028 6.82552 14.0949 6.42473C15.1278 6.05305 15.9995 5.89916 16.7136 5.95978C18.6487 6.11595 20.1024 6.87876 21.0693 8.25303C19.3386 9.30163 18.4826 10.7703 18.4996 12.6544C18.5152 14.122 19.0476 15.3432 20.0939 16.3129C20.5681 16.7629 21.0977 17.1107 21.6868 17.3578C21.5591 17.7283 21.4242 18.0832 21.2808 18.424ZM16.8428 0.960131C16.8428 2.11039 16.4226 3.18439 15.5849 4.17847C14.5741 5.36023 13.3514 6.04311 12.0256 5.93536C12.0087 5.79736 11.9989 5.65213 11.9989 5.49951C11.9989 4.39526 12.4796 3.21349 13.3333 2.24724C13.7595 1.75801 14.3015 1.35122 14.9588 1.02671C15.6147 0.707053 16.2352 0.530273 16.8187 0.5C16.8357 0.653772 16.8428 0.807554 16.8428 0.960116V0.960131Z" fill="black"/>
      </svg>`,
    label: 'Apple',
  },
  google: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect width="24" height="24" fill="none"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.04 12.2615C23.04 11.446 22.9668 10.6619 22.8309 9.90918H12V14.3576H18.1891C17.9225 15.7951 17.1123 17.013 15.8943 17.8285V20.714H19.6109C21.7855 18.7119 23.04 15.7637 23.04 12.2615Z" fill="#4285F4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.4998C15.105 23.4998 17.7081 22.47 19.6109 20.7137L15.8943 17.8282C14.8645 18.5182 13.5472 18.9259 12 18.9259C9.00474 18.9259 6.46951 16.903 5.56519 14.1848H1.72314V17.1644C3.61542 20.9228 7.50451 23.4998 12 23.4998Z" fill="#34A853"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.56523 14.185C5.33523 13.495 5.20455 12.7579 5.20455 12C5.20455 11.242 5.33523 10.505 5.56523 9.81499V6.83545H1.72318C0.944318 8.38795 0.5 10.1443 0.5 12C0.5 13.8557 0.944318 15.612 1.72318 17.1645L5.56523 14.185Z" fill="#FBBC05"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.07386C13.6884 5.07386 15.2043 5.65409 16.3961 6.79364L19.6945 3.49523C17.7029 1.63955 15.0997 0.5 12 0.5C7.50451 0.5 3.61542 3.07705 1.72314 6.83545L5.56519 9.815C6.46951 7.09682 9.00474 5.07386 12 5.07386Z" fill="#EA4335"/>
      </svg>`,
    label: 'Google',
  },
  facebook: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_12364_103198)">
          <rect width="24" height="24"  fill="none"/>
          <path d="M23.1064 12.0699C23.1064 5.7186 17.9577 0.56988 11.6064 0.56988C5.25517 0.56988 0.106445 5.7186 0.106445 12.0699C0.106445 17.8099 4.31183 22.5674 9.80957 23.4302V15.3941H6.88965V12.0699H9.80957V9.53629C9.80957 6.6541 11.5264 5.06207 14.1533 5.06207C15.4115 5.06207 16.7275 5.28668 16.7275 5.28668V8.11675H15.2774C13.8488 8.11675 13.4033 9.00322 13.4033 9.91266V12.0699H16.5928L16.0829 15.3941H13.4033V23.4302C18.9011 22.5674 23.1064 17.8099 23.1064 12.0699Z" fill="#1877F2"/>
        </g>
        <defs>
          <clipPath id="clip0_12364_103198">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    label: 'Facebook',
  },
}

type SignInButtonProps = {
  type: 'facebook' | 'google' | 'apple'
  onClick: () => void
  children: any
}
const SignInButton = ({
  type,
  onClick,
  children,
  ...props
}: SignInButtonProps) => {
  return (
    <CuiButton
      fill="solid"
      size="large"
      className={classNames(styles.network, styles[type])}
      onClick={onClick}
      {...props}
    >
      {children}
    </CuiButton>
  )
}

type SignInButtonsProps = {
  // eslint-disable-next-line no-unused-vars
  setShowForm: (showForm: boolean) => void
  labels: any
  socialSubmit: Function
}
const SignInButtons = ({
  setShowForm,
  labels,
  socialSubmit,
}: SignInButtonsProps) => {
  type ButtonData = {
    dataTestId: string
    type: 'facebook' | 'google' | 'apple'
    connectionType: CONNECTIONS
    network: Network
  }
  const buttons: ButtonData[] = [
    {
      dataTestId: 'login-google',
      type: 'google',
      connectionType: CONNECTIONS.GOOGLE,
      network: networks['google'],
    },
    {
      dataTestId: 'login-facebook',
      type: 'facebook',
      connectionType: CONNECTIONS.FACEBOOK,
      network: networks['facebook'],
    },
    {
      dataTestId: 'login-apple',
      type: 'apple',
      connectionType: CONNECTIONS.APPLE,
      network: networks['apple'],
    },
  ]

  const handleSocialClick = (connection: CONNECTIONS) => {
    socialSubmit(connection)
  }

  return (
    <div className={styles.social}>
      <div className={styles.buttons}>
        <CuiButton
          data-testid="login-form"
          color="primary"
          fill="solid"
          size="large"
          type="submit"
          onClick={() => {
            setShowForm(true)
          }}
        >
          {labels.form}
        </CuiButton>

        {buttons.map((button: ButtonData, index: number) => (
          <SignInButton
            data-testid={button.dataTestId}
            type={button.type}
            onClick={() => handleSocialClick(button.connectionType)}
            key={index}
          >
            <div className={styles.label}>
              <div
                className={styles.icon}
                dangerouslySetInnerHTML={{
                  __html: button.network.icon,
                }}
              />
              <div className={styles.text}>{labels[button.type]}</div>
            </div>
          </SignInButton>
        ))}
      </div>
    </div>
  )
}

type SignInPanelPageProps = {
  form: ReactElement
  title: string
  labels: any
  showForm: boolean
  socialSubmit: Function
  isPopup: boolean
  // eslint-disable-next-line no-unused-vars
  setShowForm: (showForm: boolean) => void
  children: ReactNode
}
const SignInPanel = ({
  form,
  title,
  labels,
  showForm,
  setShowForm,
  socialSubmit,
  isPopup,
  children,
}: SignInPanelPageProps) => {
  return (
    <div
      className={classNames(styles.panel, isPopup ? styles['panel-popup'] : '')}
    >
      <h2
        className={classNames(
          styles.title,
          isPopup ? styles['title-popup'] : '',
        )}
      >
        {title}
      </h2>
      {showForm && (
        <div
          className={classNames(
            styles.panel,
            isPopup ? styles['panel-popup'] : '',
          )}
        >
          {form}
        </div>
      )}
      {!showForm && (
        <div className={styles.actions}>
          <SignInButtons
            setShowForm={setShowForm}
            labels={labels}
            socialSubmit={socialSubmit}
          />
          {children}
        </div>
      )}
    </div>
  )
}

export default SignInPanel
