import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { rootReducer } from '../../features/rootReducer'
import rootSaga from '../../redux/saga'

const sagaMiddleware = createSagaMiddleware()
const middleware = [thunk, sagaMiddleware]

export const store = configureStore({
  reducer: rootReducer,
  middleware,
})

const StoreProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}

sagaMiddleware.run(rootSaga)

export default StoreProvider
