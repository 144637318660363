import { CuiIcon } from 'front-lib'
import Stepper from 'src/components/Stepper/Stepper'
import { getDateLabel } from 'src/utils/date'
import { getTimeZoneShortName } from 'src/utils/utils'

const NextSteps = ({ data }) => {
  const deliveryDate = new Date(data.displayDate)
  const cutoffDate = new Date(data.cutoff.time)
  const autopilotDate = new Date(cutoffDate.getTime() - 24 * 60 * 60 * 1000)

  const dateFormat = {
    weekday: 'short',
    month: 'numeric',
  }

  const delivery = getDateLabel(deliveryDate, dateFormat).replace(',', '')
  const cutoff = getDateLabel(cutoffDate, dateFormat).replace(',', '')
  const autopilot = getDateLabel(autopilotDate, dateFormat).replace(',', '')
  const timeZoneShortName = getTimeZoneShortName(data.cutoff.tzShortName)

  const [cutoffDay] = cutoff.split(' ')

  return (
    <div className={'next-steps'}>
      <h1 className={'next-steps--header only-desktop'}>
        Sweet! Order confirmed.
      </h1>
      <h2 className={'next-steps--title'}>Next Up</h2>
      <Stepper
        className={'next-steps--stepper'}
        steps={[
          {
            boxTitle: 'today',
            boxContent: (
              <CuiIcon
                name={'allDone'}
                className={'next-steps--icon color-green-2'}
              />
            ),
            title: 'Find new meals to try',
            text:
              'Explore the menu and place your next order — order soon to lock in everything you want!',
          },
          {
            boxTitle: autopilot,
            boxContent: (
              <CuiIcon name={'flashIcon'} className={'next-steps--icon'} />
            ),
            title: 'Personalized recommendations ',
            text:
              'If you don’t get a chance to pick meals by the day before your weekly order deadline, we’ll fill your cart with recommended meals.',
          },
          {
            boxTitle: cutoff,
            boxContent: (
              <CuiIcon name={'clock'} className={'next-steps--icon'} />
            ),
            title: 'Weekly order deadline',
            text: `Edit, reschedule, or skip your order by 12 pm ${timeZoneShortName} on ${cutoffDay}. — if no action is taken, you will receive a delivery with the recommended meals added to your cart the day before.`,
          },
          {
            boxTitle: delivery,
            boxContent: (
              <CuiIcon name={'truck'} className={'next-steps--icon'} />
            ),
            title: 'Delivery day',
            text:
              'Get real-time delivery updates on our website and app. Happy eating!',
          },
        ]}
      />
    </div>
  )
}

export default NextSteps
