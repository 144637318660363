const cleanResponseHook = res => {
  return res.data;
};
const handleErrorHook = data => {
  if (data.error) {
    throw new Error(data.error);
  }

  return data;
};

const hooks = [cleanResponseHook, handleErrorHook];

export const applyHooks = res => hooks.reduce((d, hook) => hook(d), res);
