import { applicationConstants } from 'src/constants'

export const setAuthAccessToken = token =>
  window.sessionStorage.setItem(
    applicationConstants.AUTH0_ACCESS_TOKEN_NAME,
    token,
  )
export const getAuthAccessToken = () =>
  window.sessionStorage.getItem(applicationConstants.AUTH0_ACCESS_TOKEN_NAME)

export const persistState = (id, state) => {
  window.localStorage.setItem(
    id,
    JSON.stringify({ body: { ...state }, timestamp: Date.now() }),
  )
}

export const getPersistedState = id => {
  const now = Date.now()
  const persistedState = JSON.parse(window.localStorage.getItem(id))
  const persistedTimestamp = persistedState && persistedState['timestamp']
  const isExpired =
    persistedTimestamp &&
    Math.floor((now - persistedTimestamp) / (1000 * 60 * 60 * 24)) > 7

  if (isExpired) return null

  return persistedState && persistedState['body']
}

export const setExperimentStarted = (experimentId, userId, variant) => {
  window.localStorage.setItem(`${applicationConstants.EXP_KEY_PREFIX}${experimentId}`, JSON.stringify({userId, variant}))
}

export const isExperimentStartedSet = (experimentId, userId) => {
  const item = window.localStorage.getItem(`${applicationConstants.EXP_KEY_PREFIX}${experimentId}`)
  return item !== null && JSON.parse(item).userId === userId
}
