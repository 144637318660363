import classNames from 'classnames'
import { CuiButton } from 'front-lib'

import { isMobile } from 'src/utils/events'

const BottomBanner = ({ onboardingURL }) => {
  const isContainer = isMobile()

  const classes = classNames('bottom-banner--container', {
    container: !isContainer,
  })

  return (
    <div className={'bg-gray-5'}>
      <div className={'bottom-banner'}>
        <div className={classes}>
          <CuiButton
            size="large"
            className={'bottom-banner--button'}
            onClick={() => (window.location = onboardingURL)}
          >
            Lock in next week’s meals
          </CuiButton>
        </div>
      </div>
    </div>
  )
}

export default BottomBanner
