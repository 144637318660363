import React, { useMemo } from 'react'
import { CuiModal, CuiButton } from 'front-lib'
import PropTypes from 'prop-types'

import CouponInfo from 'src/screens/signup/pages/SelectPlans/components/CouponInfo/CouponInfo'
import PlanSelection from 'src/screens/signup/pages/SelectPlans/components/PlanSelection/PlanSelection'
import PlanPrice from 'src/screens/signup/pages/SelectPlans/components/PlanPrice/PlanPrice'
import { DEFAULT_MEAL_PLAN } from 'src/constants/index'
import {
  getMealPlansWithDefault,
  mapPlansToOptions,
  trackDeliveryDayAssigned,
  trackMealPlanUpdated,
  getChangePlanOverlayTrackData,
  OVERLAY_CLOSED,
  OVERLAY_SUBMITTED,
} from 'src/screens/signup/pages/SelectPlans/utils/index'
import { getSearchParam } from 'src/utils/urlUtils'
import { DEFAULT_COUPON, SESSION_KEY } from 'src/constants/index'
import { getCUCookie } from 'src/utils/cookieUtils'
import { autoSelectDeliveryDate } from 'src/utils/utils'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { logInfo } from 'src/utils/logError'

import './ChangePlanModal.scss'

const ChangePlanModal = ({
  showChangePlanModal,
  setShowChangePlanModal,
  actions,
  signUpInitData,
  coupon,
  selectedPlan,
  validDates,
}) => {
  const {
    applyCoupon,
    setDeliveryOptions,
    setUserSelectedDataStart,
    sendTracking,
    persistState,
  } = actions

  const defaultPlan = useMemo(() => Object.assign({}, selectedPlan), [])
  const plansWithDefault = getMealPlansWithDefault(signUpInitData.mealPlans)
  const planOptions = useMemo(() => mapPlansToOptions(plansWithDefault), [
    plansWithDefault,
  ])
  const trackOverlayClosed = useTrackEvent(
    getChangePlanOverlayTrackData({ event: OVERLAY_CLOSED }),
  )
  const trackOverlaySubmitted = useTrackEvent(
    getChangePlanOverlayTrackData({ event: OVERLAY_SUBMITTED }),
  )

  const setPlanInState = plan => {
    setUserSelectedDataStart({
      selectedPlan: plan,
    })
  }

  const setCouponInState = () => {
    applyCoupon(
      getSearchParam('coupon') || getCUCookie('discountCode') || DEFAULT_COUPON,
    )
  }

  const onClose = () => {
    setPlanInState(defaultPlan)
    setCouponInState()
    trackOverlayClosed()
    setShowChangePlanModal(false)
  }

  const onSubmit = () => {
    const autoSelectedDate = autoSelectDeliveryDate(validDates)

    setDeliveryOptions({
      validDates,
      originalSelectedDate: {
        startDay: autoSelectedDate,
        days: [autoSelectedDate.day],
      },
      autoSelectedDate: autoSelectedDate,
      startDay: autoSelectedDate,
      firstSelectionStartDay: true,
    })
    setUserSelectedDataStart({
      startDay: autoSelectedDate,
      days: [autoSelectedDate.day],
    })
    trackDeliveryDayAssigned(sendTracking, { autoSelectedDate })
    persistState({ sessionId: SESSION_KEY })
    trackMealPlanUpdated(sendTracking, { oldPlan: defaultPlan, newPlan: selectedPlan })
    trackOverlaySubmitted()
    logInfo('Meal Step - New Plan Selected', { payload: { selectedPlan, couponData: coupon } })
    setShowChangePlanModal(false)
  }

  const onClickPlan = clickedPlanId => {
    const newSelectedPlan = plansWithDefault?.find(
      ({ id }) => id === clickedPlanId,
    )

    setPlanInState(newSelectedPlan)

    if (
      (newSelectedPlan.couponCode &&
        coupon.hiddenCouponCode !== newSelectedPlan.couponCode) ||
      (!newSelectedPlan.couponCode &&
        coupon.couponCode !== coupon.hiddenCouponCode)
    ) {
      setCouponInState()
    }
  }

  return (
    <CuiModal
      className="change-plan-modal"
      isOpen={showChangePlanModal}
      onRequestClose={onClose}
      iconClassName="change-plan-modal-close-icon"
    >
      <div className="select-plan__main">
        {coupon.promotion?.title && <section className="select-plan__main__section">
          <CouponInfo title={coupon?.promotion?.title.toUpperCase()} />
        </section>
        }
        <section className="select-plan__main__section">
          <p className="select-plan__main__section__title">Select plan size</p>
          <p className="select-plan__main__section__text">
            You can skip, pause, or change the plan size anytime.
          </p>
        </section>
        <section className="select-plan__main__section">
          <PlanSelection
            data={planOptions}
            onSelect={onClickPlan}
            selected={selectedPlan}
          />
        </section>
        {selectedPlan && (
          <section className="select-plan__main__section">
            <PlanPrice
              coupon={coupon}
              selected={selectedPlan}
              showHeader={
                selectedPlan.mealsPerDelivery === DEFAULT_MEAL_PLAN ||
                selectedPlan.priceTitle
              }
            />
          </section>
        )}
        <div className="actions__section">
          <CuiButton size="large" fill="outline" className="cancel-button" onClick={onClose}>
            Cancel
          </CuiButton>
          <CuiButton size="large" className="confirm-button" onClick={onSubmit}>
            Confirm
          </CuiButton>
        </div>
      </div>
    </CuiModal>
  )
}

ChangePlanModal.propTypes = {
  showChangePlanModal: PropTypes.bool.isRequired,
  setShowChangePlanModal: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  signUpInitData: PropTypes.object.isRequired,
  coupon: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  validDates: PropTypes.array.isRequired,
}

export default ChangePlanModal
