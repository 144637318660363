import React, { useEffect, useState } from 'react'
import { getCustomAttributeValue } from '../../../../utils/auth0'
import { formatFullName } from '../../../../utils/user'
import Modal from '../../components/NewModal'
import DeliveryFormCui from './components/DeliveryFormCui'
import MealsCartCui from './components/MealsCartCui'

import Analytics from 'src/utils/analytics'
import cart from '../../../../assets/icons/cart-black.png'
import LoggedUser from './components/LoggedUser/LoggedUser'
import LoggedUserCui from './components/LoggedUserCui/LoggedUserCui'
import { MessageContainerCui } from './components/MessageContainerCui'
import PaymentFormCui from './components/PaymentFormCui'

import useExperiment from 'src/hooks/useExperiment'
import { PATHS, pageNames } from 'src/screens/signup/constants'
import { EVENTS, isMobile } from 'src/utils/events'
import './CheckoutPageCui.scss'
import OrderSummaryCui from './components/OrderSummaryCui'
import { calculatePremiumMealsFee, formatNumberToCurrency } from 'src/utils/priceUtils'
import { authUserSelector } from 'src/features/auth/authSlice'
import { useSelector } from 'react-redux'
import { EXPERIMENTS } from 'src/constants/experiments'

const CheckoutPageCui = ({
  stepHandler,
  setForcedSkipQuiz,
  showHandler,
  deliveryOpen,
  zipcode,
  addAddress,
  addCard,
  addressError,
  addressStatus,
  addressData,
  cardData,
  cardError,
  paymentOpen,
  selectedPlan,
  coupon,
  order,
  startDay,
  toggleOpenModal,
  openModal,
  applyCoupon,
  resetApplyCoupon,
  couponError,
  createAccountError,
  cardStatus,
  applyCouponStatus,
  createAccountStatus,
  sendLogging,
  ring = {},
  store = {},
  coordinates,
  timeSlots,
  setTimeSlot,
  selectedTimeSlot,
  setDeliveryOption,
  selectedDeliveryOption,
  checkoutAccount,
  setGiftCardCode,
  sendTracking,
  giftcardCode,
  validateCoupon,
  resetCouponValidation,
  resetGiftcardValidation,
  giftcardData,
}) => {
  const [firstOpenModal, setFirstOpenModal] = useState(true)

  const checkoutImprovements = useExperiment(
    EXPERIMENTS.checkoutImprovements,
    { trackOnMount: true }
  )

  const authUser = useSelector(authUserSelector)
  const userName = authUser?.given_name
  const userLastName = authUser?.family_name
  const userFullName = formatFullName(userName, userLastName)

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEditMeals = () => {
    setForcedSkipQuiz(true)
    stepHandler(PATHS[pageNames.MEALS])
  }

  const handleViewDetails = () => {
    if (firstOpenModal) {
      sendTracking({
        eventName: EVENTS.overlayViewed,
        eventData: {
          page_name: isMobile() ? null : pageNames.CHECKOUT,
          screen_name: isMobile() ? pageNames.CHECKOUT : null,
          overlay_class: 'modal',
          overlay_source: 'custom',
          overlay_type: 'Custom',
          overlay_action: 'Breakdown_Summary_Detail',
          overlay_name: 'Sign Up - Checkout - Overlay Viewed',
          overlay_trigger: 'manual',
        },
      })

      setFirstOpenModal(false)
    }

    toggleOpenModal(true)
  }

  useEffect(() => {
    if (authUser) {
      Analytics.trackRealLoad('Checkout Page Load', {
        UserEmail: authUser.email,
        MagentoId: getCustomAttributeValue(authUser, 'magento_id'),
      })
    }
  }, [authUser])

  return (
    <>
      {checkoutImprovements.isTreatment && (
        <div className='checkout-cui-header'>
          <LoggedUser />
        </div>
      )}
      <div className={`checkout-cui-container ${checkoutImprovements.isTreatment && 'checkout-improve-cui-container'}`}>
        <div className="checkout-row-cui">
          <div className="left-side-container">
            {!checkoutImprovements.isTreatment && <LoggedUserCui />}

            <DeliveryFormCui
              deliveryOpen={deliveryOpen}
              address={addressData}
              zipcode={zipcode}
              setTimeSlot={setTimeSlot}
              selectedTimeSlot={selectedTimeSlot}
              setDeliveryOption={setDeliveryOption}
              selectedDeliveryOption={selectedDeliveryOption}
              addAddress={addAddress}
              error={addressError}
              loading={addressStatus}
              showHandler={showHandler}
              coordinates={coordinates}
              showDeliveryWindowAndOptions={ring.isLocal}
              timeSlots={timeSlots}
              stepHandler={stepHandler}
              sendTracking={sendTracking}
              planName={selectedPlan.title}
              userRingId={ring.id}
              startDay={startDay.date}
              storeName={store.name}
            />

            <PaymentFormCui
              paymentOpen={paymentOpen}
              card={cardData}
              address={addressData}
              addCard={addCard}
              setGiftCardCode={setGiftCardCode}
              giftcardCode={giftcardCode}
              isSubmitting={cardStatus}
              error={couponError || cardError || createAccountError}
              loading={cardStatus || applyCouponStatus || createAccountStatus}
              applyCoupon={applyCoupon}
              eraseCouponError={resetApplyCoupon}
              calculatedTotal={checkoutAccount.total}
              showHandler={showHandler}
              userFullName={userFullName}
              coupon={coupon}
              sendTracking={sendTracking}
              validateCoupon={validateCoupon}
              resetCouponValidation={resetCouponValidation}
              resetGiftcardValidation={resetGiftcardValidation}
            />

            <MessageContainerCui
              message="You can skip or reschedule future deliveries as long as you do so before your cutoff day. This deadline depends on location, so be sure to check Upcoming Deliveries for specifics!"
              mobile="true"
            />
          </div>

          <div className="right-side-mobile">
            <div className="right-side-container">
              <OrderSummaryCui
                selectedPlan={selectedPlan}
                startDay={startDay}
                coupon={coupon}
                checkoutAccount={checkoutAccount}
                sendTracking={sendTracking}
                giftcardData={giftcardData}
              />
              <MessageContainerCui message="You can skip or reschedule future deliveries as long as you do so before your cutoff day. This deadline depends on location, so be sure to check Upcoming Deliveries for specifics!" />
              <MealsCartCui
                sendLogging={sendLogging}
                products={order.products}
                onEditMeals={onEditMeals}
                showPremiumFees={checkoutAccount.premiumMealsFee}
                freePremiumFees={
                  checkoutAccount.premiumMealsFee ===
                  calculatePremiumMealsFee(order.products)
                }
                startDay={startDay.date}
                planName={selectedPlan.title}
                sendTracking={sendTracking}
              />
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="div-row">
            <p className="rebrand">FIRST WEEK TOTAL :</p>
            <p className="rabrand first-number">
              {formatNumberToCurrency(checkoutAccount.total)}
            </p>
          </div>
          <div className="div-column">
            <img src={cart} alt="cart icon" />
            <span className="separator" />
            <button className="rebrand-link" onClick={handleViewDetails}>
              View Details
            </button>
          </div>
        </div>

        <Modal isOpen={openModal} onRequestClose={() => toggleOpenModal(false)}>
          <>
            <OrderSummaryCui
              selectedPlan={selectedPlan}
              startDay={startDay}
              coupon={coupon}
              checkoutAccount={checkoutAccount}
              sendTracking={sendTracking}
              giftcardData={giftcardData}
            />
            <MessageContainerCui message="You can skip or reschedule future deliveries as long as you do so before your cutoff day. This deadline depends on location, so be sure to check Upcoming Deliveries for specifics!" />
            <MealsCartCui
              sendLogging={sendLogging}
              products={order.products}
              onEditMeals={onEditMeals}
              sendTracking={sendTracking}
              showPremiumFees={checkoutAccount.premiumMealsFee}
            />
          </>
        </Modal>
      </div>
    </>
  )
}

export default CheckoutPageCui
