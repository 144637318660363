import React, { useState } from 'react'
import SignUp from './pages/SignUp'
import { PAGES } from './utils'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'

const SignIn = ({ isPopup }: any) => {
  const [page, setPage] = useState(PAGES.SIGN_UP)

  if (page === PAGES.LOGIN) return <Login setPage={setPage} isPopup={isPopup} />
  if (page === PAGES.SIGN_UP)
    return <SignUp setPage={setPage} isPopup={isPopup} />
  if (page === PAGES.FORGOT_PASSWORD)
    return <ForgotPassword setPage={setPage} isPopup={isPopup} />
}

export default SignIn
