import { createSelector } from 'reselect';
import { getCart } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors';
import deliveryPageSelectors from 'src/redux/ducks/screens/signup/pages/DeliverySettings/selectors';
import { getAddressData } from 'src/redux/ducks/screens/signup/pages/Checkout/selectors';
import { logError } from 'src/utils/logError';
import { applyDDOConfigs } from 'src/utils/ddoUtils';
const getSignUpScreenState = state => state.getIn(['signUpScreen', 'commons']);

const getSignUpCommonState = state => getSignUpScreenState(state).get('initData');

export const getSignUpInitForceLoading =
  createSelector(getSignUpCommonState, state => state.get('forceLoading'));
export const getSignUpInitStatus =
  createSelector(getSignUpCommonState, state => state.get('start') || !state.get('success'));
export const getSignUpInitData = createSelector(getSignUpCommonState, state => state?.get('data'));
export const getSignUpInitDataError = createSelector(getSignUpCommonState, state => state.error);
export const getRing = createSelector(getSignUpInitData, data => data.ring);
export const getUserStore = createSelector(getSignUpInitData, data => data?.store);
export const getTimeSlots = createSelector(getSignUpInitData, data => data.timeslots);
export const getSharedMealId = createSelector(getSignUpInitData, data => data.sharedMealId);
export const getSignupInitUpcomingDays = createSelector(getSignUpInitData, data => data.upcomingDays);

const getUserSelectedDataState = state => getSignUpScreenState(state).get('userSelectedData');
export const getUserSelectedData = createSelector(getUserSelectedDataState, state => state);
export const getUserSelectedZipcode = createSelector(getUserSelectedData, state => state.zipcode);
export const getGiftCardCode = createSelector(getUserSelectedData, state => state.giftCardCode);
export const getUserSelectedPlan = createSelector(getUserSelectedData, state => state.selectedPlan)
export const getUserNewUpgradedPlan = createSelector(getUserSelectedData, state => state.newUpgradedPlan)
export const getSearchMealsIds = createSelector(getUserSelectedData, data => data.searchMealsIds);
export const getHighlightedMealsIds = createSelector(getUserSelectedData, data => data.highlightedMealsIds);

const getSignUpBreadPathState = state => getSignUpScreenState(state).get('breadPath');
export const getBreadPathData = createSelector(getSignUpBreadPathState, state => state);

const getSignUpBreadPathCurrentNameState = state => getSignUpScreenState(state).get('currentStepName')
export const getBreadPathCurrentName = createSelector(getSignUpBreadPathCurrentNameState, state => state);

const getBreadPathAfterElementState = state => getSignUpScreenState(state).get('afterElement');
export const getBreadPathAfterElement = createSelector(getBreadPathAfterElementState, state => state);

const getCouponDataState = state => getSignUpScreenState(state).get('coupon')
export const getCouponStatus = createSelector(getCouponDataState, state => state.get('start'));
export const getCoupon = createSelector(getCouponDataState, state => state.get('data'));
export const getCouponError = createSelector(getCouponDataState, state => state.get('error'));
export const getCouponSuccess = createSelector(getCouponDataState, state => state.get('success'));

const getSeoDataState = state => getSignUpScreenState(state).get('seoData');
export const getSeoData = createSelector(getSeoDataState, state => state);

const getCreateUserState = state => getSignUpScreenState(state).get('createUser');
export const getCreateUserStatus = createSelector(getCreateUserState, state => state.get('start'));
export const getCreateUserSuccess = createSelector(getCreateUserState, state => state.get('success'));
export const getCreateUserError = createSelector(getCreateUserState, state => state.get('error'));
export const getCreateUserData = createSelector(getCreateUserState, state => state.get('data'));

const getCreateOrderState = state => getSignUpScreenState(state).get('createOrder');
export const getCreateOrderStatus = createSelector(getCreateOrderState, state => state.get('start'));
export const getCreateOrderSuccess = createSelector(getCreateOrderState, state => state.get('success'));
export const getCreateOrderError = createSelector(getCreateOrderState, state => state.get('error'));
export const getCreateOrderData = createSelector(getCreateOrderState, state => state.get('data'));

const getShowTopBarState = state => getSignUpScreenState(state).get('showTopBar');
export const getShowTopBar = createSelector(getShowTopBarState, state => state);

const getRegisterRequestFlowState = state => getSignUpScreenState(state).get('registerRequestFlow');
export const getRegisterRequestFlow = createSelector(getRegisterRequestFlowState, state => state);

/**
 * @description Preferences Quiz Data
 */
const getPreferencesQuizState = state => getSignUpScreenState(state).get('preferencesQuiz');
export const getPreferencesQuizStatus = createSelector(getPreferencesQuizState, state => state.get('start'));
export const getPreferencesQuizSuccess =
  createSelector(getPreferencesQuizState, state => state.get('success'));
export const getPreferencesQuizError = createSelector(getPreferencesQuizState, state => state.get('error'));
export const getPreferencesQuizData = createSelector(getPreferencesQuizState, state => state.get('data'));

/**
 * @description Preferences Quiz Responses
 */
const getPreferencesQuizResponsesState = state => getSignUpScreenState(state).get('preferencesQuizResponses');
export const preferencesQuizResponses = createSelector(getPreferencesQuizResponsesState, state => state);

const getExperimentDataState = state => getSignUpScreenState(state).get('experiments');

export const getExperimentStatus = createSelector(getExperimentDataState, state => state.get('status'));

export const getExperimentData = createSelector(getExperimentDataState, state => state.get('data'));

const getSelectedExperimentDataState = state => getSignUpScreenState(state).get('selectedExperiment');

export const getSelectedExperimentData =
  createSelector(getSelectedExperimentDataState, state => state.get('data'));

const getForcedSkipQuizState = state => getSignUpScreenState(state).get('forceSkipQuiz');

export const getForcedSkipQuizStateData = createSelector(getForcedSkipQuizState, state => state);

export const getStateForStorage = createSelector(
  getUserSelectedData,
  getCart,
  deliveryPageSelectors.deliveryOptions.getData,
  preferencesQuizResponses,
  getAddressData,
  getForcedSkipQuizStateData,
  (
    userSelectedData,
    cartData,
    deliveryOptionsData,
    preferencesQuizData,
    addressData,
    forceSkipQuiz,
  ) => ({
    userSelectedData,
    cartData,
    deliveryOptionsData,
    preferencesQuizData,
    addressData,
    forceSkipQuiz,
  }),
);

const getChefsListState = state => getSignUpScreenState(state).get('chefsList');
export const getChefsListData = createSelector(getChefsListState, state => state.data);
export const getChefsList = createSelector(getChefsListState, state => state.data && state.data.chefs);
export const getChefsListSuccess = createSelector(getChefsListState, state => state.success);


export const getDDOToggles = createSelector(
  state => getSignUpScreenState(state).get('ddoToggles'),
  state => state,
)

export const getDeliveryDays = createSelector(
  getSignupInitUpcomingDays,
  getDDOToggles,
  (upcomingDays, ddoToggles) => {
    const deliveryDays = upcomingDays

    try {
      if (Array.isArray(ddoToggles) && ddoToggles.length) {
        return applyDDOConfigs(ddoToggles, deliveryDays)
      }

      return deliveryDays
    } catch (e) {
      logError(e)
      return deliveryDays
    }
  },
)

const getChefDetailState = state => getSignUpScreenState(state).get('chefDetail');
export const chefDetail = createSelector(getChefDetailState, state => state.data);


const getMenuSortByState = state => getSignUpScreenState(state).get('menuSortBy');
export const menuSortBy = createSelector(getMenuSortByState, state => state.data);

export const getIsEconomicFlow = createSelector(getSignUpInitData, data => data.onlyEconomicPlans);
