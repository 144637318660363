import MenuAll from '../../../../assets/icons/menuby/menu-all.svg'
import Premium from '../../../../assets/icons/menuby/menuby-premium.svg'
import Cuisine from '../../../../assets/icons/menuby/menuby-cuisine.svg'
import CuisineMediterranean from '../../../../assets/icons/menuby/mediterranean.png'
import CuisineAsian from '../../../../assets/icons/menuby/asian.png'
import CuisineAmerican from '../../../../assets/icons/menuby/american.png'
import CuisineItalian from '../../../../assets/icons/menuby/italian.png'
import CuisineMexican from '../../../../assets/icons/menuby/mexican.png'
import CuisineIndian from '../../../../assets/icons/menuby/indian.png'
import CuisineMiddleEastern from '../../../../assets/icons/menuby/middleEastern.png'
import CuisineAfrican from '../../../../assets/icons/menuby/african.png'
import CuisineCaribbean from '../../../../assets/icons/menuby/caribean.png'
import CuisineFrench from '../../../../assets/icons/menuby/french.png'
import MenuByChef from '../../../../assets/icons/menuby/menuby-chef.svg'
import MenuByDiets from '../../../../assets/icons/menuby/menuby-diets.svg'
import Paleo from '../../../../assets/icons/menuby/paleo.svg'
import Vegetarian from '../../../../assets/icons/menuby/vegetarian-icon.svg'
import Keto from '../../../../assets/icons/menuby/keto.svg'
import Vegan from '../../../../assets/icons/menuby/vegan.svg'
import LowCarbs from '../../../../assets/icons/menuby/lowCarbs.svg'
import LowCalories from '../../../../assets/icons/menuby/lowCalories.svg'
import PescatarianDiet from '../../../../assets/icons/menuby/pescatarianDiet.svg'
import Mediterranean from '../../../../assets/icons/menuby/mediterraneanDiet.svg'
import MenuByProteinV2 from '../../../../assets/icons/menuby/menuby-protein2.svg'
import Beef from '../../../../assets/icons/menuby/protein-beef.svg'
import Lamb from '../../../../assets/icons/menuby/protein-lamb.svg'
import Poultry from '../../../../assets/icons/menuby/protein-poultry.svg'
import Pork from '../../../../assets/icons/menuby/protein-pork.svg'
import FishV2 from '../../../../assets/icons/menuby/protein-fish3.svg'
import Shellfish from '../../../../assets/icons/menuby/protein-shellfish.svg'
import VegetarianProtein from '../../../../assets/icons/menuby/protein-vegetarian.svg'
import VeganProtein from '../../../../assets/icons/menuby/protein-vegan.svg'

export const FILTER_IDS = {
  ALL: '0-all',
  RECOMMENDED: '2-reco',
  PREMIUM: '3-premium',
  CUISINE: '4-cuisine',
  CHEF: '5-chef',
  DIET: '6-diet',
  PROTEIN: '7-protein'
}

export const FILTER_INDEX_EXPERIMENT = {
  ALL: 0,
  RECOMMENDED: 1,
  PREMIUM: 2,
  DIET: 3,
  PROTEIN: 4,
  CUISINE: 5,
  CHEF: 6
}

const GENERIC_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    hasSub: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.RECOMMENDED,
    name: 'Recommended',
    pattern: 'recommended',
    label: 'Recommended',
    image: 'https://static.cookunity.com/cross/front-lib/icons/review-stars-3.svg',
    withTabs: false
  },
  {
    id: FILTER_IDS.PREMIUM,
    name: 'Premium',
    pattern: 'premium',
    label: 'Premium',
    image: Premium,
    isPremium: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.DIET,
    name: 'Diets',
    image: MenuByDiets,
    withTabs: true
  },
  {
    id: FILTER_IDS.PROTEIN,
    name: 'Protein',
    image: MenuByProteinV2,
    withTabs: true
  },
  {
    id: FILTER_IDS.CUISINE,
    name: 'Cuisine',
    image: Cuisine,
    withTabs: true
  },
  {
    id: FILTER_IDS.CHEF,
    name: 'Chef',
    image: MenuByChef,
    withTabs: true
  }
]

export const MENU_ITEMS_EXPERIMENT = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.RECOMMENDED,
    name: 'Recommended',
    pattern: 'recommended',
    label: 'recommended',
    image: 'https://static.cookunity.com/cross/front-lib/icons/review-stars-3.svg',
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.PREMIUM,
    name: 'Premium',
    pattern: 'premium',
    label: 'Premium',
    image: Premium,
    isPremium: true,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.DIET,
    name: 'Diets',
    withTabs: true,
    image: MenuByDiets,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 2,
        name: 'Paleo',
        label: 'paleo',
        pattern: 'paleo',
        image: Paleo
      },
      {
        id: 3,
        name: 'Vegetarian',
        label: 'vegetarian',
        pattern: 'vegetarian',
        image: Vegetarian
      },
      {
        id: 4,
        name: 'Keto',
        label: 'keto diet',
        pattern: 'keto diet',
        image: Keto
      },
      {
        id: 8,
        name: 'Vegan',
        label: 'vegan',
        pattern: 'vegan',
        image: Vegan
      },
      {
        id: 14,
        name: 'Low Carbs',
        label: 'low carbs',
        pattern: 'low carbs',
        image: LowCarbs
      },
      {
        id: 15,
        name: 'Low Calories',
        label: 'less than 600 calories',
        pattern: 'less than 600 calories',
        image: LowCalories
      },
      {
        id: 17,
        name: 'Pescatarian',
        label: 'pescatarian diet',
        pattern: 'pescatarian diet',
        image: PescatarianDiet
      },
      {
        id: 16,
        name: 'Mediterranean',
        label: 'mediterranean diet',
        pattern: 'mediterranean diet',
        image: Mediterranean
      }
    ]
  },
  {
    id: FILTER_IDS.PROTEIN,
    name: 'Protein',
    withTabs: true,
    image: MenuByProteinV2,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 1,
        name: 'Beef',
        image: Beef,
        types: ['Beef', 'Meat', 'Other Meat']
      },
      {
        id: 2,
        name: 'Lamb',
        image: Lamb,
        types: ['Lamb']
      },
      {
        id: 3,
        name: 'Poultry',
        image: Poultry,
        types: ['Poultry', 'Chicken', 'Duck', 'Turkey']
      },
      {
        id: 4,
        name: 'Pork',
        image: Pork,
        types: ['Pork']
      },
      {
        id: 5,
        name: 'Fish',
        image: FishV2,
        types: [
          'Fish',
          'Other Fish',
          'Salmon',
          'Snapper',
          'Sole',
          'Tilapia',
          'Trout',
          'Tuna',
          'Cod',
          'Catfish'
        ]
      },
      {
        id: 6,
        name: 'Shellfish',
        image: Shellfish,
        types: ['Crab', 'Scallop', 'Shrimp', 'Shellfish']
      },
      {
        id: 7,
        name: 'Vegetarian',
        image: VegetarianProtein,
        types: ['Vegetarian']
      },
      {
        id: 8,
        name: 'Vegan',
        image: VeganProtein,
        types: ['Vegan']
      }
    ]
  },
  {
    id: FILTER_IDS.CUISINE,
    name: 'Cuisine',
    image: Cuisine,
    withTabs: true,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 1,
        name: 'Mediterranean',
        pattern: 'mediterranean',
        image: CuisineMediterranean
      },
      {
        id: 2,
        name: 'Asian',
        pattern: 'asian',
        image: CuisineAsian
      },
      {
        id: 3,
        name: 'American',
        pattern: 'american',
        image: CuisineAmerican
      },
      {
        id: 4,
        name: 'Italian',
        pattern: 'italian',
        image: CuisineItalian
      },
      {
        id: 5,
        name: 'Mexican',
        pattern: 'mexican',
        image: CuisineMexican
      },
      {
        id: 6,
        name: 'Indian',
        pattern: 'indian',
        image: CuisineIndian
      },
      {
        id: 7,
        name: 'Middle Eastern',
        pattern: 'middle eastern',
        image: CuisineMiddleEastern
      },
      {
        id: 8,
        name: 'Latin American',
        pattern: 'latin-american',
        image: CuisineMiddleEastern
      },
      {
        id: 9,
        name: 'African',
        pattern: 'african',
        image: CuisineAfrican
      },
      {
        id: 10,
        name: 'Caribbean',
        pattern: 'caribbean',
        image: CuisineCaribbean
      },
      {
        id: 11,
        name: 'French',
        pattern: 'french',
        image: CuisineFrench
      },
      {
        id: 12,
        name: 'European',
        pattern: 'european',
        image: CuisineFrench
      }
    ]
  },
  {
    id: FILTER_IDS.CHEF,
    name: 'Chef',
    image: MenuByChef,
    withTabs: true,
    tabs: [],
    hasGenericTabs: false,
    isSubMenuItem: false
  }
]
