/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { Modal, CuiButton } from 'front-lib'

import { confirmNewZipcode } from 'src/redux/ducks/screens/signup/commons/creators'
import { getUserSelectedZipcode } from 'src/redux/ducks/screens/signup/commons/selectors'
import { setAddressesReset } from 'src/redux/ducks/screens/signup/pages/Checkout/creators'
import { getAddressData } from 'src/redux/ducks/screens/signup/pages/Checkout/selectors'

import './ZipcodeMismatchSolver.scss'

const ZipcodeMismatchSolver = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isInitialMount = useRef(true)
  const address = useSelector(getAddressData)
  const userSelectedZipcode = useSelector(getUserSelectedZipcode)
  const [isOpen, setIsOpen] = useState(false)

  const handleConfirm = () => {
    const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    dispatch(confirmNewZipcode({ sessionId: qsParams.cuSession }))
    qsParams.zipcode = address.zipcode
    window.location = `/eat-everything/page-2?${qs.stringify(qsParams)}`
  }

  const handleKeep = e => {
    e.preventDefault()
    dispatch(setAddressesReset())
    setIsOpen(false)
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (
      address.zipcodeMismatch
    ) {
      if (window.google_tag_manager) {
        let dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'sendToGA',
          eventAction: 'Show_Message',
          eventCategory: 'Zip_Code_Mismatch',
        })
      }
      setIsOpen(true)
    }
  }, [address.zipcodeMismatch])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleKeep}
      smallSize
      notification
    >
      <div className="zipcode-mismatch">
        <h1>Oops! Your address doesn't match your previous zip code</h1>
        <div className="zcs">
          <p className="zc-title">PREVIOUS ZIP CODE:</p>
          <p className="zc">{userSelectedZipcode}</p>
          <p className="zc-title">ADDRESS ZIP CODE:</p>
          <p className="zc">{address.zipcode}</p>
        </div>
        <p className="explain">
          If your address and new zip code are correct, please recreate your
          order.
        </p>
        <div className="buttons">
          <p>
            <CuiButton color="dark" onClick={handleConfirm} style={{ fontSize: '16px' }}>
              Confirm new address
            </CuiButton>
          </p>
          <p>
            <a href="#" onClick={handleKeep} className="keep-btn">
              Change address to previous Zip Code
            </a>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ZipcodeMismatchSolver
