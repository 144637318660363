import { useCallback, useEffect } from 'react'
import * as qs from 'qs'
import * as auth0Util from '../../../../utils/auth0'
import { getCustomAttributeValue } from '../../../../utils/auth0'
import { logError } from 'src/utils/logError'
import { useAuth0 } from '@auth0/auth0-react'
import { PATHS, pageNames } from '../../constants'
import {
  authErrorSelector,
  authUserSelector,
  isAuthenticatedSelector,
} from 'src/features/auth/authSlice'
import { useSelector } from 'react-redux'
import { redirect } from 'react-router-dom'

export const useAuthData = (
  search,
  { setFormData, sendLogging, sendTracking },
  redirectTo,
  disabled,
) => {
  const authError = useSelector(authErrorSelector)
  const authUser = useSelector(authUserSelector)
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const { loginWithRedirect, error } = useAuth0()

  useEffect(() => {
    if (disabled) return

    let authMagentoId = null
    let authUserEmail = null
    let authDataError = null

    if (authUser) {
      authMagentoId = auth0Util.getCustomAttributeValue(authUser, 'magento_id')
      authUserEmail = authUser.email
      authDataError = authError || null
    }

    setFormData({ email: authUserEmail })

    if (!isAuthenticated) {
      const {
        email,
        cuSession,
        utm_source,
        authAttemps: authAttempts,
        zipcode,
        coupon,
      } = qs.parse(search, {
        ignoreQueryPrefix: true,
      })

      sendLogging({
        event: 'auth0Data',
        warning: !!authAttempts,
        data: {
          isAuthenticated,
          email: authUserEmail,
          error: authDataError,
          attempts: authAttempts || 0,
        },
      })

      // force authentication if the first attempt fails
      if (cuSession) {
        const countAttempts = !authAttempts ? 1 : +authAttempts + 1
        const search = `?zipcode=${zipcode}${
          coupon ? `&coupon=${coupon}` : ''
        }&authAttemps=${countAttempts}&cuSession=${cuSession}`

        const loginAuth0 = async () => {
          const redirectUriPath = redirectTo
            ? redirectTo
            : PATHS[pageNames.CHECKOUT]

          await loginWithRedirect({
            action: 'signup',
            redirectUri: `${window.location.origin}${redirectUriPath}${search}`,
            initialEmail: email,
            search: search,
            env: process.env.NODE_ENV,
            isDemo: utm_source === 'demo',
            errorMessage: error && error.message,
          })
        }

        loginAuth0()
          .then()
          .catch(caughtError => {
            logError(caughtError)
            sendTracking({
              eventName: 'Sign Up - Login uncompleted with error',
              eventData: {
                user_id: authMagentoId,
                user_email: authUserEmail,
                login_type: authUser
                  ? getCustomAttributeValue(authUser, 'provider')
                  : null,
                error: caughtError,
                error_message: error,
              },
            })
          })
      } else {
        redirect(
          `${window.location.origin}${
            PATHS[pageNames.PLANS]
          }${search}`,
        )
      }
    } else {
      sendTracking({
        eventName: 'Sign Up - Login Completed',
        eventData: {
          user_id: authMagentoId,
          user_email: authUserEmail,
          login_type: getCustomAttributeValue(authUser, 'provider'),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, authUser, isAuthenticated])
}

export const useSendLogging = (sendLogging, addSeoData) =>
  useEffect(() => {
    sendLogging({
      event: 'checkoutPage',
      warning: false,
      data: {
        category: 'new-funnel',
        label: 'test-new-funnel',
        action: 'checkout-page',
      },
    })

    addSeoData({ title: 'A Chef Collective | CookUnity | Checkout' })
  }, [sendLogging, addSeoData])

export const useCallbacks = (
  { setUserSelectedDataStart, setCheckoutStep },
  formSteps,
) => {
  const setTimeSlot = useCallback(
    selectedTimesLot => setUserSelectedDataStart({ selectedTimesLot }),
    [setUserSelectedDataStart],
  )
  const setDeliveryOption = useCallback(
    deliveryOption => setUserSelectedDataStart({ deliveryOption }),
    [setUserSelectedDataStart],
  )
  const setGiftCardCode = useCallback(
    giftCardCode => setUserSelectedDataStart({ giftCardCode }),
    [setUserSelectedDataStart],
  )

  const showHandler = useCallback(
    id => () => {
      setCheckoutStep({ [id]: !formSteps[id] })
    },
    [setCheckoutStep, formSteps],
  )

  return { setTimeSlot, setDeliveryOption, showHandler, setGiftCardCode }
}
