import duck from "./duck";
import * as actionCreators from "./actions";

export const startFormData = duck.createAction(actionCreators.USER_FORM_DATA.start);
export const setFormData = duck.createAction(actionCreators.USER_FORM_DATA.success);
export const setFormDataFailed = duck.createAction(actionCreators.USER_FORM_DATA.failed);

export const startUserCard = duck.createAction(actionCreators.USER_CARD.start);
export const setCard = duck.createAction(actionCreators.USER_CARD.success);
export const setCardFailed = duck.createAction(actionCreators.USER_CARD.failed);

export const startAddresses = duck.createAction(actionCreators.USER_ADDRESSES.start);
export const setAddresses = duck.createAction(actionCreators.USER_ADDRESSES.success);
export const setAddressesFailed = duck.createAction(actionCreators.USER_ADDRESSES.failed);
export const setAddressesReset = duck.createAction(actionCreators.USER_ADDRESSES.reset);

export const startSetCheckoutStep = duck.createAction(actionCreators.CHECKOUT_PAGE.startSetStep);
export const setCheckoutStep = duck.createAction(actionCreators.CHECKOUT_PAGE.setStep);

export const startCoupon = duck.createAction(actionCreators.USER_COUPON_DATA.start);
export const setCoupon = duck.createAction(actionCreators.USER_COUPON_DATA.success);
export const setCouponFailed = duck.createAction(actionCreators.USER_COUPON_DATA.failed);
export const setCouponReset = duck.createAction(actionCreators.USER_COUPON_DATA.reset);

export const startValidateGiftcard = duck.createAction(actionCreators.VALIDATE_GIFTCARD.start);
export const setValidateGiftcard = duck.createAction(actionCreators.VALIDATE_GIFTCARD.success);
export const setValidateGiftcardFailed = duck.createAction(actionCreators.VALIDATE_GIFTCARD.failed);
export const resetValidateGiftcard = duck.createAction(actionCreators.VALIDATE_GIFTCARD.reset);
