import React, { useCallback, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import MenuList from './MenuList'
import MenuListCategories from './MenuListCategories'
import * as handleQuizPreferences from 'src/redux/ducks/modules/MenuList/utils/handleQuizPreferences'
import * as selectorMenuList from 'src/redux/ducks/modules/MenuList/selectors'
import * as actionCreators from 'src/redux/ducks/modules/MenuList/creators'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as myUtils from './utils'
import * as signUpCommonActionCreators from '../../redux/ducks/screens/signup/commons/creators'
import { SESSION_KEY } from '../../constants'
import * as preOrderPageSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'

import * as creatorCommons from '../../redux/ducks/commons/creators'
import FilterTopBar from './Components/FilterTopBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { CuiButton, CuiIcon } from 'front-lib'
import classNames from 'classnames'
import { MENU_OPTIONS } from './contants'
import useTrackEvent from 'src/hooks/useTrackEvent'

const SELECTED_MEAL_FILTER_STORE_ID = 'SELECTED_MEAL_FILTER'
const DEFAULT_MEAL_FILTER_ID = 'all'
const DEFAULT_MEAL_FILTER_TYPE = 'meatType'

function MenuListModuleContainer(props) {
  const {
    selectedProducts,
    menuData,
    menuFilters,
    totalOrderedProductsQty,
    maxProductToCart,
    addProduct,
    onClickChooseForMe,
    isLoading,
    showFilterBar,
    removeProduct,
    canEdit,
    onMealClick,
    isExternalLoading,
    setShowQuiz,
    selectedPlan,
    startDay,
    preSelectError,
    preferencesQuizResponses,
    menuDate,
    zipcode,
    menuLoadingMode,
    forYouExperiment,
    bestSellerMeals,
    actions,
  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const [forcedFilter, setForcedFilter] = useState(false)
  const [selectedMenuOption, setSelectedMenuOption] = useState('')
  const [viewAll, setViewAll] = useState('')

  const trackProductListViewed = useTrackEvent({
    eventName: 'Product List Viewed',
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [viewAll, selectedMenuOption])

  /**
   * @description Track Product List Viewed event when mounting Menu 
   */
  useEffect(() => {
    const filterValue =
      menuFilters?.meatTypeFilter ||
      menuFilters?.specificationsDetailFilter ||
      'all'
    const filterType = menuFilters?.meatTypeFilter ? 'meatType' : 'specificationsDetail'

    const filtersApplied = [{
      filter_group: 'All',
      filter_type: filterType,
      filter_value: filterValue,
    }]

    if (menuFilters?.searchInput) {
      filtersApplied.push({
        filter_group: '',
        filter_type: 'Search',
        filter_value: menuFilters?.searchInput,
      })
    }

    if (!showLoading) {
      trackProductListViewed({
        count_products_available: menuData?.menu?.allMeals?.length,
        count_products_displayed: menuData?.menu?.meals?.length,
        delivery_date: startDay?.date,
        delivery_date_cutoff_time: startDay?.cutoff?.time,
        filters_applied: filtersApplied,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData?.menu?.meals])

  useEffect(() => {
    const mealsMenu = menuData.menu

    const showRecommendationsFilter = menuData.showRecommendationsFilter

    if (!isLoading && showRecommendationsFilter && !forcedFilter) {
      actions.filterMeals({
        ...mealsMenu,
        filters: menuFilters,
        id: myUtils.recommendationsFilterOption.id,
        type: myUtils.recommendationsFilterOption.type,
        productsInCart: getProductDataOfCart(),
      })

      setForcedFilter(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedFilter, isLoading, menuData.showRecommendationsFilter])

  useEffect(() => {
    const goals = handleQuizPreferences.filter(
      preferencesQuizResponses.goals,
      'goals',
    )
    const tastes = handleQuizPreferences.filter(
      preferencesQuizResponses.proteins,
      'proteins',
    )
    const allergies = handleQuizPreferences.filter(
      preferencesQuizResponses.allergies,
      'allergens',
    )
    const diets = handleQuizPreferences.filter(
      preferencesQuizResponses.diets,
      'diets',
    )
    const specificationsAvoid = handleQuizPreferences.filter(
      preferencesQuizResponses.specificationsAvoid,
      'specificationsAvoid',
    )

    const allergens = handleQuizPreferences.filter(
      preferencesQuizResponses.allergens,
      'allergens',
    )

    if (zipcode && menuDate) {
      actions.loadMenuData({
        date: menuDate,
        zipcode,
        goals: goals ? goals : [],
        ingredientsAvoid: allergies ? allergies : [],
        tastes: tastes ? tastes : [],
        diets: diets,
        specificationsAvoid: specificationsAvoid,
        allergens: allergens,
        useMealServiceAllergens: true,
        mode: menuLoadingMode,
        productsInCart: getProductDataOfCart(),
      })

      if (forYouExperiment) {
        actions.loadMenuSortBy({
          menuDate,
          zipcode,
          sortBy: {
            bestSellers: true,
          },
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const selectedMealFilterStorage = getSelectedMealFilter()

    if (
      !isLoading &&
      menuData.menu.meals.length > 0 &&
      selectedMealFilterStorage &&
      !forYouExperiment
    ) {
      actions.filterMeals({
        ...menuData.menu,
        filters: menuFilters,
        id: selectedMealFilterStorage.body.id,
        type: selectedMealFilterStorage.body.type,
        productsInCart: getProductDataOfCart(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const getProductDataOfCart = useCallback(() => {
    return myUtils.getProductDataListOfCart(selectedProducts)
  }, [selectedProducts])

  const getCategory = meal => {
    const MEAT_TYPES = [
      'Beef',
      'Chicken',
      'Other fish',
      'Other meat',
      'Pork',
      'Salmon',
      'Seafood',
      'Vegan',
      'Vegetarian',
      'Poultry',
      'Sea food',
      'Meat',
      'Fish',
    ]

    const meatTag = meal.specifications_detail
      .filter(tag => tag && tag.label.trim())
      .find(tag => MEAT_TYPES.indexOf(tag) !== -1)
    const meatLabel =
      meal.meat_type || (typeof meatTag === 'undefined' ? meatTag : null)

    // [TODO] this is a similar hardcoded list that is in acquisition-web/src/redux/ducks/modules/MenuList/utils/index.js
    const meats = ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork', 'Meat']
    const seafood = [
      'Catfish',
      'Cod',
      'Crab',
      'Other fish',
      'Salmon',
      'Scallop',
      'Seafood',
      'Shrimp',
      'Snapper',
      'Sole',
      'Tilapia',
      'Trout',
      'Tuna',
      'fish',
      'Sea food',
      'Fish',
    ]
    const poultry = ['Chicken', 'Turkey', 'Poultry']
    const vegan = ['Vegan']
    const vegetarian = ['Vegetarian']

    if (meats.includes(meatLabel)) {
      return 'meat'
    } else if (seafood.includes(meatLabel)) {
      return 'seafood'
    } else if (poultry.includes(meatLabel)) {
      return 'poultry'
    } else if (vegan.includes(meatLabel)) {
      return 'vegan'
    } else if (vegetarian.includes(meatLabel)) {
      return 'vegetarian'
    }

    return null
  }

  const getQtyOrderedMeal = id => {
    if (selectedProducts) {
      const product =
        selectedProducts && selectedProducts.length > 0
          ? selectedProducts.find(p => p.id === id)
          : null

      return product ? product.qty : 0
    }
  }

  const setSessionMealFilter = (id, type, deleteStorage = false) => {
    const currentSessionMealFilter = getSelectedMealFilter()

    if (deleteStorage) {
      window.sessionStorage.removeItem(SELECTED_MEAL_FILTER_STORE_ID)
      return
    }

    const resetFilter =
      currentSessionMealFilter?.body.id === id &&
      currentSessionMealFilter?.body.type === type

    const body = {
      id: resetFilter ? DEFAULT_MEAL_FILTER_ID : id,
      type: resetFilter ? DEFAULT_MEAL_FILTER_TYPE : type,
    }

    window.sessionStorage.setItem(
      `${SELECTED_MEAL_FILTER_STORE_ID}`,
      JSON.stringify({ body, timestamp: Date.now() }),
    )
  }

  const getSelectedMealFilter = () => {
    return JSON.parse(
      window.sessionStorage.getItem(`${SELECTED_MEAL_FILTER_STORE_ID}`),
    )
  }

  const onFilterMeals = (
    id,
    type,
    isFiltersBy,
    isDefault = false,
    searchInputFilter,
  ) => {
    const searchInput =
      typeof searchInputFilter !== 'undefined'
        ? searchInputFilter
        : menuFilters?.searchInput

    const currentFilter =
      menuFilters[
        type === 'meatType' ? 'meatTypeFilter' : 'specificationsDetailFilter'
      ]

    if (isFiltersBy) {
      actions.filterBy({
        menu: id,
        tab: type,
        isDefault,
      })

      return
    }

    actions.filterMeals({
      ...menuData.menu,
      filters: menuFilters,
      id,
      type,
      productsInCart: getProductDataOfCart(),
      searchInput,
      currentFilter,
    })

    setSessionMealFilter(id, type)
  }

  const goToSelectDeliveryDateStep = () => {
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection - Change delivery date clicked',
    })

    const deliveryDateStepKey = '/eat-everything/page-2'
    const nextRoute = ['', deliveryDateStepKey].join('')
    actions.persistState({ sessionId: SESSION_KEY })
    navigate(`${nextRoute}${location.search}`)
  }

  const showLoading = isLoading || isExternalLoading
  const planMeals = selectedPlan ? selectedPlan.mealsPerDelivery : 0
  const personalizedMenu = menuData.personalized
  const hasPersonalized = personalizedMenu.meals.length > 0

  const MenuListExp = !forYouExperiment ? MenuList : MenuListCategories

  const filteredMeals =
    menuData?.menu?.meals?.filter(meal => meal.matches_preferences).length > 0

  useEffect(() => {
    if (forYouExperiment) {
      handleSelectMenuOption(MENU_OPTIONS.FOR_YOU)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectMenuOption = useCallback(option => {
    setSelectedMenuOption(option)
  }, [])

  const setShowQuizRemoveFilterState = value => {
    setSessionMealFilter('', '', true)
    setShowQuiz(value)
  }

  return (
    <>
      {viewAll && (
        <div className="container-cui back-for-you">
          <CuiButton fill="clear" color="dark" onClick={() => setViewAll('')}>
            <CuiIcon name="chevronLeft" />
            Back to For You
          </CuiButton>
        </div>
      )}

      {planMeals > 0 && !viewAll && (
        <div
          className={classNames({
            filter_bar_for_you: forYouExperiment,
          })}
        >
          <FilterTopBar
            startDay={startDay}
            goToSelectDeliveryDateStep={goToSelectDeliveryDateStep}
            hasPersonalized={hasPersonalized}
            setShowQuiz={setShowQuizRemoveFilterState}
            sendTracking={actions.sendTracking}
          />
        </div>
      )}

      <MenuListExp
        canEdit={canEdit}
        addProduct={addProduct}
        removeProduct={removeProduct}
        isLoading={showLoading}
        handlerFilterOnClick={onFilterMeals}
        categoryList={getCategory.bind(this)}
        showFilterBar={showFilterBar}
        countOrderProduct={getQtyOrderedMeal.bind(this)}
        onMealClick={onMealClick}
        meatTypeFilter={menuFilters.meatTypeFilter}
        specificationsDetailFilter={menuFilters.specificationsDetailFilter}
        totalOrderedProductsQty={totalOrderedProductsQty}
        maxProductToCart={maxProductToCart}
        showRecommendationsFilter={menuData.showRecommendationsFilter}
        preSelectError={preSelectError}
        setShowQuiz={setShowQuizRemoveFilterState}
        hasPersonalized={hasPersonalized}
        preferencesQuizResponses={preferencesQuizResponses}
        getCategory={getCategory}
        getProductDataOfCart={getProductDataOfCart}
        mealsMenu={menuData.menu}
        sendTracking={actions.sendTracking}
        menuDate={menuDate}
        zipcode={zipcode}
        onClickChooseForMe={onClickChooseForMe}
        highlightedMeals={menuData.highlightedMeals}
        forYouExperiment={forYouExperiment}
        selectedMenuOption={selectedMenuOption}
        personalizedMenu={personalizedMenu}
        viewAll={viewAll}
        onClickViewAll={setViewAll}
        preferencesSettled={hasPersonalized || filteredMeals}
        bestSellerMeals={bestSellerMeals?.menu || []}
        onSelectMenuOption={handleSelectMenuOption}
        onClickViewFullMenu={() => {
          setSelectedMenuOption(MENU_OPTIONS.FULL_MENU)
          onFilterMeals('all', 'meatType')
        }}
      />
    </>
  )
}

const mapStateToProps = state => ({
  menuData: selectorMenuList.getMenuData(state),
  isLoading: selectorMenuList.getSignUpMenuLoading(state),
  menuFilters: selectorMenuList.getMenuFilter(state),
  preferencesQuizResponses: signUpCommonSelectors.preferencesQuizResponses(
    state,
  ),
  experimentData: signUpCommonSelectors.getExperimentData(state).object,
  preSelectError: preOrderPageSelectors.getCart(state).preSelectError,
  selectedProducts: preOrderPageSelectors.getCart(state).products,
  bestSellerMeals: signUpCommonSelectors.menuSortBy(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadMenuData: actionCreators.startLoadProducts,
      loadMenuSortBy: signUpCommonActionCreators.getMenuSortBy,
      filterMeals: actionCreators.startFilter,
      persistState: signUpCommonActionCreators.persistState,
      sendTracking: creatorCommons.trackingStart,
      filterBy: actionCreators.startFilterBy,
    },
    dispatch,
  ),
})

export const MenuListModule = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuListModuleContainer)
