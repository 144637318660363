import { useSelector } from 'react-redux'
import { CuiButton } from 'front-lib'
import { useNavigate } from 'react-router-dom'
import { deliveryDaySelectedSelector } from 'src/features/delivery/deliverySlice'
import './DeliveryDate.scss'
import { RESURRECTION_PATHS } from 'src/constants/routes'
import useTrackEvent from 'src/hooks/useTrackEvent'

const DeliveryDate = () => {
  const navigate = useNavigate()
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)

  const trackChangeSelected = useTrackEvent({
    eventName: 'Change delivery date button clicked',
  })

  const handlerChangeDeliveryDate = () => {
    trackChangeSelected()
    navigate(RESURRECTION_PATHS.DELIVERY_DAY_SELECTION)
  }

  return (
    <div className="delivery-date">
      <p className="delivery-date--text">
        Delivery on <b>{deliveryDaySelected?.label}</b>
      </p>
      <CuiButton
        size="small"
        fill="outline"
        color="dark"
        onClick={handlerChangeDeliveryDate}
      >
        Change
      </CuiButton>
    </div>
  )
}

export default DeliveryDate
