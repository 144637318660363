import React from 'react'
import { Button } from 'src/components/Button'
import NoPreferencesMeal from 'src/assets/img/no-preferences-meal.png'
import styles from './NoPreferencesSelected.module.scss'

export function NoPreferencesSelected({ onClick }) {
  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src={NoPreferencesMeal}
        alt="no preferences meal"
      />
      <div className={styles.text}>
        <p>
          <b>Make it yours! </b>
          Set your preferences, and we'll suggest the perfect meals just for
          you.
        </p>
      </div>
      <Button
        onClick={onClick}
        className={styles.button}
        size="large"
        fill="outline"
        color="dark"
      >
        Set my preferences
      </Button>
    </div>
  )
}
