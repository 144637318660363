import {
  subscriptionMutate,
  subscriptionPublicQuery,
  subscriptionQuery,
} from '../../api/client'
import { getUserDeliveryDaySelected } from '../delivery/deliveryAPI'

const planFragment = `
  fragment PlanInfo on Plan {
    id
    title
    price
    mealsPerDelivery
    deliveries
    deliveryFee
  }
`;

const plansQuery = `
  query plans($zipCode: String!) {
    plans: availablePlans(zipcode: $zipCode) {
      ...PlanInfo
    }
  }
  ${planFragment}
`;

const userPlansQuery = `
  query userPlans {
    plans: availablePlans {
      ...PlanInfo
    }
  }
  ${planFragment}
`;

const changePlanMutation = `
mutation changePlan($planChange: ChangePlanInput!){
  changePlan(planChange: $planChange) {
    __typename
    ... on PlanChangeInfo {
      dateInEffect
    }
    ... on PlanChangeError {
      error
    }
  }
}
`

export const getPlans = ({ zipCode }) =>
  subscriptionPublicQuery(plansQuery, { zipCode })

export const getUserPlans = () => subscriptionQuery(userPlansQuery)

export const updateUserPlan = async ({ plan }) => {
  const response = await getUserDeliveryDaySelected({
    fetchPolicy: 'network-only',
  })

  const deliveryDay = response.users[0].deliveryDays[0]

  return subscriptionMutate(changePlanMutation, {
    planChange: {
      plan: plan.id,
      deliveryDays: {
        days: deliveryDay.day,
        end: deliveryDay.time_end,
        start: deliveryDay.time_start,
      },
    },
  })
}
