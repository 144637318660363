import React from 'react'
import QuizOptionsCui from './QuizOptionsCui'

const QuizOptionsCuiContainer = props => {
  const { question, nextAction, actions, preferencesQuizResponses } = props

  const selected =
    (preferencesQuizResponses && preferencesQuizResponses[question.name]) || []

  function handleSelected(key, optionsSelected) {
    actions.setResponse({ [key]: optionsSelected })
    actions.updateChefsList()

    if (nextAction && optionsSelected.length === question.max) {
      setTimeout(() => {
        nextAction()
      }, 500)
    }
  }

  return (
    <QuizOptionsCui
      {...props}
      optionsSelected={selected}
      onSelect={handleSelected}
    />
  )
}

export default QuizOptionsCuiContainer
