import classnames from 'classnames';
import { array, bool, func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { trackingStart } from 'src/redux/ducks/commons/creators';
import { getSignUpInitData, getUserSelectedData } from 'src/redux/ducks/screens/signup/commons/selectors';
import { getCart } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors';
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'
import CULogo from '../../assets/icons/cu-yellow.svg'

import {
  findSpecificationDetail, formatChefName,
  formatFee, formatMealRating,
  formatMealReviews, getLoadingContainer,
  getProteinTag,
  getPromotionTag,
} from './utils';

import { CuiAvatar, CuiCardContent, CuiFabButton, CuiIcon, CuiImage, CuiItem, CuiMealCard, CuiMealDescription, CuiMealImage, CuiMealPrice, CuiQuantitySelector, CuiTag } from 'front-lib';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'src/utils/events';
import DiamondIcon from '../../assets/svg/diamond.svg';
import styles from './MealCard.module.scss';
import images from './images';
import { useVariant } from '@unleash/proxy-client-react';
import { logError } from 'src/utils/logError';

const CONTROLLERS_OPENED_MS = 2500
const defaultCallback = () => { }
const basePathImg = process.env.REACT_APP_IMG_STORAGE

const getOrdinal = number => {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
}

const MealCard = ({
  meal,
  quantity,
  onAddItem,
  onRemoveItem,
  isEditable,
  disableAddItem,
  onMealClick,
  onChefClick,
  noSelected,
  isLoading,
  hideCartControllers,
  cartData,
  userStartDay,
  listName,
  listType,
  storeData,
  actions,
  highlightedMeal,
  menuFilters,
  selectedMenuOption,
  className,
  index,
}) => {
  const {
    name = '',
    short_description = '',
    calories = '',
    protein_type = '',
    reviews = null,
    stars = null,
    chef_firstname = '',
    chef_lastname = '',
    full_path_meal_image = null,
    full_path_chef_image = null,
    premium_fee = null,
    fixed_price = false,
    stock = 0,
    specifications_detail = [],
  } = meal

  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');

  const [showCartControllers, setShowCartControllers] = useState(false)
  const [errorImage, setErrorImage] = useState(false)
  const [maxQuantity, setMaxQuantity] = useState(30)

  const chefFullName = formatChefName(chef_firstname, chef_lastname)
  const mealRating = formatMealRating(stars)
  const mealReviews = formatMealReviews(reviews)
  const premiumFeeString = formatFee(premium_fee, fixed_price)
  const proteinTag = getProteinTag(protein_type)
  const isSpicy = findSpecificationDetail(specifications_detail, 'spicy')
  const selected = quantity > 0
  const disableAddItemBtn = disableAddItem || !isEditable || quantity >= stock
  const imageComingSoon = /no_selection|no-image|null|undefined/.test(
    full_path_meal_image
  )
  const promotionTag = getPromotionTag(meal)

  const handleAddItem = () => {
    if (hideCartControllers) {
      return
    }

    setShowCartControllers(true)
    if (!disableAddItemBtn) {

      const filterValue =
        menuFilters?.meatTypeFilter ||
        menuFilters?.specificationsDetailFilter ||
        'all'
      const filterType = menuFilters?.meatTypeFilter ? 'meatType' : 'specificationsDetail'

      const filtersApplied = [{
        filter_group: 'All',
        filter_type: filterType,
        filter_value: filterValue
      }]

      if (menuFilters?.searchInput) {
        filtersApplied.push({
          filter_group: '',
          filter_type: 'Search',
          filter_value: menuFilters.searchInput,
        })
      }

      sendTracking('Product Added', {
        cart_quantity_added: 1,
        cart_product_quantity: quantity + 1,
        cart_total_quantity: cartData.totalProductQty + 1,
        product_add_source: selectedMenuOption || 'Meals sets menu selection',
        list_name: highlightedMeal && "Best Picks Meals Row",
        list_type: highlightedMeal && "Highlighted Row",
        section: selectedMenuOption,
        filters_applied: filtersApplied
      });
      onAddItem()
    }
  }
  const handleRemoveItem = () => {
    if (hideCartControllers) {
      return
    }

    setShowCartControllers(true)
    sendTracking('Product Removed', {
      cart_quantity_removed: 1,
      cart_product_quantity: quantity - 1,
      cart_total_quantity: cartData.totalProductQty - 1,
      product_remove_source: 'Meals sets menu selection',
    });
    onRemoveItem()
  }

  const sendTracking = (eventName, params = {}) => {
    const stock = meal.stock ? meal.stock : 0
    const specificationsDetails =
      meal.specificationsDetails || meal.specifications_detail || []
    const chefName = `${meal.chef_firstname ||
      meal?.chef?.firstName ||
      ''} ${meal.chef_lastname || meal?.chef?.lastName || ''}`
    const premiumFee = meal.premium_fee || meal.premiumFee || 0
    const trackEvent = {
      eventName,
      eventData: {
        menu_store: storeData.name,
        delivery_date: userStartDay.date,
        list_name: listName,
        list_type: listType,
        product: {
          id: meal.entity_id || meal.mealExternalId,
          chef_name: chefName,
          category: meal.category_id || meal.categoryId,
          heat_instructions_microwave: null,
          heat_instructions_oven: null,
          image_url: meal.primaryImageUrl,
          ingredients: meal.ingredients
            .map(specification => specification.name)
            .join(', '),
          name: meal.name,
          nutritional_info: null,
          price: meal.price,
          rating: meal.stars,
          rating_count: meal.reviews,
          ratio_carb: meal.carbs,
          ratio_fat: meal.fat,
          ratio_protein: meal.protein,
          sku: meal.sku,
          specification: specificationsDetails
            .map(specification => specification.label)
            .join(', '),
          premium: premiumFee > 0,
        },
        product_initial_capacity: stock,
        product_remaining_capacity: stock - params.cart_product_quantity,
        screen_name: isMobile() ? 'Meals Selection' : null,
        page_name: isMobile() ? null : 'Meals Selection',
        ...params,
      },
    }

    actions.sendTracking(trackEvent)
  }

  useEffect(() => {
    setMaxQuantity(disableAddItemBtn ? quantity : 30)
  }, [disableAddItemBtn, quantity])

  useEffect(() => {
    const cartTimer = setTimeout(() => {
      setShowCartControllers(false)
    }, CONTROLLERS_OPENED_MS)
    return () => {
      clearTimeout(cartTimer)
    }
  }, [quantity, showCartControllers])

  if (isLoading) {
    return getLoadingContainer()
  }

  const chefProps = {
    className: styles.meal_card__chef_container
  }

  if (typeof onChefClick === 'function') {
    chefProps.onClick = () => onChefClick()
    chefProps.className = classnames(styles.meal_card__chef_container, {
      [styles.linkable]: !!onChefClick
    })
  }

  const handleMealClick = () => {
    if (typeof onMealClick === 'function') {
      onMealClick()
    }
  }

  const MealTags = () => (
    <>
      {promotionTag && (
        <CuiTag
          color={promotionTag.color}
          fill="solid"
          size="small"
          type="tag"
          style={{ position: 'absolute', top: '10px', left: '10px' }}
        >
          {promotionTag.label}
        </CuiTag>
      )}

      <div className="cui-meal-card-tags">
        {parseInt(mealReviews) > 0 && parseInt(mealRating) > 0 && (
          <CuiTag color="white" fill="solid" size="small" type="tag">
            <CuiIcon name="starFull" />
            {mealRating} <span className="reviews">({mealReviews})</span>
          </CuiTag>
        )}
        <CuiTag
          className="calories"
          color="white"
          fill="solid"
          size="small"
          type="tag"
        >
          {calories} cal
        </CuiTag>

        {proteinTag && proteinTag.icon && (
          <CuiTag
            className={classnames(styles.cui_meal_tag_icon, styles.only_icon)}
            color="white"
            fill="solid"
            size="small"
            type="tag"
          >
            <CuiImage src={proteinTag.icon} alt={proteinTag.label} />
            <div className={styles.tooltip}>{`${proteinTag.label}`}</div>
          </CuiTag>
        )}

        {isSpicy && (
          <CuiTag
            className={classnames(styles.cui_meal_tag_icon, styles.only_icon)}
            color="white"
            fill="solid"
            size="small"
            type="tag"
          >
            <CuiImage src={images.spicyIcon} alt={isSpicy.label} />
            <div className={styles.tooltip}>{`${isSpicy.label}`}</div>
          </CuiTag>
        )}
      </div>
    </>
  )
  const trackMealErrorImage = (imgError) => {
    const error = new Error(`Meal image id: ${meal.entity_id}`)
    const context = { message: 'error on MealCardPremium MealCard', meal, ...imgError }
    logError(error, context)
  }

  const trackChefErrorImage = (imgError) => {
    const error = new Error(`Chef image error id: ${meal.chef_id}. Meal id: ${meal.entity_id}`)
    const context = { message: 'error on MealCardPremium MealCard', meal, ...imgError }
    logError(error, context)
  }

  const hasPremiumFee = premium_fee > 0;
  const firstCard = index === 0

  return (
    <CuiMealCard
      className={
        classnames(
          styles.cui_meal_card,
          premium_fee && styles.cui_meal_card_premium,
          onMealClick && styles.cui_meal_card__clickable,
          selected && !noSelected && styles.in_cart,
          className,
        )
      }
      color="dark"
      direction="column"
    >
      <div className={styles.cui_actions}>
        {(!showCartControllers || !selected) ? (
          <>
            <CuiFabButton color={hasPremiumFee ? 'gold' : 'dark'} disabled={!(isEditable && quantity > 0) && disableAddItemBtn} onClick={() => {
              if (!hideCartControllers) {
                !selected
                  ? handleAddItem()
                  : setShowCartControllers(true)
              }
            }}>
              {isEditable && quantity > 0 ? quantity : <CuiIcon name="plus" />}
            </CuiFabButton>
          </>
        ) : (
          <CuiQuantitySelector color={hasPremiumFee ? 'gold' : 'dark'} onRemoveItem={() => quantity && handleRemoveItem()} onAddItem={() => handleAddItem()} value={quantity} max={maxQuantity} showBorder={true} />
        )}
      </div>
      <CuiMealImage onClick={handleMealClick}>
        {isTreatment ? (
          <CuiImage
            className={styles.cui_meal_card_image}
            onClick={handleMealClick}
            basePath={basePathImg}
            relativePath={meal.image_path + meal.image}
            noImageSrc={images.noMealImg}
            alt={imageComingSoon || errorImage ? 'no meal image' : name}
            loading="lazy"
            onError={trackMealErrorImage}
          />
        ) : (
          <CuiImage
            className={styles.cui_meal_card_image}
            onClick={handleMealClick}
            src={`${imageComingSoon || errorImage
              ? images.noMealImg
              : full_path_meal_image
              }`}
            onError={(imgError) => { trackMealErrorImage(imgError); setErrorImage(true) }}
            alt={imageComingSoon || errorImage ? 'no meal image' : name}
            loading="lazy"
          />
        )}
        {MealTags()}
        
        {(!!index || firstCard) && (
          <div
            className={classnames(styles.cui_top_ordinal_tag, {
              [styles['cui_top_ordinal_tag--first']]: firstCard,
            })}
          >
            <img src={CULogo} alt="cookunity" />
            <p> {getOrdinal(index + 1)}</p>
          </div>
        )}

      </CuiMealImage>

      <CuiCardContent className={styles.cui_meal_card_content}>
        {hasPremiumFee && (
          <div className={styles.container_chef_special}>
            <CuiImage
              src={DiamondIcon}
              title="Chef Specials"
            />
            <p>
              Chef Specials
            </p>
          </div>
        )}
        <CuiItem data-testid="container-chef-image" onClick={() => onChefClick && onChefClick()}>
          <CuiAvatar
            data-testid="chef-image"
            border="rounded"
            src={full_path_chef_image}
            title={chefFullName}
            onError={trackChefErrorImage}
            loading="lazy"
          />
          <p>
            {chefFullName}
          </p>
        </CuiItem>
        <CuiMealDescription className={styles.cui_meal_description} onClick={handleMealClick}>
          <b>{name}</b> {short_description}
        </CuiMealDescription>
        {hasPremiumFee ? (
          <CuiMealPrice>
            {premiumFeeString}
          </CuiMealPrice>
        ) : null}
      </CuiCardContent>
    </CuiMealCard>
  )
}

MealCard.propTypes = {
  meal: shape({
    name: string.isRequired,
    short_description: string,
    calories: number,
    protein_type: string,
    reviews: number,
    stars: number,
    chef_firstname: string.isRequired,
    chef_lastname: string.isRequired,
    full_path_meal_image: string.isRequired,
    full_path_chef_image: string.isRequired,
    is_celebrity_chef: bool,
    premium_fee: number,
    fixed_price: bool,
    feature: shape({
      name: string,
      description: string,
      background: string,
      color: string
    }),
    stock: number,
    specifications_detail: array,
    warning: string,
    allergens: array,
    user_rating: number,
    premium_special: string
  }),
  isEditable: bool,
  disableAddItem: bool,
  quantity: number,
  noExtraFee: bool,
  onAddItem: func,
  onRemoveItem: func,
  onClick: func,
  onMealClick: func,
  onChefClick: func,
  buttonLike: bool,
  onWarnings: bool,
  isLikeMarked: bool,
  onLikeMeal: func,
  compact: bool,
  enableCelebrityFeatures: bool,
  hideCartControllers: bool,
  cartData: shape({}),
  storeData: shape({}),
  userStartDay: shape({}),
  actions: shape({})
}

MealCard.defaultProps = {
  meal: {},
  quantity: 0,
  noExtraFee: false,
  isEditable: true,
  disableAddItem: false,
  buttonLike: false,
  onWarnings: false,
  onAddItem: defaultCallback,
  onRemoveItem: defaultCallback,
  onClick: defaultCallback,
  onMealClick: defaultCallback,
  onChefClick: null,
  onLikeMeal: defaultCallback,
  enableCelebrityFeatures: false,
  hideCartControllers: false,
  cartData: {},
  storeData: {},
  userStartDay: {},
  actions: null
}

const mapStateToProps = state => ({
  cartData: getCart(state),
  storeData: getSignUpInitData(state).store,
  userStartDay: getUserSelectedData(state).startDay,
  menuFilters: getMenuFilter(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: trackingStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MealCard)
