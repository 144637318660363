import React from 'react'
import { CuiButton, CuiIcon } from 'front-lib'
import Portal from '../Portal/Portal'
import Backdrop from '../Backdrop/Backdrop'
import './Modal.scss'
import classnames from 'classnames'

const Modal = ({
  open,
  onClose,
  showCloseButton = true,
  closeOnOverlayClick = true,
  className,
  children,
}) => {
  const handleBackdropClick = () => {
    if (closeOnOverlayClick) {
      onClose()
    }
  }

  return (
    <Portal>
      <Backdrop open={open} onClick={handleBackdropClick}>
        <div className={classnames('modal', className)}>
          {showCloseButton && (
            <CuiButton className="modal__close" onClick={onClose} fill="clear">
              <CuiIcon name="close" />
            </CuiButton>
          )}
          {children}
        </div>
      </Backdrop>
    </Portal>
  )
}

export default Modal
