import {
  IRecord as record
} from "src/redux/helpers/Immutable";

import duck from "./duck";
import * as actions from "./actions";

const initialState = record({
  detail: record({
    start: false,
    success: false,
    data: null,
    selectedProductId: null,
    error: null
  }),
});

const productDetailReducer = {
  [actions.LOAD_PRODUCT_DETAIL.start]: (state) => {
    return state
      .setIn(["detail", "start"], true);
  },
  [actions.LOAD_PRODUCT_DETAIL.success]: (state, {payload}) => {
    return state
      .setIn(["detail", "start"], false)
      .setIn(["detail", "success"], true)
      .setIn(["detail", "data"], payload);
  },

  [actions.LOAD_PRODUCT_DETAIL.failed]: (state, {payload}) => {
    return state
      .setIn(["detail", "start"], false)
      .setIn(["detail", "success"], false)
      .setIn(["detail", "data"], initialState.getIn(["detail", "data"]))
      .setIn(["detail", "error"], payload);
  },

  [actions.LOAD_PRODUCT_DETAIL.reset]: (state) => {
    return state
      .setIn(["detail"], initialState.getIn(["detail"]));
  },
};

export default duck.createReducer({
  ...productDetailReducer,
}, initialState);
