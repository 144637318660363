import { compose, contains, head, path } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { connect } from 'react-redux'
import { getUserSelectedZipcode } from 'src/redux/ducks/screens/signup/commons/selectors'
import { logError } from 'src/utils/logError'

const ADDRESS_FIELDS = [
  'street_number',
  'route',
  'locality',
  'sublocality',
  'administrative_area_level_1',
  'postal_code',
]

function AutocompleteAddress(props) {
  const {
    value,
    coordinates,
    error,
    touched,
    placeholder,
    className,
    id,
    disabled,
    setFieldValue,
    setFieldTouched,
    userZipCode,
    tempResurrectionZipCode = null,
  } = props

  const defaultSearchOptions = useMemo(
    () => ({
      location: new window.google.maps.LatLng(coordinates.lat, coordinates.lng),
      radius: 20000,
      types: ['address'],
      fields: ['address_components'],
      componentRestrictions: { country: ['us'] },
    }),
    [coordinates.lat, coordinates.lng],
  )

  useEffect(() => {
    // TODO: Remove `tempResurrectionZipCode` after funnel migration
    const zipCode = tempResurrectionZipCode || userZipCode
    setFieldValue('zipcode', zipCode)

    const geocoder = new window.google.maps.Geocoder()

    geocoder.geocode({ address: zipCode }, ([result], status) => {
      if (status === 'OK') {
        // Loop through the address components of the result
        result.address_components.forEach(component => {
          // Check if the component has a type of locality (city), and log it to the console
          if (component.types.includes('locality')) {
            // console.log('City: ' + component.long_name)
            setFieldValue('city', component.long_name)
          }
          // Check if the component has a type of administrative_area_level_1 (state), and log it to the console
          if (component.types.includes('administrative_area_level_1')) {
            setFieldValue('state', component.long_name)
            // console.log('State: ' + component.long_name)
          }
        })
      } else {
        // If the geocoder was unable to find a result, log an error message to the console
        console.log(
          'Geocode was not successful for the following reason: ' + status,
        )
      }
    })
  }, [userZipCode, setFieldValue, tempResurrectionZipCode])

  // console.log('userZipCode', userZipCode);

  const handleChange = address => {
    setFieldValue('address', address)
    setFieldTouched('address', true, false)
  }

  const handleSelect = address => {
    // console.log('handleSelect address', address)

    const addressArr = address.split(',').map(value => value.trim())
    geocodeByAddress(address)
      .then(selected => {
        const addressDetailsArray = compose(
          path(['address_components']),
          head,
        )(selected).filter(obj => contains(obj.types[0], ADDRESS_FIELDS))

        let zipCode = null
        let state = null

        addressDetailsArray.forEach(component => {
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name
          }

          if (component.types.includes('postal_code')) {
            zipCode = component.short_name
          }
        })

        setFieldValue('address', addressArr[0])
        setFieldValue('city', addressArr[1])

        if (state !== null) {
          setFieldValue('state', state)
        }
        if (zipCode !== null) {
          setFieldValue('zipcode', zipCode)
        }
        return
      })
      // eslint-disable-next-line no-console
      .catch(error => logError(error))
  }

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      searchOptions={defaultSearchOptions}
      onSelect={handleSelect}
      shouldFetchSuggestions={value.length > 6}
      googleCallbackName="googleMapsLoadedCallback"
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <input
            {...getInputProps({
              placeholder,
              className,
              id,
              disabled,
            })}
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              zIndex: 1,
            }}
            className="autocomplete-dropdown-container"
          >
            {loading && <div>Loading...</div>}
            {value.length > 6 &&
              suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                const style = suggestion.active
                  ? {
                      backgroundColor: '#efefef',
                      cursor: 'pointer',
                      padding: '7px 20px',
                      color: '#97225a',
                      borderTop: '1px solid #ced4da',
                      borderRight: '1px solid #ced4da',
                      borderLeft: '1px solid #ced4da',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                      padding: '7px 20px',
                      borderTop: '1px solid #ced4da',
                      borderRight: '1px solid #ced4da',
                      borderLeft: '1px solid #ced4da',
                    }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
          </div>
          {error && touched && <div className="invalid-feedback">{error}</div>}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

const mapStateToProps = state => ({
  userZipCode: getUserSelectedZipcode(state),
})

export default connect(mapStateToProps, null)(AutocompleteAddress)
