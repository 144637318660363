import { IRecord as record } from 'src/redux/helpers/Immutable'
import duck from './duck'
import { VALID_ZIP_CODE, LOGGING, TRACKING, IDENTIFY } from './actions'

const initialState = record({
  zipcode: record({
    start: false,
    success: false,
    data: null,
    email: null,
    isValid: false,
    error: null,
  }),
  logging: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),
  tracking: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),
  identify: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),
});

const validZipCodeReducer = {
  [VALID_ZIP_CODE.start]: state =>
    state
      .set('zipcode', initialState.getIn(['zipcode']))
      .setIn(['zipcode', 'start'], true),
  [VALID_ZIP_CODE.success]: (state, { payload }) =>
    state
      .set('zipcode', initialState.getIn(['zipcode']))
      .setIn(['zipcode', 'start'], false)
      .setIn(['zipcode', 'success'], true)
      .setIn(['zipcode', 'data'], payload.zipcode)
      .setIn(['zipcode', 'email'], payload.email)
      .setIn(['zipcode', 'isValid'], payload.isValid),
  [VALID_ZIP_CODE.failed]: (state, { payload }) =>
    state
      .set('zipcode', initialState.getIn(['zipcode']))
      .setIn(['zipcode', 'error'], payload),
  [VALID_ZIP_CODE.reset]: state => state.set('zipcode', initialState.getIn(['zipcode'])),
};

const loggingReducer = {
  [LOGGING.start]: state =>
    state
      .set('logging', initialState.getIn(['logging']))
      .setIn(['logging', 'start'], true),
  [LOGGING.success]: (state, { payload }) =>
    state
      .set('logging', initialState.getIn(['logging']))
      .setIn(['logging', 'start'], false)
      .setIn(['logging', 'success'], true)
      .setIn(['logging', 'data'], payload),
  [LOGGING.failed]: (state, { payload }) =>
    state
      .set('logging', initialState.getIn(['logging']))
      .setIn(['logging', 'error'], payload),
  [LOGGING.reset]: state => state.set('logging', initialState.getIn(['logging'])),
};

const trackingReducer = {
  [TRACKING.start]: state =>
    state
      .set('tracking', initialState.getIn(['tracking']))
      .setIn(['tracking', 'start'], true),
  [TRACKING.success]: (state, { payload }) =>
    state
      .set('tracking', initialState.getIn(['tracking']))
      .setIn(['tracking', 'start'], false)
      .setIn(['tracking', 'success'], true)
      .setIn(['tracking', 'data'], payload),
  [TRACKING.failed]: (state, { payload }) =>
    state
      .set('tracking', initialState.getIn(['tracking']))
      .setIn(['tracking', 'error'], payload),
  [TRACKING.reset]: state => state.set('tracking', initialState.getIn(['tracking'])),
};

const identifyReducer = {
  [IDENTIFY.start]: state =>
    state
      .set('identify', initialState.getIn(['identify']))
      .setIn(['identify', 'start'], true),
  [IDENTIFY.success]: (state, { payload }) =>
    state
      .set('identify', initialState.getIn(['identify']))
      .setIn(['identify', 'start'], false)
      .setIn(['identify', 'success'], true)
      .setIn(['identify', 'data'], payload),
  [IDENTIFY.failed]: (state, { payload }) =>
    state
      .set('identify', initialState.getIn(['identify']))
      .setIn(['identify', 'error'], payload),
  [IDENTIFY.reset]: state => state.set('identify', initialState.getIn(['identify'])),
};

export default duck.createReducer({
    ...validZipCodeReducer,
    ...loggingReducer,
    ...trackingReducer,
    ...identifyReducer,
  },
  initialState,
);
