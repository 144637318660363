import * as yup from 'yup'

/* eslint-disable no-unused-vars */
export enum PAGES {
  LOGIN = 'login',
  SIGN_UP = 'signup',
  FORGOT_PASSWORD = 'forgotPassword',
}

export enum CONNECTIONS {
  CU = 'cookunity',
  FACEBOOK = 'facebook',
  GOOGLE = 'google-oauth2',
  APPLE = 'apple',
}

type MigratePassword = {
  email: string
}

export const validateEmail = async (email: string): Promise<boolean> => {
  try {
    const { payload } = await (
      await fetch(
        process.env.REACT_APP_SITE_BASE_URL + '/internal/api/validate-email',
        {
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: {
            'content-type': 'application/json',
          },
        },
      )
    ).json()

    return payload.valid as boolean
  } catch (error) {
    return false
  }
}

const minStringMessage = (name: string) => `${name} too short`
const maxStringMessage = (name: string) => `${name} too long`
const requiredMessage = (name: string) =>
  `Missing ${name.toLowerCase()} information`
const invalidStringMessage = (name: string) => `Please enter a valid ${name}`
const email = yup
  .string()
  .email(invalidStringMessage('email'))
  .required(requiredMessage('Email'))
  .matches(
    /^[\w_.+!\-!#$%&'*=?^]+@([\w-]+\.)+[\w-]{2,}$/,
    invalidStringMessage('email'),
  )

const password = yup
  .string()
  .min(8, 'At least 8 characters in length.')
  .required(requiredMessage('Password'))

export const ForgotPasswordSchema = yup.object().shape({ email })
export const LoginSchema = yup.object().shape({ email, password })
export const SignUpSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, minStringMessage('First name'))
    .max(15, maxStringMessage('First name'))
    .required(requiredMessage('First name')),
  lastName: yup
    .string()
    .min(2, minStringMessage('Last name'))
    .max(15, maxStringMessage('Last name'))
    .required(requiredMessage('Last name')),
  email,
  password,
})
export const LogInSchema = yup.object().shape({
  email,
  password,
})

export async function migrateLogin(params: {
  email: string
  password: string
}) {
  return await (
    await fetch(
      process.env.REACT_APP_SUBSCRIPTION_API_URL + 'auth0/migratelogin',
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'content-type': 'application/json',
          Authorization: `${process.env.REACT_APP_SUBSCRIPTION_MIGRATE_AUTH}`,
        },
      },
    )
  ).json()
}

export async function migratePassword({ email }: MigratePassword): Promise<boolean> {
  try {
    const response = await (
      await fetch(
        process.env.REACT_APP_SUBSCRIPTION_API_URL + 'auth0/migrateresetpass',
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `${process.env.REACT_APP_SUBSCRIPTION_MIGRATE_AUTH}`,
          },
          body: JSON.stringify({ email }),
        },
      )
    ).text()

    return response === 'OK'
  } catch (error) {
    return false
  }
}
