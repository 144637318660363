import React from 'react'
import { Button } from 'src/components/Button'
import styles from './ForYouTitle.module.scss'

export function ForYouTitle({ title, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <Button
          className={styles.button}
          fill="clear"
          color="dark"
          onClick={onClick}
        >
          View all
        </Button>
      </div>
    </div>
  )
}
