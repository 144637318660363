import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { consumeCoupon, createOrder, getTaxRateByZipCode, validateCoupon } from './cartAPI'

const initialState = {
  coupon: null,
  taxRate: null,
  couponApplied: null,
  orderCreated: null,
  couponStatus: 'idle',
  taxRateStatus: 'idle',
  createOrderStatus: 'idle',
}

export const fetchCouponValidation = createAsyncThunk(
  'cart/fetchCouponValidation',
  async ({ code }) => {
    const response = await validateCoupon({ code })
    return { ...response, coupon: { ...response.coupon, code } }
  },
)

export const fetchTaxRate = createAsyncThunk(
  'cart/fetchTaxRate',
  async ({ zipCode }) => {
    const response = await getTaxRateByZipCode({ zipCode })
    return response
  },
)

export const assignCoupon = createAsyncThunk(
  'cart/assignCoupon',
  async ({ couponCode, isResurrected }) => {
    const response = await consumeCoupon({ couponCode, isResurrected })
    return response
  },
)

export const requestCreateOrder = createAsyncThunk(
  'cart/requestCreateOrder',
  async ({ order }) => {
    const response = await createOrder({ order })
    return response
  },
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCoupon: (state, { payload }) => {
      state.coupon = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCouponValidation.pending, state => {
        state.couponStatus = 'loading'
      })
      .addCase(fetchCouponValidation.fulfilled, (state, { payload }) => {
        state.couponStatus = 'idle'
        state.coupon = payload.coupon
      })
      .addCase(fetchTaxRate.pending, state => {
        state.taxRateStatus = 'loading'
      })
      .addCase(fetchTaxRate.fulfilled, (state, { payload }) => {
        state.taxRateStatus = 'idle'
        state.taxRate = payload.taxRate
      })
      .addCase(assignCoupon.pending, state => {
        state.createOrderStatus = 'loading'
      })
      .addCase(assignCoupon.fulfilled, state => {
        state.createOrderStatus = 'idle'
        state.couponApplied = true
      })
      .addCase(requestCreateOrder.pending, state => {
        state.createOrderStatus = 'loading'
      })
      .addCase(requestCreateOrder.fulfilled, state => {
        state.createOrderStatus = 'idle'
        state.orderCreated = true
      })
  },
})

export const { setCoupon } = cartSlice.actions

export const couponSelector = state => state.get('cart').coupon
export const taxRateSelector = state => state.get('cart').taxRate
export const orderCreatedSelector = state => state.get('cart').orderCreated

export default cartSlice.reducer
