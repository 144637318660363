import duck from "./duck";

export const CART = {
  startAdd: duck.defineType('PRE_ORDER_CART_START_ADD'),
  add: duck.defineType('PRE_ORDER_CART_ADD'),

  startRemove: duck.defineType('PRE_ORDER_CART_START_REMOVE'),
  remove: duck.defineType('PRE_ORDER_CART_REMOVE'),


  reset: duck.defineType('PRE_ORDER_CART_RESET'),

  setPreviousSelectionCart: duck.defineType('PRE_ORDER_PREVIOUS_SELECTION_CART_SET'),

  setCartData: duck.defineType('PRE_ORDER_SET_CART_DATA'),

  setPreselectError: duck.defineType('PRE_ORDER_PRESELECT_ERROR'),
};

export const CHOOSE_FOR_ME = {
  start: duck.defineType('CHOOSE_FOR_ME_START'),
  success: duck.defineType('CHOOSE_FOR_ME_SUCCESS'),
  startUndo: duck.defineType('CHOOSE_FOR_ME_UNDO_START'),
  startRefresh: duck.defineType('CHOOSE_FOR_ME_REFRESH_START'),
}


