import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

const useAppSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setSearchParam = useCallback(
    (key, value) => {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams.set(key, value)

      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams],
  )

  const removeSearchParam = useCallback(
    key => {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams.delete(key)

      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams],
  )

  const getSearchParam = useCallback(
    key => {
      return searchParams.get(key)
    },
    [searchParams],
  )

  const getAllSearchParams = useCallback(() => {
    const paramsObject = {}

    searchParams.forEach((value, key) => {
      paramsObject[key] = value
    })

    return paramsObject
  }, [searchParams])

  return {
    searchParams,
    setSearchParam,
    removeSearchParam,
    getSearchParam,
    getAllSearchParams,
    setSearchParams,
  }
}

export default useAppSearchParams
