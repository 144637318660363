import { CuiButton } from 'front-lib'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { planSelectedSelector, changePlan } from 'src/features/plans/plansSlice'
import { couponSelector } from 'src/features/cart/cartSlice'
import PlanSelect from 'src/features/plans/PlanSelect'
import './EditPlan.scss'
import { useDispatch } from 'react-redux'
import {
  formatNumberToCurrency,
  getPlanPriceDiscount,
} from 'src/utils/priceUtils'

const title = 'Select your membership plan'
const subtitle =
  'Start from 4 to 16 meals per week. You can skip, pause and change the plan size anytime.'

function EditPlan({ onSave }) {
  const dispatch = useDispatch()
  const coupon = useSelector(couponSelector)
  const planSelected = useSelector(planSelectedSelector)
  const [unconfirmedPlanSelected, setUnconfirmedPlanSelected] = useState(
    planSelected,
  )

  const handleClickSave = () => {
    dispatch(changePlan({ plan: unconfirmedPlanSelected }))
    onSave()
  }

  const currencyPlanDiscount =
    unconfirmedPlanSelected &&
    formatNumberToCurrency(
      getPlanPriceDiscount({
        plan: unconfirmedPlanSelected,
        promotion: coupon.promotion,
      }),
    )

  return (
    <div>
      <div className="select-plan">
        <div className="select-plan__main">
          <section className="select-plan__main__section">
            <div className="green-tag">{coupon?.promotion?.title}</div>
            <p className="select-plan__main__section__title">{title}</p>
            <p className="select-plan__main__section__text">{subtitle}</p>
          </section>
          <section className="select-plan__main__section">
            <PlanSelect
              planSelected={unconfirmedPlanSelected}
              onSelectPlan={setUnconfirmedPlanSelected}
            />
          </section>
        </div>
      </div>
      <div
        className="container-cui-row edit-modal__modal-text-container"
        style={{ marginTop: '56px' }}
      >
        {unconfirmedPlanSelected && (
          <div className="edit-modal__modal-text-container__text">
            <p>
              {unconfirmedPlanSelected?.mealsPerDelivery *
                unconfirmedPlanSelected?.deliveries}{' '}
              meals delivered weekly for ${unconfirmedPlanSelected?.price}
            </p>
            <p>
              <strong>This week SAVE {currencyPlanDiscount}</strong>
            </p>
          </div>
        )}
        <CuiButton
          size="medium"
          onClick={handleClickSave}
          disabled={!unconfirmedPlanSelected}
        >
          Save
        </CuiButton>
      </div>
    </div>
  )
}

export default EditPlan
