import { CuiCard, Modal } from 'front-lib'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EditPlan from './components/EditPlan/EditPlan'
import { planSelectedSelector } from 'src/features/plans/plansSlice'

import './PlanPage.scss'
import { couponSelector } from 'src/features/cart/cartSlice'
import { getSymbolAmount } from 'src/utils/priceUtils'

const PlanPage = ({ showModal, toggleModal }) => {
  const [discountText, setDiscountText] = useState('')
  const planSelected = useSelector(planSelectedSelector)
  const coupon = useSelector(couponSelector)

  useEffect(() => {
    if (coupon?.promotion?.discount) {
      const symbolAmount = getSymbolAmount(coupon.promotion.discount)
      setDiscountText(`${symbolAmount}  off your order`)
    } else if (coupon?.promotion?.title) {
      setDiscountText(coupon.promotion.title)
    }
  }, [coupon])

  return (
    <div className="resurection-cards plan-card">
      <CuiCard>
        <div className="green-tag">{discountText}</div>
        <div className="container-cui-row">
          <div>
            <p>
              <strong>
                {planSelected?.mealsPerDelivery * planSelected?.deliveries}-meal
              </strong>{' '}
              plan delivered weekly
            </p>
            <p className="grey">
              You can skip, pause or change the plan size anytime.
            </p>
          </div>
          <button className="edit" onClick={() => toggleModal()}>
            Edit
          </button>
        </div>
      </CuiCard>
      <Modal isOpen={showModal} onRequestClose={toggleModal}>
        <EditPlan onSave={toggleModal} />
      </Modal>
    </div>
  )
}

export default PlanPage
