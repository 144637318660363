import React, { Formik, FormikHelpers } from 'formik'
import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CuiButton, CuiIcon } from 'front-lib'
import { LogInSchema, SignUpSchema, validateEmail } from '../../utils'
import styles from './SignInForm.module.scss'
import formStyles from '../../styles/forms.module.scss'
import FormCuiInput from '../FormCuiInput'
import { useSelector } from 'react-redux'
import { isAuthLoadingSelector } from 'src/features/auth/authSlice'

const FormInput = ({ formValues, fieldInfo }: any) => {
  const { value, handleChange, error, touched } = formValues
  const { testId, name, placeholder, type } = fieldInfo
  return (
    <div className={formStyles.fieldGroup}>
      <FormCuiInput
        data-testid={testId}
        color="white"
        fill="solid"
        name={name}
        placeholder={placeholder}
        size="small"
        type={type}
        value={value}
        onChange={handleChange}
        className={error && touched ? formStyles.errorInput : ''}
      />
      {error && touched && (
        <div className={formStyles.errorMessageGroup}>
          <CuiIcon name="alert" />
          <p>{`${error}`}</p>
        </div>
      )}
    </div>
  )
}

type SignInFormProps = {
  setShowForm: Function
  handleSubmit: any
  submitLabel: string
  customErrorMessage: ReactNode
  isSignUp?: boolean
  subtitle?: string
}
const SignInForm = ({
  setShowForm,
  handleSubmit,
  submitLabel,
  customErrorMessage,
  isSignUp,
  subtitle,
}: SignInFormProps) => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const isAuthLoading = useSelector(isAuthLoadingSelector)

  const signInInitialValues = {
    email: email || '',
    password: '',
    firstName: '',
    lastName: '',
  }

  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setErrors }: FormikHelpers<any>,
  ) => {
    setSubmitting(true)

    const validEmail = await validateEmail(values.email)

    if (!validEmail) {
      setErrors({ email: 'Invalid email address. Please try again.' })
    } else {
      handleSubmit(values)
    }

    setSubmitting(false)
  }

  return (
    <div className={styles.form}>
      <CuiButton
        fill="clear"
        size="small"
        className={styles.backButton}
        onClick={() => setShowForm(false)}
      >
        <div className={styles.label}>
          <div className={styles.icon}>
            <CuiIcon name="chevronLeft" />
          </div>
          <div className={styles.text}>Back</div>
        </div>
      </CuiButton>

      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

      <Formik
        initialValues={signInInitialValues}
        validationSchema={isSignUp ? SignUpSchema : LogInSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className={formStyles.form} onSubmit={handleSubmit}>
            {isSignUp && (
              <div className={formStyles.fieldsGroup}>
                <FormInput
                  formValues={{
                    value: values.firstName,
                    handleChange,
                    error: errors.firstName,
                    touched: touched.firstName,
                  }}
                  fieldInfo={{
                    testId: 'firstName',
                    name: 'firstName',
                    placeholder: 'First Name',
                    type: 'text',
                  }}
                />
                <FormInput
                  formValues={{
                    value: values.lastName,
                    handleChange,
                    error: errors.lastName,
                    touched: touched.lastName,
                  }}
                  fieldInfo={{
                    testId: 'lastName',
                    name: 'lastName',
                    placeholder: 'Last Name',
                    type: 'text',
                  }}
                />
              </div>
            )}
            <FormInput
              formValues={{
                value: values.email,
                handleChange,
                error: errors.email,
                touched: touched.email,
              }}
              fieldInfo={{
                testId: 'email',
                name: 'email',
                placeholder: 'Email address',
                type: 'email',
              }}
            />
            <FormInput
              formValues={{
                value: values.password,
                handleChange,
                error: errors.password,
                touched: touched.password,
              }}
              fieldInfo={{
                testId: 'password',
                name: 'password',
                placeholder: 'Password',
                type: 'password',
              }}
            />

            <p className={formStyles.errorMessage}>{customErrorMessage}</p>

            <CuiButton
              data-testid="submit-form"
              color="primary"
              fill="solid"
              size="large"
              loading={isSubmitting}
              type="submit"
              disabled={isSubmitting || isAuthLoading }
              className={styles.submit}
            >
              {isSubmitting || isAuthLoading ? (
                <i className="spinner fas fa-circle-notch" />
              ) : (
                `${submitLabel}`
              )}
            </CuiButton>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SignInForm
