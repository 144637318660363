import React from 'react'

import './ImageLayoutCui.scss'
import ImgSection from '../../assets/img/quiz-image.jpg'
import ImgSectionExp from '../../assets/img/quiz-image-2.jpg'
import classnames from 'classnames'

const ImageLayoutCui = ({ image, children, className }) => {
  if (!image) {
    image = ImgSectionExp
  } else if (!image) {
    image = ImgSection
  }
  return (
    <div
      id="layout-container"
      className={classnames(
        'image-layout-cui__container',
        'image-layout-cui__container--exp',
      )}
    >
      <aside className="image-layout-cui__image">
        <img src={image} alt="aside" />
      </aside>
      <div
        className={classnames(
          'image-layout-cui__main',
          'image-layout-cui__main--exp',
          className,
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default ImageLayoutCui
