const MOCK_KUSTOMER = false
const MOCK_FORETHOUGTH = false

// export const sendMessageToCustomerService = message => {
//   if (window.Kustomer) {
//     window.Kustomer.openAndSendMessage({body: message}, {}, {icon: false})
//   }
// }

export const openCustomerServiceChat = (helpRequestedCallback = null) => {
  showChat()
  openChatForethought()
  if (typeof helpRequestedCallback === 'function') {
    helpRequestedCallback({ cta_text: 'Need Help?' })
  }  
}

const notAvailable = methodName => {
  console.error(`Kustomer SDK::${methodName} not available`)
}

const kustomerMock = {
  start: notAvailable.bind(undefined, 'start()'),
  login: notAvailable.bind(undefined, 'login()'),
  open: notAvailable.bind(undefined, 'open()'),
  describeCustomer: notAvailable.bind(undefined, 'describeCustomer()'),
  openAndSendMessage: notAvailable.bind(undefined, 'openAndSendMessage()'),
}

const forethoughtMock = () => {
  console.error(`Forethought SDK not available`)
}

const getKustomer = () => {
  if (window.Kustomer && !MOCK_KUSTOMER) return window.Kustomer
  return kustomerMock
}

const getForethought = () => {
  if (window.Forethought && !MOCK_FORETHOUGTH) return window.Forethought
  return forethoughtMock
}

/**
 * It opens Forethought chat except if Kustomer
 * was already opened in which case kustomer is reopened
 */
export const openChat = () => {
  getForethought()('widget', 'open')
  getKustomer().open()
}

// Hide widget: don't show it on the page at all
export const hideChat = () => {
  getForethought()('widget', 'hide')
}

// "Un-hide" or Show widget: display widget in 'Closed' form
export const showChat = () => {
  getForethought()('widget', 'show')
}

export const openChatForethought = () => {
  getForethought()('widget', 'open')
}

/**
 * Open chat window and send message
 *
 * - Currently not working with Forethougth chat enabled
 * - Temporarily replaced with just opening the chat
 */
// eslint-disable-next-line no-unused-vars
export const sendMessage = message => {
  openChat()
}
