let identify = console.log
let track = console.log
let alias = console.log
let page = console.log
let Analytics = {
  identify,
  page,
  track,
  group: console.log,
  alias,
  debug: console.log,
}

if (window.analytics) {
  identify = (...props) => window.analytics.identify(...props)
  track = (...props) => window.analytics.track(...props)
  alias = (...props) => window.analytics.alias(...props)
  page = (...props) => window.analytics.page(...props)
  Analytics = window.analytics
} else console.log('window analytics not initialized')

const segment = {
  ...Analytics,
  identify,
  track,
  alias,
  page,
}

export default segment
