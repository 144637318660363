import Loader from '../../components/Loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { loaderSelector } from 'src/features/loader/loaderSlice'

const LoaderProvider = ({ children }) => {
    const loader = useSelector(loaderSelector)

    return (
        <>
            {loader && <Loader />}
            {children}
        </>
    )
}

export default LoaderProvider
