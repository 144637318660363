import { CuiIcon } from 'front-lib'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlanSelectionImg from 'src/assets/img/plan-selection.png'
import GovxStatusBar from 'src/components/GovxComponents/GovxStatusBar'
import {
  DEFAULT_COUPON,
  DEFAULT_UPGRADED_HIGH_RISK_COUPON,
  DEFAULT_UPGRADED_HIGH_RISK_COUPON_NOT_DEFAULT,
  ECONOMIC_PROMO_COPY,
  commonQuestionsHome,
} from 'src/constants'
import { EXPERIMENTS } from 'src/constants/experiments'
import useExperiment from 'src/hooks/useExperiment'
import {
  changeCouponOnSearch,
  getCouponDisclaimer,
  isFreeDessertCoupon,
  isNewYorkStore,
  transformFreeDessertCoupon,
} from 'src/utils/coupons'
import CommonQuestionsCui from '../../../../components/CommonQuestionsCui'
import ImageLayoutCui from '../../../../components/ImageLayoutCui/ImageLayoutCui'
import './SelectPlanPageCui.scss'
import CouponInfo from './components/CouponInfo'
import Footer from './components/Footer'
import Header from './components/Header'
import PlanPrice from './components/PlanPrice'
import PlanSelection from './components/PlanSelection'
import { logInfo } from 'src/utils/logError'

const mapPlansToOptions = plans =>
  plans.map(
    ({
      id,
      mealsPerDelivery,
      highlight,
      couponCode,
      topText,
      topIcon,
    }) => ({
      id,
      label: mealsPerDelivery,
      topIcon: couponCode && !topText && topIcon && <CuiIcon name={topIcon} />,
      bottomIcon: highlight && <CuiIcon className="starIcon" name="starFull" />,
      topText: couponCode && topText,
    }),
  )

function SelectPlanPageCui({
  plans,
  selectedPlan,
  onClickPlan,
  coupon,
  onGoNext,
  applyCoupon,
  sendTracking,
  govx,
  isEconomicFlow,
  defaultPlan,
  mainSectionText,
  promotionTitle,
  plansColor,
  signUpInitData,
}) {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const highRiskZipCodeExperiment = useExperiment(EXPERIMENTS.highRiskZipCode, {
    trackOnMount: true,
  })

  const { isTreatment: isNo4MealsPlanTreatment } = useExperiment(
    EXPERIMENTS.no4MealsPlan,
  )

  const options = useMemo(() => mapPlansToOptions(plans), [plans])

  useEffect(() => {
    if (
      highRiskZipCodeExperiment.isEnabled &&
      highRiskZipCodeExperiment.isTreatment
    ) {
      if (coupon?.couponCode !== DEFAULT_UPGRADED_HIGH_RISK_COUPON) {
        let couponCode = DEFAULT_UPGRADED_HIGH_RISK_COUPON_NOT_DEFAULT;
        if (coupon?.couponCode === DEFAULT_COUPON || !coupon?.couponCode) {
          couponCode = DEFAULT_UPGRADED_HIGH_RISK_COUPON;
        }
        applyCoupon(couponCode)
        navigate(pathname + changeCouponOnSearch(search, couponCode), { replace: true });
      }
    }
  }, [
    highRiskZipCodeExperiment.isEnabled,
    highRiskZipCodeExperiment.isTreatment,
    pathname, search, applyCoupon, navigate, coupon?.couponCode
  ])

  /**
   * @description This useEffect is used to change the coupon code when the user is not in the New York store and has a free dessert coupon
   */
  useEffect(() => {
    if (isFreeDessertCoupon({ couponCode: coupon?.couponCode }) && !isNewYorkStore({ storeId: signUpInitData?.ring?.store_id })) {
      const newCoupon = transformFreeDessertCoupon({ couponCode: coupon?.couponCode })
      applyCoupon(newCoupon)
      logInfo('Plan Step - Free dessert promotion', { payload: { couponCode: newCoupon } })
      navigate(pathname + changeCouponOnSearch(search, newCoupon), { replace: true })
    }
  }, [coupon?.couponCode, signUpInitData?.ring?.store_id, navigate, pathname, search, applyCoupon])

  const handleOnGoNext = () => {
    onGoNext()
  }

  const couponDisclaimer = useMemo(() => {
    return getCouponDisclaimer({ couponCode: coupon?.couponCode })
  }, [coupon.couponCode])

  return (
    <>
      <ImageLayoutCui
        image={PlanSelectionImg}
        className="image-layout-cui__container--exp"
      >
        <div className="select-plan">
          {!isEconomicFlow && <Header coupon={coupon} govx={govx} />}

          {govx && govx.isAuthenticated && (
            <GovxStatusBar
              isVerified={govx.isVerified}
              signOut={govx.signOut}
            />
          )}

          <div className="select-plan__main">
            {isEconomicFlow && (
              <section className="select-plan__main__section">
                <CouponInfo title={ECONOMIC_PROMO_COPY} />
              </section>
            )}

            {coupon?.promotion?.title && (
              <section className="select-plan__main__section">
                <CouponInfo
                  title={
                    promotionTitle?.toUpperCase() ||
                    coupon.promotion.title.toUpperCase()
                  }
                  disclaimer={couponDisclaimer}
                />
              </section>
            )}
            <section className="select-plan__main__section">
              <p className="select-plan__main__section__title">
                How many weekly meals would you like to receive?
              </p>
              <p className="select-plan__main__section__text">
                {mainSectionText ??
                  (isNo4MealsPlanTreatment
                    ? 'Choose from 6 to 16 meals per week. You can skip, pause, or change the plan size anytime.'
                    : 'Choose from 4 to 16 meals per week. You can skip, pause, or change the plan size anytime.')}
              </p>
            </section>
            <section className="select-plan__main__section">
              <PlanSelection
                data={options}
                onSelect={onClickPlan}
                selected={selectedPlan}
                sendTracking={sendTracking}
              />
            </section>
            {selectedPlan && (
              <section className="select-plan__main__section">
                <PlanPrice
                  className={
                    plansColor && `plan-price-experiment__${plansColor}`
                  }
                  selected={selectedPlan}
                  coupon={coupon}
                  showHeader={
                    selectedPlan.mealsPerDelivery === defaultPlan ||
                    selectedPlan.priceTitle
                  }
                />
              </section>
            )}
          </div>
        </div>
      </ImageLayoutCui>
      <CommonQuestionsCui className="rebrand" options={commonQuestionsHome} />

      <Footer
        selectedPlan={selectedPlan}
        coupon={coupon}
        onGoNext={handleOnGoNext}
      />
    </>
  )
}

export default SelectPlanPageCui
