export const TYPES = {
  discountPromotion: 'discountPromotion',
  creditPromotion: 'creditPromotion',
  vipCreditPromotion: 'vipCreditPromotion',
  lowPricePromotion: 'lowPricePromotion',
  deliveryAndPlanB2B: 'deliveryAndPlanB2B',
  discountAndFreeShipping: 'discountAndFreeShipping',
  compoundPromotion: 'compoundPromotion',
}

export const DISCOUNT_TYPES = {
  percent: 'percent',
  absoluteAmount: 'absoluteAmount',
}

export const LOW_PRICE_FIXED_AMOUNT_PER_MEAL = 8

export const B2B_MONTHLY_MAX_DISCOUNT = { 'Storm8 Employee Benefit': 100 }

export const DEFAULT_DISCOUNTS = {
  planDiscount: 0,
  premiumMealsDiscount: 0,
  deliveryDiscount: 0,
  extraMealsDiscount: 0,
  totalDiscount: 0,
  credit: 0,
}
