import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader: (state, { payload }) => {
      state.show = payload
    },
  },
})

export const { setLoader } = loaderSlice.actions

export const loaderSelector = state => state.get('loader').show

export default loaderSlice.reducer
