import React, { useState, useEffect, createContext, useContext } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'

import { trackingStart } from 'src/redux/ducks/commons/creators'

import GovxModal from 'src/components/GovxComponents/GovxModal'
import { SIGN_UP_PATHS } from 'src/constants/routes'
import { logError } from 'src/utils/logError'
import Loader from 'src/components/Loader'

export const GOVX_CALLBACK_PATH = 'govx/callback'
const STORAGE_ITEM = 'govxusr'
const CLIENT_ID = '862e0617-dc71-ee11-8a1e-000d3ac50276'
const REDIRECT_URI = `${window.location.origin}/${GOVX_CALLBACK_PATH}`
const VERIFICATION_LINK = `https://auth.govx.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile+verification&response_type=token&display=full&goTo=login`

export const GovxContext = createContext()
export const useGovXUser = () => {
  return useContext(GovxContext)
}

export const getTokenFromHash = () => {
  const hashParams = new URLSearchParams(window.location.hash.substring(1))
  return hashParams.get('access_token')
}

const storedQueryParams = sessionStorage.getItem(
  'queryParamsBeforeRedirect',
)

function GovXOAuthProvider({children, actions}) {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)

    const { sendTracking } = actions

    const trackModalOpened = (eventData = {}) =>
      sendTracking({
        eventName: 'Sign Up - Govx - Modal Opened',
        eventData,
      })

    const trackAuthenticate = (eventData = {}) =>
      sendTracking({
        eventName: 'Sign Up - Govx - Authenticate Link Clicked',
        eventData,
      })

      const trackSignOut = (eventData = {}) =>
      sendTracking({
        eventName: 'Sign Up - Govx - Sign Out Clicked',
        eventData,
      })

    const trackGovxUser = (eventData = {}) =>
      sendTracking({
        eventName: 'Sign Up - Govx - Authentication Status',
        eventData,
      })

    const trackGovxFlag = (eventData = {}) =>
      sendTracking({
        eventName: 'Sign Up - Govx - Flag Enabled',
        eventData,
      })

    function handleModalState() {
      const isOpen = !openModal

      if (isOpen) {
        trackModalOpened()
      }

      setOpenModal(isOpen)
    }

    const isFlagEnabled = useFlag('gro-govx-verification')

    useEffect(() => {
      if (isFlagEnabled && !storedQueryParams) {
        trackGovxFlag()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFlagEnabled])


    useEffect(() => {
      const fetchUserDetails = async () => {
        const token = getTokenFromHash()
        let govxusr = null
        try {
          if (token) {
            setLoading(true)
            const response = await fetch('https://auth.govx.com/api/data', {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
              },
            })

            govxusr = await response.json()

            if(govxusr?.userProfile?.id ){
                sessionStorage.setItem(STORAGE_ITEM, JSON.stringify(govxusr))
            }
          } else {
            const storedUser = sessionStorage.getItem(STORAGE_ITEM)
            if (storedUser) {
                govxusr = JSON.parse(storedUser)
            }
          }

          setUser(govxusr)
          setIsAuthenticated(govxusr?.userProfile?.id ? true : false)
          setIsVerified(govxusr?.verification?.status === 'Approved' || false)

        } catch (error) {
          logError('Error fetching govx user data:', error)
        } finally {
          if (storedQueryParams) {
            const currentBaseURL = window.location.origin
            sessionStorage.removeItem('queryParamsBeforeRedirect')
            window.location.href = `${currentBaseURL}${SIGN_UP_PATHS['PLAN_SELECTION']}${storedQueryParams}`;
          } else {
            if (govxusr?.userProfile?.id) {
              trackGovxUser({
                govx_user_id: govxusr?.userProfile?.id,
                govx_user_status: govxusr?.verification?.status,
              })
            }
          }
          setLoading(false)
        }
      }

      fetchUserDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const authenticate = () => {
      sessionStorage.setItem(
        'queryParamsBeforeRedirect',
        window.location.search,
      )
      setIsRedirecting(true)

      trackAuthenticate()

      window.location.href = VERIFICATION_LINK
    }

    const signOut = govxusr => {

      setLoading(true)

      trackSignOut({
        govx_user_id: govxusr?.userProfile?.id,
        govx_user_status: govxusr?.verification?.status,
      })

      sessionStorage.removeItem(STORAGE_ITEM)

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    if (loading) {
      return <Loader />
    }

    return (
      <GovxContext.Provider
        value={{
          isFlagEnabled,
          isAuthenticated,
          isVerified,
          user,
          authenticate,
          signOut: () => signOut(user),
          handleModalState,
        }}
      >
        <GovxModal
          openModal={openModal}
          handleModalState={handleModalState}
          authenticate={authenticate}
          isRedirecting={isRedirecting}
        />

        {children}
      </GovxContext.Provider>
    )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: trackingStart,
    },
    dispatch,
  ),
})



export default connect(null, mapDispatchToProps)(GovXOAuthProvider)

