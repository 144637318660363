import { CuiIcon } from 'front-lib'

import './GovxStatusBar.scss'

export default function GovxStatusBar({ isVerified, signOut }) {
  return (
    <div
      className={`govx_statusbar authed ${
        isVerified ? 'verified' : 'noverified'
      }`}
    >
      <div className="govx_statusbar__body">
        <div className={`govx_statusbar__authed govx_row`}>
          {isVerified ? (
            <>
              <div className="govx_col">
                <CuiIcon className="govx_check_icon" name="checkmarkCircle" />
              </div>
              <div className="govx_col">
                <p>
                  Your <b>GovX</b> account has been verified!
                </p>
                <p>
                  <span className="gvx_logout" onClick={signOut}>
                    Log out from GovX account
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="govx_col">
                <CuiIcon className="govx_check_icon" name="alert" />
              </div>

              <div className="govx_col">
                <p>
                  <b>Your GovX verification request is being processed</b>
                </p>
                <p style={{color: '#4D4D4F'}}>
                  GovX will notify you via email within a few hours and you can
                  complete the sign-up process.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
