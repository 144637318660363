const imageUrl = filename =>
  `https://static.cookunity.com/cross/front-lib/images/${filename}`

const imagesMap = {
  noMealImg: imageUrl('no-image.png'),
  allStarChefBudge: imageUrl('all-star.png'),
  spicyIcon: imageUrl('icon-spicy.png'),
  star: imageUrl('star.png'),
  btnWhitePlus: imageUrl('btn-white-plus.png'),
  btnBlackPlus: imageUrl('btn-black-plus.png'),
  btnBlackMinus: imageUrl('btn-black-minus.png'),
  blackStar: imageUrl('black_star.png'),
  blackHeart: imageUrl('black_heart.png'),
  emptyHeart: imageUrl('empty_heart.png'),
  veganIcon: imageUrl('vegan.png'),
  vegetarianIcon: imageUrl('vegetarian.png'),
  warningRedIcon: imageUrl('alert-circle.png'),
  diamond: imageUrl('diamond.png'),
}

export default imagesMap
