import React, { useEffect, useState } from 'react'
import PaymentPage from './PaymentPage'
import { useSelector } from 'react-redux'
import {
  cardSelector,
  clearAddCardError,
  errorAddCardSelector,
  fetchPaymentsMethods,
} from 'src/features/payment/paymentSlice'
import { useDispatch } from 'react-redux'

const Payment = () => {
  const dispatch = useDispatch()
  const addCardError = useSelector(errorAddCardSelector)
  const card = useSelector(cardSelector)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(fetchPaymentsMethods())
  }, [dispatch])

  const handleToggleModal = () => {
    if (addCardError) {
      dispatch(clearAddCardError())
    }
    setShowModal(prev => !prev)
  }

  return (
    card && (
      <PaymentPage
        card={card}
        showModal={showModal}
        toggleModal={handleToggleModal}
      />
    )
  )
}

export default Payment
