import { v4 } from 'uuid'

const COOKUNITY_PREFIX = '@CU/'

export const getCookies = () => {
  const cookies = document.cookie.split('; ').reduce((obj, cookie) => {
    const [name, value] = cookie.split('=')

    if (name) {
      obj[name] = decodeURIComponent(value)
    }

    return obj
  }, {})

  return cookies
}

export const getCookie = name => {
  const cookies = getCookies()

  return cookies[name]
}

export const createCookie = (name, value, options = {}) => {
  const { expirationDays = 365, domain, path = '/' } = options

  const expirationDate = new Date()

  expirationDate.setDate(expirationDate.getDate() + expirationDays)

  let cookie = `${name}=${encodeURIComponent(
    value,
  )}; expires=${expirationDate.toUTCString()}; path=${path}`

  if (domain) {
    cookie += `; domain=${domain}`
  }

  document.cookie = cookie
}

export const deleteCookie = (name, options = {}) => {
  const { domain } = options

  let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`

  if (domain) {
    cookie += `; domain=${domain}`
  }

  document.cookie = cookie
}

export const getCUDomain = (options = {}) => {
  const { includeSubdomains = false } = options

  let domain = window.location.hostname

  if (includeSubdomains && domain !== 'localhost') {
    domain = `.${domain.replace('signup.', '')}`
  }

  return domain
}

export const getCUCookie = name => {
  return getCookie(`${COOKUNITY_PREFIX}${name}`)
}

export const createCUCookie = (name, value, options = {}) => {
  const {
    expirationDays = 365,
    addPrefix = true,
    includeSubdomains = false,
  } = options

  const cookieName = addPrefix ? `${COOKUNITY_PREFIX}${name}` : name
  const domain = getCUDomain({ includeSubdomains })

  createCookie(cookieName, value, { expirationDays, domain })
}

export const deleteCUCookie = (name, options = {}) => {
  const { includeSubdomains = false } = options

  const domain = getCUDomain({ includeSubdomains })

  deleteCookie(name, { domain })
}

export const getCUTrackUuid = () => {
  let response = getCUCookie('TrackUuid')

  if (!response) {
    response = v4()
    createCUCookie('TrackUuid', response, { includeSubdomains: true })
  }

  return response
}

export const cookUnityUuid = getCUTrackUuid()
