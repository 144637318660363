/**
 * @description Remove duplicated objects from Array
 * @param myArr {Array}
 * @param prop {String}
 * @returns {Array}
 */
export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export const getCoordinates = store =>
  store.latitude && store.longitude
    ? {
        lat: store.latitude,
        lng: store.longitude,
      }
    : {}
