import { dayNames } from "src/screens/signup/constants"

/**
 * Takes a string and returns an object with properties based on the string's format.
 * @param {string} string - The string to parse.
 * @returns {Object|null} An object with properties based on the string's format, or null if the string is empty or does not have the correct format.
 */
export const buildConfigFromString = (string) => {
    // If the string is empty or falsy, return null
    if (!string) {
        return null
    }

    // Split the string into parts based on the '-' character
    const parts = string.split('-')

    // The first part is the prefix, which should be 'ddo'
    const prefix = parts[0] && parts[0].toLowerCase()

    // If the prefix is not 'ddo', return null
    if (prefix !== 'ddo') {
        return null
    }

    // The second part is the action, which should be a lowercase string
    const action = parts[1] && parts[1].toLowerCase()

    // The third part is the day, which should be a lowercase string
    const day =
      parts[2] &&
      (parts[2].endsWith('s') ? parts[2].slice(0, -1) : parts[2]).toLowerCase()

    // The fourth part is the condition, which should be the string 'if' if it exists
    const condition = parts[3] && parts[3].toLowerCase() === 'if'

    // If there is a condition, the fifth and sixth parts are the words 'first' and the first day of the condition
    const conditionFirstDay =
        condition &&
        parts[4] &&
        parts[5] &&
        parts[4].toLowerCase() === 'first' &&
        parts[5]

    // If there is a condition, set ifFirstDay to the first day of the condition, otherwise set it to null
    const ifFirstDay = condition ? conditionFirstDay : null

    // Return an object with the action, day, and ifFirstDay properties
    return {
        action,
        day,
        ifFirstDay
    }
}
/**
 * Filters an array of toggles to return only those with names starting with 'ddo-'.
 * @param {Array} toggles - An array of toggles.
 * @returns {Array} An array of toggles with names starting with 'ddo-'.
 */

export function filterDDOToggles(toggles) {
  return Array.isArray(toggles)
    ? toggles.filter(toggle => toggle.name && toggle.name.indexOf('ddo-') === 0)
    : []
}

/**
 * Applies DDO (Dynamic Delivery Options) configurations to an array of delivery days.
 * @param {Array} ddoToggles - An array of Unelash DDO toggles.
 * @param {Array} deliveryDays - An array of delivery days.
 * @returns {Array} An array of delivery days with DDO configurations applied.
 */
export function applyDDOConfigs(ddoToggles, deliveryDays) {

    if (!Array.isArray(deliveryDays) || !deliveryDays.length) {
      return deliveryDays
    }

    const ddoTogglesConfigs = ddoToggles.map(toggle => {
      if(!toggle.enabled) return null
      return buildConfigFromString(toggle.name)
    }).filter(e => !!e)
  
    const firstDeliveryDay =
      dayNames[deliveryDays[0].day] &&
      dayNames[deliveryDays[0].day].toLowerCase()
  
    return deliveryDays.map(e => {
  
      const deliveryDayName = dayNames[e.day] && dayNames[e.day].toLowerCase()
      const ddoConfig = ddoTogglesConfigs.find(
        e =>
          (deliveryDayName === e.day && !e.ifFirstDay) ||
          (deliveryDayName === e.day && firstDeliveryDay === e.ifFirstDay),
      )
  
      const applyConfig =
        ddoConfig &&
        ((ddoConfig.ifFirstDay && ddoConfig.ifFirstDay === firstDeliveryDay) ||
          (!ddoConfig.ifFirstDay && ddoConfig))
    
      const ddo = {
        soldout: !!applyConfig && ddoConfig.action === 'soldout',
        recommended: !!applyConfig && ddoConfig.action === 'recommend',
      }
  
      return {
        ...e,
        ...ddo,
      }
    })
  }