import { CuiHeader, TabsMenu } from 'front-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MealsCardsGrid from 'src/components/MealsCardsGrid/MealsCardsGrid'
import { zipCodeSelector } from 'src/features/address/addressSlice'
import {
  deliveryDaySelectedSelector,
  deliveryDaysSelector,
  fetchDeliveryDays,
  setDeliveryDaySelected,
} from 'src/features/delivery/deliverySlice'
import {
  fetchMeals,
  mealsSelectedSelector,
} from 'src/features/meals/mealsSlice'
import { filtersOptions } from 'src/features/ui/constants'
import { filterSelectedSelector } from 'src/features/ui/uiSlice'
import useRouter from 'src/hooks/useRouter'
import useTrackEvent from 'src/hooks/useTrackEvent'
import './MealsSelection.scss'
import DeliveryDate from './components/DeliveryDate/DeliveryDate'
import Footer from './components/Footer/Footer'
import Cart from './components/Cart/Cart'

const MealsSelection = () => {
  const dispatch = useDispatch()
  const { nextRoute } = useRouter()
  const zipCode = useSelector(zipCodeSelector)
  const mealsSelected = useSelector(mealsSelectedSelector)
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const deliveryDays = useSelector(deliveryDaysSelector)
  const [filterSelected, setFilterSelected] = useState(
    useSelector(filterSelectedSelector),
  )
  const trackMealsSelected = useTrackEvent({
    eventName: 'Resurrection - Meals Selection Completed',
  })
  const [isCartOpen, setIsCartOpen] = useState(false)

  useEffect(() => {
    if (!deliveryDays && zipCode) {
      dispatch(fetchDeliveryDays({ zipCode }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode])

  useEffect(() => {
    if (!deliveryDaySelected && deliveryDays) {
      dispatch(setDeliveryDaySelected(deliveryDays[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDays])

  useEffect(() => {
    if (deliveryDaySelected) {
      const { date } = deliveryDaySelected

      dispatch(fetchMeals({ zipCode, date }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDaySelected])

  const handlerFilterOnClick = filter => {
    if (filter.id === filterSelected.id) {
      setFilterSelected(filtersOptions[0])
    } else {
      setFilterSelected(filter)
    }
  }

  const handleNext = () => {
    trackMealsSelected({
      products: mealsSelected.map(mealSelected => ({
        product_id: mealSelected.id,
        sku: mealSelected.sku,
        category: mealSelected.categoryId,
        name: mealSelected.name,
        chef: mealSelected.chef?.id,
        price: mealSelected.price,
        quantity: mealSelected.quantity,
      })),
    })
    nextRoute()
  }

  return (
    <>
      <CuiHeader
        showNeedHelp
        showNeedHelpTitle
        showLogo
        homeLink={process.env.REACT_APP_SITE_BASE_URL}
      />
      <Cart
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        onContinue={handleNext}
      />
      <div className="meals-selection-container">
        <DeliveryDate />
        <TabsMenu
          handleOnClick={handlerFilterOnClick}
          tabsItems={filtersOptions}
          selectedTab={filterSelected}
        />
        <MealsCardsGrid
          isEditable={true}
          filterSelected={filterSelected}
        />
      </div>
      <Footer
        onGoNext={handleNext}
        onViewSelection={() => setIsCartOpen(true)}
      />
    </>
  )
}

export default MealsSelection
