/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react'

import QuizOptionsCui from './components/QuizOptionsCui'
import GutterCui from 'src/components/GutterCui/GutterCui'
import { reduceQuestionsToPreferences } from './utils'
import './QuizCui.scss'

import ImgSection from '../../../../../../assets/img/quiz-image.jpg'
import ImageLayoutCui from 'src/components/ImageLayoutCui/ImageLayoutCui'
import StepTitleCui from 'src/components/StepTitleCui/StepTitleCui'

import back from '../../../../../../assets/icons/caret-black-left.png'

const QuizCui = props => {
  const { questions, actions, preferencesQuizResponses, setShowQuiz } = props

  const [questionIndexes, setQuestionIndexes] = useState({
    previousQuestionIndex: 0,
    questionPosition: 0,
  })

  const sendTrack = action => {

    actions.sendTracking({
      eventName: 'Sign Up - Preferences Settled',
      eventData: {
        action,
        preference: reduceQuestionsToPreferences(
          questions,
          preferencesQuizResponses,
        ),
      },
    })
  }

  const handleSkipButton = () => {
    sendTrack('Skip')
    actions.resetResponse()
    setShowQuiz(false)
  }

  const getNextQuestionIndex = () => {
    const dietsQuestionIndex = questions.findIndex(
      question => question.name === 'diets',
    )
    const proteinsQuestionIndex = questions.findIndex(
      question => question.name === 'proteins',
    )
    // If the current question is the diets question
    if (questionIndexes.questionPosition === dietsQuestionIndex) {
      return {
        previousQuestionIndex: dietsQuestionIndex,
        questionPosition: proteinsQuestionIndex,
      }
    }
    // If the current question is other than diets question
    if (questions.length - 1 > questionIndexes.questionPosition) {
      return {
        previousQuestionIndex: questionIndexes.questionPosition,
        questionPosition: questionIndexes.questionPosition + 1,
      }
    }

    return {
      previousQuestionIndex: questionIndexes.questionPosition,
      questionPosition: -1,
    }
  }

  const handleNextButton = () => {
    window.scrollTo(0, 0)
    sendTrack('Continue')
    const questionIndexes = getNextQuestionIndex()
    if (questionIndexes.questionPosition === -1) {
      setShowQuiz(false)
    } else {
      setQuestionIndexes(questionIndexes)
    }
  }

  return (
    <>
      <ImageLayoutCui className={'quiz-cui-layout'} image={ImgSection}>
        <button className="quiz-cui__back-button" onClick={handleSkipButton}>
          Skip All
        </button>
        {questionIndexes.questionPosition !== 0 && (
          <button
            className="quiz-cui__back-question"
            onClick={() => setQuestionIndexes(prev => ({
              questionPosition: prev.questionPosition - 1,
            }))}
          >
            <img src={back} alt="back" />
            Back
          </button>
        )}

        <section
          id={`question_${questionIndexes.questionPosition}`}
          className={`quiz-cui__questions_container question_${questionIndexes.questionPosition}`}
        >
          <p className='rebrand question-title'>QUESTION {questionIndexes.questionPosition + 1}/{questions.length}</p>
          <StepTitleCui
            title={questions[questionIndexes.questionPosition].title}
            subtitle={questions[questionIndexes.questionPosition].subtitle}
          />
          <QuizOptionsCui
            actions={actions}
            question={questions[questionIndexes.questionPosition]}
            preferencesQuizResponses={preferencesQuizResponses}
          />
        </section>
      </ImageLayoutCui>

      <GutterCui ctaText="Continue" onGoNext={handleNextButton}>
        <button className="without-back-button" onClick={handleSkipButton}>
          Skip All
        </button>
      </GutterCui>
    </>
  )
}

export default QuizCui
