export const COUPON_CHANGED_EVENT_NAME = 'Sign Up - Coupon Changed'
export const OVERLAY_VIEWED = 'overlay_viewed'
export const OVERLAY_CLOSED = 'overlay_closed'
export const OVERLAY_SUBMITTED = 'overlay_submitted'

export const getChangeCouponOverlayTrackData = ({ event }) => {
  const overlayTrackData = {
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'Custom',
    overlay_action: 'change coupon',
  }

  switch (event) {
    case OVERLAY_VIEWED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Viewed',
        overlay_name: 'Sign Up - Change Coupon - Overlay Viewed',
        overlay_trigger: 'manual',
      }
    case OVERLAY_CLOSED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Closed',
        overlay_name: 'Sign Up - Change Coupon - Overlay Closed',
        overlay_trigger: 'manual',
      }

    case OVERLAY_SUBMITTED:
      return {
        ...overlayTrackData,
        eventName: 'Overlay Submitted',
        overlay_name: 'Sign Up - Change Coupon - Overlay Submitted',
        overlay_trigger: 'manual',
      }

    default:
      break
  }
}
