export const OVERLAY_VIEWED = 'overlay_viewed'
export const OVERLAY_CLOSED = 'overlay_closed'
export const OVERLAY_SUBMITTED = 'overlay_submitted'
export const OVERLAY_NAME_OUT_OF_DELIVERY_AREA = 'Sign Up - Out of delivery area'
export const OVERLAY_NAME_CANNOT_VALIDATE_ZIP_CODE = 'Sign Up - Cannot validate zip code'

export const getOverlayTrackData = ({ event, overlayName }) => {
  const overlayTrackData = {
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'Custom',
    overlay_action: 'save email',
  }

  switch (event) {
    case OVERLAY_VIEWED:
      return {
        ...overlayTrackData,
        overlay_name: overlayName,
        eventName: 'Overlay Viewed',
        overlay_trigger: 'manual',
      }
    case OVERLAY_CLOSED:
      return {
        ...overlayTrackData,
        overlay_name: overlayName,
        eventName: 'Overlay Closed',
        overlay_trigger: 'manual',
      }

    case OVERLAY_SUBMITTED:
      return {
        ...overlayTrackData,
        overlay_name: overlayName,
        eventName: 'Overlay Submitted',
        overlay_trigger: 'manual',
      }

    default:
      break
  }
}
