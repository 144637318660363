import React from 'react'
import classNames from 'classnames'
import Fade from '../Fade/Fade'
import './Backdrop.scss'

const Backdrop = ({ open, onClick, className, children }) => {
  const handleBackdropClick = event => {
    if (event.target === event.currentTarget) {
      onClick()
    }
  }

  return (
    <Fade in={open}>
      <div
        onClick={handleBackdropClick}
        className={classNames('backdrop', className)}
      >
        {children}
      </div>
    </Fade>
  )
}

export default Backdrop
