import React, { useEffect, useState, useRef, useCallback } from 'react'
import { MenuListModule } from 'src/modules/MenuListCui'
import ProductDetail from './components/ProductDetail'
import './PreOrderPageCui.scss'
import Footer from './components/Footer'
import FooterWarning from './components/FooterWarning'
import SelectedMealsCui from './components/SelectedMealsCui'
import GoTopButtonCui from 'src/screens/signup/components/GoTopButtonCui'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import SignIn from 'src/features/signIn'
import { isAuthenticatedSelector } from 'src/features/auth/authSlice'
import { useSelector } from 'react-redux'
import useTrackEvent from 'src/hooks/useTrackEvent'
import ChangePlanModal from './components/ChangePlanModal/ChangePlanModal'

const SneakPeekClickCatcher = ({ children, onClick, disabled }) => {
  useEffect(() => {
    const checkClickOverSignInPanel = node => {
      if (!node) return false
      if (typeof node.className === "string" && node.className.includes('SignInPanel')) return true
      return checkClickOverSignInPanel(node.parentElement)
    }
    
    const handleClickListener = event => {
      if (!checkClickOverSignInPanel(event.target)) {
        onClick(event.currentTarget)
      }
    }

    if (!disabled) {
      document.addEventListener('mousedown', handleClickListener)
      document.addEventListener('touchstart', handleClickListener)

      return () => {
        document.removeEventListener('mousedown', handleClickListener)
        document.removeEventListener('touchstart', handleClickListener)
      }
    }
  }, [disabled, onClick])

  return <div>{children}</div>
}

const SneakPeekSignUpPopup = () => {
  return (
    <div className="sneakPeek-popup-wrapper">
      <div className="sneakPeek-popup">
        <SignIn isPopup />
      </div>
    </div>
  )
}

const PreOrderPageCui = props => {
  const {
    selectedPlan,
    startDay,
    addProduct,
    removeProduct,
    selectedProducts,
    totalProductQty,
    toggleSelectedMeals,
    openSelectedMeals,
    productDetailHandler,
    setShowQuiz,
    zipcode,
    stepHandler,
    resetProductDetail,
    showProductDetail,
    activeFilter,
    diet,
    onClickChooseForMe,
    onClickUndoChooseForMe,
    onClickRefreshChooseForMe,
    sendTracking,
    actions,
    signUpInitData,
    coupon,
    validDates
  } = props

  const [scrollPosition, setScrollPosition] = useState(0)
  const [showGoTop, setShowGoTop] = useState('goTopHidden')
  const signInRef = useRef()
  const [isSignInViewed, setIsSignInViewed] = useState(false)
  const [showChangePlanModal, setShowChangePlanModal] = useState(false)
  const [popUpVisible, setPopUpVisible] = useState(false)
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const refScrollUp = useRef()

  const { isTreatment: planInMealStepExperiment } = useExperiment(EXPERIMENTS.planInMealStep, {
    trackOnMount: true,
  })

  const overlayTrackData = {
    eventName: 'Overlay Viewed',
    overlay_class: 'Popup',
    overlay_source: 'custom',
    overlay_type: 'form',
    overlay_action: 'sign up form',
  }

  const trackSignInPopup = useTrackEvent({
    ...overlayTrackData,
    overlay_name: 'Sign Up - Sneak Peek - Popup Opened',
    overlay_trigger: 'manual',
  })
  const trackSignInVisible = useTrackEvent({
    ...overlayTrackData,
    overlay_name: 'Sign Up - Sneak Peek - Form Viewed',
    overlay_trigger: 'automatic',
  })

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton)
  })

  useEffect(
    () => {
      window.addEventListener('scroll', isSneakPeekViewed)
      return () => window.removeEventListener('scroll', isSneakPeekViewed)
    },
    [isSignInViewed], // eslint-disable-line
  )

  const handleScrollUp = () => {
    refScrollUp.current.scrollIntoView({ behavior: 'smooth' })

    sendTracking({
      eventName: 'Sign Up - Button Clicked Up Meal Selection',
    })
  }

  const { isTreatment: forYouExperiment } = useExperiment(
    EXPERIMENTS.forYouExperiment,
    {
      trackOnMount: true,
    },
  )

  const onOpenSignInPopup = useCallback(() => {
    trackSignInPopup()
    setPopUpVisible(true)
  }, [trackSignInPopup])

  const handleVisibleButton = () => {
    const position = window.pageYOffset
    setScrollPosition(position)

    if (scrollPosition > 400) {
      return setShowGoTop('goTop')
    } else if (scrollPosition < 400) {
      return setShowGoTop('goTopHidden')
    }
  }

  const removeNewUpgradedPlan = () => {
    actions.setUserSelectedDataStart({
      upgradedPlan: undefined,
      newUpgradedPlan: false,
    })
  }

  const isSneakPeekViewed = () => {
    if (isSignInViewed) return

    const pageTop = 0
    const pageBottom = pageTop + window.innerHeight
    const elementTop = signInRef.current?.getBoundingClientRect().top + 150
    const elementBottom =
      elementTop + signInRef.current?.getBoundingClientRect().height

    if (elementTop <= pageBottom && elementBottom >= pageTop) {
      setIsSignInViewed(true)
      trackSignInVisible()
    }
  }

  const numberOfMealsExceeded = totalProductQty - selectedPlan?.mealsPerDelivery

  const displayFooterWarning = planInMealStepExperiment && !showChangePlanModal && numberOfMealsExceeded > 0
  const footerWarningMessage =
  numberOfMealsExceeded === 1
    ? `You need to remove ${numberOfMealsExceeded} meal`
    : `You need to remove ${numberOfMealsExceeded} meals`;

  return (
    <>
      {!isAuthenticated && popUpVisible && <SneakPeekSignUpPopup />}
      <SneakPeekClickCatcher
        onClick={onOpenSignInPopup}
        disabled={isAuthenticated}
      >
        <div
          className={`cookunity__signup_b_preorder-cui ${
            forYouExperiment ? 'with-for-you-experiment' : ''
          }`}
        >
          <div ref={refScrollUp}> </div>
          {showProductDetail && (
            <ProductDetail
              addProduct={addProduct}
              removeProduct={removeProduct}
              selectedProducts={selectedProducts}
              totalOrderedProducts={totalProductQty}
              maxProductToCart={selectedPlan?.mealsPerDelivery}
              resetProductDetail={resetProductDetail}
            />
          )}

          {openSelectedMeals && (
            <SelectedMealsCui
              isOpen={openSelectedMeals}
              onClose={toggleSelectedMeals}
              selectedProducts={selectedProducts}
              planSizeExceeded={
                totalProductQty >= selectedPlan?.mealsPerDelivery
              }
              addProduct={addProduct}
              removeProduct={removeProduct}
              mealCount={totalProductQty}
              selectedPlan={selectedPlan}
              stepHandler={stepHandler}
              onClickUndoChooseForMe={onClickUndoChooseForMe}
              onClickRefreshChooseForMe={onClickRefreshChooseForMe}
              removeNewUpgradedPlan={removeNewUpgradedPlan}
              planInMealStepExperiment={planInMealStepExperiment}
              setShowChangePlanModal={setShowChangePlanModal}
              displayFooterWarning={displayFooterWarning}
              footerWarningMessage={footerWarningMessage}
            />
          )}
          {showChangePlanModal &&
            <ChangePlanModal
              showChangePlanModal={showChangePlanModal}
              setShowChangePlanModal={setShowChangePlanModal}
              actions={actions}
              signUpInitData={signUpInitData}
              coupon={coupon}
              selectedPlan={selectedPlan}
              validDates={validDates}
            />}

          <div className={!isAuthenticated ? 'sneak-peek-wrapper' : ''}>
            <div className="sneak-peek-menu">
              <MenuListModule
                activeFilter={activeFilter}
                addProduct={addProduct}
                canEdit
                diet={diet}
                forYouExperiment={forYouExperiment}
                maxProductToCart={selectedPlan?.mealsPerDelivery}
                menuDate={startDay ? startDay.date : null}
                onClickChooseForMe={onClickChooseForMe}
                onMealClick={productDetailHandler}
                removeProduct={removeProduct}
                selectedPlan={selectedPlan}
                selectedProducts={selectedProducts}
                setShowQuiz={setShowQuiz}
                showFilterBar={true}
                startDay={startDay}
                stepHandler={stepHandler}
                toggleSelectedMeals={toggleSelectedMeals}
                totalOrderedProductsQty={totalProductQty}
                zipcode={zipcode}
              />
            </div>

            {!isAuthenticated && (
              <>
                <div className={'login-panel-fade'}></div>
                <div className={'login-panel'} ref={signInRef}>
                  <SignIn />
                </div>
              </>
            )}
          </div>

          <GoTopButtonCui showGoTop={showGoTop} scrollUp={handleScrollUp} />

          {displayFooterWarning && <FooterWarning
            footerWarningMessage={footerWarningMessage}
          />}

          <Footer
            totalProductQty={totalProductQty}
            selectedPlan={selectedPlan}
            onGoNext={stepHandler}
            onViewSelection={toggleSelectedMeals}
            displayFooterWarning={displayFooterWarning}
            footerWarningMessage={footerWarningMessage}
          />
        </div>
      </SneakPeekClickCatcher>
    </>
  )
}

export default PreOrderPageCui
