import React from 'react'
import classNames from 'classnames'
import './ToggleButtonGroup.scss'

const addPropsToReactElement = (element, props) =>
  React.isValidElement(element)
    ? React.cloneElement(
        element,
        typeof props === 'function' ? props(element) : props,
      )
    : element

const addPropsToChildren = (children, props) =>
  !Array.isArray(children)
    ? addPropsToReactElement(children, props)
    : children.map(childElement => addPropsToReactElement(childElement, props))

const calculatePropsToReactElement = (element, props) => {
  const {
    value,
    fullWidth,
    disabled,
    multiple,
    maxSelections,
    handleChange,
  } = props

  let selected
  const forceEnable = element.props.value < 0
  let isDisabled = disabled || element.props.disabled

  if (Array.isArray(value)) {
    selected = value.some(
      el => el?.toString() === element.props.value?.toString(),
    )

    isDisabled =
      isDisabled ||
      (!selected && maxSelections && multiple && value.length >= maxSelections)
  } else {
    selected = value && value.toString() === element.props.value?.toString()
  }

  isDisabled = forceEnable ? false : isDisabled

  return {
    selected: selected,
    disabled: isDisabled,
    onClick: handleChange,
    fullWidth,
  }
}

function ToggleButtonGroup({
  value: selectedValue,
  onChange,
  orientation,
  fullWidth,
  disabled,
  multiple,
  maxSelections,
  className,
  children,
}) {
  const handleChange = (event, value) => {
    if (!multiple) {
      return onChange(event, value)
    }

    if (!Array.isArray(selectedValue)) {
      return onChange(event, [value])
    }

    if (value) {
      return onChange(event, [...selectedValue, value])
    }

    return onChange(
      event,
      selectedValue.filter(
        el => el.toString() !== event.currentTarget.value?.toString(),
      ),
    )
  }

  return (
    <div
      role="group"
      className={classNames(
        'toggle-button-group',
        {
          'toggle-button-group--column': orientation === 'column',
          'toggle-button-group--full-width': fullWidth,
        },
        className,
      )}
    >
      {addPropsToChildren(children, element =>
        calculatePropsToReactElement(element, {
          value: selectedValue,
          fullWidth,
          disabled,
          multiple,
          maxSelections,
          handleChange,
        }),
      )}
    </div>
  )
}

export default ToggleButtonGroup
