import React, { useCallback } from 'react'
import ComingSoonModal from './components/ComingSoonModal'

import './HomePageCui.scss'
import InputZipcodeCui from './components/InputZipcodeCui'
import imageHeader from '../../assets/img/headerImage.jpg'
import { CuiHeader } from 'front-lib'
import CommonQuestionsCui from '../../components/CommonQuestionsCui'
import { commonQuestionsHome } from 'src/constants'
import { openCustomerServiceChat } from 'src/utils/customerServiceChat'
import useTrackEvent from 'src/hooks/useTrackEvent'

const HomePage = ({
  isLoading,
  validZipCodeError,
  validZipCodeReset,
  continueAction,
  askEmail,
  initialZipcode,
  actions: { setUserSelectedDataStart, sendIdentify},
}) => {
  const helpRequestedCallback = useTrackEvent({
    eventName: 'CTA Button Clicked',
  });
  
  const handleHelpClick = () => {
    openCustomerServiceChat(helpRequestedCallback);
  };  

  const handleOnSubmit = useCallback(
    (email) => {
      const zipcode = validZipCodeError?.data?.zipcode
      setUserSelectedDataStart({
        email,
        zipcode,
        isZipcodeNotReached: validZipCodeError.errorCode === "ZIP_CODE_DO_NOT_COVER" ? true : false,
      })
      validZipCodeReset()
      sendIdentify()
    },
    [validZipCodeError, setUserSelectedDataStart, validZipCodeReset, sendIdentify],
  )

  const handleOnClose = () => {
    validZipCodeReset()
  }

  const openComingSoonModal = !!validZipCodeError

  return (
    <div className="containerHome-cui">
      {openComingSoonModal && <ComingSoonModal
        isOpen={openComingSoonModal}
        onClose={handleOnClose}
        isLoading={isLoading}
        onSubmit={handleOnSubmit}
        email={validZipCodeError?.data.email}
        validZipCodeError={validZipCodeError}
      />}

      <div className="homeHeaderContainer">
        <CuiHeader
          showNeedHelp
          showNeedHelpTitle
          showLogo
          homeLink={process.env.REACT_APP_SITE_BASE_URL}
          onClickHelp={handleHelpClick}
        />
        <div className="container-cui container-cui-row">
          <div className="container-text">
            <h1 className="rebrand">Sign Up</h1>
            <p className="rebrand">Get started with your zip code</p>
            <InputZipcodeCui
              continueAction={continueAction}
              isLoading={isLoading}
              askEmail={askEmail}
              initialZipcode={initialZipcode}
            />
          </div>
          <div className="half-container-image">
            <img src={imageHeader} alt="hero" />
          </div>
        </div>
      </div>

      <CommonQuestionsCui className="rebrand" options={commonQuestionsHome} />
    </div>
  )
}

export default HomePage
