import { put, call, takeEvery, all, select } from 'redux-saga/effects'

import * as checkoutPageActionCreator from './creators'
import * as checkoutPageActionSelector from './selectors'
import * as signUpCommonsActionCreator from 'src/redux/ducks/screens/signup/commons/creators'
import * as signUpCommonsSelector from 'src/redux/ducks/screens/signup/commons/selectors'
import * as checkoutPageActions from './actions'

import * as commonCreators from 'src/redux/ducks/commons/creators'

import { GQL } from 'src/api/services'

import * as ErrorBeautifier from 'src/utils/errorsBeautifier'

import { SESSION_KEY } from 'src/constants'
import { identify } from 'src/redux/ducks/commons/saga'
import { logError, logInfo } from 'src/utils/logError'
import { getRecaptcha } from 'src/utils/recaptcha'
import {
  isExpiredCoupon,
  hasRestrictions,
  isEligibleCoupon,
  isEligibleZeroInvoices,
  hasEconomicData,
  hasUnavailableDay,
} from 'src/utils/coupons'

const getApplicationState = () => select(state => state)

function* getUserEmail() {
  const applicationState = yield getApplicationState()
  const { email } = checkoutPageActionSelector.getFormData(applicationState)
  return email
}

function* getUserClientSecret() {
  const applicationState = yield getApplicationState()
  const { clientSecret, stripeId } = checkoutPageActionSelector.getFormData(applicationState)
  return {
    clientSecret,
    stripeId
  }
}

function* getZipcodeMismatch() {
  const applicationState = yield getApplicationState()
  const { zipcodeMismatch } = checkoutPageActionSelector.getAddressData(applicationState)
  return zipcodeMismatch
}

function* getSelectedZipcode() {
  const applicationState = yield getApplicationState()
  return signUpCommonsSelector.getUserSelectedZipcode(applicationState)
}

function* getSelectedRing() {
  const applicationState = yield getApplicationState()
  return signUpCommonsSelector.getRing(applicationState)
}

function* getPaymentMethod() {
  const applicationState = yield getApplicationState()
  return checkoutPageActionSelector.getCardData(applicationState)
}

export function* setCheckoutPageSteps({ payload }) {
  yield put(checkoutPageActionCreator.setCheckoutStep(payload))
}

function* track(event, data, warning = false) {
  yield put(
    commonCreators.loggingStart({
      event,
      warning,
      data,
    }),
  )
}

export function* addAddress({ payload: address }) {
  try {
    const zipcode = address.zipcode
    address.zipcodeMismatch = yield getZipcodeMismatch()
    const email = yield getUserEmail()
    const selectedZipcode = yield getSelectedZipcode()
    const selectedRing = yield getSelectedRing()

    if (zipcode !== selectedZipcode && !address.zipcodeMismatch) {
      const ring = yield call(GQL.getRingByZipcode, zipcode)
      if (ring.id !== selectedRing.id) {
        address.zipcodeMismatch = true
        yield put(checkoutPageActionCreator.setAddresses(address))
        return
      }
    }

    const response = yield call(GQL.publicAddAddress, {
      addressInfo: {
        firstname: address.firstname,
        lastname: address.lastname,
        street: address.address,
        floor: address.apt,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
        phone: address.phone.replace(/\D/g, ''),
        address_instruction: address.deliveryInstructions
      },
    })

    if (response.error) {
      yield track(
        'addressError',
        {
          email,
          address,
          error: response.error,
          showClientError: ErrorBeautifier.addAddressesError(
            response.error,
          ),
        },
        true,
      )

      yield put(
        checkoutPageActionCreator.setAddressesFailed(
          ErrorBeautifier.addAddressesError(response.error),
        ),
      )
    } else {
      address.id = response.id
      yield put(
        checkoutPageActionCreator.setCheckoutStep({
          delivery: false,
          payment: true,
        }),
      )

      yield put(checkoutPageActionCreator.setAddresses(address))
      yield put(signUpCommonsActionCreator.persistState({ sessionId: SESSION_KEY }))

      yield track('addAddressAction', {
        category: 'new-funnel',
        label: 'test-new-funnel',
        action: 'checkout-address-completed',
      })
    }
  } catch (error) {
    logError(error)
    const email = yield getUserEmail()
    const errorMessage = error.message

    yield track(
      'addressError',
      {
        email,
        address,
        error: errorMessage,
        showClientError: ErrorBeautifier.addAddressesError(errorMessage),
      },
      true,
    )

    yield put(
      checkoutPageActionCreator.setAddressesFailed(
        ErrorBeautifier.addAddressesError(errorMessage),
      ),
    )
  } finally {
    yield put(checkoutPageActionCreator.setAddresses(address))
    yield identify()
  }
}

export function* addCard({ payload }) {
  try {
    const { stripe, cardInfo, paymentMethodType, token, device, captchaEnabled, isPaymentElementEnabled } = payload;
    let recaptchaSetupIntents = '';

    if (captchaEnabled) {
      recaptchaSetupIntents = yield getRecaptcha('setupIntent');
    }

    const email = yield getUserEmail();

    if (stripe && paymentMethodType === 'creditCard') {

      let { clientSecret, stripeId: customerId } = yield getUserClientSecret();

      if (!clientSecret) {
        const getSecret = yield call(GQL.setupIntents, {
          token: recaptchaSetupIntents,
          // Stripe types: payment_element = p_e, card = c
          type: isPaymentElementEnabled ? 'p_e' : 'c',
        });
        clientSecret = getSecret.client_secret;
        customerId = getSecret.customer_id;
        yield put(checkoutPageActionCreator.setFormData({ clientSecret, stripeId: customerId }));
      }

      const paymentMethodFromState = yield getPaymentMethod();

      if (!paymentMethodFromState.id) {
        let result;

        if (isPaymentElementEnabled) {
          logInfo(`[STRIPE] PaymentElement Enabled. Customer ID ${customerId}`)
          result = yield stripe.confirmSetup({
            elements: cardInfo.element,
            clientSecret,
            redirect: 'if_required',
            confirmParams: {
              return_url: window.location.href,
              payment_method_data: {
                billing_details: {
                  address: {
                    country: 'US'
                  }
                }
              }
            }
          });
        } else {
          result = yield stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardInfo.element,
              billing_details: {
                name: cardInfo.name,
              },
            }
          });
        }

        if (result.error) {
          const errorMessage = result.error.message;
          logInfo(`[STRIPE] ${errorMessage}. Customer ID: ${customerId}`);
          yield put(checkoutPageActionCreator.setCardFailed(errorMessage));
        } else {
          if (isPaymentElementEnabled) {
            logInfo(`[STRIPE] PaymentElement Enabled success. Customer ID ${customerId}`)
          }
          yield put(
            commonCreators.loggingStart({
              event: 'addPaymentMethodAction',
              warning: false,
              data: {
                category: 'new-funnel',
                label: paymentMethodType,
                action: 'checkout-card-completed',
              },
            }),
          )
          yield put(
            checkoutPageActionCreator.setCard({
              id: customerId,
              paymentMethod: result.setupIntent.payment_method,
              paymentMethodType
            }),
          )
          yield put(signUpCommonsActionCreator.startCreateUser());
        }
      } else {
        yield put(
          checkoutPageActionCreator.setCard({ ...paymentMethodFromState }),
        )
        yield put(signUpCommonsActionCreator.startCreateUser());
      }
      return;
    }

    if (paymentMethodType === 'paypal') {
      const publicAddCardData = yield call(GQL.publicAddPaypal, {
        email,
        cardInfo,
        token,
        device,
      })

      if (publicAddCardData.error) {
        yield put(
          checkoutPageActionCreator.setCardFailed(publicAddCardData.error),
        )
      } else {
        yield put(
          commonCreators.loggingStart({
            event: 'addPaymentMethodAction',
            warning: false,
            data: {
              category: 'new-funnel',
              label: paymentMethodType,
              action: 'checkout-card-completed',
            },
          }),
        )
        yield put(
          checkoutPageActionCreator.setCard({
            ...publicAddCardData,
            paymentMethodType: payload.paymentMethodType,
          }),
        )
        yield put(signUpCommonsActionCreator.startCreateUser())
      }
      return;
    }
  } catch (error) {
    logError(error)
    const email = yield getUserEmail()
    const errorMessage = error.message ? error.message : error.error

    yield put(
      commonCreators.loggingStart({
        event: 'cardsError',
        warning: true,
        data: {
          email,
          error: errorMessage,
        },
      }),
    )
    yield put(checkoutPageActionCreator.setCardFailed(errorMessage))
  }
}

/**
 * @description Validates if the new coupon is applicable to the selected plan based on the following conditions:
 * restrictions
 * notAvailablePlans
 * isEligible
 *
 * @param {Object} coupon - New coupon to be applied
 * @param {Object} selectedPlan - User selected plan
 * @returns {boolean} - True if the coupon is applicable, false otherwise
 */
const isCouponApplicable = ({ coupon, selectedPlan, startDay, store }) =>
  coupon &&
  !isExpiredCoupon(coupon) &&
  !hasRestrictions(coupon, selectedPlan?.id) &&
  isEligibleCoupon(coupon) &&
  !isEligibleZeroInvoices(coupon) &&
  !hasUnavailableDay({ coupon, startDay, store })

/**
 * @description Validates a coupon code against business rules and the user selected plan
 *
 * @param {string} payload.couponCode - The new coupon code to validate.
 * @returns {Object} The validation result.
 */
function* validateCoupon({ payload }) {
  const { couponCode } = payload

  // Get application state and relevant data
  const applicationState = yield getApplicationState()
  const zipCode = signUpCommonsSelector.getUserSelectedZipcode(applicationState)
  const store = signUpCommonsSelector.getUserStore(applicationState)
  const { startDay } = signUpCommonsSelector.getUserSelectedData(
    applicationState,
  )
  const { email } = checkoutPageActionSelector.getFormData(applicationState)
  const selectedPlan = signUpCommonsSelector.getUserSelectedPlan(
    applicationState,
  )
  const { onlyEconomicPlans } = signUpCommonsSelector.getSignUpInitData(
    applicationState,
  )

  // Run coupon and available plans calls concurrently
  const [newCouponData, availablePlans] = yield all([
    call(GQL.applyCouponCode, couponCode),
    call(GQL.getMealPlans, zipCode, couponCode, onlyEconomicPlans),
  ])

  // Validate new coupon and selected plan
  const applicableCoupon = isCouponApplicable({
    coupon: newCouponData?.coupon,
    selectedPlan,
    startDay,
    store,
  })
  const isSelectedPlanValid = availablePlans?.plans.some(
    availablePlan => availablePlan.id === selectedPlan.id,
  )

  const isEconomicFlow = hasEconomicData({
    coupon: couponCode,
    onlyEconomicPlans,
  })

  const logInfoPayload = {
    userData: {
      email,
      zipCode,
      selectedPlan,
    },
    newCouponData,
    availablePlans,
    onlyEconomicPlans,
  }

  if (!applicableCoupon || !isSelectedPlanValid || isEconomicFlow) {
    let reason = ''

    if (!applicableCoupon) {
      reason = 'Coupon is not applicable'
    } else if (!isSelectedPlanValid) {
      reason = 'Selected plan is not valid for the coupon'
    } else if (isEconomicFlow) {
      reason = 'Economic flow cannot be used with a coupon'
    }

    yield put(
      checkoutPageActionCreator.setCouponFailed({
        success: false,
        error: {
          message: 'Invalid coupon for this order',
          reason,
        },
      }),
    )

    logInfo('Change coupon in checkout - coupon validation failed', {
      ...logInfoPayload,
      reason,
    })
    return
  }

  yield put(
    checkoutPageActionCreator.setCoupon({
      success: true,
      data: {
        message: 'Coupon applied successfully',
        ...newCouponData,
      },
    }),
  )

  logInfo(
    'Change coupon in checkout - coupon validation succeeded',
    logInfoPayload,
  )
  return
}

export function* validateGiftcard({ payload }) {
  const giftcardCode = payload?.giftcardCode
  try {
    const response = yield call(GQL.validateGiftcard, giftcardCode)
    const state = {
      giftcardCode: giftcardCode,
      amount: response.amount,
      validated: response.validated,
    }
    yield put(checkoutPageActionCreator.setValidateGiftcard(state))
    logInfo('Giftcard validation succeeded', state)
  } catch (error) {
    yield put(
      checkoutPageActionCreator.setValidateGiftcardFailed(error.message),
    )
    logError(error, {
      message: 'Giftcard validation failed', giftcardCode,
    })
  }
  return
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(
      checkoutPageActions.CHECKOUT_PAGE.startSetStep,
      setCheckoutPageSteps,
    ),
    takeEvery(checkoutPageActions.USER_ADDRESSES.start, addAddress),
    takeEvery(checkoutPageActions.USER_CARD.start, addCard),
    takeEvery(checkoutPageActions.USER_COUPON_DATA.start, validateCoupon),
    takeEvery(checkoutPageActions.VALIDATE_GIFTCARD.start, validateGiftcard),
  ])
}
