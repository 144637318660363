import { CuiCard } from 'front-lib'
import DetailWithIcon from './DetailWithIcon'
import MealsDetail from './MealsDetail'

const OrderDetails = ({
  deliveryDate,
  deliveryTime,
  mealsPerWeek,
  products,
}) => {
  return (
    <CuiCard className={'order-details--card'}>
      <h2 className={'order-details--title'}>Order Details</h2>
      <DetailWithIcon color={'green-2'} icon={'calendar'} title={'Delivery'}>
        <p>
          Your order will be delivered on{' '}
          <span className="font-weight-bold">{deliveryDate}</span>, between{' '}
          <span className="font-weight-bold">{deliveryTime}.</span>
        </p>
      </DetailWithIcon>
      <DetailWithIcon
        color={'green-2'}
        icon={'cart'}
        title={'What’s in your order'}
      >
        <p>
          Your subscription plan:{' '}
          <span className="font-weight-bold">
            {mealsPerWeek} meals per week.
          </span>
        </p>
      </DetailWithIcon>
      <MealsDetail meals={products} />
    </CuiCard>
  )
}
export default OrderDetails
