import ImageWithDefaultPlaceholder from 'src/components/ImageWithDefaultPlaceHolder'
import Bullet from 'src/components/Bullet/Bullet'
import noMealPlaceholder from 'src/assets/no-image.jpg'

const MealCard = ({ image, name, quantity }) => {
  return (
    <div className={'meal-card'}>
      <div className={'meal-card--product'}>
        <div className={'meal-card--image'}>
          <ImageWithDefaultPlaceholder
            src={image}
            srcDefault={noMealPlaceholder}
            alt={name}
            className="cui-meal-image"
          />
        </div>
        <p className={'meal-card--product-name rebrand small'}>{name}</p>
      </div>
      <p className={'rebrand bold'}>
        <Bullet className={'bg-wine-2 color-white-1'}>{quantity}</Bullet>
      </p>
    </div>
  )
}

export default MealCard
