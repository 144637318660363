import auth0js, { Auth0Callback, Auth0Error, DbSignUpOptions } from 'auth0-js'
import { CONNECTIONS } from '../utils'
import * as sessionStorageUtil from 'src/utils/sessionStorage'
import { useDispatch } from 'react-redux'
import {
  setAuthUser,
  setAuthError,
  setIsAuthLoading,
  saveAuthData,
  setToken,
} from 'src/features/auth/authSlice'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { logError } from 'src/utils/logError'
import { EVENTS } from 'src/utils/events'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { getCustomAttributeValue } from 'src/utils/auth0'

const useAuth0Api = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const signInEvent = useTrackEvent({ eventName: EVENTS.loginCompleted })

  const webAuth: auth0js.WebAuth = new auth0js.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirectUri: window.location.href,
    responseType: 'token',
    protocol: 'oauth2',
    scope: 'openid profile email',
  })

  const socialLogin = (connection: string) => {
    webAuth.authorize({
      connection,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })
  }

  const login = async (credentials: any, cb?: any) => {
    try {
      dispatch(setAuthError(null))
      dispatch(setIsAuthLoading(true))
      await new Promise((resolve, reject) => {
        webAuth.login(
          {
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
            realm: CONNECTIONS.CU,
            username: credentials.email,
            password: credentials.password,
          },
          error => {
            if (cb) cb(error)
            reject(error)
          },
        )

        webAuth.parseHash({ hash: window.location.hash }, function(
          err,
          authResult,
        ) {
          if (err) {
            return console.log(err)
          }
          webAuth.client.userInfo(authResult?.accessToken as string, function(
            err,
            user,
          ) {
            dispatch(setAuthUser(user))
            signInEvent({
              login_type: getCustomAttributeValue(user, 'provider'),
            })
          })
        })

        resolve('')
      })
    } catch (error) {
      logError(error, '[sneak peek - login error]')
      dispatch(setAuthError(error))
    } finally {
      dispatch(setIsAuthLoading(false))
    }
  }

  const logout = (returnTo: string) => {
    try {
      webAuth.logout({
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        returnTo,
      })
      dispatch(setAuthError(''))
    } catch (error) {
      logError(error, '[sneak peek - logout error]')
      dispatch(setAuthError(error))
    }
  }

  const signUp = async (
    connection: string,
    credentials: any,
    userData: any,
    cb?: Auth0Callback<any, Auth0Error>,
  ) => {
    try {
      dispatch(setAuthError(null))
      dispatch(setIsAuthLoading(true))
      await new Promise((resolve, reject) => {
        webAuth.signup(
          {
            connection,
            email: credentials.email,
            password: credentials.password,
            given_name: userData.firstName,
            family_name: userData.lastName,
          } as DbSignUpOptions,
          (error, authResult) => {
            if (cb) cb(error, authResult)
            if (error) {
              reject(error)
              return
            }

            webAuth.login(
              {
                clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
                realm: CONNECTIONS.CU,
                username: credentials.email,
                password: credentials.password,
              },
              error => {
                reject(error)
              },
            )
            dispatch(setAuthUser(authResult))
            dispatch(saveAuthData())
            signInEvent({
              login_type: getCustomAttributeValue(authResult, 'provider'),
            })
            resolve('')
          },
        )
      })
    } catch (error) {
      logError(error, '[sneak peek - sign up error]')
      dispatch(setAuthError(error))
      dispatch(setIsAuthLoading(false))
    }
  }

  const resetPassword = (
    connection: string,
    email: string,
    cb?: Auth0Callback<any, Auth0Error>,
  ) => {
    try {
      webAuth.changePassword(
        {
          connection,
          email,
        },
        (error, result) => {
          if (cb) cb(error, result)
        },
      )
    } catch (error) {
      logError(error, '[sneak peek - reset password error]')
    }
  }

  const checkSession = async () => {
    try {
      dispatch(setIsAuthLoading(true))

      await new Promise((resolve, reject) => {
        webAuth.checkSession({}, function(err, authResult) {
          const { accessTokenParam } = parse(location.search, {
            ignoreQueryPrefix: true,
          })
          const accessToken = accessTokenParam ?? authResult?.accessToken

          if (err) {
            if (err.error === 'consent_required') {
              webAuth.authorize({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              })
            }
            reject(err)
            return
          }

          if (!accessToken) {
            reject(err)
            return
          }

          sessionStorageUtil.setAuthAccessToken(accessToken)
          webAuth.client.userInfo(accessToken, function(error: any, user: any) {
            if (error) {
              reject(error)
              return
            }

            const authUser = {
              ...user,
              magento_id: user["https://cookunity.com/magento_id"]
            }

            dispatch(setAuthError(''))
            dispatch(setAuthUser(authUser))
            dispatch(setToken(accessToken))
            sessionStorageUtil.setAuthAccessToken(accessToken)
            dispatch(saveAuthData())
          })
          resolve('')
        })
      })
    } catch (error) {
      logError(error, '[sneak peek - check session error]')
      dispatch(setAuthError(error))
      dispatch(setAuthUser(null))
      dispatch(setToken(null))
    } finally {
      dispatch(setIsAuthLoading(false))
    }
  }

  return {
    webAuth,
    socialLogin,
    login,
    signUp,
    resetPassword,
    checkSession,
    logout,
  }
}
export default useAuth0Api
