import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import IndexScreen from '../screens'
import HomeScreen from '../screens/home'
import ThankYouScreen from '../screens/thankyou'
import OpenStoreScreen from '../screens/open-store'
import AuthenticationGuard from '../app/guards/AuthenticationGuard/AuthenticationGuard'
import ResurrectionGuard from '../app/guards/ResurrectionGuard/ResurrectionGuard'
import ConfirmOrder from './confirmation/ConfirmOrder'
import MealsSelection from './meals-selection/MealsSelection'
import ResurrectionPage from './resurrection/ResurrectionPage'
import ThankYou from './thank-you/ThankYou'
import ConfirmOrderGuard from './confirmation/ConfirmOrderGuard'
import ThankYouGuard from './thank-you/ThankYouGuard'
import MealSelectionGuard from './meals-selection/MealSelectionGuard'
import DeliveryDaySelection from './delivery-day-selection/DeliveryDaySelection'
import { RESURRECTION_PATHS, REDIRECT_PATHS } from '../constants/routes'
import DeliveryDaySelectionGuard from './delivery-day-selection/DeliveryDaySelectionGuard'
import { GOVX_CALLBACK_PATH } from 'src/app/providers/GovXOAuthProvider'
import Loader from 'src/components/Loader'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <IndexScreen />,
      },
      {
        path: '*',
        element: <IndexScreen />,
      },
      {
        path: 'attn',
        element: <HomeScreen />,
      },
      {
        path: 'thankyou',
        element: <ThankYouScreen />,
      },
      {
        path: GOVX_CALLBACK_PATH,
        element: <Loader />,
      },
      {
        path: RESURRECTION_PATHS.DASHBOARD,
        element: (
          <AuthenticationGuard>
            <ResurrectionGuard />
          </AuthenticationGuard>
        ),
        children: [
          {
            index: true,
            element: <ResurrectionPage />,
          },
          {
            path: RESURRECTION_PATHS.DELIVERY_DAY_SELECTION,
            element: (
              <DeliveryDaySelectionGuard>
                <DeliveryDaySelection />
              </DeliveryDaySelectionGuard>
            ),
          },
          {
            path: RESURRECTION_PATHS.MEAL_SELECTION,
            element: (
              <MealSelectionGuard>
                <MealsSelection />
              </MealSelectionGuard>
            ),
          },
          {
            path: RESURRECTION_PATHS.CONFIRM_ORDER,
            element: (
              <ConfirmOrderGuard>
                <ConfirmOrder />
              </ConfirmOrderGuard>
            ),
          },
          {
            path: RESURRECTION_PATHS.THANK_YOU,
            element: (
              <ThankYouGuard>
                <ThankYou />
              </ThankYouGuard>
            ),
          },
        ],
      },
      {
        path: REDIRECT_PATHS.REDIRECT_TO_STORE,
        element: <OpenStoreScreen />,
      },
    ],
  },
])
