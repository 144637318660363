import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import { removeDuplicates } from 'src/screens/signup/utils'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'

import { CuiIcon } from 'front-lib';
import ToggleButtonGroup from '../../../../../../components/ToggleButtonGroup'
import ToggleButton from '../../../../../../components/ToggleButton'
import './DeliveryOptionsCui.scss'

function DeliveryOptionsCui({
  validDates,
  firstSelectionStartDay,
  startDay,
  onChange,
}) {
  const { isTreatment: isDeliveryDayInFunnelTreatment } = useExperiment(
    EXPERIMENTS.deliveryDayInFunnel,
  )

  const handleChange = useCallback(
    (e, value) => {
      onChange(value)
    },
    [onChange],
  )

  const deliveryOptions = removeDuplicates(validDates, 'day')

  return (
    <ToggleButtonGroup
      value={firstSelectionStartDay && startDay.displayDate}
      onChange={handleChange}
      className="delivery-options"
      orientation="column"
      fullWidth
    >
      {deliveryOptions.map(validDate => {
        if (!validDate) return null
        const [day, date] = validDate.label.split(',')
        return isDeliveryDayInFunnelTreatment ? (<ToggleButton
          key={date}
          value={validDate.displayDate}
          disabled={validDate.soldout}
          className="delivery-options__option_exp"
        >
          {!validDate.soldout && validDate.recommended && (
            <div className="delivery-options__option_exp__suggested">
              <CuiIcon
                className="delivery-options__option_exp__suggested_icon"
                name='starFull'
                alt="star icon"
              />
              <span>
                SUGGESTED DATE
              </span>
            </div>
          )}

          <div className='delivery-options__option_exp__content'>
            <div className="delivery-options__option_exp__content--right">
              <span className="delivery-options__option_exp__content--day">
                {day},
              </span>
              <span>
                {date}
              </span>
            </div>

            {validDate.soldout && (
              <div className="delivery-options__option_exp__label soldout_tag">
                <CuiIcon
                  className="delivery-options__option_exp__label--icon"
                  name='alertTriangle'
                  alt="alert icon"
                />
                <span className="delivery-options__option_exp__label--text">
                  Sold out
                </span>
              </div>
            )}
          </div>
        </ToggleButton>)
        :
        (<ToggleButton
            key={date}
            value={validDate.displayDate}
            disabled={validDate.soldout}
            className="delivery-options__option"
          >
            <div className="delivery-options__option__content">
              <span className="delivery-options__option__content--day">
                {day},
              </span>
              <span className="delivery-options__option__content--date">
                {date}
              </span>
            </div>
            {!validDate.soldout && validDate.recommended && (
              <div className="delivery-options__option__label suggested_tag">
                <CuiIcon
                  className="delivery-options__option__label--icon"
                  name='starFull'
                  alt="star icon"
                />
                <span className="delivery-options__option__label--text">
                  Suggested
                </span>
              </div>
            )}
            {validDate.soldout && (
              <div className="delivery-options__option__label soldout_tag">
                <CuiIcon
                  className="delivery-options__option__label--icon"
                  name='alertTriangle'
                  alt="alert icon"
                />
                <span className="delivery-options__option__label--text">
                  Sold out
                </span>
              </div>
            )}
          </ToggleButton>)
        })}
    </ToggleButtonGroup>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: creatorCommons.trackingStart,
    },
    dispatch,
  ),
})

export default connect(null, mapDispatchToProps)(DeliveryOptionsCui)
