import React, { useEffect, useMemo } from 'react'

import { CuiIcon, CuiTooltip } from 'front-lib'
import { useSelector } from 'react-redux'
import {
  couponSelector,
  fetchTaxRate,
  taxRateSelector,
} from 'src/features/cart/cartSlice'
import { zipCodeSelector } from 'src/features/address/addressSlice'
import { useDispatch } from 'react-redux'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import { mealsSelectedSelector } from 'src/features/meals/mealsSlice'
import { calculateSummary, formatNumberToCurrency } from 'src/utils/priceUtils'

const ConfirmSummary = () => {
  const dispatch = useDispatch()
  const zipCode = useSelector(zipCodeSelector)
  const planSelected = useSelector(planSelectedSelector)
  const mealsSelected = useSelector(mealsSelectedSelector)
  const coupon = useSelector(couponSelector)
  const taxRate = useSelector(taxRateSelector)

  useEffect(() => {
    if (!taxRate && zipCode) {
      dispatch(fetchTaxRate({ zipCode }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode])

  const summary = useMemo(
    () =>
      calculateSummary({
        plan: planSelected,
        meals: mealsSelected,
        promotion: coupon.promotion,
        taxRate,
      }),
    [coupon, mealsSelected, planSelected, taxRate],
  )

  return (
    <div className="confirm-order-summary-container ">
      <h3>Order Description</h3>
      <div className="table">
        <div className="table-item__confirm-order">
          <p>
            Your plan (
            {planSelected?.mealsPerDelivery * planSelected?.deliveries} meals)
          </p>
          <p>{formatNumberToCurrency(summary.planPrice)}</p>
        </div>

        <div className="table-item__confirm-order">
          <p>Extras</p>
          <p>{formatNumberToCurrency(summary.premiumMealsFee)}</p>
        </div>

        <div className="table-item__confirm-order">
          <p>Delivery</p>
          <p>{formatNumberToCurrency(summary.deliveryFee)}</p>
        </div>

        <div className="table-item__confirm-order">
          <p className="rebrand row">
            Others
            <CuiTooltip small tip={<div>Taxes & Service Fee</div>}>
              <CuiIcon name="infoEmptyCircled" />
            </CuiTooltip>
          </p>
          <p className="bold_checkout">{formatNumberToCurrency(summary.tax)}</p>
        </div>

        <div className="table-item__confirm-order table-item__border">
          <p className="table-item__green">
            <strong>Discount applied</strong>
          </p>
          <p className="table-item__green">
            <strong>- {formatNumberToCurrency(summary.discount)}</strong>
          </p>
        </div>

        <div className="table-item__confirm-order">
          <p>
            <strong>ORDER TOTAL</strong>
          </p>
          <p>
            <strong>{formatNumberToCurrency(summary.total)}</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmSummary
