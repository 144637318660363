import { CuiCard, Modal } from 'front-lib'
import React from 'react'
import EditPayment from './components/EditPayment/EditPayment'

import './PaymentPage.scss'

const PaymentPage = ({ toggleModal, showModal, card }) => {
  return (
    <div className="resurection-cards payment-card">
      <h3>Payment method</h3>
      <CuiCard>
        <div className="container-cui-row">
          <div className='payment-row'>
            <i className="fas fa-credit-card"></i>
            <p>
            {`${card.cardType} ****${card.last4}`}
          </p>
          </div>
          <button className="edit" onClick={toggleModal}>
            Edit
          </button>
        </div>
      </CuiCard>
      <Modal isOpen={showModal} onRequestClose={toggleModal}>
        <EditPayment onSubmit={toggleModal} />
      </Modal>
    </div>
  )
}

export default PaymentPage
