import React from 'react'
import PaymentFrom from '../PaymentForm/PaymentForm'
import './EditPayment.scss'

const EditPayment = ({ onSubmit }) => {
  return (
    <div className="edit-payment">
      <h2>Payment method</h2>

      <PaymentFrom onSave={onSubmit} />
    </div>
  )
}

export default EditPayment
