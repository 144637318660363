import { CuiButton, CuiIcon } from 'front-lib'
import { CHEFS_CHOICE_BUTTON_LABEL } from '../contants'

const ChooseForMe = ({
  onClickChooseForMe,
  totalOrderedProductsQty,
  maxProductToCart,
  isChooseForMeLoading,
  size = 'medium',
}) => {
  return (
    <CuiButton
      size={size}
      fill="outline"
      color="dark"
      onClick={onClickChooseForMe}
      disabled={
        totalOrderedProductsQty >= maxProductToCart || isChooseForMeLoading
      }
    >
      <CuiIcon name="flashIcon" />
      <span className="text">{CHEFS_CHOICE_BUTTON_LABEL}</span>
    </CuiButton>
  )
}

export default ChooseForMe
