import React from 'react'
import ConfirmMeal from './components/ConfirmMeals/ConfirmMeals'
import { useSelector } from 'react-redux'
import { mealsSelectedSelector } from 'src/features/meals/mealsSlice'
import { useVariant } from '@unleash/proxy-client-react'

function ConfirmMealsCart() {
  const mealsSelected = useSelector(mealsSelectedSelector)

  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');

  return (
    <>
      <div className="confirm_cart_container-cui">
        <h2>My Meals</h2>
        {mealsSelected?.map(meal => (
          <ConfirmMeal
            key={meal.id}
            name={meal.name}
            image={isTreatment ? meal.image_path + meal.image : meal.image}
            orderProductQty={meal.quantity}
            shortDescription={meal.shortDescription}
          />
        ))}
      </div>
    </>
  )
}

export default ConfirmMealsCart
