export function filter(arr = [], type, onlyId = false) {
  if (type === 'goals') {
    return arr.map(goal => {
      return onlyId
        ? goal.id
        : {
            id: goal.id,
            name: goal.name,
          }
    })
  } else if (type === 'proteins') {
    const tastesMapped = []
    arr.forEach(protein => {
      // eslint-disable-line
      protein.tastes.forEach(protein => {
        // eslint-disable-line
        tastesMapped.push(
          onlyId
            ? protein.id
            : {
                id: protein.id,
                magento_id: protein.magento_id,
                name: protein.name,
              },
        )
      })
    })
    return tastesMapped
  } else if (type === 'allergens') {
    return arr.map(allergy => {
      return onlyId
        ? allergy.id
        : {
            id: allergy.id,
            name: allergy.name,
          }
    })
  } else if (type === 'diets') {
    return arr.filter(diet => {
      return diet.id !== -2
    })
  } else if (type === 'specificationsAvoid') {
    return arr.filter(specification => {
      return specification.id !== -2
    })
  }
  return []
}
