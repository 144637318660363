import React from 'react'

import HomeScreen from 'src/screens/home'
import SignUpScreen from 'src/screens/signup'
import { createCUCookie } from 'src/utils/cookieUtils'
import useAppSearchParams from 'src/hooks/useAppSearchParams'

import { getTokenFromHash } from 'src/app/providers/GovXOAuthProvider'

function IndexScreenContainer() {
  const { getAllSearchParams } = useAppSearchParams()
  const { zipcode, hsl, giftcard, giftCardCode } = getAllSearchParams()

  const token = getTokenFromHash()
  const showHomePage = (!zipcode && !token) || hsl
  const hasGiftCardCode = giftcard || giftCardCode

  if (hasGiftCardCode) {
    createCUCookie('giftcardCode', hasGiftCardCode, { includeSubdomains: true })
  }

  return showHomePage ? (
    <HomeScreen askEmail={!hsl} initialZipcode={zipcode} />
  ) : (
    <SignUpScreen />
  )
}

export default IndexScreenContainer
