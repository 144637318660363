import { CuiMealCardLoading } from 'front-lib'
import { useSelector } from 'react-redux'
import { isLoadingMealsSelector, mealsSelector } from 'src/features/meals/mealsSlice'

import MealCard from '../MealCard/MealCard'
import './MealsCardsGrid.scss'
import { mealsToShowSelector } from 'src/features/ui/uiSlice'

const MealsCardsGrid = ({ isEditable, filterSelected }) => {
  const meals = useSelector(mealsSelector)
  const mealsToShow = useSelector(mealsToShowSelector(meals, filterSelected))
  const isLoadingMeals = useSelector(isLoadingMealsSelector)

  return (
    <div className="meals-cards-grid">
      {!isLoadingMeals && Array.isArray(mealsToShow) ? (
        mealsToShow.map(meal => (
          <MealCard key={meal.id} meal={meal} isEditable={isEditable} />
        ))
      ) : (
        <>
          <CuiMealCardLoading />
          <CuiMealCardLoading />
          <CuiMealCardLoading />
          <CuiMealCardLoading />
        </>
      )}
    </div>
  )
}

export default MealsCardsGrid
