import { put, takeEvery, select } from 'redux-saga/effects'

import * as actionCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'
import * as actions from 'src/redux/ducks/screens/signup/pages/DeliverySettings/actions'
import selectors from 'src/redux/ducks/screens/signup/pages/DeliverySettings/selectors'

import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import { logError } from 'src/utils/logError'

const getApplicationState = () => select(state => state)

export function* setDeliveryOptions({ payload }) {
  try {
    const applicationState = yield getApplicationState(),
      deliveryOptions = selectors.deliveryOptions.getData(applicationState)

    yield put(
      actionCreators.deliveryOptionCreators.success({
        ...deliveryOptions,
        ...payload,
      }),
    )

    yield put(signUpCommonActionCreators.resetChefsList())
  } catch (error) {
    logError(error)
    yield put(
      actionCreators.deliveryOptionCreators.failed(
        'Invalid set delivery option',
      ),
    )
  }
}

const deliveryOptionsArray = [
  takeEvery(actions.DELIVERY_OPTIONS.start, setDeliveryOptions),
]

export default deliveryOptionsArray
