import React from 'react'
import { CuiButton } from 'front-lib'
import CookUnityLogo from 'src/assets/img/logo-cookunity.png'
import './FullScreenError.scss'

const FullScreenError = () => {
  const onReload = e => {
    e.preventDefault()
    window.location = '/'
  }

  return (
    <div className="fullScreenError">
      <img className="logo" src={CookUnityLogo} alt="CookUnity Logo" />
      <img className="errorHero" alt="Something went wrong" />
      <h1>Something went wrong</h1>
      <p>We're already investigating the issue.</p>
      <CuiButton color="dark" onClick={onReload}>
        Reload
      </CuiButton>
      <p>or come back in a few minutes.</p>
    </div>
  )
}

export default FullScreenError
