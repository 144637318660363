import { logError } from './logError'

export function removeUrlParameter(search, paramsToRemove = []) {
  try {
    const searchParams = new URLSearchParams(search)
    paramsToRemove.map(param => searchParams.delete(param))
    return `?${searchParams.toString()}`
  } catch (error) {
    logError(error)
    return search
  }
}

export const getSearchParam = name => {
  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.search)
  return searchParams.get(name)
}

export const removeTrailingSlash = (str) => {
  if (str.endsWith('/')) {
    return str.slice(0, -1);
  } else {
    return str;
  }
}