import React from 'react'
import Select from 'react-select'
import ToggleButtonGroup from '../../../../../../../../components/ToggleButtonGroup'
import ToggleButton from '../../../../../../../../components/ToggleButton'
import { CuiIcon } from 'front-lib'
import { EXPERIMENTS } from 'src/constants/experiments'
import useExperiment from 'src/hooks/useExperiment'
import classnames from 'classnames'

const QuizOptionsCui = props => {
  const { question, onSelect, optionsSelected } = props
  const { options } = props.question

  const { isTreatment: simplifiedDietsQuizExperiment } = useExperiment(
    EXPERIMENTS.simplifiedDietsQuizExperiment,
  )

  const selectStyles = {
    control: base => ({
      ...base,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid black',
      },
    }),
  }

  const getBaseImage = url => {
    if (!url) {
      return undefined
    }

    const filenameWithExtension = url.split('/').pop()
    const filename = filenameWithExtension.split('.')[0]
    const baseFilename = filename.split('-')[0]

    return baseFilename
  }

  const handleSelect = (questionName, values = []) => {
    const valuesArray = Array.isArray(values) ? values : [values]
    const lastValue = valuesArray.slice(-1)
    const firstValue = valuesArray[0]
    
    let selectedValues
    if (lastValue < 0) {
      selectedValues = lastValue
    } else if (firstValue < 0) {
      selectedValues = valuesArray.slice(1)
    } else {
      selectedValues = valuesArray
    }

    const newOptionsSelected = selectedValues.map(value => {
      return options.find(option => option.value === value)
    })

    onSelect(questionName, newOptionsSelected)
  }

  const isMultipleSelection = question.max && question.max !== 1

  return (
    <div
      id={`quiz-cui__${question.name}`}
      className={classnames({
        'simplified-diets-quiz-exp': simplifiedDietsQuizExperiment,
      })}
    >
      {question.type === 'checkbox' && (
        <ToggleButtonGroup
          value={optionsSelected.map(optionSelected => optionSelected.id)}
          onChange={(e, values) => handleSelect(question.name, values, e)}
          multiple={isMultipleSelection}
          maxSelections={question.max}
          orientation="column"
          fullWidth
          className="quiz-cui__checkbox"
        >
          {options.map(option => (
            <ToggleButton
              key={option.value}
              value={option.value}
              className={`quiz-cui__checkbox__option ${
                option.name === 'None' ? 'none_option' : ''
              }`}
            >
              {(option.path || option.image) && (
                <CuiIcon
                  path={option.path}
                  name={getBaseImage(option.image)}
                  className="quiz-cui__checkbox__option--img"
                />
              )}
              <span className="quiz-cui__checkbox__option--text">
                {option.label === 'Meat' ? 'Beef' : option.label}
              </span>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}

      {question.type === 'select' && (
        <div className="quiz__option_select">
          <Select
            styles={selectStyles}
            value={null}
            onChange={e => {
              onSelect(question.name, e)
            }}
            options={options.map(option => {
              return optionsSelected.find(
                element => element.value === option.value,
              )
                ? { ...option, isDisabled: true }
                : option
            })}
            placeholder={question.placeholder}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'silver',
              },
            })}
          />

          <div className="quiz__options_tags">
            {optionsSelected.map((option, i) => {
              return (
                <span
                  key={`tag_${i}`}
                  onClick={() => onSelect(question.name, option)}
                >
                  {option.label} <button>x</button>
                </span>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuizOptionsCui
