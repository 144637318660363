import { IRecord as record } from 'src/redux/helpers/Immutable'

import * as preOrderActions from './actions'
import duck from './duck'

const initialState = record({
  cart: record({
    products: [],
    previousSelectionCart: [],
    totalProductQty: 0,
    preSelectError: '',
  }),
  chooseForMe: record({
    start: false,
    success: false,
  })
})

const cartReducer = {
  [preOrderActions.CART.add]: (state, { payload }) => {
    return state
      .setIn(['cart', 'products'], payload.products)
      .setIn(['cart', 'totalProductQty'], payload.totalProductQty)
      .setIn(['cart', 'planSizeExceeded'], payload.planSizeExceeded)
  },

  [preOrderActions.CART.remove]: (state, { payload }) => {
    return state
      .setIn(['cart', 'products'], payload.products)
      .setIn(['cart', 'totalProductQty'], payload.totalProductQty)
  },

  [preOrderActions.CART.reset]: state => {
    return state
      .setIn(['cart', 'products'], [])
      .setIn(['cart', 'totalProductQty'], 0)
  },

  [preOrderActions.CART.setPreviousSelectionCart]: (state, { payload }) => {
    return state.setIn(['cart', 'previousSelectionCart'], payload)
  },

  [preOrderActions.CART.setCartData]: (state, { payload }) => {
    return state.setIn(['cart'], payload)
  },

  [preOrderActions.CART.setPreselectError]: (state, { payload }) => {
    return state.setIn(['cart', 'preSelectError'], payload)
  },
}

const chooseForMe = {
  [preOrderActions.CHOOSE_FOR_ME.start]: state => {
    return state
      .setIn(['chooseForMe', 'start'], true)
      .setIn(['chooseForMe', 'success'], false)
  },
  [preOrderActions.CHOOSE_FOR_ME.success]: state => {
    return state
    .setIn(['chooseForMe', 'start'], false)
    .setIn(['chooseForMe', 'success'], true)
  },
  [preOrderActions.CHOOSE_FOR_ME.startRefresh]: state => {
    return state
      .setIn(['chooseForMe', 'start'], true)
      .setIn(['chooseForMe', 'success'], false)
  },
  [preOrderActions.CHOOSE_FOR_ME.startUndo]: state => {
    return state
      .setIn(['chooseForMe', 'start'], false)
      .setIn(['chooseForMe', 'success'], false)
  },
}

export default duck.createReducer(
  {
    ...cartReducer,
    ...chooseForMe
  },
  initialState,
)
