import duck from "./duck";

export const APPLY_COUPON = {
  start: duck.defineType('APPLY_COUPON_START'),
  success: duck.defineType('APPLY_COUPON_SUCCESS'),
  failed: duck.defineType('APPLY_COUPON_FAILED'),
  reset: duck.defineType('APPLY_COUPON_RESET'),
};

export const INIT_DATA = {
  start: duck.defineType('INIT_DATA_START'),
  success: duck.defineType('INIT_DATA_SUCCESS'),
  failed: duck.defineType('INIT_DATA_FAILED'),
  reset: duck.defineType('INIT_DATA_RESET'),
  loading: duck.defineType('INIT_DATA_LOADING'),
};

export const USER_SELECTED_DATA = {
  start: duck.defineType('USER_SELECTED_DATA_START'),
  set: duck.defineType('SET_USER_SELECTED_DATA'),
};

export const BREAD_PATH = {
  start: duck.defineType('BREAD_PATH_START'),
  set: duck.defineType('SET_BREAD_PATH'),
  startSetCurrentStepName: duck.defineType('START_BREAD_PATH_CURRENT_STEP_NAME'),
  setCurrentStepName: duck.defineType('SET_BREAD_PATH_CURRENT_STEP_NAME'),
  startAfterElement: duck.defineType('START_BREAD_PATH_AFTER'),
  setAfterElement: duck.defineType('SET_BREAD_PATH_CURRENT_AFTER'),
};

export const SEO_DATA = {
  startAddSeoData: duck.defineType('SEO_DATA_START_ADD'),
  addSeoData: duck.defineType('SEO_DATA_ADD'),
};

export const CREATE_USER = {
  start: duck.defineType('CREATE_USER_START'),
  success: duck.defineType('CREATE_USER_SUCCESS'),
  failed: duck.defineType('CREATE_USER_FAILED'),
  reset: duck.defineType('CREATE_USER_RESET'),
};

export const CREATE_ORDER = {
  start: duck.defineType('CREATE_ORDER_START'),
  success: duck.defineType('CREATE_ORDER_SUCCESS'),
  failed: duck.defineType('CREATE_ORDER_FAILED'),
  reset: duck.defineType('CREATE_ORDER_RESET'),
};

export const SHOW_TOP_BAR = {
  start: duck.defineType('SHOW_TOP_BAR_START'),
  success: duck.defineType('SHOW_TOP_BAR_SUCCESS'),
};

export const REGISTER_REQUEST_FLOW = {
  start: duck.defineType('REGISTER_REQUEST_FLOW_START'),
  success: duck.defineType('REGISTER_REQUEST_FLOW_SUCCESS'),
};

export const INIT_PREFERENCES_QUIZ = {
  start: duck.defineType('INIT_PREFERENCES_QUIZ_START'),
  success: duck.defineType('INIT_PREFERENCES_QUIZ_SUCCESS'),
  failed: duck.defineType('INIT_PREFERENCES_QUIZ_FAILED'),
  reset: duck.defineType('INIT_PREFERENCES_QUIZ_RESET'),
};

export const PREFERENCES_QUIZ_RESPONSE = {
  setResponse: duck.defineType('PREFERENCES_QUIZ_SET_RESPONSE'),
  resetResponse: duck.defineType('PREFERENCES_QUIZ_RESPONSE_RESET'),
};

export const EXPERIMENTS = {
  start: duck.defineType('EXPERIMENTS_START'),
  success: duck.defineType('EXPERIMENTS_SUCCESS'),
  failed: duck.defineType('EXPERIMENTS_FAILED'),
  reset: duck.defineType('EXPERIMENTS_RESET'),
};

export const FORCED_SKIP_QUIZ = {
  start: duck.defineType('FORCED_SKIP_QUIZ_START'),
  success: duck.defineType('FORCED_SKIP_QUIZ_SUCCESS'),
};

export const AUTHENTICATED = {
  start: duck.defineType('SET_AUTHENTICATED'),
};

export const CONFIRM_NEW_ZIPCODE = {
  start: duck.defineType('CONFIRM_NEW_ZIPCODE'),
};

export const PERSIST_STATE = {
  start: duck.defineType('PERSIST_STATE'),
}

export const RECOVER_STATE = {
  start: duck.defineType('RECOVER_STATE'),
}

export const CHEFS_LIST = {
  start: duck.defineType('CHEFS_LIST_START'),
  success: duck.defineType('CHEFS_LIST_SUCCESS'),
  failed: duck.defineType('CHEFS_LIST_FAILED'),
  update: duck.defineType('CHEFS_LIST_UPDATE'),
  reset: duck.defineType('CHEFS_LIST_RESET'),
};

export const LOAD_CHEF_DETAIL = {
  start: duck.defineType('LOAD_CHEF_DETAIL_START'),
  success: duck.defineType('LOAD_CHEF_DETAIL_SUCCESS'),
  failed: duck.defineType('LOAD_CHEF_DETAIL_FAILED'),
  reset: duck.defineType('LOAD_CHEF_DETAIL_RESET'),
};


export const LOAD_MENU_SORT_BY = {
  start: duck.defineType('LOAD_MENU_SORT_BY_START'),
  success: duck.defineType('LOAD_MENU_SORT_BY_SUCCESS'),
  failed: duck.defineType('LOAD_MENU_SORT_BYL_FAILED'),
  reset: duck.defineType('LOAD_MENU_SORT_BYL_RESET'),
}

export const TAX_RATE_ZIP_CODE = {
  set: duck.defineType('SET_TAX_RATE_ZIP_CODE'),
};

export const SEARCH_MEALS_IDS = {
  set: duck.defineType('SEARCH_MEALS_IDS_SET'),
  reset: duck.defineType('SEARCH_MEALS_IDS_RESET'),
};

export const DDO_TOGGLES = {
  set: duck.defineType('DDO_TOGGLES_SET'),
}
