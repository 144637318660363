import React from 'react'
import PropTypes from 'prop-types'

let paypal;

class PaypalButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enabled: false,
      btnPaypalId: props.id || 'btnPaypal'
    }
    this.dataCollector = null
    this.paypalCheckout = null
  }

  handleAuthorize = data => {
    const deviceData = JSON.parse(this.dataCollector.deviceData)
    return this.paypalCheckout
      .tokenizePayment(data)
      .then(payload =>
        this.props.onSubmit(payload.nonce, deviceData.correlation_id)
      )
  }

  async componentDidMount() {
    const braintree = await import("braintree-web")
    paypal = await import("paypal-checkout")
    const props = this.props

    const clientInstance = await braintree.client.create({
      authorization: props.client
    })
    const [dataCollector, paypalCheckout] = await Promise.all([
      braintree.dataCollector.create({client: clientInstance, paypal: true}),
      braintree.paypalCheckout.create({client: clientInstance})
    ])
    this.dataCollector = dataCollector
    this.paypalCheckout = paypalCheckout

    this.setState({ enabled: true }, () => {
      paypal.Button.render(
        {
          locale: 'en_US',
          style: {
            color: 'gold',
            shape: 'pill',
            label: 'pay',
            height: 45,
            layout: 'horizontal',
            size: 'responsive'
          },
          env: props.env,
          onAuthorize: this.handleAuthorize,
          onCancel: data => props.onCancel(data),
          onError: error => props.onError(error),
          payment: () =>
            paypalCheckout.createPayment({
              flow: 'vault',
              billingAgreementDescription: props.description
            })
        },
        this.state.btnPaypalId
      )
    })
  }

  componentWillUnmount() {
    if (this.dataCollector) {
      this.dataCollector.teardown()
    }
  }

  render() {
    return (
      <>
        {this.state.enabled && (
          <div id={this.state.btnPaypalId}/>
        )}
      </>
    )
  }
}

PaypalButton.propTypes = {
  id: PropTypes.string,
  env: PropTypes.oneOf(['sandbox', 'production']).isRequired,
  client: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired
};

export default PaypalButton
