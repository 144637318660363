import { datadogRum } from '@datadog/browser-rum'

export const logError = (error, context) => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      console.error('[logError]', error, context)
      return
    }
    datadogRum.addError(error, context)
  } catch (error) {
    console.error('[logError]', error, context)
  }
}

export const logInfo = (message, context) => {
  try {
    datadogRum.addAction(message, context)
    if (process.env.NODE_ENV !== 'production') {
      console.log('[logInfo]', message, context)
    }
  } catch (error) {
    logError(error, context)
  }
}

export const logView = (viewName, context) => {
  try {
    datadogRum.startView(viewName)
    const prevContext = datadogRum.getGlobalContext();
    datadogRum.setGlobalContext({
      ...prevContext,
      ...context
    })
    datadogRum.addAction(`Page view: ${viewName}`, context)
    if (process.env.NODE_ENV !== 'production') {
      console.log('[logView]', viewName, context)
    }
  } catch (error) {
    logError(error, context)
  }
}
