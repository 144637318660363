import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import Segment from '../utils/segment'
import { PAGE_INFORMATION } from 'src/constants/tracking'

function usePageView() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.hash, [
      'trackNewFunnel',
      'trackNewFunnelForTesting',
    ])

    const pageData =
      PAGE_INFORMATION[location.pathname] || PAGE_INFORMATION.default

    Segment.page(pageData.name, pageData)
  }, [location])
}

export default usePageView
