import React, { useState } from 'react'
import useAuth0Api from '../../hooks/Auth0'
import { CuiLink } from 'front-lib'
import SignInPanel from '../../components/SignInPanel/SignInPanel'
import SignInForm from '../../components/SignInForm'
import { CONNECTIONS, PAGES } from '../../utils'
import styles from './SignUp.module.scss'
import { SignInPanelProps } from '../../types'
import { useSelector } from 'react-redux'
import { authErrorSelector } from 'src/features/auth/authSlice'

const TITLE = 'Create an account to explore your menu'

const BUTTON_LABELS = {
  form: 'Sign up with email',
  google: 'Continue with Google',
  facebook: 'Continue with Facebook',
  apple: 'Continue with Apple',
}

const SignUp = ({ setPage, isPopup }: SignInPanelProps) => {
  const { signUp, socialLogin } = useAuth0Api()
  const [showForm, setShowForm] = useState(false)
  const authError = useSelector(authErrorSelector)

  const ErrorMessage = () => {
    return (
      <div className={styles.error}>
        <p>
          There's an existing account with this email address. Already have an
          account?
        </p>
        <CuiLink
          fill="clear"
          size="small"
          showUnderline={true}
          onClick={() => setPage(PAGES.LOGIN)}
        >
          Log in
        </CuiLink>
      </div>
    )
  }

  const submit = async (values: any) => {
    await signUp(
      CONNECTIONS.CU,
      {
        email: values.email,
        password: values.password,
      },
      {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    )
  }

  return (
    <SignInPanel
      form={
        <SignInForm
          setShowForm={setShowForm}
          handleSubmit={submit}
          submitLabel={'Sign Up'}
          customErrorMessage={
            authError?.code === 'invalid_signup' ? <ErrorMessage /> : null
          }
          isSignUp
        />
      }
      title={TITLE}
      labels={BUTTON_LABELS}
      showForm={showForm}
      setShowForm={setShowForm}
      socialSubmit={(connection: string) => {
        socialLogin(connection)
      }}
      isPopup={isPopup}
    >
      <div className={styles.haveAccountLink}>
        <CuiLink
          data-testid="login"
          fill="clear"
          size="small"
          showUnderline={true}
          onClick={() => setPage(PAGES.LOGIN)}
        >
          I have an account
        </CuiLink>
      </div>
    </SignInPanel>
  )
}

export default SignUp
