import { getCookie } from 'src/utils/cookieUtils'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { sanitizeGA4EventName } from './utils'

export const LIMIT_DELIVERY_DAYS = false
export const SHOW_REFERRAL_WAITLIST = false
export const LIMITED_STORES = [2]
export const LIMITED_DAYS = [2, 3, 4]
export const REFERRAL_UTM = 'utm_source=referral'
export const REFERRAL_PROMO_IDs = [14, 81]

function checkReferralSource() {
  const utmSource = getCookie('UTMQuery')
  return utmSource && utmSource.indexOf(REFERRAL_UTM) !== -1
}

function checkisLimitedStore(store) {
  return LIMITED_STORES.indexOf(store) !== -1
}

export const filterReferralUpcomingsDays = (store, upcomingDays, notAvailableDays) => {
  if (notAvailableDays) {
    const restrictedDays = notAvailableDays.find(restriction => restriction.store === store)
    if(!restrictedDays) return upcomingDays
    const filteredUpcomingDays = upcomingDays.map((day) => {
      return {
        ...day,
        soldout: day.day in restrictedDays.days ? true : false
      }
    })
    return filteredUpcomingDays
  }

  if (!LIMIT_DELIVERY_DAYS) return upcomingDays

  const isSourceReferral = checkReferralSource()
  const isLimitedStore = checkisLimitedStore(store)

  if (isSourceReferral && isLimitedStore) {
    ReactGA.ga('trackNewFunnel.send', 'event', {
      eventCategory: 'Referral Limits',
      eventLabel: 'Only Tue, Wed, Thu',
      eventAction: 'Show Limited Delivery Days',
    })
    ReactGA.ga('trackNewFunnelForTesting.send', 'event', {
      eventCategory: 'Referral Limits',
      eventLabel: 'Only Tue, Wed, Thu',
      eventAction: 'Show Limited Delivery Days',
    })

    ReactGA4.event({
      category: 'Referral Limits',
      label: 'Only Tue, Wed, Thu',
      action: sanitizeGA4EventName('Show Limited Delivery Days'),
    })

    return upcomingDays.filter(date => LIMITED_DAYS.indexOf(date.day) >= 0)
  }

  return upcomingDays
}

export const showReferralWaitlist = store => {
  if (!SHOW_REFERRAL_WAITLIST) return false

  const isSourceReferral = checkReferralSource()
  const isLimitedStore = checkisLimitedStore(store)

  return isSourceReferral && isLimitedStore
}
