import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { CuiButton } from 'front-lib'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CuiIcon } from 'front-lib'
import Modal from '../NewModal/Modal'
import { getMenuFilter } from '../../redux/ducks/modules/MenuList/selectors'
import useTrackEvent from 'src/hooks/useTrackEvent'

import './CuiSearch.scss'

const SEARCH_PLACEHOLDER = 'Search Meals, Chefs and more...'

const CuiSearch = forwardRef(
  (
    {
      size = 'large',
      menuBy,
      handleSearchCallback,
      placeholder = SEARCH_PLACEHOLDER,
    },
    ref,
  ) => {
    const { searchInput } = useSelector(state => getMenuFilter(state))
    const [value, setSearchValue] = useState(searchInput)
    const [searchModalOpened, setSearchModalOpened] = useState(false)
    const trackSearchInputFocus = useTrackEvent({
      eventName: 'Sign Up - Meals Selection - Search Filter Clicked',
    })

    useImperativeHandle(ref, () => ({
      resetInput: () => {
        setSearchValue('')
      },
    }))

    const isSearchEnabled = () => value?.length >= 3

    const handleChangeInput = e => {
      setSearchValue(e.target.value?.toLocaleLowerCase())
    }

    const toggleSearchModal = () =>
      setSearchModalOpened(searchModalOpened => !searchModalOpened)

    const handleOnFocusInput = () => {
      trackSearchInputFocus()
    }

    const handleSubmitSearch = e => {
      e.preventDefault()
      handleSearchCallback({ searchInput: value?.toLocaleLowerCase().trim() })

      if (searchModalOpened) toggleSearchModal()
    }

    const handleClearSearch = e => {
      e.preventDefault()
      setSearchValue('')
      handleSearchCallback({ searchInput: '' })
    }

    return (
      <>
        <div className="cui-search__only-mobile">
          <button
            className={classNames(
              'cui-search-button-mobile',
              'icon-search',
              'icon-button',
              {
                'cui-search-button-mobile__menuby': menuBy,
                'disabled-search': !isSearchEnabled(),
                'enabled-search': isSearchEnabled(),
              },
            )}
            onClick={toggleSearchModal}
            aria-hidden="true"
            type="button"
          >
            <CuiIcon name="search" role="icon" />
            <span className="cui-search__only-desktop">Search</span>
          </button>
          {searchModalOpened && (
            <Modal
              smallSize
              withCloseIcon
              overlayClassName="cui-search-modal-mobile__overlay"
              className="cui-search-modal-mobile"
              isOpen={searchModalOpened}
              onRequestClose={toggleSearchModal}
            >
              <div className="cui-search-modal__title">Search</div>
              <form onSubmit={handleSubmitSearch}>
                <div className="cui-search cui-search__form-mobile">
                  <button
                    className={classNames(
                      'icon-search icon-button',
                      { 'disabled-search': !isSearchEnabled() },
                      { 'enabled-search': isSearchEnabled() },
                    )}
                    aria-hidden="true"
                    type="submit"
                  >
                    <CuiIcon name="search" role="icon" />
                  </button>
                  <input
                    type="text"
                    value={value}
                    onChange={handleChangeInput}
                    onFocus={handleOnFocusInput}
                    placeholder={SEARCH_PLACEHOLDER}
                  />
                  <button
                    className={classNames(
                      'icon-button',
                      { enabledClear: value },
                      { disabledClear: !value },
                    )}
                    onClick={handleClearSearch}
                    type="button"
                  >
                    <CuiIcon name="close" role="icon" />
                  </button>
                </div>
                <div className="cui-search-modal__button">
                  <CuiButton fill="solid" color="dark" type="submit">
                    Search
                  </CuiButton>
                </div>
              </form>
            </Modal>
          )}
        </div>

        <div className="cui-search__only-desktop">
          <form onSubmit={handleSubmitSearch} className="cui-search__form">
            <div className={classNames('cui-search', `cui-search-${size}`)}>
              <button
                className={classNames(
                  'icon-search icon-button',
                  { 'disabled-search': !isSearchEnabled() },
                  { 'enabled-search': isSearchEnabled() },
                )}
                aria-hidden="true"
                type="submit"
              >
                <CuiIcon name="search" role="icon" />
              </button>
              <input
                type="text"
                value={value}
                onChange={handleChangeInput}
                onFocus={handleOnFocusInput}
                placeholder={placeholder}
              />
              <button
                className={classNames(
                  'icon-button',
                  { enabledClear: value },
                  { disabledClear: !value },
                )}
                onClick={handleClearSearch}
                type="button"
              >
                <CuiIcon name="close" role="icon" />
              </button>
            </div>
            <CuiButton
              size="large"
              fill="outline"
              color="dark"
              className="cui-search__button"
              type="submit"
            >
              <span className="only-desktop">Search</span>
            </CuiButton>
          </form>
        </div>
      </>
    )
  },
)

export default CuiSearch
