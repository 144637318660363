import { IRecord as record } from 'src/redux/helpers/Immutable'

import * as signUpAction from './actions'
import duck from './duck'

const initialState = record({
  formData: record({
    email: null,
    clientSecret: null,
    stripeId: null,
  }),

  address: record({
    start: false,
    success: false,
    data: {},
    error: null,
  }),

  card: record({
    start: false,
    success: false,
    data: {},
    error: null,
  }),

  formSteps: record({
    delivery: true,
    payment: false,
  }),

  couponData: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  validateGiftCard: record({
    start: false,
    success: false,
    data: {},
    error: null,
  }),
})

const formDataReducers = {
  [signUpAction.USER_FORM_DATA.success]: (state, { payload }) => {
    return state.set('formData', {
      ...state.get('formData'),
      ...payload,
    })
  },
}

const addressesReducers = {
  [signUpAction.USER_ADDRESSES.start]: state => {
    return state
      .setIn(['address', 'error'], null)
      .setIn(['address', 'start'], true)
  },
  [signUpAction.USER_ADDRESSES.success]: (state, { payload }) => {
    return state
      .setIn(['address', 'start'], false)
      .setIn(['address', 'success'], true)
      .setIn(['address', 'data'], payload)
  },
  [signUpAction.USER_ADDRESSES.failed]: (state, { payload }) => {
    return state
      .setIn(['address', 'start'], false)
      .setIn(['address', 'success'], false)
      .setIn(['address', 'data'], state.getIn(['address', 'data']))
      .setIn(['address', 'error'], payload)
  },
  [signUpAction.USER_ADDRESSES.reset]: (state) => {
    return state
      .setIn(['address', 'start'], initialState.getIn(['address', 'start']))
      .setIn(['address', 'success'], initialState.getIn(['address', 'success']))
      .setIn(['address', 'data'], {
        ...initialState.getIn(['address', 'data']),
        zipcodeMismatch: false
      })
      .setIn(['address', 'error'], initialState.getIn(['address', 'error']))
  },
}

const cardReducers = {
  [signUpAction.USER_CARD.start]: state => {
    return state.setIn(['card', 'error'], null).setIn(['card', 'start'], true)
  },
  [signUpAction.USER_CARD.success]: (state, { payload }) => {
    return state
      .setIn(['card', 'start'], false)
      .setIn(['card', 'success'], true)
      .setIn(['card', 'data'], payload)
  },

  [signUpAction.USER_CARD.failed]: (state, { payload }) => {
    return state
      .setIn(['card', 'start'], false)
      .setIn(['card', 'success'], false)
      .setIn(['card', 'data'], state.getIn(['card', 'data']))
      .setIn(['card', 'error'], payload)
  },
}

const checkoutPageReducers = {
  [signUpAction.CHECKOUT_PAGE.setStep]: (state, { payload }) => {
    return state.setIn(['formSteps'], {
      ...state.get('formSteps'),
      ...payload,
    })
  },
}

const couponDataReducers = {
  [signUpAction.USER_COUPON_DATA.start]: state => {
    return state
      .setIn(['couponData', 'start'], true)
      .setIn(['couponData', 'success'], false)
      .setIn(['couponData', 'data'], null)
      .setIn(['couponData', 'error'], null)
  },
  [signUpAction.USER_COUPON_DATA.success]: (state, { payload }) => {
    return state
      .setIn(['couponData', 'start'], false)
      .setIn(['couponData', 'success'], true)
      .setIn(['couponData', 'data'], payload)
      .setIn(['couponData', 'error'], null)
  },
  [signUpAction.USER_COUPON_DATA.failed]: (state, { payload }) => {
    return state
      .setIn(['couponData', 'start'], false)
      .setIn(['couponData', 'success'], false)
      .setIn(['couponData', 'data'], null)
      .setIn(['couponData', 'error'], payload)
  },
  [signUpAction.USER_COUPON_DATA.reset]: (state) => {
    return state
      .setIn(['couponData', 'start'], initialState.getIn(['couponData', 'start']))
      .setIn(['couponData', 'success'], initialState.getIn(['couponData', 'success']))
      .setIn(['couponData', 'data'], initialState.getIn(['couponData', 'data']))
      .setIn(['couponData', 'error'], initialState.getIn(['couponData', 'error']))
  },
}

const validateGiftCardReducers = {
  [signUpAction.VALIDATE_GIFTCARD.start]: state => {
    return state
      .setIn(['validateGiftCard', 'start'], true)
      .setIn(['validateGiftCard', 'success'], false)
      .setIn(['validateGiftCard', 'data'], initialState.getIn(['validateGiftCard', 'data']))
      .setIn(['validateGiftCard', 'error'], initialState.getIn(['validateGiftCard', 'error']))
  },
  [signUpAction.VALIDATE_GIFTCARD.success]: (state, { payload }) => {
    return state
      .setIn(['validateGiftCard', 'start'], false)
      .setIn(['validateGiftCard', 'success'], true)
      .setIn(['validateGiftCard', 'data'], payload)
      .setIn(['validateGiftCard', 'error'], initialState.getIn(['validateGiftCard', 'error']))
  },
  [signUpAction.VALIDATE_GIFTCARD.failed]: (state, { payload }) => {
    return state
      .setIn(['validateGiftCard', 'start'], false)
      .setIn(['validateGiftCard', 'success'], false)
      .setIn(['validateGiftCard', 'data'], initialState.getIn(['validateGiftCard', 'data']))
      .setIn(['validateGiftCard', 'error'], payload)
  },
  [signUpAction.VALIDATE_GIFTCARD.reset]: (state) => {
    return state
      .setIn(['validateGiftCard', 'start'], initialState.getIn(['validateGiftCard', 'start']))
      .setIn(['validateGiftCard', 'success'], initialState.getIn(['validateGiftCard', 'success']))
      .setIn(['validateGiftCard', 'data'], initialState.getIn(['validateGiftCard', 'data']))
      .setIn(['validateGiftCard', 'error'], initialState.getIn(['validateGiftCard', 'error']))
  },
}


export default duck.createReducer(
  {
    ...formDataReducers,
    ...addressesReducers,
    ...cardReducers,
    ...checkoutPageReducers,
    ...couponDataReducers,
    ...validateGiftCardReducers,
  },
  initialState,
)
