import React from 'react';

import './FooterWarning.scss'

const FooterWarning = ({ footerWarningMessage }) => {
  return (
    <div className="footer__warning">
      <div className="warning-message">{footerWarningMessage}</div>
    </div>
  );
};

export default FooterWarning;
