import { isArray, isNumber, isObject } from './typeUtils'

export const sumElements = value => {
  if (!isArray(value)) {
    throw new TypeError('The parameter must be an array.')
  }

  return value.reduce((acc, val) => {
    if (!isNumber(val)) {
      throw new TypeError('The element must be a number.')
    }

    return acc + val
  }, 0)
}

export const sumObjectValues = value => {
  if (!isObject(value)) {
    throw new TypeError('The parameter must be an object.')
  }

  return sumElements(Object.values(value))
}

export const roundToTwo = value => {
  if (!isNumber(value)) {
    throw new TypeError('The parameter must be a number.')
  }

  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const roundToTwoElements = value => {
  if (!isArray(value)) {
    throw new TypeError('The parameter must be an array.')
  }

  return value.map(el => roundToTwo(el))
}

export const roundToTwoValues = value => {
  if (!isObject(value)) {
    throw new TypeError('The parameter must be an object.')
  }

  return Object.entries(value).reduce((acc, [key, val]) => {
    if (!isNumber(val)) {
      throw new TypeError('The object values must be numbers.')
    }

    acc[key] = roundToTwo(val)

    return acc
  }, {})
}
