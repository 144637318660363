import { getDeliveryDays } from 'src/features/delivery/deliveryAPI'
import { formatDeliveryDay } from 'src/utils/date'
import { autoSelectDeliveryDate } from 'src/utils/utils'

export const validatePersistedStateData = async (persistedState, zipCode) => {
  if (persistedState === null) {
    return
  }
  const {
    userSelectedData: { startDay },
  } = persistedState
  if (startDay) {
    const { deliveryDays: validDeliveryDays } = await getDeliveryDays({
      zipCode,
    })
    const isSelectedDateValid = !!validDeliveryDays.find(
      validDate => validDate.date === startDay.date && validDate.available,
    )
    if (!isSelectedDateValid) {
      const autoSelectedDate = autoSelectDeliveryDate(validDeliveryDays)
      const formattedAutoSelectedDate = formatDeliveryDay(autoSelectedDate)
      return {
        ...persistedState,
        userSelectedData: {
          ...persistedState.userSelectedData,
          startDay: formattedAutoSelectedDate,
        },
        cartData: { products: [] },
      }
    }
  }
  return persistedState
}
