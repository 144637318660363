import { isNullOrUndefined } from './typeUtils'

export const getMealPlanSize = plan => plan.deliveries * plan.mealsPerDelivery

export const getMealsQuantity = meals =>
  meals
    .map(meal => {
      if (isNullOrUndefined(meal.qty) && isNullOrUndefined(meal.quantity)) {
        throw new Error('Meal must have qty or quantity defined.')
      }
      if (meal.qty === 0 || meal.quantity === 0) {
        throw new Error('Meal qty or quantity cannot be zero.')
      }

      return meal.qty || meal.quantity
    })
    .reduce((acc, curr) => acc + curr, 0)

export const getAmountOfExtraMeals = (plan, meals) => {
  const mealPlanSize = getMealPlanSize(plan)
  const mealsQuantity = getMealsQuantity(meals)

  return mealsQuantity >= mealPlanSize ? mealsQuantity - mealPlanSize : 0
}
