import React from 'react'
import { Outlet } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import './App.scss'
import './assets/styles/scss/bootstrap.css'

import { withAuth0App } from 'src/hocs/withAuth0App'
import withStateManagement from './hocs/withStateManagement'
import { compose } from 'redux'
import usePageView from './hooks/usePageView'
import useUTM from './hooks/useUTM'
import Footer from './components/Footer/Footer'

const App = () => {
  usePageView()
  useUTM()

  return (
    <div className="app-routes-container">
      <ToastContainer
        enableMultiContainer
        containerId={'upgrade-coupon-toast'}
      />
      <Outlet />
      <Footer />
    </div>
  )
}

export default compose(withAuth0App, withStateManagement)(App)
