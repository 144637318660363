import React, { forwardRef } from 'react';
import CuiSearch from '../CuiSearch/CuiSearch';
import './CuiSearchBar.scss';

const CuiSearchBar = forwardRef(({ handlerFilterOnClick, menuFilters, placeholder }, ref) => {
  const { meatTypeFilter, specificationsDetailFilter } = menuFilters;

  const handleSearch = ({ searchInput }) => {
    const id = meatTypeFilter || specificationsDetailFilter;
    const type = meatTypeFilter ? 'meatType' : 'specificationsDetail';
    handlerFilterOnClick(id, type, undefined, false, searchInput);
  };

  return (
    <div className="cui-search-bar">
      <CuiSearch ref={ref} size="xs" menuBy handleSearchCallback={handleSearch} placeholder={placeholder} />
    </div>
  );
});

export default CuiSearchBar;
