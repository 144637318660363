import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { updateUserPlan, getPlans, getUserPlans } from './plansAPI'
import { zipCodeSelector } from '../address/addressSlice'
import { userSelector } from '../user/userSlice'

const initialState = {
  plans: null,
  planSelected: null,
  planStatus: 'idle',
}

export const fetchPlans = createAsyncThunk(
  'plans/fetch',
  async (_, { getState }) => {
    const state = getState()
    const user = userSelector(state)
    let response

    if (!user) {
      const zipCode = zipCodeSelector(state)
      response = await getPlans({ zipCode })
    } else {
      response = await getUserPlans()
    }

    const reversedPlans = { ...response, plans: [...response.plans].reverse() }
    return reversedPlans
  },
)

export const changePlan = createAsyncThunk(
  'plans/changePlan',
  async ({ plan }) => {
    updateUserPlan({ plan })
    return { plan }
  },
)

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlanSelected: (state, { payload }) => {
      state.planSelected = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPlans.pending, state => {
        state.planStatus = 'loading'
      })
      .addCase(fetchPlans.fulfilled, (state, { payload }) => {
        state.planStatus = 'idle'
        state.plans = payload.plans
      })
      .addCase(changePlan.pending, state => {
        state.planStatus = 'loading'
      })
      .addCase(changePlan.fulfilled, (state, { payload }) => {
        state.planStatus = 'idle'
        state.planSelected = payload.plan
      })
  },
})

export const { setPlanSelected } = plansSlice.actions

export const plansSelector = state => state.get('plans').plans
export const planSelectedSelector = state => state.get('plans').planSelected
export const planStatusSelector = state => state.get('plans').planStatus === 'loading'

export default plansSlice.reducer
