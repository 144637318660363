import duck from './duck'

export const USER_ADDRESSES = {
  start: duck.defineType('USER_ADDRESSES_START'),
  success: duck.defineType('USER_ADDRESSES_SUCCESS'),
  failed: duck.defineType('USER_ADDRESSES_FAILED'),
  reset: duck.defineType('USER_ADDRESSES_RESET')
}

export const USER_CARD = {
  start: duck.defineType('USER_CARD_START'),
  success: duck.defineType('USER_CARD_SUCCESS'),
  failed: duck.defineType('USER_CARD_FAILED'),
  reset: duck.defineType('USER_CARD_RESET'),
}

export const USER_FORM_DATA = {
  start: duck.defineType('USER_FORM_DATA_START'),
  success: duck.defineType('USER_FORM_DATA_SUCCESS'),
}

export const CHECKOUT_PAGE = {
  startSetStep: duck.defineType('CHECKOUT_PAGE_STEP_START'),
  setStep: duck.defineType('CHECKOUT_PAGE_STEP_SUCCESS'),
}

export const USER_COUPON_DATA = {
  start: duck.defineType('USER_COUPON_DATA_START'),
  failed: duck.defineType('USER_COUPON_DATA_FAILED'),
  success: duck.defineType('USER_COUPON_DATA_SUCCESS'),
  reset: duck.defineType('USER_COUPON_DATA_RESET'),
}

export const VALIDATE_GIFTCARD = {
  start: duck.defineType('VALIDATE_GIFTCARD_START'),
  success: duck.defineType('VALIDATE_GIFTCARD_SUCCESS'),
  failed: duck.defineType('VALIDATE_GIFTCARD_FAILED'),
  reset: duck.defineType('VALIDATE_GIFTCARD_RESET'),
}
