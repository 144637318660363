import React from 'react'
import { useEffect, useCallback, useRef } from 'react'

function ScrollDownTracking({ children, callback, reset }) {
  const thisEl = useRef()
  let maxScroll = 0
  let prevReset = null

  const handleScroll = useCallback(() => {
    if (!thisEl.current && !thisEl.current?.getBoundingClientRect) {
      return
    }

    const element = thisEl.current?.getBoundingClientRect()

    // Calculate the percentual scroll position rounded as multiple of ten
    const scrollPercent = -element.top / (element.height - window.innerHeight)
    const scrollPercentRounded = Math.round(scrollPercent * 100)
    const percentMultipleOfTen = Math.round(scrollPercentRounded / 10) * 10

    // Reset the maximum scroll position when reset value changes
    maxScroll = reset === prevReset ? maxScroll : 0 // eslint-disable-line
    prevReset = reset // eslint-disable-line

    // Update the maximum scroll position if it have a highter value
    if (percentMultipleOfTen > maxScroll) {
      maxScroll = percentMultipleOfTen

      // execute the callback with the max scroll position value
      if (typeof callback === 'function') {
        callback({
          maxScroll: maxScroll,
          elementHeight: Math.round(element.height / 1000) * 1000,
        })
      }
    }
  }, [reset])

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    },
    [reset], // eslint-disable-line
  )

  return <div ref={thisEl}>{children}</div>
}

export default ScrollDownTracking
