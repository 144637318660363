import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import Loader from 'src/components/Loader'

import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as checkoutPageSelectors from 'src/redux/ducks/screens/signup/pages/Checkout/selectors'
import thankYouPageSelectors from 'src/redux/ducks/screens/signup/pages/ThankYou/selectors'
import * as MealsStepSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'

import * as thankYouActionCreators from 'src/redux/ducks/screens/signup/pages/ThankYou/creators'
import * as creatorCommons from 'src/redux/ducks/commons/creators'

import { sanitize, sha256 } from 'src/utils/hash.js'

import ThankYouPageOnboarding from './ThankYouPageOnboarding'
import { useGovXUser } from 'src/app/providers/GovXOAuthProvider'
import { calculateSummary } from 'src/utils/priceUtils'
import { authUserSelector } from 'src/features/auth/authSlice'
import { useSelector } from 'react-redux'
import useAppSearchParams from 'src/hooks/useAppSearchParams'
import { PATHS_NAME } from 'src/constants/routes'
import { logView } from 'src/utils/logError'
import { ringSelector } from 'src/features/address/addressSlice'

function ThankYouPageContainer({
  createUserSuccess,
  userSelectedData,
  cartData,
  createUserData,
  coupon,
  addressData,
  createOrderSuccess,
  createOrderError,
  createOrderData,
  settingRequestSuccess,
  settingRequestError,
  cardData,
  actions,
  isEconomicFlow,
  giftcardCredit,
}) {
  const { getAllSearchParams } = useAppSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const authUser = useSelector(authUserSelector)
  const [isTrackedGTM, setIsTrackedGTM] = useState(false)
  const [deliveryDays, setDeliveryDays] = useState(null)

  const ring = useSelector(ringSelector)
  const previousStoreRingInformation = useSelector(
    signUpCommonSelectors.getRing,
  )

  const govx = useGovXUser()

  useEffect(() => {
    if (authUser) {
      const magentoId = createUserData.magento_id

      if (createUserSuccess) {
        const dayNames = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ]
        const deliveryDaysAux = (userSelectedData.days || [])
          .reduce((acc, val) => [...acc, dayNames[val]], [])
          .toString()
          .replace(/,/g, ', ')

        setDeliveryDays(deliveryDaysAux)

        actions.sendLogging({
          event: 'ThankYouPage',
          warning: false,
          data: {
            category: 'new-funnel',
            label: 'test-new-funnel',
            action: 'thankyou-page',
          },
        })

        handlePostAffiliates(magentoId)

        actions.sendTracking({
          eventName: 'Sign Up - Checkout Completed',
          eventData: {
            user_id: magentoId,
            subscriber_id: createUserData.id,
            user_email: createUserData.email,
            user_name: createUserData.name,
            user_address: addressData.address,
            user_address_city: addressData.city,
            user_address_state: addressData.state,
            user_zipcode: addressData.zipcode,
            user_phone: addressData.phone,
            user_delivery_timeframe: userSelectedData.selectedTimesLot.label,
            user_delivery_delay: '', // TODO: to be completed in a new ticket
            user_delivery_instructions: userSelectedData.deliveryOption,
            payment_method: cardData && cardData.paymentMethodType,
            is_economic_flow: isEconomicFlow,
            is_govx_user: govx?.isVerified ? true : false,
            govx_user_id: govx?.user?.userProfile?.id,
          },
        })

        setTracks({
          ...userSelectedData,
          coupon: coupon,
          deliveryDays: deliveryDays,
          email: createUserData.email,
          cartData: cartData,
          userId: createUserData.id,
          magentoId: magentoId,
          firstName: authUser.given_name,
          lastName: authUser.family_name,
          city: addressData.city,
        })
      }

      const paramsObj = getAllSearchParams()

      logView(PATHS_NAME[location?.pathname], {
        userId: magentoId,
        subscriberId: createUserData.id,
        ringId: ring?.storeId || previousStoreRingInformation?.id,
        storeId: ring?.storeId || previousStoreRingInformation?.storeId,
        isLocal: ring?.isLocal || previousStoreRingInformation?.isLocal,
        planId: userSelectedData?.selectedPlan?.id,
        coupon: coupon?.couponCode,
        email: paramsObj?.email || createUserData.email,
        zipCode: paramsObj?.zipcode || addressData.zipcode,
        params: paramsObj,
        viewName: PATHS_NAME[location?.pathname],
        currentPath: location?.pathname,
        previousPath: null,
      })
    } else {
      window.location = `${process.env.REACT_APP_SUBSCRIPTION_URL}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (createOrderSuccess || createOrderError) {
      handleSetSetting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError, createOrderSuccess])

  useEffect(() => {
    if ((settingRequestSuccess || settingRequestError) && isTrackedGTM) {
      if (window && window.hj) {
        window.hj('trigger', 'notcompletepreferencesOnboarding')
      }
    }
  }, [isTrackedGTM, settingRequestError, settingRequestSuccess])

  useEffect(() => {
    if (isTrackedGTM && createOrderData?.createOrder?.id) {
      const orderSummary = calculateSummary({
        plan: userSelectedData.selectedPlan,
        meals: cartData.products.map(el => ({ ...el.data, qty: el.qty })),
        promotion: coupon.promotion,
        taxRate: userSelectedData.taxesAmount,
        giftcardCredit,
      })

      const dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'OrderComplete',
        order_value: orderSummary.total,
        order_id: createOrderData.createOrder.id,
        enhanced_conversion_data: {
          email: createUserData.email,
          phone_number: addressData.phone,
          first_name: authUser.given_name,
          last_name: authUser.family_name,
          street: addressData.address,
          city: addressData.city,
          region: addressData.state,
          postal_code: addressData.zipcode,
          country: 'US',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackedGTM, createOrderData?.createOrder?.id])

  const handleSetSetting = () => {
    const deliveryDate = userSelectedData.startDay.date.replace(/-/g, '')
    actions.sendUserSettings({
      key: 'satisfactionQuestion',
      value: JSON.stringify({ firstDelivery: deliveryDate }),
    })
    actions.sendUserSettings({
      key: 'firstDeliveryDate',
      value: userSelectedData.startDay.date,
    })
  }

  const handlePostAffiliates = userid => {
    if (window.PostAffTracker) {
      window.PostAffTracker.setAccountId('default1')
      var sale = window.PostAffTracker.createAction('signup')

      sale.setCampaignID('c76f7c20')
      sale.setOrderID(userid)
      sale.setData1(userid)
      window.PostAffTracker.register()
    }
  }

  const setTracks = data => {
    var checkGTM = setInterval(() => {
      if (window.google_tag_manager) {
        clearInterval(checkGTM)
        const pricePerMeal =
          data.selectedPlan.price /
          (data.selectedPlan.mealsPerDelivery * data.selectedPlan.deliveries)

        var dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'ThankyouPage',
          Delivery: data.selectedPlan.deliveries,
          Plan: data.selectedPlan.id,
          Value: data.selectedPlan.price,
          ZipCode: data.zipcode,
          Plan_name:
            data.selectedPlan.mealsPerDelivery +
            ' meals / ' +
            data.selectedPlan.deliveries +
            ' delivery weekly',
          Preferences: data.flow,
          Delivery_days: data.deliveryDays,
          UserEmail: data.email,
          UserId: data.userId,
          MagentoId: data.magentoId,
          UserCart: data.cartData.products.map(product => {
            return {
              ...product,
              price: pricePerMeal,
            }
          }),
          MealsPerDelivery: data.selectedPlan.mealsPerDelivery,
          Summary: data.summary,
          Coupon: data.coupon,
          UserHashedEmail: sha256(sanitize(data.email)),
          UserHashedFirstName: sha256(sanitize(data.firstName)),
          UserHashedLastName: sha256(sanitize(data.lastName)),
          UserHashedExternalId: sha256(sanitize(data.magentoId)),
          UserCity: sanitize(data.city),
        })

        const splitedDay = data.startDay.label.split(',')
        dataLayer.push({
          event: 'sendToGA',
          eventAction: 'delivery date selected',
          eventLabel: `isRecommended: ${!!data?.startDay?.recommended}`,
          eventCategory: splitedDay[0],
        })
        setTimeout(() => {
          setIsTrackedGTM(true)
        }, 300)
      }
    }, 10)
  }

  const getNextPageUrl = page => {
    let nextPageURL = process.env.REACT_APP_SUBSCRIPTION_URL

    nextPageURL += page
    return nextPageURL
  }

  if (!createUserSuccess) navigate('/')
  if (!createOrderError && !createOrderSuccess) return <Loader />

  return (
    <ThankYouPageOnboarding
      onboardingURL={getNextPageUrl('/nextOrder')}
      userSelectedData={userSelectedData}
      cartData={cartData}
    />
  )
}

const mapStateToProps = state => ({
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state).toJS(),

  cardData: checkoutPageSelectors.getCardData(state),

  createOrderError: signUpCommonSelectors.getCreateOrderError(state),
  createOrderSuccess: signUpCommonSelectors.getCreateOrderSuccess(state),
  createOrderData: signUpCommonSelectors.getCreateOrderData(state),

  createUserError: signUpCommonSelectors.getCreateUserError(state),
  createUserSuccess: signUpCommonSelectors.getCreateUserSuccess(state),
  createUserData: signUpCommonSelectors.getCreateUserData(state),

  settingRequestSuccess: thankYouPageSelectors.sendSettings.getRequestSuccess(
    state,
  ),
  settingRequestError: thankYouPageSelectors.sendSettings.getRequestError(
    state,
  ),

  cartData: MealsStepSelectors.getCart(state),

  signUpInitData: signUpCommonSelectors.getSignUpInitData(state),

  coupon: signUpCommonSelectors.getCoupon(state),
  experimentData: signUpCommonSelectors.getExperimentData(state).object,
  addressData: checkoutPageSelectors.getAddressData(state),

  cartPremiumFeesTotal: MealsStepSelectors.getCartPremiumFeesTotal(state),
  isEconomicFlow: signUpCommonSelectors.getIsEconomicFlow(state),
  giftcardCredit: checkoutPageSelectors.getGiftcardCredit(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendLogging: creatorCommons.loggingStart,
      sendUserSettings: thankYouActionCreators.sendSettingsCreators.start,
      sendTracking: creatorCommons.trackingStart,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouPageContainer)
