import duck from "./duck";
import * as actionCreators from "./actions";

export const startLoadProducts = duck.createAction(actionCreators.LOAD_PRODUCT.start);
export const loadProducts = duck.createAction(actionCreators.LOAD_PRODUCT.success);
export const loadProductsFailed = duck.createAction(actionCreators.LOAD_PRODUCT.failed);
export const loadProductsReset = duck.createAction(actionCreators.LOAD_PRODUCT.reset);

export const startFilter = duck.createAction(actionCreators.LOAD_PRODUCT.startFilter);
export const filter = duck.createAction(actionCreators.LOAD_PRODUCT.filter);
export const filterReset = duck.createAction(actionCreators.LOAD_PRODUCT.filterReset);

export const startFilterBy = duck.createAction(actionCreators.LOAD_PRODUCT.startFilterBy);
export const resetFilterBy = duck.createAction(actionCreators.LOAD_PRODUCT.resetFilterBy);
