import duck from "./duck";
import {
  VALID_ZIP_CODE,
  LOGGING,
  SESSION,
  TRACKING,
  IDENTIFY
} from "./actions";

export const validZipCodeStart = duck.createAction(VALID_ZIP_CODE.start);
export const validZipCodeSuccess = duck.createAction(VALID_ZIP_CODE.success);
export const validZipCodeFailed = duck.createAction(VALID_ZIP_CODE.failed);
export const validZipCodeReset = duck.createAction(VALID_ZIP_CODE.reset);


export const loggingStart = duck.createAction(LOGGING.start);
export const loggingSuccess = duck.createAction(LOGGING.success);
export const loggingFailed = duck.createAction(LOGGING.failed);
export const loggingReset = duck.createAction(LOGGING.reset);

export const sessionStart = duck.createAction(SESSION.start);
export const sessionSuccess = duck.createAction(SESSION.success);
export const sessionFailed = duck.createAction(SESSION.failed);
export const sessionReset = duck.createAction(SESSION.reset);

export const trackingStart = duck.createAction(TRACKING.start);
export const trackingSuccess = duck.createAction(TRACKING.success);
export const trackingFailed = duck.createAction(TRACKING.failed);
export const trackingReset = duck.createAction(TRACKING.reset);

export const identifyStart = duck.createAction(IDENTIFY.start);
export const identifySuccess = duck.createAction(IDENTIFY.success);
export const identifyFailed = duck.createAction(IDENTIFY.failed);
export const identifyReset = duck.createAction(IDENTIFY.reset);