import './Footer.scss'

const Footer = () => (
    <footer className='legal-footer'>
        <p>
            This site is protected by reCAPTCHA and the Google <br />
            <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
            >
                Privacy Policy
            </a>{' '}
            and{' '}
            <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
            >
                Terms of Service
            </a>{' '}
            apply.
        </p>
    </footer>
)

export default Footer;