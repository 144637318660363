import React from 'react'
import { useSelector } from 'react-redux'
import { mealsSelectedQuantitySelector } from 'src/features/meals/mealsSlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import './CartHeader.scss'

const CartHeader = () => {
  const planSelected = useSelector(planSelectedSelector)
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector)

  return (
    <h2 className="cart-header">
      Selected meals:{' '}
      {`${mealsSelectedQuantity} / ${planSelected.mealsPerDelivery}`}
    </h2>
  )
}

export default CartHeader
