import classNames from 'classnames'
import './Bullet.scss'

const Bullet = ({ className, children }) => {
  const classes = classNames('bullet', className)

  return <div className={classes}>{children}</div>
}

export default Bullet
