import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { CuiIcon } from 'front-lib'
import { useFlag } from '@unleash/proxy-client-react'
import { isMobile } from 'src/utils/events'
import useTrackEvent from 'src/hooks/useTrackEvent';
import './PlanPrice.scss'
import {
  formatNumberToCurrency,
  getPlanPriceDiscount,
  getPricePerMeal,
  getOrdersDetailed
} from '../../../../../../utils/priceUtils'

function PlanPrice({ selected, coupon, showHeader, className }) {
  const currencyPlanPrice = selected && formatNumberToCurrency(selected?.price)
  const currencyPlanDiscount = useMemo(
    () =>
      (selected &&
        coupon?.promotion &&
        getPlanPriceDiscount({
          plan: selected,
          promotion: coupon.promotion,
        })) ||
      0,
    [coupon.promotion, selected],
  )

  const planPriceWithPromotion = formatNumberToCurrency(
    selected?.price - currencyPlanDiscount,
  )

  const pricePerMeal = formatNumberToCurrency(
    getPricePerMeal({ plan: selected }),
  )
  const pricePerMealWithPromotion = formatNumberToCurrency(
    getPricePerMeal({
      plan: selected,
      promotion: coupon.promotion,
    }),
  )

  const Header = ({ label, color }) => {
    return (
      <div
        className="plan-price-experiment__header"
        style={{ ...({ backgroundColor: color }) }}
      >
        <div>{label ?? 'MOST POPULAR'}</div>
      </div>
    )
  }

  const [displayMultiWeekOrders, setDisplayMultiWeekOrders] = useState(false)

  const OrderDetailRow = ({ className, order }) => {
    const { ordinalText, percentage, price, priceWithPromotion } = order;

    return (
      <>
      <div className={classNames('plan-price-experiment__block', className)}>
        <span className="plan-price-experiment--title">
          {ordinalText}
        </span>
        <span className="plan-price-experiment--percentage">
          {percentage}
        </span>        
        <span className="plan-price-experiment__block--price">
          <span className="plan-price-experiment__base--crossed-out" aria-label={`Order price: ${price}`}>
            {price}
          </span>
          <span className="plan-price-experiment__base--discount" aria-label={`Order price with promotion: ${priceWithPromotion}`}>
            {priceWithPromotion}
          </span>
        </span>
      </div>
    </>      
    )
  }

  const MultiWeekPlanPrice = () => {
    const trackShowPromotionDetails = useTrackEvent({
      eventName: 'CTA Button Clicked',
    });
    const trackHidePromotionDetails = useTrackEvent({
      eventName: 'CTA Button Clicked',
    });

    const ordersDetailed = getOrdersDetailed({ plan: selected, promotion: coupon.promotion })

    const ORDER_DETAIL_ROW_MAX_HEIGHT = 56;
    const ACCORDION_HEIGHT = ORDER_DETAIL_ROW_MAX_HEIGHT * (ordersDetailed.length + 1)

    const isFirstOrder = (order) => order?.count === 1;

    const triggerAccordion = (isOpen) => {
      setDisplayMultiWeekOrders(display => !display)

      let elementIdToScrollTo = ''
      if (isOpen) {
        elementIdToScrollTo='plan-price-container'
        trackShowPromotionDetails({cta_text: 'Show promotion details - Plan step'})
      } else {
        elementIdToScrollTo='layout-container'
        trackHidePromotionDetails({cta_text: 'Hide promotion details - Plan step'});
      }

      if (isMobile()) {
          document.getElementById(elementIdToScrollTo)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }

    return (
      <div className={classNames('plan-price-experiment', className)} id="plan-price-container">
        {showHeader && <Header label={selected.priceTitle} color={className ? null : "#97225a"}/>}
        <div className="plan-price-experiment__block">
          <span className="plan-price-experiment--title">Price per meal</span>
          <span className="plan-price-experiment__block--price">
            <span
              className={classNames('plan-price-experiment__base', {
                'plan-price-experiment__base--crossed-out': coupon?.promotion,
              })}
            >
              {pricePerMeal}
            </span>
            {coupon?.promotion && (
              <span className="plan-price-experiment__base--discount" aria-label={`Price per meal with promotion: ${pricePerMealWithPromotion}`}>
                {pricePerMealWithPromotion}
              </span>
            )}
          </span>
        </div>
        {coupon?.promotion &&
          <>
            <div className="plan-price-experiment--orders">
              {ordersDetailed?.map(order => (
                isFirstOrder(order) &&
                <OrderDetailRow
                  className="plan-price-experiment--first-order'"
                  key={order.count}
                  order={order}
                />
              ))}
            </div>
            <div
              className="plan-price-experiment--orders plan-price-experiment--rest-orders"
              style={{ 'maxHeight': displayMultiWeekOrders ? `${ACCORDION_HEIGHT}px` : 0 }}
            >
              {ordersDetailed?.map(order => (
                !isFirstOrder(order) &&
                <OrderDetailRow
                  className="plan-price-experiment--secondary-orders"
                  key={order.count}
                  order={order}
                />
              ))}

            <div className="plan-price-experiment--terms">
              <span>See terms and conditions for more details</span>
            </div>
            </div>
            <div className="plan-price-experiment__block plan-price-experiment--show-more" id="trigger-accordion-container">
              <button onClick={() => triggerAccordion(!displayMultiWeekOrders)}>
                { displayMultiWeekOrders
                  ? <><span>Hide Promotion details</span> <CuiIcon className="hide-promos" name="chevronUp" role="button" /></>
                  : <><span>Show Promotion details</span> <CuiIcon className="display-promos" name="chevronDown" role="button" /></>
                }
              </button>
            </div>
          </>
        }
      </div>
    )
  }

  const isMultiWeekPromotionEnabled = useFlag('gro-gtt-4271-multi-week-plan-price');
  const hasMultiWeekPromotion = () => coupon?.promotion?.compoundPromotions?.length > 1

  if (isMultiWeekPromotionEnabled && hasMultiWeekPromotion()) {
    return <MultiWeekPlanPrice />
  }

  return (
    <div className={classNames('plan-price-experiment', className)}>
    {showHeader && <Header label={selected.priceTitle} color={className ? null : "#97225a"}/>}
    <div className="plan-price-experiment__block">
      <span className="plan-price-experiment--title">Price per meal</span>
      <span className="plan-price-experiment__block--price">
        <span
          className={classNames('plan-price-experiment__base', {
            'plan-price-experiment__base--crossed-out': coupon?.promotion,
          })}
        >
          {pricePerMeal}
        </span>
        {coupon?.promotion && (
          <span className="plan-price-experiment__base--discount" aria-label={`Price per meal with promotion: ${pricePerMealWithPromotion}`}>
            {pricePerMealWithPromotion}
          </span>
        )}
      </span>
    </div>
    {coupon?.promotion && (
      <>
        <hr className="plan-price-experiment__divider" />

        <div className="plan-price-experiment__block">
          <span className="plan-price-experiment--title">
            {selected.mealsPerDelivery} meals
            <br />
            delivered weekly
          </span>
          <span className="plan-price-experiment__block--price">
            <span className="plan-price-experiment__base--crossed-out">
              {currencyPlanPrice}
            </span>
            <span className="plan-price-experiment__base--discount" aria-label={`Plan price with promotion: ${planPriceWithPromotion}`}>
              {planPriceWithPromotion}
            </span>
          </span>
        </div>
      </>
    )}
  </div>
  )
}

export default PlanPrice
