import {
  filtersOptions,
  meatTypeMap,
  recommendationsFilterOption,
} from './constants'
import {
  preferencesSettledSelector,
  preferencesSelector,
} from '../preferences/preferencesSlice'

export const filterSelectedSelector = state =>
  preferencesSettledSelector(state)
    ? recommendationsFilterOption
    : filtersOptions[0]

export const filterOptionsSelector = state => {
  const filters = [...filtersOptions]
  if (preferencesSettledSelector(state)) {
    filters.splice(1, 0, recommendationsFilterOption)
  }
  return filters
}

export const mealsToShowSelector = (meals, filterSelected) => state => {
  if (meals) {
    if (!filterSelected || filterSelected.id === 'all') {
      return meals
    } else if (filterSelected.id === 'recommendations') {
      const preferences = preferencesSelector(state)
      const meatTypes = preferences.categoryTastes
        .map(
          categoryTaste =>
            meatTypeMap[categoryTaste.name] ||
            categoryTaste.tastes.map(taste => taste.name),
        )
        .flat()

      return meals.filter(meal =>
        meatTypes.some(meatType => meal.meatType === meatType),
      )
    } else {
      const meatTypes = meatTypeMap[filterSelected.id]
      if (meatTypes) {
        return meals.filter(meal =>
          meatTypes.some(meatType => meal.meatType === meatType),
        )
      } else {
        return meals.filter(meal =>
          meal.specificationsDetails.some(
            specificationDetail =>
              specificationDetail.label === filterSelected.id,
          ),
        )
      }
    }
  }
  return meals
}
