import { CuiButton } from 'front-lib'
import React, { useMemo } from 'react'
import './CartFooter.scss'
import { useSelector } from 'react-redux'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import { mealsSelectedQuantitySelector } from 'src/features/meals/mealsSlice'

const CartFooter = ({ onClick }) => {
  const planSelected = useSelector(planSelectedSelector)
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector)

  const isDisabled = useMemo(
    () => planSelected.mealsPerDelivery !== mealsSelectedQuantity,
    [mealsSelectedQuantity, planSelected.mealsPerDelivery],
  )
  return (
    <div className="cart-footer">
      <CuiButton
        className="cart-footer__button"
        size="large"
        onClick={onClick}
        disabled={isDisabled}
      >
        Continue
      </CuiButton>
    </div>
  )
}

export default CartFooter
