import React from 'react'
import classNames from 'classnames'
import AccordionItem from '../AccordionItem'

const Accordion = ({ className, options }) => (
  <div className={classNames('accordion', className)}>
    {options.map(({ id, trigger, content }) => (
      <AccordionItem
        key={`question-${id}`}
        trigger={trigger}
        content={content}
      />
    ))}
  </div>
)

export default Accordion
