const summaryLabels = {
  GIFTCARD: 'GiftCard Credit',
  UNITYCLUB: 'Unity Club Reward',
}

function isUnityClubGiftCard(giftcardCode) {
  return typeof giftcardCode === 'string' && giftcardCode.indexOf('CU-') === 0
}

export function getGiftCardLabel(giftcardCode) {
  if (isUnityClubGiftCard(giftcardCode)) {
    return summaryLabels.UNITYCLUB
  }
  return summaryLabels.GIFTCARD
}
