import React from 'react'
import { Helmet } from 'react-helmet'
import { CuiButton, CuiHeader, CuiIcon } from 'front-lib'

import SubDetails from './components/SubDetails/SubDetails'

import noMealPlaceholder from 'src/assets/no-image.jpg'
import ImageWithDefaultPlaceHolder from 'src/components/ImageWithDefaultPlaceHolder'

import './ThankYou.scss'
import { useSelector } from 'react-redux'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import { mealsSelectedSelector } from 'src/features/meals/mealsSlice'
import { deliveryDaySelectedSelector } from 'src/features/delivery/deliverySlice'
import { storeSelector } from 'src/features/address/addressSlice'
import PaymentMethod from './components/PaymentMethod/PaymentMethod'
import { getDateLabel } from 'src/utils/date'
import { useVariant } from '@unleash/proxy-client-react'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const IMG_STORAGE = process.env.REACT_APP_IMG_STORAGE

const ThankYou = () => {
  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');
  const planSelected = useSelector(planSelectedSelector)
  const mealsSelected = useSelector(mealsSelectedSelector)
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const store = useSelector(storeSelector)


  const createOrderError = false

  const S3_GROWTH_ASSETS = `${process.env.REACT_APP_S3_GROWTH_ASSETS}/media/videos-cui/`

  return (
    <>
      <Helmet>
        <title>A Chef Collective | CookUnity | Thank You</title>
      </Helmet>

      <CuiHeader
        showNeedHelp
        showNeedHelpTitle
        showLogo
        homeLink={process.env.REACT_APP_SITE_BASE_URL}
      />

      <div className="thank-you container-cui">
        {!createOrderError && (
          <div className="thank-you__hero">
            <div className="video-container">
              {store && (
                <>
                  <video
                    className="video-desktop"
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="auto"
                    src={`${S3_GROWTH_ASSETS}thank-you-desktop-${store.id}.mp4`}
                  />
                  <video
                    className="video-mobile"
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="auto"
                    src={`${S3_GROWTH_ASSETS}thank-you-mobile-${store.id}.mp4`}
                  />
                </>
              )}
            </div>
            <div className="welcome-container">
              <div
                className={`prev-delivery ${createOrderError ? 'buttons' : ''}`}
              >
                <div className="top">
                  <div>
                    <h2 className="delivery-date">
                      {deliveryDaySelected?.label}
                    </h2>
                    <div className="thank-row info-top">
                      <div className="thank-row">
                        <CuiIcon name="clock" />
                        <span>
                          {!createOrderError ? 'Scheduled' : 'Available'}
                        </span>
                      </div>
                      {!createOrderError && (
                        <p> | Your order is being created</p>
                      )}
                    </div>
                  </div>
                </div>
                {!createOrderError && (
                  <div className="thank-row column-mobile preorder">
                    <h3>
                      {planSelected?.mealsPerDelivery *
                        planSelected?.deliveries}{' '}
                      meals
                    </h3>
                    <div className="container-meals">
                      <div className="gradient" />
                      {mealsSelected.map(meal => (
                        <ImageWithDefaultPlaceHolder
                          key={meal.id}
                          className="meal"
                          alt="meal"
                          src={
                            isTreatment ?
                              IMG_STORAGE + meal.image_path + meal.image :
                              IMG_URL_SOURCE + meal.image
                          }
                          srcDefault={noMealPlaceholder}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <hr className="separator" />
              <div className="subs-details">
                <div className="subs-container">
                  <h2>Subscription details:</h2>
                  <SubDetails />
                </div>
                <hr className="separator-mobile" />
                <div className="bill-container">
                  <h2>Billing Info</h2>
                  <div>
                    <p>
                      <b>Upcoming Invoice</b> <br />
                      {getDateLabel(
                        new Date(deliveryDaySelected?.cutoff?.time),
                      )}
                    </p>
                  </div>
                  <PaymentMethod />
                </div>
              </div>
              <div className="container-button">
                <CuiButton
                  color="dark"
                  fill="solid"
                  onClick={() => (window.location = process.env.REACT_APP_SUBSCRIPTION_URL)}
                >
                  Continue
                </CuiButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ThankYou
