/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { CuiButton, CuiModal } from 'front-lib'
import { formatPrice } from 'src/utils/utils'
import Analytics from 'src/utils/analytics'
import { cookUnityUuid } from 'src/utils/cookieUtils'
import { EVENTS, getUserOs, isMobile } from 'src/utils/events'
import { getCustomAttributeValue } from 'src/utils/auth0'
import { pageNames } from 'src/screens/signup/constants'
import PlanSelection from 'src/screens/signup/pages/SelectPlans/components/PlanSelection'
import PlanPrice from 'src/screens/signup/pages/SelectPlans/components/PlanPrice'
import { getDiscount, getPriceString } from '../../utils/prices'
import { authUserSelector } from 'src/features/auth/authSlice'

import './SlidingPanelCui.scss'
import { useSelector } from 'react-redux'

const mapPlansToOptions = plans =>
  plans.map(({ id, mealsPerDelivery }) => ({
    id,
    label: mealsPerDelivery,
  }))

const SlidingPanelCui = props => {
  const {
    createUserData,
    signUpInitData,
    userSelectedData,
    coupon,
    isOpen,
    onClose,
    title,
    content,
    actionButtons,
    className,
    style,
    isLoading,
    noAnimation,
    actions,
    planInMealStepExperiment,
    displayFooterWarning,
    footerWarningMessage,
  } = props
  const selectedPlan = userSelectedData.upgradedPlan
  const originalSelectedPlan = useRef(userSelectedData.selectedPlan)
  const [showPanels, setShowPanels] = useState({
    slidingPanelOpen: isOpen,
    showUpgradePlanModal: false,
  })
  const plans = signUpInitData.mealPlans
  const options = useMemo(() => mapPlansToOptions(plans), [plans])
  const authUser = useSelector(authUserSelector)

  const undoPlanHandler = previousPlan => {
    actions.setUserSelectedDataStart({
      selectedPlan: previousPlan || null,
      upgradedPlan: null,
    })
  }

  const planHandler = newPlan => {
    const userMagentoId =
      authUser && getCustomAttributeValue(authUser, 'magento_id')
    const userEmail =
      authUser?.email ||
      createUserData?.email ||
      userSelectedData.email ||
      signUpInitData.email

    Analytics.track(EVENTS.changePlan, {
      user_id: userMagentoId,
      subscriber_id: createUserData?.id,
      anonymous_id: cookUnityUuid,
      user_email: userEmail,
      device: isMobile() ? ' Mobile Web' : 'Web Desktop',
      deviceOs: getUserOs(),
      page_name: isMobile() ? null : pageNames.MEALS,
      old_planmeal: selectedPlan?.title,
      new_planmeal: newPlan?.title,
    })

    actions.setUserSelectedDataStart({
      selectedPlan: newPlan,
      upgradedPlan: null,
      newUpgradedPlan: true,
    })

    setShowPanels({ slidingPanelOpen: false, showUpgradePlanModal: false })
  }

  const upgradePlanHandler = newUpgradedPlan => {
    actions.setUserSelectedDataStart({
      upgradedPlan: newUpgradedPlan,
    })
  }

  const onClickPlan = clickedPlanId => {
    upgradePlanHandler(
      signUpInitData.mealPlans?.find(({ id }) => id === clickedPlanId),
    )
  }

  const saveUpgradedPlan = () => {
    planHandler(selectedPlan)
  }

  const closeUpgradePlanModal = () => {
    undoPlanHandler(originalSelectedPlan.current)
    setShowPanels({ slidingPanelOpen: true, showUpgradePlanModal: false })
  }

  return showPanels.slidingPanelOpen ? (
    <div
      className={classnames('cookunity__sliding_panel_wrapper', className)}
      style={style}
      onClick={onClose}
    >
      <div
        className={classnames('cookunity__sliding_panel', { noAnimation })}
        onClick={e => e.stopPropagation()}
      >
        <div className="header-cui">
          <h2 className="rebrand">{title}</h2>
          <i className="fas fa-times close-icon" onClick={onClose} />
        </div>
        <div className={classnames('content', { loading: isLoading })}>
          {!isLoading && content}
        </div>
        {displayFooterWarning && <div className="cart-footer__warning">
          <span className="warning-message">{footerWarningMessage}</span>
        </div>}
        <div className={classnames({
            'cart-footer-warning-background': displayFooterWarning,
          })}>
          <div className="cart-footer">
            {actionButtons && (
              <div className={classnames({
                'cart-footer__actions': !planInMealStepExperiment,
                'cart-footer__actions--exp': planInMealStepExperiment,
              })}>{actionButtons}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CuiModal
      className="upgrade-plan-modal"
      isOpen={showPanels.showUpgradePlanModal}
      onRequestClose={closeUpgradePlanModal}
      iconClassName="updgrade-plan-modal-close-icon"
    >
      <div className="select-plan__main">
        <section className="select-plan__main__section">
          {coupon && coupon.promotion && coupon.promotion.title && (
            <div className="green-tag">{coupon.promotion.title}</div>
          )}
          <p className="select-plan__main__section__title">
            Select your membership plan
          </p>
          <p className="select-plan__main__section__text">
            Start from 4 to 16 meals per week. You can skip, pause and change
            the plan size anytime.
          </p>
        </section>
        <section className="select-plan__main__section">
          <PlanSelection
            data={options}
            onSelect={onClickPlan}
            selected={selectedPlan || originalSelectedPlan.current}
          />
        </section>
        {(selectedPlan || originalSelectedPlan.current) && (
          <section className="select-plan__main__section">
            <PlanPrice
              coupon={coupon}
              selected={selectedPlan || originalSelectedPlan.current}
            />
          </section>
        )}
      </div>
      <div className="select-plan__footer">
        {selectedPlan || originalSelectedPlan.current ? (
          <div className="select-plan__footer__selected">
            <div className="select-plan__footer__selected__info">
              <span>
                {
                  (selectedPlan || originalSelectedPlan.current)
                    .mealsPerDelivery
                }{' '}
                meals delivered weekly for{' '}
                {getPriceString(
                  (selectedPlan || originalSelectedPlan.current).price,
                )}
              </span>
              {coupon?.promotion && (
                <div className="select-plan__footer__coupon__info">
                  <span className="select-plan__footer__selected--bold">
                    This week SAVE{' '}
                    {getPriceString(
                      getDiscount(
                        coupon,
                        (selectedPlan || originalSelectedPlan.current).price,
                      ),
                    )}
                  </span>
                </div>
              )}
            </div>
            <CuiButton
              size="large"
              className="save-button"
              onClick={saveUpgradedPlan}
              data-testid="next-step"
            >
              Save
            </CuiButton>
          </div>
        ) : (
          <span className="select-plan__footer__non-selected">
            Select the number of meals to continue.
          </span>
        )}
      </div>
    </CuiModal>
  )
}

export default SlidingPanelCui
