import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { CuiIcon } from 'front-lib'
import CheckoutCouponOverlay from '../CheckoutCouponOverlay'
import {
  getChangeCouponOverlayTrackData,
  OVERLAY_VIEWED,
  OVERLAY_CLOSED,
  OVERLAY_SUBMITTED,
  COUPON_CHANGED_EVENT_NAME,
} from 'src/screens/signup/pages/Checkout/utils'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { changeCouponOnSearch } from 'src/utils/coupons'
import { logError } from 'src/utils/logError'

import {
  getCouponData,
  getCouponError,
  getCouponStatus,
} from '../../../../../../redux/ducks/screens/signup/pages/Checkout/selectors'
import { getCouponApplicationEventData } from 'src/redux/ducks/screens/signup/utils'

import './CheckoutCoupon.scss'

function CheckoutCoupon({
  className,
  couponData,
  validateCoupon,
  resetCouponValidation,
}) {
  const { pathname, search } = useLocation()
  const [newCoupon, setNewCoupon] = useState('')
  const [displayOverlay, setDisplayOverlay] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const changeCouponData = useSelector(getCouponData)
  const changeCouponError = useSelector(getCouponError)
  const couponInProgress = useSelector(getCouponStatus)

  const userCoupon = couponData?.userCoupon

  const trackOverlayViewed = useTrackEvent(
    getChangeCouponOverlayTrackData({ event: OVERLAY_VIEWED }),
  )
  const trackOverlayClosed = useTrackEvent(
    getChangeCouponOverlayTrackData({ event: OVERLAY_CLOSED }),
  )
  const trackOverlaySubmitted = useTrackEvent(
    getChangeCouponOverlayTrackData({ event: OVERLAY_SUBMITTED }),
  )
  const trackCouponChanged = useTrackEvent({
    eventName: COUPON_CHANGED_EVENT_NAME,
  })

  const onClickChangeCoupon = () => {
    setDisplayOverlay(true)
    trackOverlayViewed()
  }

  const onSubmitOverlay = async newCouponCode => {
    setNewCoupon(newCouponCode)
    setIsSubmitting(true)
    try {
      await validateCoupon({ couponCode: newCouponCode })
    } catch (error) {
      logError(error, { message: 'error on change coupon submit overlay' })
    }
    trackOverlaySubmitted()
  }

  /**
   * @description Works as 'finally' for the coupon validation
   */
  useEffect(() => {
    if (!couponInProgress) {
      setIsSubmitting(false)
      const validationSuccess = changeCouponData && !changeCouponError
      if (validationSuccess) {
        trackCouponChanged({
          ...getCouponApplicationEventData(changeCouponData?.data?.coupon),
          coupon_code: newCoupon,
          validation_status: 'success',
        })
        window.location = pathname + changeCouponOnSearch(search, newCoupon)
      } else if (changeCouponError) {
        trackCouponChanged({
          coupon_code: newCoupon,
          validation_status: 'failed',
          validation_failed_error: changeCouponError.error?.reason,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponInProgress, changeCouponData, changeCouponError])

  const onCloseOverlay = () => {
    setDisplayOverlay(false)
    resetCouponValidation()
    trackOverlayClosed()
  }

  return (
    <>
      <div className="coupon-overlay-trigger" onClick={onClickChangeCoupon}>
        <input
          className={classNames('coupon-input', className)}
          type="text"
          value={userCoupon}
          readOnly
        />
        <PromotionButtonIcon promotion={couponData?.promotion} />
      </div>
      <CheckoutCouponOverlay
        displayOverlay={displayOverlay}
        onSubmitOverlay={onSubmitOverlay}
        onCloseOverlay={onCloseOverlay}
        couponCode={userCoupon}
        couponError={changeCouponError}
      />
      {isSubmitting && (
        <div className="coupon-loader">
          <Spinner />
        </div>
      )}
    </>
  )
}

const PromotionButtonIcon = ({ promotion }) => {
  if (promotion) {
    return (
      <span className="coupon-button coupon-button__success">
        <CuiIcon
          name="checkmarkCircle2"
          className="coupon-button__success--icon"
        />
      </span>
    )
  } else {
    return (
      <span className="coupon-button coupon-button__plus">
        <CuiIcon name="plus" className="coupon-button__plus--icon" />
      </span>
    )
  }
}

const Spinner = () => {
  return (
    <svg
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      className="cui-spinner"
    >
      <circle className="cui-spinner__circle" cx="25" cy="25" r="20" />
    </svg>
  )
}

export default CheckoutCoupon
