export const validateDate = inputDate => {
  const date = new Date(inputDate)

  if (isNaN(date)) {
    throw new Error(`Invalid date: ${inputDate}`)
  }

  return date
}

export const isValidDate = date => {
  return date instanceof Date && !isNaN(date)
}

export const getDateLabel = (date, format = {}) => {
  if (!isValidDate(date)) {
    return null
  }

  const defaultFormat = {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    timeZone: 'UTC',
  }

  return new Intl.DateTimeFormat('en-US', {
    ...defaultFormat,
    ...format,
  }).format(date)
}

export const formatDeliveryDay = deliveryDate => {
  if (!deliveryDate || !deliveryDate.displayDate) return null
  const date = new Date(deliveryDate.displayDate + 'T00:00:00')
  return {
    ...deliveryDate,
    day: date.getDay(),
    label: getDateLabel(date),
  }
}
