import React from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDispatch } from 'react-redux'
import FullScreenError from 'src/components/FullScreenError'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import { logError } from 'src/utils/logError'

const ErrorBoundary = props => {
  const dispatch = useDispatch()

  const onError = error => {
    logError(error)
    dispatch(
      creatorCommons.loggingStart({
        event: 'componentDidCatch',
        warning: true,
        data: {
          error: error ? error.message : 'unexpected error',
        },
      }),
    )

    dispatch(
      creatorCommons.trackingStart({
        eventName: 'Sign Up - Error Screen Displayed',
        eventData: {
          error: error ? error.message : 'unexpected error',
        },
      }),
    )
  }

  const { ErrorBoundary: ErrorBoundaryWrapper } = useErrorBoundary({
    onError,
    FallbackComponent: FullScreenError,
  })

  return <ErrorBoundaryWrapper>{props.children}</ErrorBoundaryWrapper>
}

export default ErrorBoundary
