import {IRecord as record} from 'src/redux/helpers/Immutable';
import {createSimpleReducerFlow} from 'src/redux/helpers/reducer';
import duck from './duck';
import * as actions from './actions';

const initialState = record({
  deliveryOptions: record({
    start: false,
    success: false,
    data: {
      firstSelectionStartDay: false,
      validDates: [],
      setDeliveryOptions: null,
    },
    error: null,
  }),
});

const deliveryOptionsReducer = createSimpleReducerFlow({
  simpleActions: actions.DELIVERY_OPTIONS,
  stateNames: ['deliveryOptions'],
  initialState,
});

export default duck.createReducer(
  {
    ...deliveryOptionsReducer,
  },
  initialState,
);
