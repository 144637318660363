import { fullImagePath } from 'front-lib'
import { menuPublicQuery } from '../../api/client'

export const mealsQuery = `
query meals($date: String!, $zipCode: String!) {
  menu(date: $date, zipCode: $zipCode) {
    meals {
      id
      name
      shortDescription
      image
      image_path: imagePath
      price
      premiumFee
      premiumSpecial
      sku
      stock
      reviews
      stars
      warning
      categoryId
      category {
        id
      }
      chef {
        id
        firstName
        lastName
        bannerPic
        isCelebrityChef
      }
      ingredients {
        name
      }
      meatType
      nutritionalFacts {
        calories
        carbs
        fat
        protein
      }
      specificationsDetails {
        label
      }
      sideDish {
        id
        name
      }
      weight
      feature {
        background
        color
        description
        icon
        name
      }
    }
  }
}
`

const mealImageFullPath = process.env.REACT_APP_IMG_URL_SOURCE
const defaultImagePath = fullImagePath('no-image.png')

export const getMeals = ({ zipCode, date }) =>
  menuPublicQuery(mealsQuery, { zipCode, date }).then(data => ({
    ...data,
    menu: {
      ...data.menu,
      meals: data.menu.meals.map(meal => ({
        ...meal,
        imageFullPath: meal.image
          ? mealImageFullPath + meal.image
          : defaultImagePath,
      })),
    },
  }))
