import {IRecord as record} from 'src/redux/helpers/Immutable';
import {createSimpleReducerFlow} from 'src/redux/helpers/reducer';
import duck from './duck';
import * as actions from './actions';

const initialState = record({
  sendUserSetting: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),
});

const sendSettingsReducer = createSimpleReducerFlow({
  simpleActions: actions.SEND_SETTINGS,
  stateNames: ['sendUserSetting'],
  initialState,
});

export default duck.createReducer(
  {
    ...sendSettingsReducer,
  },
  initialState,
);
