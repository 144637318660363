import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'

import LostSelectionStepsWarningPopup from 'src/components/Popups/LostSelectionStepsWarning'

import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'

import deliverySettingSelectors from 'src/redux/ducks/screens/signup/pages/DeliverySettings/selectors'
import * as deliverySettingCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'

import * as creatorCommons from 'src/redux/ducks/commons/creators'
import * as creatorMealsStep from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'

import DeliveryPageCui from './DeliveryPageCui'
import * as preOrderPageSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'

import Analytics from 'src/utils/analytics'
import { PATHS, pageNames } from '../../constants'
import { SESSION_KEY } from 'src/constants'

function DeliverySettingPageContainer({
  stepHandler,
  signUpInitData,
  upcomingDays,
  userSelectedData,
  deliveryOptions,
  cartTotalProductQty,
  experimentData,
  actions,
}) {
  const [temporalSelectedDay, setTemporalSelectedDay] = useState(null)
  const [showLostSelectionPoUp, setShowLostSelectionPoUp] = useState(false)

  useEffect(() => {
    actions.sendIdentify()
    Analytics.trackRealLoad('Delivery Page Load')

    if (userSelectedData.startDay) {
      const persistedDate = upcomingDays.find(
        e => e.date === userSelectedData.startDay.date,
      )
      if (!persistedDate || !persistedDate.available) {
        actions.resetProductCart()
        actions.setUserSelectedDataStart({
          startDay: {},
          days: [],
        })
      }
    }

    actions.setDeliveryOptions({
      upcomingDays,
    })

    actions.sendLogging({
      event: 'deliverySettingsPage',
      warning: false,
      data: {
        category: 'new-funnel',
        label: 'test-new-funnel',
        action: 'delivery-page',
        validDates: upcomingDays,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof actions.addSeoData === 'function')
      actions.addSeoData({
        title: 'A Chef Collective | CookUnity | Delivery Options',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setOriginalSelectedDate = originalDate => {
    actions.setDeliveryOptions({
      originalSelectedDate: {
        startDay: originalDate,
        days: [originalDate.day],
      },
    })
  }

  const isValidForShowPopUpLostSelectionCart = firstDate => {
    const originalDay =
      deliveryOptions.originalSelectedDate?.startDay?.date || null

    return originalDay !== firstDate.date
  }

  const setFirstSelectionStartDay = () => {
    if (!deliveryOptions.firstSelectionStartDay) {
      actions.setDeliveryOptions({
        firstSelectionStartDay: true,
      })
    }
  }

  const dateHandler = firstDate => {
    actions.setUserSelectedDataStart({
      startDay: firstDate,
      days: [firstDate.day],
    })
  }

  const handleDateChange = displayDate => {
    const firstDate = upcomingDays.find(
      date => date.displayDate === displayDate,
    )
    if (firstDate) {
      const isAvailableForProtectCurrentSelection =
        !showLostSelectionPoUp &&
        userSelectedData.startDay !== firstDate &&
        isValidForShowPopUpLostSelectionCart(firstDate) &&
        cartTotalProductQty > 0

      if (isAvailableForProtectCurrentSelection) {
        setTemporalSelectedDay(firstDate)
        setShowLostSelectionPoUp(true)
      } else {
        setFirstSelectionStartDay()
        dateHandler(firstDate)
      }
    }
  }

  const nextPageWithResetSelectedMealPage = () => {
    actions.setUserSelectedDataStart({
      startDay: temporalSelectedDay,
      days: [temporalSelectedDay.day],
    })

    setShowLostSelectionPoUp(false)
    actions.setForcedSkipQuiz(true)
  }

  const onGoNext = () => {
    setOriginalSelectedDate(userSelectedData.startDay)
    actions.persistState({ sessionId: SESSION_KEY })

    stepHandler(PATHS[pageNames.MEALS])
  }

  const buttonNextDisabled =
    signUpInitData.daysAvailable - userSelectedData.days.length > 0 ||
    !deliveryOptions.firstSelectionStartDay ||
    isEmpty(userSelectedData.startDay)

  return (
    <>
      <LostSelectionStepsWarningPopup
        text={`Please note that some of the meals you selected may not be available on the new delivery day.`}
        isOpen={showLostSelectionPoUp}
        onClose={() => setShowLostSelectionPoUp(false)}
        onActionClick={nextPageWithResetSelectedMealPage}
      />

      <DeliveryPageCui
        handleDateChange={handleDateChange}
        stepHandler={onGoNext}
        firstSelectionStartDay={deliveryOptions.firstSelectionStartDay}
        validDates={upcomingDays}
        startDay={userSelectedData.startDay}
        buttonNextDisabled={buttonNextDisabled}
        showDateInBar={deliveryOptions.firstSelectionStartDay}
        ring={signUpInitData.ring}
        experimentData={experimentData}
        userSelectedData={userSelectedData}
      />
    </>
  )
}

const mapStateToProps = state => ({
  signUpInitData: signUpCommonSelectors.getSignUpInitData(state),
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
  deliveryOptions: deliverySettingSelectors.deliveryOptions.getData(state),
  cartTotalProductQty: preOrderPageSelectors.getCart(state).totalProductQty,
  cartSelectionProducts: preOrderPageSelectors.getCart(state).products,
  experimentData: signUpCommonSelectors.getExperimentData(state).object,
  upcomingDays: signUpCommonSelectors.getDeliveryDays(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendLogging: creatorCommons.loggingStart,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
      addSeoData: signUpCommonActionCreators.startAddSeoData,
      setDeliveryOptions: deliverySettingCreators.deliveryOptionCreators.start,
      resetProductCart: creatorMealsStep.resetCart,
      setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
      sendIdentify: creatorCommons.identifyStart,
      persistState: signUpCommonActionCreators.persistState,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliverySettingPageContainer)
