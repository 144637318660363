import React from 'react'
import Numeral from 'numeral'
import noMealPlaceholder from 'src/assets/no-image.jpg'
import ImageWithDefaultPlaceHolder from 'src/components/ImageWithDefaultPlaceHolder'
import CrossedOutLabel, {
  CROSSED_OUT_VARIANTS,
} from '../../../../../components/CrossedOutLabel'
import ChefSpecialLabel from './ChefSpecialLabel'

const MealCheckoutCui = ({
  orderProductQty,
  product,
  showPremiumFees,
  freePremiumFees,
}) => {
  const title = [
    product.name.trim(),
    ...(product.short_description ? [product.short_description.trim()] : []),
  ].join(' ')

  return (
    <div className="cart_check_container">
      <ImageWithDefaultPlaceHolder
        src={product.primaryImageUrl}
        srcDefault={noMealPlaceholder}
        alt="meal"
        className="meal-image"
      />
      {showPremiumFees && product.premium_fee > 0 ? (
        <div className="meal-description-container">
          <ChefSpecialLabel />
          <p className="rebrand">{title}</p>
          <div className="premium-fee-wrapper">
            <CrossedOutLabel
              shouldCross={freePremiumFees}
              variant={CROSSED_OUT_VARIANTS.PREMIUM}
            >
              {Numeral(product.premium_fee).format('$0.00')}
            </CrossedOutLabel>
          </div>
        </div>
      ) : (
        <p className="rebrand">{title}</p>
      )}
      <p className="rebrand circle"> {orderProductQty} </p>
    </div>
  )
}

export default MealCheckoutCui
