import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { createCUCookie } from 'src/utils/cookieUtils'

const useUTM = () => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = location.search?.substring(1)
    const utmQuery = queryParams
      ?.split('&')
      .filter(el => el.includes('utm_'))
      .join('&')
    if (utmQuery) {
      createCUCookie('UTMQuery', utmQuery, {
        expirationDays: 7,
        addPrefix: false,
        includeSubdomains: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useUTM
