const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
}

export const formatFullName = (name, lastname) => {
  name = capitalize(name)
  lastname = capitalize(lastname)

  if (!name && !lastname) return ""

  return `${name}${lastname ? ` ${lastname}` : ''}`
}

export const formatEmail = email => {
  if (!email || typeof email !== 'string') return ""

  if (email.includes('privaterelay.appleid.com')) 
    return 'Hidden Email'

  return email
}
