import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { formatFullName, formatEmail } from 'src/utils/user'

import './LoggedUserCui.scss'
import * as qs from 'qs'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authUserSelector } from 'src/features/auth/authSlice'
import { PATHS, pageNames } from 'src/screens/signup/constants'
import useAuth0Api from 'src/features/signIn/hooks/Auth0'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import useTrackEvent from 'src/hooks/useTrackEvent';

const LoggedUserCui = () => {
  const { search } = useLocation()
  const { loginWithRedirect } = useAuth0()
  const { logout } = useAuth0Api()

  const { isTreatment: isSneakPeekTreatment } = useExperiment(
    EXPERIMENTS.sneakPeek,
  )

  const trackChangeAccount = useTrackEvent({
    eventName: 'CTA Button Clicked',
  })

  const authUser = useSelector(authUserSelector)
  const userName = authUser?.given_name || authUser?.name
  const email = authUser?.email

  const formattedName = formatFullName(userName)
  const formattedEmail = formatEmail(email)

  const changeAccountHandler = async () => {
    if(isSneakPeekTreatment){
      logout(`${window.location.origin}${PATHS[pageNames.MEALS]}${search}`)
    }
    else{
      const { zipcode, coupon, cuSession } = qs.parse(search, {
        ignoreQueryPrefix: true,
      })
      
      const cleanSearch = `?zipcode=${zipcode}${
        coupon ? `&coupon=${coupon}` : ''
      }&cuSession=${cuSession}`

      trackChangeAccount({cta_text: 'Change account'})

      await loginWithRedirect({
        action: 'signup',
        redirectUri: `${window.location.origin}${PATHS[pageNames.CHECKOUT]}${cleanSearch}`,
        initialEmail: '',
        search: `${cleanSearch}`,
        env: process.env.NODE_ENV,
        max_age: 0,
        prompt: 'login',
      })
    }
  }

  if (!formattedName && !formattedEmail) {
    return null
  }

  return (
    <div className="logged-used-cui">
      <div className="welcome" data-testid="welcome">
        <h4>Welcome {`${formattedName || formattedEmail}!`}</h4>
        {formattedName && <p className="rebrand small">
          {formattedEmail ? `(${formattedEmail})` : ''}
        </p>}
      </div>
      <div className="change">
        <p className="rebrand">Not you? </p>
        <a
          className={'rebrand'}
          href="#"
          onClick={changeAccountHandler}
        >
          Change account
        </a>
      </div>
    </div>
  )
}

export default LoggedUserCui
