import React from 'react'
import '../CheckoutPageCui.scss'
import MealCheckoutCui from './MealCheckoutCui'

function MealsCart({
  products,
  sendLogging,
  onEditMeals,
  showPremiumFees,
  freePremiumFees,
  sendTracking,
  planName,
  startDay,
}) {
  return (
    <>
      <div className="cart_container-cui">
        <div className="title-row">
          <h3 className="rebrand">My Meals</h3>
          <button
            className="rebrand-link"
            id="Checkout_Edit_Meals"
            onClick={() => {
              sendLogging({
                event: 'Checkout Edit Meals',
                warning: false,
                data: {
                  category: 'new-funnel',
                  label: 'test-new-funnel',
                  action: 'Chekout Edit Meals',
                },
              })
              onEditMeals()
              sendTracking({
                eventName: 'Sign Up - Checkout - Edit Meals Clicked',
                eventData: {
                  first_delivery_date: startDay,
                  plan_name: planName,
                },
              })
            }}
          >
            Edit meals
          </button>
        </div>

        {products.map((product, index) => {
          return (
            <MealCheckoutCui
              key={`mealCheckout-${index}`}
              orderProductQty={product.qty}
              product={product.data}
              showPremiumFees={showPremiumFees}
              freePremiumFees={freePremiumFees}
            />
          )
        })}
      </div>
    </>
  )
}

export default MealsCart
