import duck from "./duck";
import * as actionCreators from "./actions";

export const applyCouponStart = duck.createAction(actionCreators.APPLY_COUPON.start);
export const applyCouponSuccess = duck.createAction(actionCreators.APPLY_COUPON.success);
export const applyCouponFailed = duck.createAction(actionCreators.APPLY_COUPON.failed);
export const applyCouponReset = duck.createAction(actionCreators.APPLY_COUPON.reset);

export const initDataStart = duck.createAction(actionCreators.INIT_DATA.start);
export const initDataSuccess = duck.createAction(actionCreators.INIT_DATA.success);
export const initDataFailed = duck.createAction(actionCreators.INIT_DATA.failed);
export const setForceLoading = duck.createAction(actionCreators.INIT_DATA.loading);

export const setUserSelectedDataStart = duck.createAction(actionCreators.USER_SELECTED_DATA.start);
export const setUserSelectedData = duck.createAction(actionCreators.USER_SELECTED_DATA.set);

export const setBreadPathStart = duck.createAction(actionCreators.BREAD_PATH.start);
export const setBreadPath = duck.createAction(actionCreators.BREAD_PATH.set);
export const startSetCurrentStepName = duck.createAction(actionCreators.BREAD_PATH.startSetCurrentStepName);
export const setCurrentStepName = duck.createAction(actionCreators.BREAD_PATH.setCurrentStepName);
export const startAfterElement = duck.createAction(actionCreators.BREAD_PATH.startAfterElement);
export const setAfterElement = duck.createAction(actionCreators.BREAD_PATH.setAfterElement);

export const startAddSeoData = duck.createAction(actionCreators.SEO_DATA.startAddSeoData);
export const addSeoData = duck.createAction(actionCreators.SEO_DATA.addSeoData);

export const startCreateUser = duck.createAction(actionCreators.CREATE_USER.start);
export const createUser = duck.createAction(actionCreators.CREATE_USER.success);
export const createUserFailed = duck.createAction(actionCreators.CREATE_USER.failed);

export const startCreateOrder = duck.createAction(actionCreators.CREATE_ORDER.start);
export const createOrder = duck.createAction(actionCreators.CREATE_ORDER.success);
export const createOrderFailed = duck.createAction(actionCreators.CREATE_ORDER.failed);

export const startShowTopBar = duck.createAction(actionCreators.SHOW_TOP_BAR.start);
export const showTopBarSuccess = duck.createAction(actionCreators.SHOW_TOP_BAR.success);

export const startRegisterRequestFlow = duck.createAction(actionCreators.REGISTER_REQUEST_FLOW.start);
export const registerRequestFlowSuccess = duck.createAction(actionCreators.REGISTER_REQUEST_FLOW.success);

export const startPreferencesQuiz = duck.createAction(actionCreators.INIT_PREFERENCES_QUIZ.start);
export const preferencesQuizSuccess = duck.createAction(actionCreators.INIT_PREFERENCES_QUIZ.success);
export const preferencesQuizFailed = duck.createAction(actionCreators.INIT_PREFERENCES_QUIZ.failed);

export const preferencesQuizSetResponse = duck.createAction(actionCreators.PREFERENCES_QUIZ_RESPONSE.setResponse);
export const preferencesQuizResetResponse = duck.createAction(actionCreators.PREFERENCES_QUIZ_RESPONSE.resetResponse);

export const experimentStart = duck.createAction(actionCreators.EXPERIMENTS.start);
export const experimentSuccess = duck.createAction(actionCreators.EXPERIMENTS.success);
export const experimentFailed = duck.createAction(actionCreators.EXPERIMENTS.failed);
export const experimentReset = duck.createAction(actionCreators.EXPERIMENTS.reset);


export const forcedSkipQuizStart = duck.createAction(actionCreators.FORCED_SKIP_QUIZ.start);
export const forcedSkipQuizSuccess = duck.createAction(actionCreators.FORCED_SKIP_QUIZ.success);

export const setAuthenticated = duck.createAction(actionCreators.AUTHENTICATED.start);

export const confirmNewZipcode = duck.createAction(actionCreators.CONFIRM_NEW_ZIPCODE.start);
export const persistState = duck.createAction(actionCreators.PERSIST_STATE.start);
export const recoverState = duck.createAction(actionCreators.RECOVER_STATE.start);

export const initChefsList = duck.createAction(actionCreators.CHEFS_LIST.start);
export const successChefsList = duck.createAction(actionCreators.CHEFS_LIST.success);
export const updateChefsList = duck.createAction(actionCreators.CHEFS_LIST.update);
export const failedChefsList = duck.createAction(actionCreators.CHEFS_LIST.failed);
export const resetChefsList = duck.createAction(actionCreators.CHEFS_LIST.reset);


export const getChefDetail = duck.createAction(actionCreators.LOAD_CHEF_DETAIL.start);
export const loadChefDetail = duck.createAction(actionCreators.LOAD_CHEF_DETAIL.success);
export const loadChefDetailFailed = duck.createAction(actionCreators.LOAD_CHEF_DETAIL.failed);
export const resetChefDetail = duck.createAction(actionCreators.LOAD_CHEF_DETAIL.reset);

export const getMenuSortBy = duck.createAction(actionCreators.LOAD_MENU_SORT_BY.start);
export const loadMenuSortBy = duck.createAction(actionCreators.LOAD_MENU_SORT_BY.success);
export const loadMenuSortByFailed = duck.createAction(actionCreators.LOAD_MENU_SORT_BY.failed);
export const resetMenuSortBy = duck.createAction(actionCreators.LOAD_MENU_SORT_BY.reset);

export const setTaxRateFromZipCode = duck.createAction(actionCreators.TAX_RATE_ZIP_CODE.set)

export const setSearchMealsIds = duck.createAction(actionCreators.SEARCH_MEALS_IDS.set)
export const resetSearchMealsIds = duck.createAction(actionCreators.SEARCH_MEALS_IDS.reset)

export const setDDOToggles = duck.createAction(actionCreators.DDO_TOGGLES.set)
