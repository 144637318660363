import React from 'react'
import { CuiLink } from 'front-lib'
import StainImg from '../../assets/img/stain.png'
import './slick-title-cui.scss'

const SlickTitleCui = ({
  title,
  center,
  pretitle,
  text,
  ctaText,
  onClick,
  large,
  noSlick
}) => {
  return (
    <div className="stick-title-cui">
      {pretitle && <p className="rebrand upper">{pretitle}</p>}
      <h2
        className={`rebrand text-slick ${center ? 'center' : ''} ${
          !large ? 'small' : ''
        }`}
      >
        {!noSlick &&<img src={StainImg} alt="slick" />}
        <span>{title}</span>
      </h2>
      {text && <p className="rebrand">{text}</p>}
      {ctaText && (
        <CuiLink size="large" color="tertiary" onClick={onClick}>
          {ctaText}
        </CuiLink>
      )}
    </div>
  )
}

export default SlickTitleCui
