import React, {
  ButtonHTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { CuiButton } from 'front-lib'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { EVENTS } from 'src/utils/events'

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    PropsWithChildren {
  trackText?: string
  trackParams?: Record<string, string>
  shouldTrack?: boolean
  fill?: 'clear' | 'outline' | 'solid'
  size?: 'small' | 'medium' | 'large'
}

function getTextFromChildren(children: ReactNode): string {
  if (typeof children === 'string') return children

  if (React.isValidElement(children))
    return getTextFromChildren(children.props.children)

  if (Array.isArray(children)) {
    return children
      .flatMap(getTextFromChildren)
      .filter(child => child)
      .join(' - ')
  }

  return ''
}

export function Button({
  onClick,
  trackText,
  trackParams = {},
  shouldTrack = true,
  children,
  ...rest
}: ButtonProps) {
  const trackEvent = useTrackEvent({ eventName: EVENTS.buttonClicked })

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    let textToTrack = trackText || getTextFromChildren(children)

    if (shouldTrack) {
      const combinedParams = { cta_text: textToTrack, ...trackParams }
      trackEvent(combinedParams)
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  return (
    <CuiButton onClick={handleClick} {...rest}>
      {children}
    </CuiButton>
  )
}
