import React, {useState} from 'react'
import DeliveryPage from './DeliveryPage'

const Delivery = () => {
  const [showModal, setShowModal] = useState(false)


  const handleToggleModal = () => {
    setShowModal(prev => !prev)
  }
  return (
    <DeliveryPage
      showModal={showModal}
      toggleModal={handleToggleModal} />
  )
}

export default Delivery
