import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUsers, reactivateSubscription, changeUserAddress } from './userAPI'

const initialState = {
  user: null,
  subscriptionReactivated: null,
  userStatus: 'idle',
  addressStatus: 'idle',
  reactivateSubscriptionStatus: 'idle',
  preferences: {
    hasSmsEnable: false,
  },
}

export const fetchUser = createAsyncThunk('user/fetch', async () => {
  const response = await getUsers()
  return response
})

export const updateAddress = createAsyncThunk(
  'user/updateAddress',
  async ({ address }) => {
    const response = await changeUserAddress({ address })
    return response
  },
)

export const requestSubscriptionReactivation = createAsyncThunk(
  'user/reactivateSubscription',
  async ({ date }) => {
    const response = await reactivateSubscription({ date })
    return response
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setUserPreferences: (state, { payload }) => {
      state.preferences = {
        ...state.preferences,
        ...payload,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, state => {
        state.userStatus = 'loading'
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle'
        state.user = payload.users[0]
      })
      .addCase(updateAddress.pending, state => {
        state.addressStatus = 'loading'
      })
      .addCase(updateAddress.fulfilled, state => {
        state.addressStatus = 'idle'
      })
      .addCase(requestSubscriptionReactivation.pending, state => {
        state.reactivateSubscriptionStatus = 'loading'
      })
      .addCase(requestSubscriptionReactivation.fulfilled, state => {
        state.reactivateSubscriptionStatus = 'idle'
        state.subscriptionReactivated = true
      })
  },
})

export const { setUser, setUserPreferences } = userSlice.actions

export const userStatusSelector = state =>
  state.get('user').userStatus === 'loading'
export const userSelector = state => state.get('user').user
export const subscriptionReactivatedSelector = state =>
  state.get('user').subscriptionReactivated
export const subscriptionReactivatedStatusSelector = state =>
  state.get('user').reactivateSubscriptionStatus === 'loading'
export const userNameSelector = state => state.get('user').user?.name
export const userEmailSelector = state => state.get('user').user?.email
export const userPreferences = state => state.get('user').preferences

export default userSlice.reducer
