import React from 'react'
import classNames from 'classnames'
import SlidingPanelCui from 'src/components/SlidingPanelCui'
import PlusBlack from 'src/assets/icons/plus-black.png'

import MinusBlack from 'src/assets/icons/minus-black.png'

import noMealPlaceholder from 'src/assets/no-image.jpg'
import { formatPrice } from 'src/utils/utils'
import ImageWithDefaultPlaceHolder from 'src/components/ImageWithDefaultPlaceHolder'
import { trackingStart } from 'src/redux/ducks/commons/creators'
import {
  getSignUpInitData,
  getUserSelectedData,
  getUserNewUpgradedPlan,
} from 'src/redux/ducks/screens/signup/commons/selectors'
import {
  getCart,
  getChooseForMeStatus,
  getSomeProductChoseForMe,
} from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'

import './SelectedMealsCui.scss'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isMobile } from 'src/utils/events'
import { CuiButton, CuiIcon, CuiLoader } from 'front-lib'
import { Button } from 'src/components/Button/Button'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { getChangePlanOverlayTrackData, OVERLAY_VIEWED } from 'src/screens/signup/pages/SelectPlans/utils/index'

const SelectedMealsCui = props => {
  const {
    isOpen,
    onClose,
    selectedProducts,
    newUpgradedPlan,
    planSizeExceeded,
    addProduct,
    removeProduct,
    mealCount,
    selectedPlan,
    stepHandler,
    storeData,
    userStartDay,
    cartData,
    actions,
    showChooseForMeMessage,
    onClickUndoChooseForMe,
    onClickRefreshChooseForMe,
    isChooseForMeLoading,
    menuFilters,
    removeNewUpgradedPlan,
    planInMealStepExperiment,
    setShowChangePlanModal,
    displayFooterWarning,
    footerWarningMessage,
  } = props
  const mealsPerDelivery = selectedPlan ? selectedPlan.mealsPerDelivery : 0
  const pricePerMeal = selectedPlan ? selectedPlan.price / mealsPerDelivery : 0

  const handleAddItem = (product, productOrderQty) => {
    addProduct(product)

    const filterValue =
      menuFilters?.meatTypeFilter ||
      menuFilters?.specificationsDetailFilter ||
      'all'
    const filterType = menuFilters?.meatTypeFilter ? 'meatType' : 'specificationsDetail'

    const filtersApplied = [{
      filter_group: 'All',
      filter_type: filterType,
      filter_value: filterValue
    }]

    if (menuFilters?.searchInput) {
      filtersApplied.push({
        filter_group: '',
        filter_type: 'Search',
        filter_value: menuFilters.searchInput,
      })
    }

    sendTracking(product, 'Product Added', {
      cart_quantity_added: 1,
      cart_product_quantity: productOrderQty + 1,
      cart_total_quantity: cartData.totalProductQty + 1,
      product_add_source: 'Meals sets panel selection',
      filters_applied: filtersApplied,
    })
  }

  const handleRemoveItem = (product, productOrderQty) => {
    removeProduct(product)
    sendTracking(product, 'Product Removed', {
      cart_quantity_removed: 1,
      cart_product_quantity: productOrderQty - 1,
      cart_total_quantity: cartData.totalProductQty - 1,
      product_remove_source: 'Meals sets panel selection',
    })
  }

  const sendTracking = (product, eventName, params = {}) => {
    const stock = product.stock ? product.stock : 0
    const specificationsDetails =
      product.specificationsDetails || product.specifications_detail || []
    const chefName = `${product.chef_firstname ||
      product?.chef?.firstName ||
      ''} ${product.chef_lastname || product?.chef?.lastName || ''}`
    const premiumFee = product.premium_fee || product.premiumFee || 0
    const trackEvent = {
      eventName,
      eventData: {
        menu_store: storeData.name,
        delivery_date: userStartDay.date,
        list_name: 'PreOrder Selected Meals',
        list_type: 'Meals Selection',
        product: {
          id: product.entity_id || product.mealExternalId,
          chef_name: chefName,
          category: product.category_id || product.categoryId,
          heat_instructions_microwave: null,
          heat_instructions_oven: null,
          image_url: (product.image_full_url || product.primaryImageUrl) ?? '',
          ingredients: Array.isArray(product?.ingredients)
            ? product?.ingredients
                .map(specification => specification.name)
                .join(', ')
            : null,
          name: product.name,
          nutritional_info: null,
          price: product.price,
          rating: product.stars,
          rating_count: product.reviews,
          ratio_carb: product.carbs,
          ratio_fat: product.fat,
          ratio_protein: product.protein,
          sku: product.sku,
          specification: specificationsDetails
            .map(specification => specification.label)
            .join(', '),
          premium: premiumFee > 0,
        },
        product_initial_capacity: stock,
        product_remaining_capacity: stock - params.cart_product_quantity,
        screen_name: isMobile() ? 'Meals Selection' : null,
        page_name: isMobile() ? null : 'Meals Selection',
        ...params,
      },
    }

    actions.sendTracking(trackEvent)
  }

  const trackOverlayViewed = useTrackEvent(getChangePlanOverlayTrackData({ event: OVERLAY_VIEWED }))

  const handleChangePlan = () => {
    setShowChangePlanModal(true)
    trackOverlayViewed()
  }

  let selectedProductListPartialQuantity = 0
  let lastCartProductsQty = 0
  const selectedProductsList = selectedProducts.map(product => {
    if (newUpgradedPlan) {
      product.exceededUnits = undefined
      selectedProductListPartialQuantity += product.qty
      if (selectedProductListPartialQuantity > mealsPerDelivery) {
        const exceededUnits =
          selectedProductListPartialQuantity -
          (lastCartProductsQty || mealsPerDelivery)
        product.exceededUnits = exceededUnits
        lastCartProductsQty = selectedProductListPartialQuantity
      } else {
        product.exceededUnits = undefined
      }
      removeNewUpgradedPlan()
    } else {
      if (!planSizeExceeded) {
        product.exceededUnits = undefined
      }
    }
    return product
  })

  const disableContinueButton = mealCount !== mealsPerDelivery

  const actionButtons = (
    <>
      {planInMealStepExperiment && (
        <Button
          size="large"
          fill="clear"
          onClick={handleChangePlan}
          className="cart-footer__button-change"
        >
          Change plan size
        </Button>
      )}
      <CuiButton
        className={classNames({'cart-footer__button-continue': planInMealStepExperiment})}
        size="large"
        fill="solid"
        disabled={disableContinueButton}
        onClick={stepHandler}
      >
        Continue
      </CuiButton>
    </>
  );  

  return (
    <SlidingPanelCui
      className="sliding-panel-cui"
      showUpgradePlan={mealCount > mealsPerDelivery}
      planSizeExceeded={planSizeExceeded}
      displayFooterWarning={displayFooterWarning}
      footerWarningMessage={footerWarningMessage}
      extraMeals={
        mealCount > mealsPerDelivery ? mealCount - mealsPerDelivery : undefined
      }
      title={
        <>
          Selected meals:
          {!isChooseForMeLoading && (
            <div className="selected_meals_header-cui">
              <h2 className="rebrand selected_meals_count">{mealCount}/</h2>
              <h2 className="rebrand meals_in_plan">{mealsPerDelivery}</h2>
            </div>
          )}
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div className="selected_meals_panel_content">
          {isChooseForMeLoading && (
            <div className="choose-for-me-loader">
              <CuiLoader />
              <h3>
                Cooking up tasty recommendations based on your preferences…
              </h3>
            </div>
          )}
          {showChooseForMeMessage && (
            <div className="choose-for-me-message">
              <div className="choose-for-me-message__content">
                <CuiIcon name="flashIcon" />
                <div className="choose-for-me-message__content__message">
                  <div className="choose-for-me-message__content__message--text">
                    We have filled your cart with our best recommendations!
                  </div>

                  <div className="choose-for-me-message__content__message__actions">
                    <CuiButton
                      onClick={onClickRefreshChooseForMe}
                      fill="outline"
                      size="small"
                    >
                      Refresh
                    </CuiButton>
                    <button
                      className="choose-for-me-message__content__message__actions--button"
                      onClick={onClickUndoChooseForMe}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="selected-meals-cui">
            {!isChooseForMeLoading &&
              selectedProductsList.map((productOrder, index) => {
                const product = {
                  exceededUnits: productOrder.exceededUnits,
                  ...productOrder.data,
                }
                const productName = [
                  product.name.trim(),
                  ...(product.short_description
                    ? [product.short_description.trim()]
                    : []),
                ].join(' ')

                const productOrderQty = productOrder.qty
                const disableAddButton = planSizeExceeded

                if (productOrderQty > 0) {
                  return (
                    <div className="product" key={`selectedMeals-${index}`}>
                      <ImageWithDefaultPlaceHolder
                        src={product.image_full_url || product.primaryImageUrl}
                        srcDefault={noMealPlaceholder}
                        alt="meal"
                        className="product_image"
                      />

                      <div className="product_name">
                        <p className="rebrand small">{productName}</p>
                        {productOrder.exceededUnits && (
                          <p className="rebrand bold extraMeal">
                            $
                            {formatPrice(
                              productOrder.exceededUnits * pricePerMeal,
                            )}{' '}
                            extra for this order
                          </p>
                        )}
                      </div>
                      <div className="buttons-meals-cui">
                        <button
                          className="button"
                          disabled={productOrderQty <= 0}
                          onClick={() => {
                            if (!(productOrderQty <= 0)) {
                              handleRemoveItem(product, productOrderQty)
                            }
                          }}
                        >
                          <img src={MinusBlack} alt="minus" />
                        </button>
                        <p className="rebrand bold count">{productOrderQty}</p>
                        <button
                          className="button"
                          disabled={disableAddButton}
                          onClick={() => {
                            if (!disableAddButton) {
                              handleAddItem(product, productOrderQty)
                            }
                          }}
                        >
                          <img src={PlusBlack} alt="plus" />
                        </button>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })}
          </div>
        </div>
      }
      actionButtons={actionButtons}
      planInMealStepExperiment={planInMealStepExperiment}
    />
  )
}

const mapStateToProps = state => ({
  cartData: getCart(state),
  storeData: getSignUpInitData(state).store,
  userStartDay: getUserSelectedData(state).startDay,
  isChooseForMeLoading: getChooseForMeStatus(state),
  showChooseForMeMessage: getSomeProductChoseForMe(state),
  menuFilters: getMenuFilter(state),
  newUpgradedPlan: getUserNewUpgradedPlan(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: trackingStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectedMealsCui)
