import React from 'react'
import './CouponInfo.scss'

const CouponInfo = ({ title, disclaimer }) => {
  return (
    <>
      <div className="coupon-amount">
        <p className="coupon-amount--text">{title}</p>
      </div>
      {disclaimer && (
        <div className="coupon-disclaimer">
          <p>{disclaimer}</p>
        </div>
      )}
    </>
  )
}

export default CouponInfo
