import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import axios from 'axios'
import { getAuthAccessToken } from 'src/utils/sessionStorage'

const SUBSCRIPTION_URL = process.env.REACT_APP_GRAPH_API_URL
const SUBSCRIPTION_PUBLIC_URL = process.env.REACT_APP_GRAPH_API_PUBLIC_URL
const MENU_PUBLIC_URL = process.env.REACT_APP_MENU_SERVICE_PUBLIC_URL
const EAT_URL_API = process.env.REACT_APP_EAT_API

export const cuEatApiClient = (
  path,
  verb,
  data = null,
  baseUrl = '/internal',
) =>
  axios(
    Object.assign(
      {},
      {
        url: EAT_URL_API + baseUrl + path,
        method: verb,
        data,
      },
    ),
  )

const subscriptionHttpLink = createHttpLink({
  uri: SUBSCRIPTION_URL,
  credentials: 'include',
})

const authLink = setContext(async (_, { headers }) => {
  const token = getAuthAccessToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  }
})

const subscriptionClient = new ApolloClient({
  link: authLink.concat(subscriptionHttpLink),
  cache: new InMemoryCache(),
})

const subscriptionPublicClient = new ApolloClient({
  uri: SUBSCRIPTION_PUBLIC_URL,
  cache: new InMemoryCache(),
})

const menuPublicClient = new ApolloClient({
  uri: MENU_PUBLIC_URL,
  cache: new InMemoryCache(),
})

export const subscriptionQuery = (
  query,
  variables = {},
  fetchPolicy = 'cache-first',
) =>
  subscriptionClient
    .query({
      query: gql(query),
      variables,
      fetchPolicy,
    })
    .then(res => res.data)

export const subscriptionMutate = (mutation, variables = {}) =>
  subscriptionClient
    .mutate({
      mutation: gql(mutation),
      variables,
    })
    .then(res => res.data)

export const subscriptionPublicQuery = (query, variables = {}) =>
  subscriptionPublicClient
    .query({
      query: gql(query),
      variables,
    })
    .then(res => res.data)

export const menuPublicQuery = (query, variables = {}) =>
  menuPublicClient
    .query({
      query: gql(query),
      variables,
    })
    .then(res => res.data)
