import { useVariant } from '@unleash/proxy-client-react'
import React, { useMemo } from 'react'
import Slider from 'src/components/Slider/Slider'
import { Lazy, Navigation } from 'swiper'
import 'swiper/scss/lazy'
import 'swiper/scss/navigation'
import MealCardFromApp from '../../../components/MealCardPremiumFee'
import './MealsSlider.scss'
import classNames from 'classnames'
import { useFlag } from '@unleash/proxy-client-react'

const sliderConfigs = {
  spaceBetween: 10,
  slidesPerView: 'auto',
  modules: [Lazy, Navigation],
  centeredSlides: true,
  allowTouchMove: true,
  centeredSlidesBounds: true,
  preloadImages: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
  },
  breakpoints: {
    1200: {
      centeredSlides: false,
      allowTouchMove: false,
    },
    768: {
      allowTouchMove: false,
    },
  },
}

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const IMG_STORAGE = process.env.REACT_APP_IMG_STORAGE

export function MealsSlider({
  addProduct,
  removeProduct,
  onMealClick,
  maxProductToCart,
  totalOrderedProductsQty,
  getCategory,
  meals,
  getOrderQty,
  selectedMenuOption,
  maxMeals,
  lastElement,
  mealCard = MealCardFromApp,
  showOrdinals,
}) {
  const variant = useVariant('use-imgix')
  const isTreatment = variant && variant.enabled && variant.name === 'TREATMENT'

  const isQueryPublicEnabled = useFlag(
    'gro-gtt-4387-enable-meal-public-query-funnel',
  )

  const mealsToShow = useMemo(
    () => (maxMeals ? meals.slice(0, maxMeals) : meals),
    [meals, maxMeals],
  )

  const MealCard = mealCard

  return (
    <div className="meals-slider">
      <Slider
        configs={{
          ...sliderConfigs,
        }}
      >
        {mealsToShow.map((meal, index) => (
          <MealCard
            index={showOrdinals && index}
            key={meal.entity_id}
            meal={{
              ...meal,
              protein_type: getCategory(meal),
              full_path_meal_image: isTreatment
                ? IMG_STORAGE + meal.image_path + meal.image
                : `${IMG_URL_SOURCE}${meal.image}`,
              full_path_chef_image: `${IMG_URL_SOURCE}${meal.bannerpic}`,
            }}
            onAddItem={() => {
              addProduct(meal)
            }}
            onRemoveItem={() => {
              removeProduct(meal)
            }}
            onMealClick={() => {
              onMealClick({
                entity_id: meal.entity_id,
                stock: meal.stock,
                quizRecommendation: meal.quizRecommendation || false,
                position_in_menu: meal.position_in_menu || 0,
                isQueryPublicEnabled,
                inventoryId: meal.inventoryId,
              })
            }}
            disableAddItem={totalOrderedProductsQty >= maxProductToCart}
            quantity={getOrderQty(meal.entity_id)}
            selectedMenuOption={selectedMenuOption}
            className={classNames({
              'meals-slider__meal-card': mealCard === MealCardFromApp,
            })}
          />
        ))}
        <div
          className={classNames({
            'meals-slider__meal-card': mealCard === MealCardFromApp,
          })}
        >
          {lastElement}
        </div>
      </Slider>
    </div>
  )
}
