import { CuiCard, Modal } from 'front-lib'
import React from 'react'
import EditDelivery from './components/EditDelivery/EditDelivery'

import './DeliveryPage.scss'
import { useSelector } from 'react-redux'
import { addressSelector } from 'src/features/address/addressSlice'

const DeliveryPage = ({ showModal, toggleModal }) => {
  const address = useSelector(addressSelector)

  return (
    <div className="resurection-cards delivery-card">
      <h3>Delivery address</h3>
      <CuiCard>
        <div className="container-cui-row">
          <p>
            {`${address?.street}${address?.apt ? `, ${address?.apt}` : ''}`}
            <br />
            {`${address?.city}, ${address?.state} ${address?.zipCode}`}
          </p>
          <button className="edit" onClick={toggleModal}>
            Edit
          </button>
        </div>
      </CuiCard>
      <Modal isOpen={showModal} onRequestClose={toggleModal}>
        <EditDelivery onSubmit={toggleModal} />
      </Modal>
    </div>
  )
}

export default DeliveryPage
