import Box from './Box'

const Step = ({ boxTitle, boxContent, title, text }) => {
  return (
    <div className={'stepper--step'}>
      <Box title={boxTitle} content={boxContent} />
      <div className={'stepper--text'}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Step
