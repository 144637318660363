const questions = [
  {
    name: 'goals',
    title: 'What’s your top wellness goal?',
    subtitle: null,
    max: 1,
    type: '',
    options: [
      {
        id: null,
        image: 'all-icon.png',
        label: 'Just eating delicious food!',
        name: 'Just eating delicious food!',
        value: '',
      },
    ],
  },
  {
    name: 'diets',
    title: 'Do you have any dietary preferences?',
    subtitle: 'Tell us your favorite ways to eat and we\'ll serve up relevant recommendations.',
    max: 1,
    type: 'checkbox',
  },
  {
    name: 'proteins',
    title: 'What are your favorite proteins?',
    subtitle: 'Choose up to 3 and we\'ll recommend new meals you might love.',
    max: 3,
    type: 'checkbox',
    options: [],
  },
  {
    name: 'specificationsAvoid',
    title: 'Is there something you need to avoid?',
    subtitle: "We'll remove that type of meal from your recommendations.",
    max: 1,
    type: 'checkbox',
    options: [
      {
        id: 1,
        path: 'https://static.cookunity.com/growth/media/icons/dairy-free.svg',
        name: 'Dairy Free',
        label: 'Dairy',
        filter: true,
      },
      {
        id: 2,
        path: 'https://static.cookunity.com/growth/media/icons/soy-free.svg',
        name: 'Soy Free',
        label: 'Soy',
        filter: true,
      },
      {
        id: 3,
        path: 'https://static.cookunity.com/growth/media/icons/gluten-free.svg',
        name: 'Gluten Free',
        label: 'Gluten',
        filter: true,
      },
      {
        id: 4,
        path: 'https://static.cookunity.com/growth/media/icons/nut-free.svg',
        name: 'Nut Free',
        label: 'Nuts',
        filter: true,
      },
      {
        id: 6,
        path: 'https://static.cookunity.com/growth/media/icons/spicy.svg',
        name: 'Spicy',
        label: 'Spicy Flavors',
        filter: false,
      },
      {
        id: 7,
        name: 'None',
        label: 'I eat everything!',
      },
    ],
  },
  {
    name: 'allergens',
    title: 'Is there something you need to avoid?',
    subtitle: "We'll remove that type of meal from your recommendations.",
    max: 3,
    type: 'checkbox',
    options: [
      {
        id: 1,
        name: 'treeNuts',
        image: 'treeNuts',
        label: 'Tree Nuts',
        filter: true,
      },
      {
        id: 2,
        name: 'milk',
        image: 'milk',
        label: 'Milk',
        filter: true,
      },
      {
        id: 5,
        name: 'soybeans',
        image: 'soybeans',
        label: 'Soybeans',
        filter: true,
      },
      {
        id: 6,
        name: 'wheat',
        image: 'wheat',
        label: 'Wheat',
        filter: true,
      },
      {
        id: 8,
        name: 'crustaceanShellfish',
        image: 'crustaceanShellfish',
        label: 'Shellfish',
        filter: true,
      },
      {
        id: 9,
        name: 'fish',
        image: 'fish',
        label: 'Fish',
      },
      {
        id: 11,
        name: 'sesameSeeds',
        image: 'sesameSeeds',
        label: 'Sesame Seeds',
      },
      {
        id: 12,
        name: 'eggs',
        image: 'eggs',
        label: 'Eggs',
      },
      {
        id: 14,
        name: 'peanuts',
        image: 'peanuts',
        label: 'Peanuts',
      },
    ],
  },
]

export default questions
