import React from 'react'
import { Button } from 'src/components/Button'
import icon from 'src/assets/img/no-preferences-icon.svg'
import styles from './ViewFullMenu.module.scss'

interface ViewFullMenuProps {
  mealsCount: number
  onClickFullMenu: () => void
}

export function ViewFullMenu({ mealsCount, onClickFullMenu }: ViewFullMenuProps) {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Hungry for more?</h3>
      <p className={styles.text}>Explore our variety of {mealsCount} meals.</p>
      <img src={icon} alt="meal icon" className={styles.img} />
      <Button
        onClick={onClickFullMenu}
        fill="outline"
        color="dark"
        size="large"
        className={styles.button}
      >
        View Full Menu
      </Button>
    </div>
  )
}
