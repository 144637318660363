import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMeals } from './mealsAPI'

const initialState = {
  meals: null,
  mealsSelected: [],
  unconfirmedMeals: null,
  availableMeals: [],
  unavailableMeals: [],
  mealsStatus: 'idle',
}

export const fetchMeals = createAsyncThunk(
  'meals/fetch',
  async ({ zipCode, date }) => {
    const response = await getMeals({ zipCode, date })
    return response
  },
)

export const mealsSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {
    setMealsSelected: (state, { payload }) => {
      state.mealsSelected = payload
    },
    addMeal: (state, { payload }) => {
      const mealAlreadySelectedIndex = state.mealsSelected.findIndex(
        meal => meal.id === payload.id,
      )
      if (mealAlreadySelectedIndex >= 0) {
        state.mealsSelected[mealAlreadySelectedIndex].quantity++
      } else {
        state.mealsSelected.push({ ...payload, quantity: 1 })
      }
    },
    removeMeal: (state, { payload }) => {
      const mealAlreadySelectedIndex = state.mealsSelected.findIndex(
        meal => meal.id === payload.id,
      )
      if (mealAlreadySelectedIndex >= 0) {
        if (state.mealsSelected[mealAlreadySelectedIndex].quantity === 1) {
          state.mealsSelected = state.mealsSelected.filter(
            mealSelected => mealSelected.id !== payload.id,
          )
        } else {
          state.mealsSelected[mealAlreadySelectedIndex].quantity--
        }
      }
    },
    setMealsAvailability: (state, { payload }) => {
      state.unconfirmedMeals = payload.unconfirmedMeals
      state.availableMeals = payload.availableMeals
      state.unavailableMeals = payload.unavailableMeals
    },
    confirmChangeMeals: state => {
      state.mealsSelected = state.availableMeals
      state.meals = state.unconfirmedMeals
      state.unconfirmedMeals = null
      state.availableMeals = []
      state.unavailableMeals = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMeals.pending, state => {
        state.mealsStatus = 'loading'
      })
      .addCase(fetchMeals.fulfilled, (state, action) => {
        state.mealsStatus = 'idle'
        state.meals = action.payload.menu.meals
      })
  },
})

export const {
  setMealsSelected,
  addMeal,
  removeMeal,
  setMealsAvailability,
  confirmChangeMeals,
} = mealsSlice.actions

export const mealsSelector = state => state.get('meals').meals
export const mealsSelectedSelector = state => state.get('meals').mealsSelected
export const mealsSelectedQuantitySelector = state => {
  const meals = mealsSelectedSelector(state)
  return meals?.length
    ? meals
        .map(mealSelected => mealSelected.quantity)
        .reduce((acc, curr) => acc + curr)
    : 0
}
export const isLoadingMealsSelector = state =>
  state.get('meals').mealsStatus === 'loading'

export default mealsSlice.reducer
