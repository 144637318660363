import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as preOrderPageSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import * as creatorMealsStep from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as deliverySettingCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'
import { setAddresses } from 'src/redux/ducks/screens/signup/pages/Checkout/creators'
import SlidingPanelCui from './SlidingPanelCui'

const mapStateToProps = state => ({
    createUserData: signUpCommonSelectors.getCreateUserData(state),
    signUpInitData: signUpCommonSelectors.getSignUpInitData(state),
    coupon: signUpCommonSelectors.getCoupon(state),
    userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
    cartTotalProductQty: preOrderPageSelectors.getCart(state).totalProductQty,
  })

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        applyCoupon: signUpCommonActionCreators.applyCouponStart,
        sendLogging: creatorCommons.loggingStart,
        setUserSelectedDataStart:
          signUpCommonActionCreators.setUserSelectedDataStart,
        addSeoData: signUpCommonActionCreators.startAddSeoData,
        resetProductCart: creatorMealsStep.resetCart,
  
        setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
        sendTracking: creatorCommons.trackingStart,
        setDeliveryOptions: deliverySettingCreators.deliveryOptionCreators.start,
        persistState: signUpCommonActionCreators.persistState,
        setAddresses: setAddresses,
        sendIdentify: creatorCommons.identifyStart,
        setLoading: signUpCommonActionCreators.setForceLoading,
      },
      dispatch,
    ),
  })

export default connect(mapStateToProps, mapDispatchToProps)(SlidingPanelCui)
