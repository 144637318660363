import classNames from 'classnames'
import {
  CuiCardContent,
  CuiImage,
  CuiMealCard,
  CuiMealDescription,
  CuiMealImage,
  CuiMealPrice,
} from 'front-lib'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { mealsSelectedSelector } from 'src/features/meals/mealsSlice'
import ChefDetailRow from './components/ChefDetailRow/ChefDetailRow'
import ChefSpecialRow from './components/ChefSpecialRow/ChefSpecialRow'
import MealActions from './components/MealActions/MealActions'
import MealTags from './components/MealTags/MealTags'
import { formatNumberToCurrency } from 'src/utils/priceUtils'
import './MealCard.scss'
import { useVariant } from '@unleash/proxy-client-react'
import { logError } from 'src/utils/logError'

const basePathImg = process.env.REACT_APP_IMG_STORAGE
const ErrorImagePath = 'https://static.cookunity.com/cross/front-lib/images/no-image.png'

const MealCard = ({
  meal,
  onMealClick,
  hideCartControllers = false,
  isEditable = false,
}) => {
  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');
  const { imageFullPath, name } = meal

  const [errorImage, setErrorImage] = useState(false)

  const mealImage = errorImage || imageFullPath
  const mealImageAlt = errorImage ? 'no meal image' : name

  const mealsSelected = useSelector(mealsSelectedSelector)
  const mealSelected = useMemo(
    () => mealsSelected.find(selectedMeal => selectedMeal.id === meal.id),
    [mealsSelected, meal],
  )

  const handleMealClick = () => {
    if (typeof onMealClick === 'function') {
      onMealClick(meal)
    }
  }

  const trackMealErrorImage = (imgError) => {
    const error = new Error(`Meal image id: ${meal.entity_id}`)
    const context = { message: 'error on MealCard MealCard', meal, ...imgError }
    logError(error, context)
  }

  return (
    <CuiMealCard
      className={classNames('meal-card', {
        'meal-card-premium': meal.premiumFee,
        'meal-in-cart': !!mealSelected,
        'meal-card-clickable': onMealClick,
      })}
      color={meal.premiumFee ? 'light' : 'dark'}
      direction="column"
      onClick={handleMealClick}
    >
      <CuiMealImage>
        {isTreatment ? (
          <CuiImage
            basePath={basePathImg}
            relativePath={meal.image_path + meal.image}
            noImageSrc={ErrorImagePath}
            alt={mealImageAlt}
            width="600"
            height="429"
            loading="lazy"
            onError={trackMealErrorImage}
          />
        ) : (
          <CuiImage
            src={mealImage}
            alt={mealImageAlt}
            width="600"
            height="429"
            onError={(imgError) => { trackMealErrorImage(imgError); setErrorImage(true) }}
            loading="lazy"
          />
        )}

        <MealTags meal={meal} />
      </CuiMealImage>

      <CuiCardContent>
        {!!meal.premiumFee && <ChefSpecialRow />}

        <ChefDetailRow chef={meal?.chef} />

        <CuiMealDescription>
          <b>{meal.name}</b> {meal.shortDescription}
        </CuiMealDescription>

        {!!meal.premiumFee && (
          <CuiMealPrice>{`+ ${formatNumberToCurrency(
            meal.premiumFee,
          )}`}</CuiMealPrice>
        )}

        {!hideCartControllers && (
          <MealActions
            meal={{ ...meal, fullPathMealImage: mealImage }}
            quantity={mealSelected?.quantity}
            color={meal.premiumFee ? 'gold' : 'dark'}
            isSelected={!!mealSelected}
            isEditable={isEditable}
            hideControllers={hideCartControllers}
          />
        )}
      </CuiCardContent>
    </CuiMealCard>
  )
}

export default MealCard
