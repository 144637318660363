import React, { Formik } from 'formik'
import { CuiButton, CuiIcon, CuiLink } from 'front-lib'
import { useState } from 'react'
import styles from './ForgotPassword.module.scss'
import { CONNECTIONS, ForgotPasswordSchema, PAGES, migratePassword } from '../../utils'
import formStyles from '../../styles/forms.module.scss'
import useAuth0Api from '../../hooks/Auth0'
import FormCuiInput from '../../components/FormCuiInput'

export type ForgotPasswordForm = {
  email: string
}

let ForgotPasswordValues: ForgotPasswordForm = {
  email: '',
}

const ForgotPassword = ({ setPage }: any) => {
  const [passwordSent, setPasswordSent] = useState(false)
  const [authLoading, setAuthLoading] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [authError, setAuthError] = useState('')

  const { resetPassword } = useAuth0Api()

  const handleSendLinkEmail = (email: string) => {
    resetPassword(CONNECTIONS.CU, email, (error) => {
      if (error) {
        setAuthError(error.description || '')
      } else {
        setPasswordSent(true)
      }

      setAuthLoading(false)
    })
  }

  const handleSubmitForm = async (values: ForgotPasswordForm) => {
    setAuthLoading(true)
    setUserEmail(values.email)

    const response = await migratePassword(values)

    if (!response) {
      setPasswordSent(true)
      setAuthLoading(false)
    } else {
      handleSendLinkEmail(values.email)
    }
  }
  const Form = () => (
    <>
      <p>We will email you a link so you can reset your password.</p>
      <Formik
        initialValues={ForgotPasswordValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className={formStyles.form} onSubmit={handleSubmit}>
            <div className={formStyles.fieldGroup}>
              <FormCuiInput
                color="white"
                fill="solid"
                name="email"
                placeholder="Email address"
                size="small"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              <p className="cui-color-dark">
                {errors.email && touched.email && errors.email}
              </p>
            </div>
            {authError && <p>{authError}</p>}
            <CuiButton
              color="primary"
              fill="solid"
              size="large"
              loading={isSubmitting || authLoading}
              disabled={authLoading}
              type="submit"
            >
              Reset my password
            </CuiButton>
          </form>
        )}
      </Formik>
    </>
  )

  const Message = () => (
    <>
      <p>
        If your account exists, you should receive a link to reset your password
        at the email below, otherwise please check your email or authentication
        method.
      </p>

      <p className="email">{userEmail}</p>

      <p className={styles.smallText}>
        Haven't received the password reset email?{' '}
        <CuiLink
          fill="clear"
          color="dark"
          className={styles.smallLink}
          onClick={() => handleSendLinkEmail(userEmail)}
        >
          Resend link
        </CuiLink>
      </p>

      <p className={styles.smallText}>
        Still having trouble?{' '}
        <CuiLink
          fill="clear"
          color="dark"
          className={styles.smallLink}
          onClick={() => (window as any).Kustomer.open()}
        >
          Contact us
        </CuiLink>
      </p>

      <CuiLink
        className={styles.messageBackButton}
        color="dark"
        fill="solid"
        onClick={() => setPage(PAGES.LOGIN)}
      >
        Back to Login
      </CuiLink>
    </>
  )

  return (
    <div className={styles.forgotPassword}>
      <CuiLink
        className={styles.backLogin}
        fill="clear"
        size="small"
        onClick={() => setPage('login')}
      >
        <CuiIcon className={styles.backLoginIcon} name="chevronRight" />
        Login
      </CuiLink>
      <h1 className="font-primary">
        Reset your <span className="font-primary">password.</span>
      </h1>

      {passwordSent ? <Message /> : <Form />}
    </div>
  )
}

export default ForgotPassword
