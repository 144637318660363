import { CuiFabButton, CuiIcon, CuiQuantitySelector } from 'front-lib'
import './MealActions.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addMeal,
  mealsSelectedQuantitySelector,
  removeMeal,
} from 'src/features/meals/mealsSlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import { storeSelector } from 'src/features/address/addressSlice'
import { deliveryDaySelectedSelector } from 'src/features/delivery/deliverySlice'
import useTrackEvent from 'src/hooks/useTrackEvent'

const defaultMaxQuantity = 30
const CONTROLLERS_OPENED_MS = 2500

const MealActions = ({
  color,
  isSelected,
  quantity = 0,
  hideControllers,
  isEditable,
  meal,
}) => {
  const dispatch = useDispatch()
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector)
  const planSelected = useSelector(planSelectedSelector)
  const store = useSelector(storeSelector)
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)

  const [showControllers, setControllers] = useState(false)
  const [disableAddItem, setDisableAddItem] = useState(false)
  const [maxQuantity, setMaxQuantity] = useState(defaultMaxQuantity)

  const trackMealAdded = useTrackEvent({
    eventName: 'Product Added',
  })
  const trackMealRemoved = useTrackEvent({
    eventName: 'Product Removed',
  })

  useEffect(() => {
    const cartTimer = setTimeout(() => {
      setControllers(false)
    }, CONTROLLERS_OPENED_MS)

    return () => {
      clearTimeout(cartTimer)
    }
  }, [quantity, showControllers])

  useEffect(() => {
    setMaxQuantity(disableAddItem ? quantity : defaultMaxQuantity)
  }, [disableAddItem, quantity])

  useEffect(() => {
    if (mealsSelectedQuantity && planSelected?.mealsPerDelivery) {
      setDisableAddItem(
        mealsSelectedQuantity >= planSelected.mealsPerDelivery,
      )
    }
  }, [planSelected.mealsPerDelivery, mealsSelectedQuantity])

  const sendTracking = (eventName, params = {}) => {
    const stock = meal.stock ? meal.stock : 0
    const chefName = `${meal.chef_firstname ||
      meal?.chef?.firstName ||
      ''} ${meal.chef_lastname || meal?.chef?.lastName || ''}`
    const specificationsDetails =
      meal.specificationsDetails || meal.specifications_detail || []
    const premiumFee = meal.premium_fee || meal.premiumFee || 0
    const trackEventData = {
      menu_store: store.name,
      delivery_date: deliveryDaySelected.date,
      list_name: chefName,
      list_type: 'Meals list',
      product: {
        id: meal.id,
        chef_name: chefName,
        category: meal.categoryId || meal.category_id,
        heat_instructions_microwave: null,
        heat_instructions_oven: null,
        image_url: meal.fullPathMealImage,
        ingredients: meal.ingredients
          ?.map(specification => specification.name)
          .join(', '),
        name: meal.name,
        nutritional_info: null,
        price: meal.price,
        rating: meal.stars,
        rating_count: meal.reviews,
        ratio_carb: meal.carbs,
        ratio_fat: meal.fat,
        ratio_protein: meal.protein,
        sku: meal.sku,
        specification: specificationsDetails
          ?.map(specification => specification.label)
          .join(', '),
        premium: premiumFee > 0,
      },
      product_initial_capacity: stock,
      product_remaining_capacity: stock - params.cart_product_quantity,
      ...params,
    }

    if (eventName === 'added') {
      trackMealAdded(trackEventData)
    }
    if (eventName === 'removed') {
      trackMealRemoved(trackEventData)
    }
  }

  const handlePreventPropagation = event => {
    event.stopPropagation()
  }

  const handleAddItem = () => {
    if (hideControllers) {
      return
    }

    sendTracking('added', {
      cart_quantity_added: 1,
      cart_product_quantity: quantity + 1,
      cart_total_quantity: mealsSelectedQuantity + 1,
      product_add_source: 'Meals sets menu selection',
    })

    dispatch(addMeal(meal))
    setControllers(true)
  }

  const handleRemoveItem = () => {
    if (hideControllers) {
      return
    }

    sendTracking('removed', {
      cart_quantity_removed: 1,
      cart_product_quantity: quantity - 1,
      cart_total_quantity: mealsSelectedQuantity - 1,
      product_remove_source: 'Meals sets menu selection',
    })

    dispatch(removeMeal(meal))
    setControllers(true)
  }

  const handleFirstAdd = event => {
    handlePreventPropagation(event)
    if (!hideControllers) {
      !isSelected ? handleAddItem() : setControllers(true)
    }
  }

  return (
    <div className="meal-actions">
      {!showControllers || !isSelected ? (
        <>
          <CuiFabButton
            color={color}
            disabled={!(isEditable && isSelected) && disableAddItem}
            onClick={handleFirstAdd}
          >
            {isEditable && isSelected ? quantity : <CuiIcon name="plus" />}
          </CuiFabButton>
        </>
      ) : (
        <CuiQuantitySelector
          color={color}
          value={quantity}
          max={maxQuantity.current}
          showBorder={true}
          onRemoveItem={handleRemoveItem}
          onAddItem={handleAddItem}
          onClick={handlePreventPropagation}
        />
      )}
    </div>
  )
}

export default MealActions
