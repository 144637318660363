import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDeliveryDays, getTimeSlots } from './deliveryAPI'
import { removeDuplicates } from '../../screens/signup/utils'
import { getDateLabel } from 'src/utils/date'
import {
  mealsSelectedSelector,
  setMealsSelected,
} from '../meals/mealsSlice'
import { zipCodeSelector } from '../address/addressSlice'
import { getMeals } from '../meals/mealsAPI'

const initialState = {
  deliveryDays: null,
  deliveryDaySelected: null,
  unconfirmedDeliveryDay: null,
  timeSlots: null,
  timeSlotSelected: null,
  confirmationNeeded: null,
  deliveryDaysStatus: 'idle',
  changeDeliveryDaySelectedStatus: 'idle',
  timeSlotsStatus: 'idle',
}

export const fetchDeliveryDays = createAsyncThunk(
  'delivery/fetchDeliveryDays',
  async ({ zipCode }) => {
    const response = await getDeliveryDays({ zipCode })

    const responseFilled = {
      ...response,
      deliveryDays: removeDuplicates(
        response.deliveryDays
          .filter(upcomingDay => upcomingDay.available)
          .map(upcomingDay => {
            const date = new Date(upcomingDay.date)
            return {
              ...upcomingDay,
              day: date.getDay(),
              label: getDateLabel(date),
            }
          }),
        'day',
      ),
    }

    return responseFilled
  },
)

export const changeDeliveryDaySelected = createAsyncThunk(
  'delivery/changeDeliveryDaySelected',
  async ({ deliveryDay }, { dispatch, getState }) => {
    const state = getState()

    const zipCode = zipCodeSelector(state)
    const mealsSelected = mealsSelectedSelector(state)

    const unconfirmedMealsResponse = await getMeals({
      zipCode,
      date: deliveryDay.date,
    })

    const unconfirmedMeals = unconfirmedMealsResponse?.menu?.meals

    const availableMeals = mealsSelected.filter(meal =>
      unconfirmedMeals.some(unconfirmedMeal => meal.id === unconfirmedMeal.id),
    )

    dispatch(setMealsSelected(availableMeals))

    return null
  },
)

export const fetchTimeSlots = createAsyncThunk(
  'delivery/fetchTimeSlots',
  async ({ zipCode }) => {
    const response = await getTimeSlots({ zipCode })
    return response
  },
)

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setDeliveryDaySelected: (state, { payload }) => {
      state.deliveryDaySelected = payload
    },
    setUnconfirmedDeliveryDay: (state, { payload }) => {
      state.unconfirmedDeliveryDay = payload
    },
    setTimeSlotSelected: (state, { payload }) => {
      state.timeSlotSelected = payload
    },
    setConfirmationNeeded: (state, { payload }) => {
      state.confirmationNeeded = payload
    },
    confirmChangeDeliveryDay: state => {
      state.deliveryDaySelected = state.unconfirmedDeliveryDay
      state.unconfirmedDeliveryDay = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeliveryDays.pending, state => {
        state.deliveryDaysStatus = 'loading'
      })
      .addCase(fetchDeliveryDays.fulfilled, (state, { payload }) => {
        state.deliveryDaysStatus = 'idle'
        state.deliveryDays = payload.deliveryDays
      })
      .addCase(changeDeliveryDaySelected.pending, state => {
        state.changeDeliveryDaySelectedStatus = 'loading'
      })
      .addCase(changeDeliveryDaySelected.fulfilled, state => {
        state.changeDeliveryDaySelectedStatus = 'idle'
      })
      .addCase(fetchTimeSlots.pending, state => {
        state.timeSlotsStatus = 'loading'
      })
      .addCase(fetchTimeSlots.fulfilled, (state, { payload }) => {
        state.timeSlotsStatus = 'idle'
        state.timeSlots = payload.timeSlots
      })
  },
})

export const {
  setDeliveryDaySelected,
  setUnconfirmedDeliveryDay,
  setTimeSlotSelected,
  setConfirmationNeeded,
  confirmChangeDeliveryDay,
} = deliverySlice.actions

export const deliveryDaysStatusSelector = state => state.get('delivery').deliveryDaysStatus === 'loading'
export const timeSlotsStatusSelector = state => state.get('delivery').timeSlotsStatus === 'loading'
export const deliveryDaysSelector = state => state.get('delivery').deliveryDays
export const deliveryDaySelectedSelector = state =>
  state.get('delivery').deliveryDaySelected
export const timeSlotsSelector = state => state.get('delivery').timeSlots
export const timeSlotSelectedSelector = state =>
  state.get('delivery').timeSlotSelected
export const confirmationNeededSelector = state =>
  state.get('delivery').confirmationNeeded
export const unconfirmedDeliveryDaySelector = state =>
  state.get('delivery').unconfirmedDeliveryDay

export default deliverySlice.reducer
