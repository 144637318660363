import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONFIGURATION } from 'src/constants/routes'
import { removeTrailingSlash } from 'src/utils/urlUtils'

const useRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const routeConfig = ROUTES_CONFIGURATION[removeTrailingSlash(location.pathname)]

  const nextRoute = () => {
    navigate(routeConfig.nextPage)
  }

  const previousRoute = () => {
    navigate(routeConfig.previousPage)
  }

  return {
    nextRoute,
    previousRoute,
  }
}

export default useRouter
