import { CuiAvatar, CuiItem } from "front-lib"
import { formatChefName } from "src/components/MealCardPremiumFee/utils"
import './ChefDetailRow.scss'

const ChefDetailRow = ({ chef }) => {
    const chefImageFullPath = `${process.env.REACT_APP_IMG_URL_SOURCE}${chef?.bannerPic}`

    const handleChefClick = (event) => {
        event.stopPropagation()
        console.log('handle chef click!', chef)
    }
    const chefFullName = formatChefName(chef?.firstName, chef?.lastName)

    return (
        <CuiItem className='chef-detail-row' data-testid="container-chef-image" onClick={handleChefClick}>
            <CuiAvatar
                data-testid="chef-image"
                border="rounded"
                src={chefImageFullPath}
                title={chefFullName}
            />
            <p>
                {chefFullName}
            </p>
        </CuiItem>
    )
}

export default ChefDetailRow