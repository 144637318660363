import React from 'react'
import classnames from 'classnames'
import { CuiIcon } from 'front-lib'
import Slide from '../Slide/Slide'
import './Drawer.scss'

const Drawer = ({ open, onClose, direction, className, children }) => {
  const handleOverlayClick = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Slide in={open} direction={direction}>
      <div className={classnames('drawer', `drawer--${direction}`, className)}>
        <CuiIcon
          name="close"
          role="button"
          className="drawer__close-btn"
          onClick={handleOverlayClick}
        />
        <div className="drawer__content">{children}</div>
      </div>
    </Slide>
  )
}

export default Drawer
