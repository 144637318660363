import React from 'react'
import { CuiButton } from 'front-lib'
import Close from 'src/assets/icons/xclose.png'
import NextArrow from 'src/assets/icons/next_arrow.png'

import './Popup.scss'

const Popup = props => {
  const { isOpen, onClose, onActionClick } = props

  return isOpen ? (
    <div className="cookunity__popup_wrapper">
      <div className="popup_container">
        <div className="popup">
          <div className="headerButtons">
            <div className="closeButtonContainer">
              <img
                className="close_button"
                src={Close}
                alt="close"
                onClick={onClose}
              />
            </div>
          </div>

          <div className="contentContainer">
            <div className="content">
              <div className="text_content">{props.text}</div>
            </div>

            <div className="contentButtonContainer">
              <div className="buttonRightContainer">
                <CuiButton fill="clear" className="buttonRight" onClick={onClose}>
                  Cancel
                </CuiButton>
              </div>

              <div className="buttonLeftContainer">
                <CuiButton color="dark" className="buttonLeft" onClick={onActionClick}>
                  Continue
                  <img className="arrow" src={NextArrow} alt="Continue" />
                </CuiButton>
              </div>
            </div>
          </div>
        </div>
        <div className="popup_overlay" onClick={onClose} />
      </div>
    </div>
  ) : null
}

export default Popup
