import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { CuiHeader } from 'front-lib'

import useOnlineStore from 'src/hooks/useOnlineStore'
import { DownloadApp } from '../thankyou/components/DownloadApp/DownloadApp'

export default function OpenStoreScreen() {
  const { url } = useOnlineStore()

  useEffect(() => {
    if (url) {
      window.location.replace(url)
    }
  }, [url])

  return (
    <>
      <Helmet>
        <title>A Chef Collective | CookUnity | Download app</title>
      </Helmet>
      <CuiHeader />
      <div className={'container'} style={{ marginTop: '15px' }}>
        <DownloadApp />
      </div>
    </>
  )
}
