import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getQuestions } from './preferencesAPI'

const initialState = {
  questions: null,
  preferences: {},
  status: 'idle',
}

export const fetchQuestions = createAsyncThunk(
  'preferences/fetchQuestions',
  async () => {
    const response = await getQuestions()
    return response
  },
)

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setPreferences: (state, { payload }) => {
      state.preferences[payload.questionId] = payload.preferences
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchQuestions.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchQuestions.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.questions = payload.questions
      })
  },
})

export const { setPreferences } = preferencesSlice.actions

export const questionsSelector = state => state.get('preferences').questions
export const preferencesSelector = state => state.get('preferences').preferences
export const preferencesSettledSelector = state => {
  const preferences = preferencesSelector(state)
  return !!(
    preferences?.categoryTastes?.length || preferences?.allergens?.length
  )
}

export default preferencesSlice.reducer
