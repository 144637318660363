import React, { useCallback } from 'react'
import classNames from 'classnames'
import './ToggleButton.scss'

function ToggleButton({
  value,
  selected,
  onClick,
  fullWidth,
  disabled,
  className,
  children,
}) {
  const handleClick = useCallback(
    e => {
      e.preventDefault()
      return selected ? onClick(e) : onClick(e, value)
    },
    [onClick, selected, value],
  )

  return (
    <button
      type="button"
      className={classNames(
        'toggle-button',
        {
          'toggle-button--selected': selected,
          'toggle-button--full-width': fullWidth,
        },
        className,
      )}
      onClick={e => handleClick(e, value)}
      value={value}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default ToggleButton
