import { createSelector } from 'reselect'

const getProductDetailState = state => state.get('productDetailModule')

const getDetailState = state => getProductDetailState(state).get('detail')
export const getDetailStatus = createSelector(getDetailState, state =>
  state.get('start'),
)
export const getDetailSuccess = createSelector(getDetailState, state =>
  state.get('success'),
)
export const getDetailError = createSelector(getDetailState, state =>
  state.get('error'),
)
export const getProductDetail = createSelector(getDetailState, state =>
  state.get('data'),
)
export const showProductDetail = createSelector(
  getDetailSuccess,
  getDetailStatus,
  (success, status) => success || status,
)
