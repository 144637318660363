import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'src/components/Loader'
import * as signUpCommonSelectors from '../../../../redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from '../../../../redux/ducks/screens/signup/commons/creators'
import * as checkoutPageSelectors from '../../../../redux/ducks/screens/signup/pages/Checkout/selectors'
import * as checkoutPageActionCreators from '../../../../redux/ducks/screens/signup/pages/Checkout/creators'
import * as preOrderPageSelectors from '../../../../redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as creatorCommons from '../../../../redux/ducks/commons/creators'
import { useSendLogging, useAuthData, useCallbacks } from './hooks'
import { getCoordinates } from '../../utils'
import { getCUCookie } from 'src/utils/cookieUtils'
import CheckoutPageCui from './CheckoutPageCui'
import { useLocation, useNavigate } from 'react-router-dom'
import { calculateSummary } from 'src/utils/priceUtils'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import {
  authErrorSelector,
  isAuthenticatedSelector,
} from 'src/features/auth/authSlice'
import useAuth0Api from 'src/features/signIn/hooks/Auth0'
import { PATHS, pageNames } from '../../constants'
import { useAuth0 } from '@auth0/auth0-react'
import { parse } from 'qs'
import { SESSION_KEY } from 'src/constants'
import { removeUrlParameter } from 'src/utils/urlUtils'

const CheckoutPageContainer = ({
  stepHandler,
  actions,
  userSelectedData,
  coupon,
  couponError,
  applyCouponStatus,
  cardData,
  addressData,
  addressError,
  addressStatus,
  cardError,
  cardStatus,
  formSteps,
  createUserStatus,
  createUserError,
  cartData,
  ring,
  store,
  timeSlots,
  giftcardCredit,
  giftcardData,
}) => {
  const {
    loginWithRedirect,
    isAuthenticated: isAuthenticatedAuth0,
  } = useAuth0()
  const location = useLocation()
  const navigate = useNavigate()
  const { sendLogging, addSeoData } = actions
  const {
    setTimeSlot,
    setDeliveryOption,
    showHandler,
    setGiftCardCode,
  } = useCallbacks(actions, formSteps)

  const { isTreatment: isSneakPeekTreatment } = useExperiment(
    EXPERIMENTS.sneakPeek,
  )

  const {
    selectedPlan,
    startDay,
    zipcode,
    selectedTimesLot: selectedTimeSlot,
    deliveryOption: selectedDeliveryOption,
    taxesAmount,
  } = userSelectedData

  const [openModal, toggleOpenModal] = useState(false)
  const coordinates = useCallback(getCoordinates(store), []) // eslint-disable-line react-hooks/exhaustive-deps
  const authError = useSelector(authErrorSelector)
  const isAuthenticatedSneakPeek = useSelector(isAuthenticatedSelector)
  const isAuthenticated = isSneakPeekTreatment
    ? isAuthenticatedSneakPeek
    : isAuthenticatedAuth0
  const { checkSession } = useAuth0Api()

  useAuthData(location.search, actions, '', isSneakPeekTreatment)

  useEffect(() => {
    actions.sendIdentify()
    if (authError?.code === 'login_required') {
      navigate(`${PATHS[pageNames.MEALS]}${location.search}`)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSneakPeekTreatment) {
      checkSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSneakPeekTreatment])

  useEffect(() => {
    if (!isAuthenticated) {
      if (isSneakPeekTreatment) {
        navigate(`${PATHS[pageNames.MEALS]}${location.search}`)
      }

      const { email, cuSession, utm_source } = parse(location.search, {
        ignoreQueryPrefix: true,
      })
      const searchForAuth0 = !cuSession ? `&cuSession=${SESSION_KEY}` : ''
      const search = removeUrlParameter(location.search, [
        'planSize',
        'meals',
        'noSkip',
      ])

      loginWithRedirect({
        action: 'signup',
        redirectUri: `${window.location.origin}${PATHS[pageNames.MEALS]}${search}${searchForAuth0}`,
        initialEmail: email,
        search: `${search}${searchForAuth0}`,
        env: process.env.NODE_ENV,
        isDemo: utm_source === 'demo',
      })
    }

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const giftcardCode = getCUCookie('giftcardCode')
  useEffect(() => {
    if (giftcardCode) {
      actions.validateGiftcard({ giftcardCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcardCode])

  const summary = useMemo(
    () =>
      calculateSummary({
        plan: selectedPlan,
        meals: cartData.products.map(el => ({ ...el.data, qty: el.qty })),
        promotion: coupon.promotion,
        taxRate: taxesAmount,
        giftcardCredit,
      }),

    [
      cartData.products,
      coupon.promotion,
      selectedPlan,
      taxesAmount,
      giftcardCredit,
    ],
  )

  useSendLogging(sendLogging, addSeoData)
  
  if (!isAuthenticated) return <Loader />

  return (
    <CheckoutPageCui
      stepHandler={stepHandler}
      setCheckoutStepHandler={actions.setCheckoutStep}
      setForcedSkipQuiz={actions.setForcedSkipQuiz}
      addAddress={actions.addAddress}
      applyCoupon={actions.applyCoupon}
      resetApplyCoupon={actions.resetApplyCoupon}
      addCard={actions.addCard}
      showHandler={showHandler}
      toggleOpenModal={toggleOpenModal}
      cardData={cardData}
      addressData={addressData}
      addressError={addressError}
      addressStatus={addressStatus}
      cardError={cardError}
      cardStatus={cardStatus}
      zipcode={zipcode}
      selectedPlan={selectedPlan}
      order={cartData}
      coupon={coupon}
      couponError={couponError}
      applyCouponStatus={applyCouponStatus}
      startDay={startDay}
      deliveryOpen={formSteps.delivery}
      paymentOpen={formSteps.payment}
      openModal={openModal}
      createAccountStatus={createUserStatus}
      createAccountError={createUserError}
      sendLogging={actions.sendLogging}
      ring={ring}
      store={store}
      coordinates={coordinates}
      timeSlots={timeSlots}
      setTimeSlot={setTimeSlot}
      selectedTimeSlot={selectedTimeSlot}
      setDeliveryOption={setDeliveryOption}
      setGiftCardCode={setGiftCardCode}
      selectedDeliveryOption={selectedDeliveryOption}
      checkoutAccount={summary}
      sendTracking={actions.sendTracking}
      giftcardCode={giftcardCode}
      validateCoupon={actions.validateCoupon}
      resetCouponValidation={actions.resetCouponValidation}
      resetGiftcardValidation={actions.resetGiftcardValidation}
      giftcardData={giftcardData}
    />
  )
}

const mapStateToProps = state => ({
  applyCouponStatus: signUpCommonSelectors.getCouponStatus(state),

  coupon: signUpCommonSelectors.getCoupon(state),
  couponError: signUpCommonSelectors.getCouponError(state),
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),

  cartData: preOrderPageSelectors.getCart(state),
  cardData: checkoutPageSelectors.getCardData(state),
  cardError: checkoutPageSelectors.getCardError(state),
  cardStatus: checkoutPageSelectors.getCardStatus(state),

  addressData: checkoutPageSelectors.getAddressData(state),
  addressError: checkoutPageSelectors.getAddressError(state),
  addressStatus: checkoutPageSelectors.getAddressStatus(state),

  formSteps: checkoutPageSelectors.getSteps(state),

  createUserStatus: signUpCommonSelectors.getCreateUserStatus(state),
  createUserError: signUpCommonSelectors.getCreateUserError(state),

  ring: signUpCommonSelectors.getRing(state),
  store: signUpCommonSelectors.getUserStore(state),
  timeSlots: signUpCommonSelectors.getTimeSlots(state),

  giftcardCredit: checkoutPageSelectors.getGiftcardCredit(state),
  giftcardData: checkoutPageSelectors.getGiftcardData(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      applyCoupon: signUpCommonActionCreators.applyCouponStart,
      resetApplyCoupon: signUpCommonActionCreators.applyCouponReset,
      addSeoData: signUpCommonActionCreators.startAddSeoData,
      setCheckoutStep: checkoutPageActionCreators.startSetCheckoutStep,
      addAddress: checkoutPageActionCreators.startAddresses,
      addCard: checkoutPageActionCreators.startUserCard,
      sendLogging: creatorCommons.loggingStart,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
      setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
      setFormData: checkoutPageActionCreators.setFormData,
      sendTracking: creatorCommons.trackingStart,
      sendIdentify: creatorCommons.identifyStart,
      validateCoupon: checkoutPageActionCreators.startCoupon,
      resetCouponValidation: checkoutPageActionCreators.setCouponReset,
      validateGiftcard: checkoutPageActionCreators.startValidateGiftcard,
      resetGiftcardValidation: checkoutPageActionCreators.resetValidateGiftcard,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutPageContainer)
