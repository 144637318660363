import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getCustomAttributeValue } from 'src/utils/auth0'
import { useDispatch } from 'react-redux'
import { userSelector } from 'src/features/user/userSlice'
import {
  setAddress,
  setZipCodeValidation,
} from 'src/features/address/addressSlice'
import { setPlanSelected } from 'src/features/plans/plansSlice'
import {
  couponSelector,
  fetchCouponValidation,
} from 'src/features/cart/cartSlice'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from '../../../components/Loader'

const ResurrectionGuard = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [ searchParams ] = useSearchParams()
  const { user: userAuth } = useAuth0()
  const user = useSelector(userSelector)
  const coupon = useSelector(couponSelector)

  useEffect(() => {
    if (!getCustomAttributeValue(userAuth, 'isSubscribed')) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      const address = user.addresses[0]
      dispatch(
        setAddress({
          zipCode: address.postcode,
          street: address.street,
          city: address.city,
          state: address.region,
        }),
      )
      dispatch(
        setZipCodeValidation({
          ring: user.ring,
          store: user.store,
        }),
      )
      dispatch(setPlanSelected(user.currentPlan))
      const couponCode = state?.couponCode || searchParams?.get('coupon') || 'GET30OFF'
      if (couponCode) {
        dispatch(fetchCouponValidation({ code: couponCode }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (!user || !coupon) {
    return <Loader />
  }

  return children || <Outlet />
}

export default React.memo(ResurrectionGuard)
