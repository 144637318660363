import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PAGE_INFORMATION } from 'src/constants/tracking'
import { authUserSelector } from 'src/features/auth/authSlice'
import { userSelector } from 'src/features/user/userSlice'
import {
  getUserSelectedData,
  getUserStore,
} from 'src/redux/ducks/screens/signup/commons/selectors'
import { getCustomAttributeValue } from 'src/utils/auth0'
import { cookUnityUuid } from 'src/utils/cookieUtils'
import { getUserOs } from 'src/utils/events'
import { isMobile } from 'src/utils/events'
import segment from 'src/utils/segment'

const useTrackEvent = ({ eventName, ...restParams }) => {
  const user = useSelector(userSelector)
  const authUser = useSelector(authUserSelector)
  const location = useLocation()
  const deliveryDate = useSelector(getUserSelectedData)?.startDay?.date
  const storeName = useSelector(getUserStore)?.name

  const page = (PAGE_INFORMATION[location.pathname] || PAGE_INFORMATION.default)
    .name

  const userId =
    user?.magento_id ||
    (authUser && getCustomAttributeValue(authUser, 'magento_id')) ||
    undefined

  const subscriberId =
    user?.id ||
    (authUser && getCustomAttributeValue(authUser, 'subscription_id')) ||
    undefined

  const userEmail = user?.email || authUser?.email || undefined

  const eventGenericData = useMemo(
    () => ({
      user_id: userId,
      anonymous_id: cookUnityUuid,
      subscriber_id: subscriberId,
      user_email: userEmail,
      device: isMobile() ? ' Mobile Web' : 'Web Desktop',
      device_os: getUserOs(),
      screen_name: isMobile() ? page : undefined,
      page_name: isMobile() ? undefined : page,
      context: 'funnel',
      delivery_date: deliveryDate,
      menu_store: storeName,
    }),
    [deliveryDate, page, storeName, subscriberId, userEmail, userId],
  )

  const track = useCallback(
    (eventData = {}) => {
      segment.track(eventName, {
        ...eventGenericData,
        ...restParams,
        ...eventData,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventGenericData, eventName],
  )

  return track
}

export default useTrackEvent
