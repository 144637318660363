import { put, takeEvery, call } from 'redux-saga/effects'

import * as actionCreators from 'src/redux/ducks/screens/signup/pages/ThankYou/creators'
import * as actions from 'src/redux/ducks/screens/signup/pages/ThankYou/actions'

import { GQL } from 'src/api/services'
import { logError } from 'src/utils/logError'

export function* sendSettings({ payload: { key, value } }) {
  try {
    yield call(GQL.setSetting, {
      key,
      value,
    })

    yield put(
      actionCreators.sendSettingsCreators.success({
        key,
        value,
      }),
    )
  } catch (error) {
    logError(error)
    yield put(
      actionCreators.sendSettingsCreators.failed('Invalid set settings'),
    )
  }
}

const sendSettingsArray = [takeEvery(actions.SEND_SETTINGS.start, sendSettings)]

export default sendSettingsArray
