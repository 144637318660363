import { useEffect } from 'react';

const useScript = (src: string, shouldLoad = false) => {
    useEffect(() => {
        if (!shouldLoad) return;

        let script: Element | HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

        let scriptAdded = false;
        if (!script) {
            let script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
            scriptAdded = true;
        }

        return () => {
            if (scriptAdded && script) {
                document.body.removeChild(script);
            }
        };
    }, [src, shouldLoad]);
};

export default useScript;
