import React, { useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Modal from 'src/components/NewModal'
import useTrackEvent from 'src/hooks/useTrackEvent'
import {
  getOverlayTrackData,
  OVERLAY_VIEWED,
  OVERLAY_CLOSED,
  OVERLAY_SUBMITTED,
  OVERLAY_NAME_OUT_OF_DELIVERY_AREA,
  OVERLAY_NAME_CANNOT_VALIDATE_ZIP_CODE,
} from 'src/screens/home/utils'

import './ComingSoonModal.scss'

const MODAL_DATA = {
  ZIP_CODE_DO_NOT_COVER: {
    headingText: "We haven't quite reached you yet.",
    descriptionText: "But we're rapidly expanding! Give us your email and we'll let you know once we arrive in your area!",
    buttonText: "Send",
    overlayName: OVERLAY_NAME_OUT_OF_DELIVERY_AREA,
  },
  UNEXPECTED_ERROR: {
    headingText: "We can't validate your zip code right now.",
    descriptionText: "Sign up for a very special offer",
    buttonText: "Send",
    overlayName: OVERLAY_NAME_CANNOT_VALIDATE_ZIP_CODE,
  },
}

function ComingSoonModal({ isOpen, onClose, isLoading, onSubmit, email, validZipCodeError }) {
  const { headingText, descriptionText, buttonText, overlayName } = MODAL_DATA[validZipCodeError?.errorCode] || MODAL_DATA.UNEXPECTED_ERROR

  const trackOverlayViewed = useTrackEvent(
    getOverlayTrackData({ event: OVERLAY_VIEWED, overlayName }),
  )
  const trackOverlayClosed = useTrackEvent(
    getOverlayTrackData({ event: OVERLAY_CLOSED, overlayName }),
  )
  const trackOverlaySubmitted = useTrackEvent(
    getOverlayTrackData({ event: OVERLAY_SUBMITTED, overlayName }),
  )  

  useEffect(() => {
    if (isOpen) trackOverlayViewed()
  }, [isOpen, trackOverlayViewed])


  const handleOnClose = () => {
    trackOverlayClosed()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleOnClose} smallSize>
      <div className="comingSoonContainer">
        <h2>{headingText}</h2>
        <p>{descriptionText}</p>
        <br />

        <Formik
          enableReinitialize={true}
          initialValues={{
            emailValue: email || '',
          }}
          onSubmit={values => {
            trackOverlaySubmitted()
            onSubmit(values.emailValue)
          }}
          validationSchema={Yup.object().shape({
            emailValue: Yup.string()
              .email('Invalid email')
              .required('Required'),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form>
                <div>
                  <input
                    type="text"
                    className={`form-control homeInputEmail ${
                      errors.emailValue && touched.emailValue
                        ? 'errorInput'
                        : ''
                    }`}
                    id="emailValue"
                    aria-describedby="emailValueHelp"
                    placeholder={'example@email.com'}
                    value={values.emailValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="">
                  <button
                    type="button"
                    className="sendHomeData"
                    onClick={handleSubmit}
                  >
                    {!isLoading ? (
                      buttonText
                    ) : (
                      <i className="spinner fas fa-circle-notch" />
                    )}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default ComingSoonModal
