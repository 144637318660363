import { pageNames, PATHS } from '../screens/signup/constants'

export const name = '@COOK-UNITY-SIGN_UP'
export const AUTH0_NAMESPACE = 'https://cookunity.com/'
export const AUTH0_ACCESS_TOKEN_NAME = '@CU/accessToken'
export const HOMEPAGE_URL = process.env.REACT_APP_SITE_BASE_URL
export const DEFAULT_COUPON = 'GET30OFF'
export const DEFAULT_UPGRADED_HIGH_RISK_COUPON = 'GET15OFF_2WEEKS'
export const DEFAULT_UPGRADED_HIGH_RISK_COUPON_NOT_DEFAULT = 'GET25OFF_2WEEKS'
export const SESSION_KEY = 'COOKUNITY_SIGNUP'
export const EXP_KEY_PREFIX = 'CU_SIGNUP_EXP_'

export const applicationConstants = {
  name,
  AUTH0_NAMESPACE,
  AUTH0_ACCESS_TOKEN_NAME,
  HOMEPAGE_URL,
  EXP_KEY_PREFIX,
}

export const promotionTypesOfUse = {
  CONSUMABLE: 'unique',
  GENERAL: 'continuous',
}

export const zeroInvoicesReferralType = 'IS_ELIGIBLE_ZERO_INVOICES'

export const promotionTypeOfUseLabels = {
  [promotionTypesOfUse.CONSUMABLE]: 'consumable',
  [promotionTypesOfUse.GENERAL]: 'general',
  default: 'general',
}

export const pageDataByPath = {
  '/': {
    name: 'home',
  },
  [PATHS[pageNames.QUIZ]]: {
    name: pageNames.QUIZ,
  },
  [PATHS[pageNames.MEALS]]: {
    name: pageNames.MEALS,
  },
  [PATHS[pageNames.CHECKOUT]]: {
    name: pageNames.CHECKOUT,
  },
  [PATHS[pageNames.THANK_YOU]]: {
    name: pageNames.THANK_YOU,
  },
  default: {
    name: 'default',
  },
}

export const preSelectErrorMessage =
  'This meal for this delivery date is sold out'

export const commonQuestionsHome = [
  {
    id: 7,
    trigger: 'How does CookUnity work?',
    content: `
    <b>1. Pick your plan:</b> Choose from 4, 6, 8, 12, or 16 meals per week. You can always add, pause, or skip deliveries — or change your plan at any time.<br />
    <b>2. Choose your weekly meals:</b> Or forget about meal planning, and let us select for you based on your preferences.<br />
    <b>3. Get chef-crafted meals at your door:</b> All meals are delivered chilled in recyclable containers.<br />
    <b>4. Heat, plate, and enjoy:</b> Meals are ready-to-eat when you are. Just follow the simple instructions to eat and plate — then enjoy!<br />
    <b>5. Enjoy flexible scheduling:</b> Change your delivery day, add, pause, or reschedule deliveries at any time.`,
  },
  {
    id: 5,
    trigger: 'Do I get to choose my meals?',
    content: `
    Yes! With your subscription, you can either choose your weekly meals based on our menu with dozens of options, or set your preferences and let us choose for you. Feel free to edit, skip, or reschedule your orders until your delivery cutoff time.
    `,
  },
  {
    id: 8,
    trigger: 'Are CookUnity meals healthy?',
    content: `
    At CookUnity, we believe the best meals start with the best ingredients. Our chefs prioritize sustainably-sourced and seasonal ingredients. Our weekly menu accommodates numerous diets and preferences, including gluten-free, dairy-free, keto, paleo, vegan, and beyond.
    `,
  },
  {
    id: 1,
    trigger: 'Can I choose meals to meet my health goals and needs?',
    content: `
    Certainly! Be sure to specify your dietary restrictions and preferences in your account to easily find the meals that work best for you. You can flag allergies or foods you don’t like, and we’ll notify you if they appear in a meal’s ingredient list. Ingredients, nutritional information, and heating instructions are listed for every meal.<br />
    On average, our weekly menu includes meals for the following diets:<br />
    • Low-calorie (<500): 40+ meals<br />
    • Low-carbohydrate (<35g): 40–50 meals<br />
    • Dairy-free: 30–40 meals<br />
    • Plant-based: 30-40 meals<br />
    • Keto: 30-40 meals<br />
    • Paleo: 50-60 meals
    `,
  },
  {
    id: 2,
    trigger: 'How many servings are in one meal?',
    content:
      'As a general rule of thumb, one CookUnity meal is intended to serve one person. If you’re ordering for multiple people, our larger meal plans work well (8, 12, 16 meals/week). We encourage you to try different options and share “family style.”',
  },
  {
    id: 3,
    trigger: 'How long do the meals last?',
    content:
      'The meals have a refrigerated shelf life of about 4-7 days. Every meal has a “use by” date on the label.',
  },
  {
    id: 4,
    trigger: 'Are your meals frozen?',
    content:
      'No. CookUnity meals are delivered fresh and chilled, so that they’re ready to heat-and-eat when you are. If you aren’t able to finish meals by their “Enjoy Before” date, you can freeze them beforehand. Just be sure to thaw them completely in the fridge before heating.',
  },
  {
    id: 6,
    onlyLocal: true,
    trigger: 'Should I tip my delivery person?',
    content: `
    Tipping your delivery person is not necessary or expected by them. We value our delivery partners and ensure their team is compensated appropriately.
    `,
  },
]

export const PROMOTION_TYPES_WITH_DELIVERY_DISCOUNT = [
  'vipCreditPromotion',
  'deliveryAndPlanB2B',
]
export const PROMOTION_TYPES_WITH_FREE_DELIVERY = ['discountAndFreeShipping']

export const ECONOMIC_PROMO_COPY = '$8.50 PER MEAL FOR 17 WEEKS'

export const RECAPTCHA_SCRIPT =
  'https://www.google.com/recaptcha/enterprise.js?render='

export const SEATTLE_STORE_ID = 6

export const DEFAULT_MEAL_PLAN = 8

export const COUPON_SIX_WEEK_PROMO = 'SIXWEEKPROMO'

export const COUPON_DISCLAIMERS = {
  FREE_DESSERT:
    '*Dessert promotion applies automatically at checkout starting from your second order. Limit 1 free dessert per order with a maximum value of $4.99.',
}

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/cookunity-a-chef-marketplace/id1103061273'
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.cookunity.android.app'

export const PROMO_POS = [2, 4, 7, 12, 20, 27, 39, 45, 55, 70].map(
  pos => pos - 1,
)
