import { subscriptionMutate, subscriptionQuery } from 'src/api/client'
import { getRecaptcha } from 'src/utils/recaptcha'

const paymentMethodsQuery = `
  query paymentMethods {
    paymentMethods {
      id
      name
      provider
      isDefault
      card {
        id
        customerId
        last4
        owner
        company
        cardType
        ccNumber
        expirationMonth
        expirationYear
      }
    }
  }
`

const stripeClientSecretQuery = `
  query stripeClientSecret($recaptchaToken: String!) {
    stripeClientSecret(recaptchaToken: $recaptchaToken) {
      customer_id
      client_secret
    }
  }
`

const removePaymentMethodMutation = `
  mutation removePaymentMethod($cardId: String!) {
    removePaymentMethod(id: $cardId) {
      ... on PaymentRemoved {
        success
        __typename
      }
      ... on PaymentError {
        error
      }
      __typename
    }
  }
`

const defaultUserCardMutation = `
  mutation setDefaultUserCard($cardId: String!) {
    setDefaultUserCard(card_id: $cardId) {
      ... on userCardsListType {
    cards {
      id
      company
      customerId
      ccNumber
      cardType
      last4
      owner
      ccNumber
      expirationMonth
      expirationYear
    }
  }
  ... on userCardsErrorType {
    error
  }
    }
  }
`

export const getPaymentsMethods = ({ forceFetch }) =>
  subscriptionQuery(paymentMethodsQuery, {}, forceFetch ? 'network-only' : null)

export const getStripeClientSecret = async () => {
  const recaptchaToken = await getRecaptcha('getStripeClientSecret')

  return subscriptionQuery(stripeClientSecretQuery, { recaptchaToken })
}

export const removePaymentMethod = ({ cardId }) =>
  subscriptionMutate(removePaymentMethodMutation, { cardId })

export const setDefaultUserCard = ({ cardId }) =>
  subscriptionMutate(defaultUserCardMutation, { cardId })
