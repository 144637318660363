import { Formik } from 'formik'
import { isEmpty } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { Collapse } from 'reactstrap'
import * as Yup from 'yup'

import AutocompleteAddress from 'src/screens/signup/components/shared/AutocompleteAddress'
import { DropdownOne } from 'src/screens/signup/components/shared/Dropdown'
import { doormanOptions } from 'src/screens/signup/constants'

import { CuiCheckbox } from 'front-lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  setUserPreferences,
  userPreferences,
} from 'src/features/user/userSlice'
import useExperiment from 'src/hooks/useExperiment'
import ZipcodeMismatchSolver from './ZipcodeMismatchSolver'
import classnames from 'classnames'
import { authUserSelector } from 'src/features/auth/authSlice'
import { logError, logInfo } from 'src/utils/logError'
import { getAddressData, isAddressSuccess } from 'src/redux/ducks/screens/signup/pages/Checkout/selectors'
import { EXPERIMENTS } from 'src/constants/experiments'

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  '-',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const MAX_LENGTH_INSTRUCTION = 50
export const getInstructionTestMessage = num => `${num} characters max`
export const isTooLong = (value, max) => value.length > max

export default function DeliveryFormCui({
  address,
  addAddress,
  deliveryOpen,
  showHandler,
  error,
  loading,
  zipcode,
  coordinates,
  setTimeSlot,
  selectedTimeSlot,
  setDeliveryOption,
  selectedDeliveryOption,
  showDeliveryWindowAndOptions,
  timeSlots,
  planName,
  storeName,
  userRingId,
  sendTracking,
  startDay,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [formValues, setFormValues] = useState()
  const dispatch = useDispatch()
  const preferences = useSelector(userPreferences)
  const isValid = !isEmpty(address)
  const defaultTimeSlot = timeSlots[0]
  const defaultDeliveryOption = doormanOptions[0].value
  const requiredFieldsExp = useExperiment(EXPERIMENTS.checkoutRequiredFields, { trackOnMount: true })
  const user = useSelector(authUserSelector)
  const deliveryFormRef = useRef()
  const addressSuccess = useSelector(isAddressSuccess)
  const addressData = useSelector(getAddressData)

  useEffect(() => {
    if (error) {
      const email = user?.email
      const newError = new Error(`Checkout Delivery Error ${error}`)
      const context = { message: `Error: ${error}`, email }
      logError(newError, context)
    }
  }, [user?.email, error, sendTracking])

  useEffect(() => {
    if (!deliveryOpen && isSubmitted && addressSuccess && addressData?.id) {
      setIsSubmitted(false)
      sendTracking({
        eventName: 'Sign Up - Address Completed',
        eventData: {
          user_phone: formValues.phone,
          user_address_zipcode: formValues.zipcode,
          plan_name: planName,
          first_delivery_date: startDay,
          user_store_name: storeName,
          user_address_ring_id: userRingId,
          user_address: address.address,
          user_address_apt: address.apt,
          user_address_city: address.city,
          user_address_state: address.state,
          has_sms_enable: preferences.hasSmsEnable,
        },
      })
    }
  }, [address.address, address.apt, address.city, address.state, addressData?.id, addressSuccess, deliveryOpen, formValues, isSubmitted, planName, preferences.hasSmsEnable, sendTracking, startDay, storeName, userRingId])

  const submitForm = values => {
    setIsSubmitted(true);
    const {
      timeslots,
      'delivery-options': deliveryOptions,
      ...address
    } = values
    setFormValues(values)

    if (showDeliveryWindowAndOptions) {
      setTimeSlot(timeSlots.find(timeSlot => timeSlot.label === timeslots))
      setDeliveryOption(deliveryOptions)
    } else {
      setTimeSlot(defaultTimeSlot)
      setDeliveryOption(defaultDeliveryOption)
    }

    deliveryFormRef.current?.scrollIntoView({ behavior: 'smooth' });

    addAddress(address)
  }

  const getFormValidationSchema = () => {
    let schema = Yup.object().shape({
      firstname: Yup.string().required('Required'),
      lastname: Yup.string().required('Required'),
      address: Yup.string()
        .required('Required')
        .test('address', 'Invalid Address,', value => {
          if (value) {
            const regex = /(P\s?.*O\s?.*BOX\s?.*)/gi
            return !!!regex.test(value)
          } else {
            return true
          }
        }),
      apt: Yup.string().test('apt', 'Invalid Address,', value => {
        if (value) {
          const regex = /(P\s?.*O\s?.*BOX\s?.*)/gi
          return !!!regex.test(value)
        } else {
          return true
        }
      }),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipcode: Yup.string()
        .typeError('Invalid zipcode')
        .required('Required')
        .length(5, 'It must be 5 characters long')
        .matches(/^[0-9]+$/, 'It must only contain numbers'),
      phone: Yup.string()
        .required('Required')
        .test('phone', 'Invalid phone number', value => {
          if (value) {
            const cleanValue = value.replace(/\D/g, '')
            return cleanValue.length >= 10
          }
        }),
      deliveryInstructions: Yup.string()
        .test(
          'deliveryInstructions',
          getInstructionTestMessage(MAX_LENGTH_INSTRUCTION),
          value => {
            if (value) {
              return !isTooLong(value, MAX_LENGTH_INSTRUCTION)
            } else {
              return true
            }
          },
        )
        .test('deliveryInstructionsPoBox', 'Invalid Instructions,', value => {
          if (value) {
            const regex = /(P\s?.*O\s?.*BOX\s?.*)/gi
            return !!!regex.test(value)
          } else {
            return true
          }
        }),
    })

    if (showDeliveryWindowAndOptions) {
      schema = schema.shape({
        timeslots: Yup.string().required('Required'),
        'delivery-options': Yup.string()
          .nullable()
          .required('Required'),
      })
    }

    return schema
  }

  const getTimeSlotsDropdownOptions = () =>
    timeSlots.map(timeSlot => ({
      label: timeSlot.label,
      value: timeSlot.label,
    }))

  const getDeliveryDropdownOptions = () => doormanOptions

  const getDeliveryValue = selectedOption => {
    return doormanOptions.reduce((val, deliveryOption) => {
      if (deliveryOption.value === selectedOption) return deliveryOption
      return val
    }, null)
  }

  const handlerSmsConsent = () => {
    dispatch(
      setUserPreferences({
        hasSmsEnable: !preferences.hasSmsEnable,
      }),
    )
  }

  const formInitValues = () => {
    let values = {
      firstname: user?.given_name || '',
      lastname: user?.family_name || '',
      address: address.address || '',
      apt: address.apt || '',
      city: address.city || '',
      state: address.state || '',
      zipcode: address.zipcode || zipcode || '',
      phone: address.phone || '',
      deliveryInstructions: address.deliveryInstructions || '',
    }

    if (showDeliveryWindowAndOptions) {
      values.timeslots =
        timeSlots.length === 1 ? defaultTimeSlot.label : selectedTimeSlot?.label
      values['delivery-options'] = selectedDeliveryOption
    }

    return values
  }

  return (
    <div
      className={classnames('form-container-cui', {
        active: deliveryOpen,
        inactive: !deliveryOpen,
        valid: isValid,
        invalid: !isValid,
      })}
    >
      <div id="delivery"
        className="header-container"
        ref={deliveryFormRef}
        tabIndex={0}
      >
        <h2
          className="rebrand"
          onClick={!deliveryOpen ? showHandler('delivery') : undefined}
        >
          <span
            className={classnames('step', {
              'current-step': deliveryOpen,
            })}
          >
            1
          </span>
          Delivery Address
        </h2>
      </div>
      <Collapse isOpen={deliveryOpen}>
        <Formik
          enableReinitialize={true}
          initialValues={formInitValues()}
          onSubmit={submitForm}
          validationSchema={getFormValidationSchema()}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => {
            const hasError = fieldName => {
              if (errors[fieldName] && touched[fieldName]) {
                const context = { fieldName, error: errors[fieldName], email: user?.email }
                logInfo("Form validation error", context)
              }
              return errors[fieldName] && touched[fieldName]
            }

            return (
              <form className="form" onSubmit={handleSubmit}>
                {error && <div className="error-container">{error}</div>}
                <ZipcodeMismatchSolver />
                <div className="form-row form-group">
                  <div className="col-md-6">
                    <label htmlFor="firstname">First Name {requiredFieldsExp.isTreatment && '*'}</label>
                    <input
                      disabled={loading}
                      type="text"
                      className={
                        hasError('firstname')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="firstname"
                      aria-describedby="firstNameHelp"
                      placeholder="Eg: Jane"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('firstname') && (
                      <div className="invalid-feedback">{errors.firstname}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastname">Last Name {requiredFieldsExp.isTreatment && '*'}</label>
                    <input
                      disabled={loading}
                      type="text"
                      className={
                        hasError('lastname')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="lastname"
                      aria-describedby="lastNameHelp"
                      placeholder="Eg: Doe"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('lastname') && (
                      <div className="invalid-feedback">{errors.lastname}</div>
                    )}
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-8">
                    <label htmlFor="address">Address {requiredFieldsExp.isTreatment && '*'}</label>
                    <AutocompleteAddress
                      value={values.address}
                      disabled={loading}
                      className={
                        hasError('address')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="address"
                      error={errors.address}
                      touched={touched.address}
                      aria-describedby="addressHelp"
                      placeholder="Eg: 2354 54th street"
                      coordinates={coordinates}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    {hasError('address') && (
                      <div className="invalid-feedback">{errors.address}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="apt">Apt / Suite / Office</label>
                    <input
                      disabled={loading}
                      id="apt"
                      type="text"
                      className={
                        hasError('apt')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      aria-describedby="aptHelp"
                      placeholder="Eg: 3A"
                      value={values.apt}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('apt') && (
                      <div className="invalid-feedback">{errors.apt}</div>
                    )}
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-4 city">
                    <label htmlFor="city">City {requiredFieldsExp.isTreatment && '*'}</label>
                    <input
                      disabled={loading}
                      type="text"
                      className={
                        hasError('city')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="city"
                      aria-describedby="cityHelp"
                      placeholder="Eg: New York"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('city') && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div>

                  <div className="col-md-4 state">
                    <label htmlFor="state">State {requiredFieldsExp.isTreatment && '*'}</label>
                    <input
                      disabled={loading}
                      type="text"
                      id="state"
                      className={
                        hasError('state')
                          ? 'form-control is-invalid'
                          : 'form-control '
                      }
                      placeholder="Eg: New York"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('state') && (
                      <div className="invalid-feedback">{errors.state}</div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="zipcode">Zipcode {requiredFieldsExp.isTreatment && '*'}</label>
                    <input
                      type="tel"
                      id="zipcode"
                      className={
                        hasError('zipcode')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      placeholder="Eg: 10012"
                      minLength={0}
                      maxLength={5}
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('zipcode') && (
                      <div className="invalid-feedback">{errors.zipcode}</div>
                    )}
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-12">
                    <label htmlFor="phone">Phone {requiredFieldsExp.isTreatment && '*'}</label>
                    <MaskedInput
                      disabled={loading}
                      type="tel"
                      className={
                        hasError('phone')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="phone"
                      aria-describedby="telHelp"
                      placeholder="(___) ___-____"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      mask={phoneNumberMask}
                    />
                    {hasError('phone') && (
                      <div className="invalid-feedback">{errors.phone}</div>
                    )}
                  </div>
                </div>

                {showDeliveryWindowAndOptions && (
                  <div className="form-row form-group">
                    <div className="col-md-6">
                      <label htmlFor="timeslots">Delivery Window {requiredFieldsExp.isTreatment && '*'}</label>
                      <DropdownOne
                        fieldName={'timeslots'}
                        placeHolder={'Select a Delivery Window'}
                        options={getTimeSlotsDropdownOptions()}
                        value={values.timeslots?.label}
                        hasError={hasError('timeslots')}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={loading}
                        inputClassname={'form-control'}
                      />
                      {hasError('timeslots') && (
                        <div
                          className="invalid-feedback"
                          style={{ display: 'block' }}
                        >
                          {errors.timeslots}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="delivery-options">Delivery Options {requiredFieldsExp.isTreatment && '*'}</label>
                      <DropdownOne
                        fieldName={'delivery-options'}
                        placeHolder={'Select a dropoff location'}
                        options={getDeliveryDropdownOptions()}
                        value={getDeliveryValue(values['delivery-options'])}
                        hasError={hasError('delivery-options')}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={loading}
                        inputClassname={'form-rebrand form-control'}
                      />
                      {hasError('delivery-options') && (
                        <div
                          className="invalid-feedback"
                          style={{ display: 'block' }}
                        >
                          {errors['delivery-options']}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-row form-group">
                  <div className="col-md-12">
                    <label htmlFor="deliveryInstructions">
                      Address Instructions
                    </label>
                    <input
                      disabled={loading}
                      type="text"
                      className={
                        hasError('deliveryInstructions')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="deliveryInstructions"
                      placeholder="Add optional instructions like a gate code"
                      value={values.deliveryInstructions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={MAX_LENGTH_INSTRUCTION}
                    />
                    {hasError('deliveryInstructions') && (
                      <div className="invalid-feedback">
                        {errors['deliveryInstructions']}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-row form-group sms-consent">
                  <div className="col-md-12">
                    <CuiCheckbox
                      name="sms_consent"
                      checked={preferences.hasSmsEnable}
                      onChange={() => handlerSmsConsent()}
                    >
                      I agree to receive texts and updates on the latest
                      product launches, exclusive offers, and more!
                    </CuiCheckbox>
                    <p className="legal-message">
                      * I agree to receive recurring automated marketing text
                      messages (e.g. cart reminders) at the phone number
                      provided. Consent is not a condition to purchase. Msg &
                      data rates may apply. Msg frequency varies. Reply HELP
                      for help and STOP to cancel. View our{' '}
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_SITE_BASE_URL +
                          '/terms#text-messages'
                        }
                        rel="noreferrer"
                      >
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_SITE_BASE_URL +
                          '/privacy-policy#text-messages'
                        }
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="button-container">
                  <button
                    type="submit"
                    disabled={loading}
                    className="cui-button"
                  >
                    {!loading ? (
                      'Next'
                    ) : (
                      <i className="spinner fas fa-circle-notch" />
                    )}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </Collapse>
    </div>
  )
}
