export const filtersOptions = [
  {
    id: 'all',
    name: 'All',
    image: 'https://cu-product-media.s3.amazonaws.com/media/menu/all/all.svg',
  },
  {
    id: 'Seafood',
    name: 'Sea food',
    image: 'https://static.cookunity.com/cross/front-lib/icons/seafood.svg',
  },
  {
    id: 'Vegetarian',
    name: 'Vegetarian',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/vegetarian-icon.svg',
  },
  {
    id: 'Meat',
    name: 'Meat',
    image: 'https://static.cookunity.com/cross/front-lib/icons/meat.svg',
  },
  {
    id: 'Poultry',
    name: 'Poultry',
    image: 'https://static.cookunity.com/cross/front-lib/icons/poultry.svg',
  },
  {
    id: 'Vegan',
    name: 'Vegan',
    image: 'https://static.cookunity.com/cross/front-lib/icons/vegan.svg',
  },
  {
    id: 'Less Than 600 calories',
    name: 'Less Than 600 calories',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/low-calories.svg',
  },
  {
    id: 'Low Carbs',
    name: 'Low Carbs',
    image: 'https://static.cookunity.com/cross/front-lib/icons/low-carbs.svg',
  },
  {
    id: 'Gluten Free',
    name: 'Gluten Free',
    image: 'https://static.cookunity.com/cross/front-lib/icons/gluten-free.svg',
  },
  {
    id: 'Dairy Free',
    name: 'Dairy Free',
    image: 'https://static.cookunity.com/growth/media/icons/dairy-free.svg',
  },
  {
    id: 'Low Sodium',
    name: 'Low Sodium',
    image: 'https://static.cookunity.com/cross/front-lib/icons/low-sodium.svg',
  },
  {
    id: 'Keto Diet',
    name: 'Keto Diet',
    image: 'https://static.cookunity.com/cross/front-lib/icons/keto.svg',
  },
  {
    id: 'Paleo',
    name: 'Paleo',
    image: 'https://static.cookunity.com/cross/front-lib/icons/paleo.svg',
  },
]

export const recommendationsFilterOption = {
  id: 'recommendations',
  name: 'Recommendations',
  image:
    'https://static.cookunity.com/cross/front-lib/icons/review-stars-3.svg',
}

export const meatTypeMap = {
  Seafood: [
    'Catfish',
    'Cod',
    'Crab',
    'Other fish',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'fish',
    'Sea food',
    'Fish',
  ],
  Meat: ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork', 'Meat'],
  Poultry: ['Chicken', 'Turkey', 'Poultry'],
}
