import React, { useState } from 'react'
import useAuth0Api from '../../hooks/Auth0'
import { CuiLink } from 'front-lib'
import SignInPanel from '../../components/SignInPanel'
import SignInForm from '../../components/SignInForm/SignInForm'
import { CONNECTIONS, PAGES, migrateLogin } from '../../utils'
import styles from './Login.module.scss'
import { SignInPanelProps } from '../../types'

const BUTTON_LABELS = {
  form: 'Login with email',
  google: 'Login with Google',
  facebook: 'Login with Facebook',
  apple: 'Login with Apple',
}

const Login = ({ setPage, isPopup }: SignInPanelProps) => {
  const { login, socialLogin } = useAuth0Api()

  const [showForm, setShowForm] = useState(false)
  const [loginError, setLoginError] = useState('')

  const submit = (values: any) => {
    const loginInfo = {
      realm: CONNECTIONS.CU,
      email: values.email,
      password: values.password,
    }

    login(loginInfo, async (error: any) => {
      try {
        await migrateLogin(values)
        login(loginInfo)
      } catch (errorMigration) {
        const errorMessage =
          error?.description || error?.original?.response?.text
        setLoginError(errorMessage)
      }
    })
  }

  return (
    <SignInPanel
      form={
        <SignInForm
          setShowForm={setShowForm}
          handleSubmit={submit}
          submitLabel={'Log In'}
          customErrorMessage={loginError}
          subtitle={'Log in with email'}
        />
      }
      title="Welcome back!"
      labels={BUTTON_LABELS}
      showForm={showForm}
      setShowForm={setShowForm}
      socialSubmit={(connection: string) => {
        socialLogin(connection)
      }}
      isPopup={isPopup}
    >
      <div className={styles.extraActions}>
        <CuiLink
          data-testid="forgot-password"
          fill="clear"
          size="small"
          showUnderline={true}
          onClick={() => setPage(PAGES.FORGOT_PASSWORD)}
        >
          Forgot your password?
        </CuiLink>
        <CuiLink
          data-testid="create-account"
          fill="clear"
          size="small"
          showUnderline={true}
          onClick={() => setPage(PAGES.SIGN_UP)}
        >
          Create account
        </CuiLink>
      </div>
    </SignInPanel>
  )
}

export default Login
