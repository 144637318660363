import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { zipCodeSelector } from 'src/features/address/addressSlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import useRouter from 'src/hooks/useRouter'

const MealSelectionGuard = ({ children }) => {
  const { previousRoute } = useRouter()
  const zipCode = useSelector(zipCodeSelector)
  const planSelected = useSelector(planSelectedSelector)

  const isValid = zipCode && planSelected

  useEffect(() => {
    if (!isValid) {
      previousRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isValid && (children || <Outlet />)
}

export default React.memo(MealSelectionGuard)
