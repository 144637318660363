import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './Slide.scss'

const Slide = ({
  in: inProp,
  direction = 'left',
  timeout = 500,
  children,
  ...rest
}) => {
  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames={`slide-${direction}`}
      unmountOnExit
      {...rest}
    >
      {children}
    </CSSTransition>
  )
}

export default Slide
