export const EXPERIMENTS = {
  downloadApp: 'DOWNLOAD_APP_IN_THANK_YOU_PAGE',
  no4MealsPlan: 'gro-sc-71622-no-4-meals-plan',
  forYouExperiment: 'gro-sc-74124-for-you-recommendations',
  recaptchaFeatureFlag: 'signup-funnel-captcha',
  highlightedMealCard: 'gro-sc-32033-new-mealcard-funnel',
  highRiskZipCode: 'gro-gtt-4240-high-risk-zipcode',
  stripePaymentElement: 'stripe-payment-element',
  stripeApplePay: 'stripe-apple-pay',
  stripeGooglePay: 'stripe-google-pay',
  sneakPeek: 'gro-gtt-4241-show-menu-in-login-step',
  mealSearchBar: 'gro-gtt-3845-search-bar',
  resurrectionFunnel: 'growth-resurrection-funnel',
  redirectToMenu: 'redirect-reactivated-user-menu',
  simplifiedDietsQuizExperiment: 'gro-gtt-4201-simplified-diets-quiz',
  planInMealStep: 'gro-gtt-4210-plan-in-meal-step',
  quizHoldout: 'gro-gtt-4470-quiz-holdout',
  checkoutImprovements: 'gro-gtt-4437-checkout-improvements-quick-wins',
  checkoutRequiredFields: 'gro-gtt-4559-required-fields-checkout',
  eatEverythingAtTopOfQuiz: 'gro-gtt-3953-eat-everything-at-top-of-quiz',
  checkoutOrderSummaryRedesign: 'gro-gtt-4463-checkout-order-summary-redesign',
  changeCouponInCho: 'gro-gtt-4464-change-coupon-in-cho',
}

export const EXPERIMENTS_DATA = {
  [EXPERIMENTS.no4MealsPlan]: {
    id: 'gro-sc-71622',
    name: 'Growth - Sign Up - Hide 4 Meal Plan',
  },
  [EXPERIMENTS.forYouExperiment]: {
    id: 'gro-sc-74124',
    name: 'Growth - Sign Up - For You Recommendations',
  },
  [EXPERIMENTS.highlightedMealCard]: {
    id: 'gro-sc-32033',
    name: 'Growth - Sign Up - Highlight Row - New Meal Cards',
  },
  [EXPERIMENTS.sneakPeek]: {
    id: 'gro-gtt-4241',
    name: 'Growth - Sign Up - Show menu in login step',
  },
  [EXPERIMENTS.highRiskZipCode]: {
    id: 'gro-gtt-4240',
    name: 'Growth - Sign Up - High Risk Zip Code',
  },
  [EXPERIMENTS.mealSearchBar]: {
    id: 'gro-gtt-3845',
    name: 'Growth - Sign Up - Meal Search Bar',
  },
  [EXPERIMENTS.resurrectionFunnel]: {
    id: 'gro-sc-4169',
    name: 'Growth - Resurrection - Info Validation 24',
  },
  [EXPERIMENTS.simplifiedDietsQuizExperiment]: {
    id: 'gro-gtt-4201',
    name: 'Growth - Sign Up - Simplified Diets Quiz',
  },
  [EXPERIMENTS.planInMealStep]: {
    id: 'gro-gtt-4210',
    name: 'Growth - Sign Up - Plan in Meal Step',
  },
  [EXPERIMENTS.quizHoldout]: {
    id: 'gro-gtt-4470',
    name: 'Growth - Sign Up - Quiz Holdout',
  },
  [EXPERIMENTS.checkoutImprovements]: {
    id: 'gro-gtt-4437',
    name: 'Growth - Sign Up - Checkout Improvements',
  },
  [EXPERIMENTS.checkoutRequiredFields]: {
    id: 'gro-gtt-4559',
    name: 'Growth - Sign Up - Checkout Required Fields',
  },
  [EXPERIMENTS.eatEverythingAtTopOfQuiz]: {
    id: 'gro-gtt-3953',
    name: 'Growth - Sign Up - Eat Everything at Top',
  },
  [EXPERIMENTS.checkoutOrderSummaryRedesign]: {
    id: 'gro-gtt-4463',
    name: 'Growth - Sign Up - Checkout Order Summary Redesign',
  },
  [EXPERIMENTS.changeCouponInCho]: {
    id: 'gro-gtt-4464',
    name: 'Growth - Sign Up - Change coupon in Checkout',
  },
  [EXPERIMENTS.downloadApp]: {
    id: 'gtt-4793',
    name: 'Growth - Activation - Download App Thank You Page',
  },
}
