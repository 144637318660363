import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { trackingStart } from 'src/redux/ducks/commons/creators'
import {
  getSignUpInitData,
  getUserSelectedData,
} from 'src/redux/ducks/screens/signup/commons/selectors'
import { getCart } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'
import CULogo from '../../assets/icons/cu-yellow.svg'
import { formatChefName, formatFee, getLoadingContainer } from './utils'

import {
  CuiAvatar,
  CuiImage,
  CuiItem,
  CuiMealDescription,
  CuiMealImage,
  CuiQuantitySelector,
} from 'front-lib'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMobile } from 'src/utils/events'

import styles from './MealCardMini.scss'
import './MealCardMini.scss'
import images from './images'
import { useVariant } from '@unleash/proxy-client-react'
import { logError } from 'src/utils/logError'
import PremiumTag from './PremiumTag'

const CONTROLLERS_OPENED_MS = 2500
const basePathImg = process.env.REACT_APP_IMG_STORAGE
const MAX_QUANTITY = 30

const MealCardMini = ({
  meal,
  quantity,
  onAddItem,
  onRemoveItem,
  isEditable = true,
  disableAddItem,
  onMealClick,
  onChefClick,
  isLoading,
  hideCartControllers,
  cartData,
  userStartDay,
  listName,
  listType,
  storeData,
  actions,
  highlightedMeal,
  menuFilters,
  selectedMenuOption,
  index,
}) => {
  const {
    name = '',
    short_description = '',
    chef_firstname = '',
    chef_lastname = '',
    full_path_meal_image = null,
    full_path_chef_image = null,
    premium_fee = null,
    fixed_price = false,
    stock = 0,
  } = meal

  const [showCartControllers, setShowCartControllers] = useState(false)
  const [errorImage, setErrorImage] = useState(false)
  const [maxQuantity, setMaxQuantity] = useState(MAX_QUANTITY)

  const chefFullName = formatChefName(chef_firstname, chef_lastname)
  const premiumFeeString = formatFee(premium_fee, fixed_price)
  const selected = quantity > 0
  const disableAddItemBtn = disableAddItem || !isEditable || quantity >= stock
  const imageComingSoon = /no_selection|no-image|null|undefined/.test(
    full_path_meal_image,
  )

  const variant = useVariant('use-imgix')
  const isTreatment = variant && variant.enabled && variant.name === 'TREATMENT'

  const getOrdinal = number => {
    const suffixes = ['th', 'st', 'nd', 'rd']
    const v = number % 100
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
  }

  const handleAddItem = () => {
    if (hideCartControllers) {
      return
    }

    setShowCartControllers(true)
    if (!disableAddItemBtn) {
      const activeFilter =
        menuFilters?.meatTypeFilter ||
        menuFilters?.specificationsDetailFilter ||
        'all'

      sendTracking('Product Added', {
        cart_quantity_added: 1,
        cart_product_quantity: quantity + 1,
        cart_total_quantity: cartData.totalProductQty + 1,
        product_add_source: selectedMenuOption || 'Meals sets menu selection',
        list_name: highlightedMeal && 'Best Picks Meals Row',
        list_type: highlightedMeal && 'Highlighted Row',
        filters_applied: [{
          filter_group: 'All',
          filter_type: activeFilter,
          filter_value: 'For You'
        }],
        section: selectedMenuOption,
      })
      onAddItem()
    }
  }
  const handleRemoveItem = () => {
    if (hideCartControllers) {
      return
    }

    setShowCartControllers(true)
    sendTracking('Product Removed', {
      cart_quantity_removed: 1,
      cart_product_quantity: quantity - 1,
      cart_total_quantity: cartData.totalProductQty - 1,
      product_remove_source: 'Meals sets menu selection',
    })
    onRemoveItem()
  }

  const sendTracking = (eventName, params = {}) => {
    const stock = meal.stock ? meal.stock : 0
    const chefName = `${meal.chef_firstname || meal?.chef?.firstName || ''} ${meal.chef_lastname || meal?.chef?.lastName || ''}`
    const specificationsDetails = meal.specificationsDetails || meal.specifications_detail || []
    const premiumFee = meal.premium_fee || meal.premiumFee || 0
    const trackEvent = {
      eventName,
      eventData: {
        menu_store: storeData.name,
        delivery_date: userStartDay.date,
        list_name: listName,
        list_type: listType,
        product: {
          id: meal.entity_id,
          chef_name: chefName,
          category: meal.category_id || meal.categoryId,
          heat_instructions_microwave: null,
          heat_instructions_oven: null,
          image_url: meal.primaryImageUrl,
          ingredients: meal.ingredients
            .map(specification => specification.name)
            .join(', '),
          name: meal.name,
          nutritional_info: null,
          price: meal.price,
          rating: meal.stars,
          rating_count: meal.reviews,
          ratio_carb: meal.carbs,
          ratio_fat: meal.fat,
          ratio_protein: meal.protein,
          sku: meal.sku,
          specification: specificationsDetails
            .map(specification => specification.label)
            .join(', '),
          premium: premiumFee > 0,
        },
        product_initial_capacity: stock,
        product_remaining_capacity: stock - params.cart_product_quantity,
        screen_name: isMobile() ? 'Meals Selection' : null,
        page_name: isMobile() ? null : 'Meals Selection',
        ...params,
      },
    }

    actions.sendTracking(trackEvent)
  }

  useEffect(() => {
    setMaxQuantity(disableAddItemBtn ? quantity : MAX_QUANTITY)
  }, [disableAddItemBtn, quantity])

  useEffect(() => {
    const cartTimer = setTimeout(() => {
      setShowCartControllers(false)
    }, CONTROLLERS_OPENED_MS)
    return () => {
      clearTimeout(cartTimer)
    }
  }, [quantity, showCartControllers])

  if (isLoading) {
    return getLoadingContainer()
  }

  const chefProps = {
    className: styles.meal_card__chef_container,
  }

  if (typeof onChefClick === 'function') {
    chefProps.onClick = () => onChefClick()
    chefProps.className = classnames(styles.meal_card__chef_container, {
      [styles.linkable]: !!onChefClick,
    })
  }

  const handleMealClick = () => {
    if (typeof onMealClick === 'function') {
      onMealClick()
    }
  }

  const ChefAvatar = () => {
    const trackChefErrorImage = () => {
      logError(
        new Error(
          `Chef image error id: ${meal.chef_id}. Meal id: ${meal.entity_id}`,
          { message: 'error on MealCardMenu ChefAvatar' },
        ),
      )
    }

    return (
      <CuiItem
        className="cui_item_menu"
        data-testid="container-chef-image"
        onClick={() => onChefClick && onChefClick()}
      >
        <CuiAvatar
          data-testid="chef-image"
          border="rounded"
          src={full_path_chef_image}
          title={chefFullName}
          onError={trackChefErrorImage}
          loading="lazy"
        />
        {chefFullName && <p>by {chefFullName}</p>}
      </CuiItem>
    )
  }

  const RegularActions = () => (
    <div className="cui_actions">
      {!showCartControllers || !selected ? (
        <button
          onClick={() => {
            if (!hideCartControllers) {
              !selected ? handleAddItem() : setShowCartControllers(true)
            }
          }}
        >
          {!hasPremiumFee ? 'Add Meal' : 'Add'}{' '}
          {hasPremiumFee ? premiumFeeString : ''}
          {isEditable && quantity > 0 && <span>{quantity}</span>}
        </button>
      ) : (
        <CuiQuantitySelector
          onRemoveItem={() => quantity && handleRemoveItem()}
          onAddItem={() => handleAddItem()}
          value={quantity}
          max={maxQuantity}
          showBorder={true}
        />
      )}
    </div>
  )

  const trackMealErrorImage = () => {
    logError(
      new Error(`Meal image id: ${meal.entity_id}`, {
        message: 'error on MealCardMenu MealCard',
      }),
    )
  }

  const hasPremiumFee = premium_fee > 0
  const firstCard = index === 0

  return (
    <>
      <div
        className={classnames(`mealcard-new-mini`, {
          'first-meal-card': firstCard,
        })}
      >
        <div className="mealcard-new-mini__image">
          {hasPremiumFee && (
            <div className="cui_meal_card_premium_tag_wrapper">
              <PremiumTag />
            </div>
          )}
          <CuiMealImage onClick={handleMealClick}>
            {isTreatment ? (
              <CuiImage
                className={styles.cui_meal_card_image}
                onClick={handleMealClick}
                basePath={basePathImg}
                relativePath={meal.image_path + meal.image}
                noImageSrc={images.noMealImg}
                alt={imageComingSoon || errorImage ? 'no meal image' : name}
                loading="lazy"
                onError={trackMealErrorImage}
              />
            ) : (
              <CuiImage
                className={styles.cui_meal_card_image}
                onClick={handleMealClick}
                src={`${
                  imageComingSoon || errorImage
                    ? images.noMealImg
                    : full_path_meal_image
                }`}
                onError={() => {
                  trackMealErrorImage()
                  setErrorImage(true)
                }}
                alt={imageComingSoon || errorImage ? 'no meal image' : name}
                loading="lazy"
              />
            )}
          </CuiMealImage>
          <div className="cui-top-tag">
            <img src={CULogo} alt="cookunity" />
            <p> {getOrdinal(index + 1)}</p>
          </div>
        </div>
        <CuiMealDescription
          className="mealcard-new-description"
          onClick={handleMealClick}
        >
          <b>{name}</b> {short_description}
        </CuiMealDescription>
        {ChefAvatar()}

        {RegularActions()}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  cartData: getCart(state),
  storeData: getSignUpInitData(state).store,
  userStartDay: getUserSelectedData(state).startDay,
  menuFilters: getMenuFilter(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: trackingStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MealCardMini)
