import { put, call, takeEvery, all, select } from 'redux-saga/effects'
import * as actions from 'src/redux/ducks/modules/ProductDetail/actions'
import * as creators from 'src/redux/ducks/modules/ProductDetail/creators'
import * as signUpCommonsSelectors from 'src/redux/ducks/screens/signup/commons/selectors'

import * as api from 'src/api'
import Numeral from 'numeral'
import { logError } from 'src/utils/logError'

const getApplicationState = () => select(state => state)

function* pushEvent(mealData, payload) {
  const { isQueryPublicEnabled } = payload
  const mealDataFromQuery = isQueryPublicEnabled
    ? mealData.data.mealPublic
    : mealData.data.meal
  const meal = mealData.entity_id ? mealData : mealDataFromQuery
  try {
    if (window.google_tag_manager) {
      const applicationState = yield getApplicationState(),
        userSelectedData = signUpCommonsSelectors.getUserSelectedData(
          applicationState,
        ),
        { mealsPerDelivery, price, deliveries } = userSelectedData.selectedPlan,
        pricePerMeal = price / (mealsPerDelivery * deliveries)

      let dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'productView',
        MealName: meal.name,
        MealId: meal.entity_id || meal.mealExternalId,
        MealInventoryId: meal.inventoryId,
        MealPrice: Numeral(pricePerMeal).format('0.00'),
        MealImage: meal.image_full_url || meal.image,
        QuizRecommendation: payload.quizRecommendation || false,
        PositionInMenu: payload.position_in_menu || 0,
      })
    }
  } catch (err) {
    console.error('pushEvent::productView:', err)
  }
}

export function* loadProductDetail({ payload }) {
  try {
    const { isQueryPublicEnabled, entity_id, inventoryId } = payload
    const getMealQuery = isQueryPublicEnabled
      ? api.getMealDetailPublic
      : api.getMealDetail
    const mealId = isQueryPublicEnabled ? inventoryId : entity_id
    const meal = yield call(getMealQuery, mealId)

    yield pushEvent(meal, payload)

    yield put(
      creators.loadProductDetail(
        isQueryPublicEnabled ? meal.data.mealPublic : meal.data.meal,
      ),
    )
  } catch (error) {
    logError(error)
    yield put(creators.loadProductDetailFailed(error))
  }
}

export default function* rootInitialSaga() {
  yield all([takeEvery(actions.LOAD_PRODUCT_DETAIL.start, loadProductDetail)])
}
