import React from 'react'
import { Button } from 'src/components/Button'
import { CuiIcon } from 'front-lib'

export function ViewAllButton({ onClick, size }) {
  return (
    <Button color="dark" onClick={onClick} size={size}>
      <CuiIcon name="diagonalArrowRightUp" />
      View all
    </Button>
  )
}
