import {List} from "immutable";

import {
  IRecord as record
} from "src/redux/helpers/Immutable";

import duck from "./duck";
import * as actions from "./actions";

const initialState = List([
  record({
    products: record({
      start: false,
      success: false,
      data: {
        menu: { 
          meals: []
        },
        personalized: {    
          meals: []
        },
        highlightedMeals: {
          meals: []
        },
        highlight: {
          type: '',
          id: 0
        }
      },
      error: null
    }),
    filters: record({
      meatTypeFilter: 'all',
      specificationsDetailFilter: null,
      filterBy: { menu: {}, tab: {} },
      searchInput: '',
    })
  })
]);


const loadMenuReducer = {
  [actions.LOAD_PRODUCT.start]: (state) => {
    return state
    .setIn([0,"products", "start"], true)
    .setIn([0, "filters"], initialState.getIn([0, "filters"]))
  },
  [actions.LOAD_PRODUCT.success]: (state, {payload}) => {
    return state
      .setIn([0, "products", "start"], false)
      .setIn([0, "products", "success"], true)
      .setIn([0, "products", "data"], payload)
  },

  [actions.LOAD_PRODUCT.failed]: (state, {payload}) => {
    return state
      .setIn([0, "products", "start"], false)
      .setIn([0, "products", "success"], false)
      .setIn([0, "products", "data"], initialState.getIn([0, "products", "data"]))
      .setIn([0, "products", "error"], payload);
  },

  [actions.LOAD_PRODUCT.filter]: (state, {payload}) => {
    return state
      .setIn([0, "filters"], payload.filters)
      .setIn([0, "products", "data", "menu", "meals"], payload.meals)
      .setIn([0, "products", "data", "menu", "match"], payload.match);
  },

  [actions.LOAD_PRODUCT.filterReset]: (state, {payload}) => {
    return state
      .setIn([0, "filters"], initialState.getIn([0, "filters"]))
      .setIn([0, "products", "data", "menu", "meals"], payload.meals)
      .setIn([0, "products", "data", "menu", "match"], payload.match);
  },
  [actions.LOAD_PRODUCT.resetFilterBy]: (state) => {
    return state
      .setIn([0, "filters"], initialState.getIn([0, "filters"]))
  },
};

export default duck.createReducer({
  ...loadMenuReducer,
}, initialState);
