import React from 'react'
import './ForYou.scss'
import MealCardMini from 'src/components/MealCardMini/MealCardMini'
import QuizPreferencesSelected from '../QuizPreferencesSelected'
import ChooseForMe from '../ChooseForMe'
import { ForYouTitle } from '../ForYouTitle'
import { MealsSlider } from '../MealsSlider'
import { ViewAllButton } from '../ViewAllButton'
import { NoPreferencesSelected } from '../NoPreferencesSelected'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { authUserSelector } from 'src/features/auth/authSlice'
import { isMobileOrTablet } from 'front-lib'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import { ViewFullMenu } from '../ViewFullMenu'

export function ForYou({
  preferencesSettled,
  recommendedMeals,
  onClickViewAll,
  preferencesQuizResponses,
  setShowQuiz,
  onClickChooseForMe,
  totalOrderedProductsQty,
  maxProductToCart,
  isChooseForMeLoading,
  getProductDataOfCart,
  addProduct,
  removeProduct,
  onMealClick,
  getCategory,
  getOrderQty,
  storeName,
  bestSellerMeals,
  topRatedMeals,
  onClickViewFullMenu,
  allMealsCount,
}) {
  const { isTreatment: quizHoldoutIsTreatment } = useExperiment(
    EXPERIMENTS.quizHoldout,
  )

  const authUser = useSelector(authUserSelector)

  return (
    <div className="for-you">
      <p className="for-you__welcome-message">
        {authUser ? `Welcome ${authUser?.given_name}! 👋` : 'Welcome! 👋'}
      </p>

      {/* RECOMMENDED SECTION */}
      {preferencesSettled && (
        <>
          <ForYouTitle
            title={`${isMobileOrTablet() ? 'Here are' : "We've selected"} ${
              recommendedMeals.length
            } delicious meals you will love`}
            onClick={() => onClickViewAll('recommended')}
          />
          <div className="for-you__preferences">
            <QuizPreferencesSelected
              preferencesToShow={preferencesQuizResponses}
              setShowQuiz={setShowQuiz}
            />
            <div className="only-desktop">
              <ChooseForMe
                onClickChooseForMe={onClickChooseForMe}
                totalOrderedProductsQty={totalOrderedProductsQty}
                maxProductToCart={maxProductToCart}
                isChooseForMeLoading={isChooseForMeLoading}
              />
            </div>
          </div>

          <MealsSlider
            addProduct={addProduct}
            removeProduct={removeProduct}
            onMealClick={onMealClick}
            maxProductToCart={maxProductToCart}
            totalOrderedProductsQty={totalOrderedProductsQty}
            getCategory={getCategory}
            selectedProducts={getProductDataOfCart}
            meals={recommendedMeals}
            getOrderQty={getOrderQty}
            selectedMenuOption={'For You'}
            maxMeals={11}
            lastElement={
              <div className="for-you__view-all--centered">
                <ViewAllButton onClick={() => onClickViewAll('recommended')} />
              </div>
            }
          />

          <div className="only-mobile fill-cart">
            <ChooseForMe
              onClickChooseForMe={onClickChooseForMe}
              totalOrderedProductsQty={totalOrderedProductsQty}
              maxProductToCart={maxProductToCart}
              isChooseForMeLoading={isChooseForMeLoading}
              size={'large'}
            />
            <p className="fill-cart__text">
              Try filling your cart with our recommendations based on your
              preferences!
            </p>
          </div>
        </>
      )}

      {/* BEST SELLERS SECTION */}
      <div
        className={classNames('for-you__best-sellers', {
          'for-you__best-sellers--margin-top': preferencesSettled,
        })}
      >
        <ForYouTitle
          title={
            <>
              Our top 20 best sellers in{' '}
              <span className="for-you__title--orange">{storeName}</span>
            </>
          }
          onClick={() => onClickViewAll('bestSellers')}
        />
        <MealsSlider
          addProduct={addProduct}
          removeProduct={removeProduct}
          onMealClick={onMealClick}
          maxProductToCart={maxProductToCart}
          totalOrderedProductsQty={totalOrderedProductsQty}
          getCategory={getCategory}
          selectedProducts={getProductDataOfCart}
          meals={bestSellerMeals}
          getOrderQty={getOrderQty}
          selectedMenuOption={'Best Sellers'}
          maxMeals={11}
          lastElement={
            <div className="for-you__view-all--centered for-you__view-all--mini-meal-card-width">
              <ViewAllButton
                onClick={() => onClickViewAll('bestSellers')}
                size="small"
              />
            </div>
          }
          mealCard={MealCardMini}
          showOrdinals={true}
        />
      </div>

      {/* NO PREFERENCES SELECTED SECTION */}
      {!preferencesSettled && !quizHoldoutIsTreatment && (
        <div className="for-you__no-preferences">
          <NoPreferencesSelected onClick={() => setShowQuiz(true)} />
        </div>
      )}

      {/* TOP RATED SECTION */}
      <div
        className={classNames('for-you__top-rated', {
          'for-you__top-rated--no-preferences-distance': !preferencesSettled,
        })}
      >
        <ForYouTitle
          title="Must-try meals from our amazing chefs 👩‍🍳"
          onClick={() => onClickViewAll('topRated')}
        />
        <MealsSlider
          addProduct={addProduct}
          removeProduct={removeProduct}
          onMealClick={onMealClick}
          maxProductToCart={maxProductToCart}
          totalOrderedProductsQty={totalOrderedProductsQty}
          getCategory={getCategory}
          selectedProducts={getProductDataOfCart}
          meals={topRatedMeals}
          getOrderQty={getOrderQty}
          selectedMenuOption={'Top Rated'}
          maxMeals={11}
          lastElement={
            <div className="for-you__view-all--centered">
              <ViewAllButton onClick={() => onClickViewAll('topRated')} />
            </div>
          }
        />
      </div>

      {/* SHOW ALL MEALS FOOTER */}
      <div className="for-you__show-all-meals-footer">
        <ViewFullMenu
          mealsCount={allMealsCount}
          onClickFullMenu={onClickViewFullMenu}
        />
      </div>
    </div>
  )
}
