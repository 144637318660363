import duck from "./duck";
import * as actionCreators from "./actions";

export const startAddCart = duck.createAction(actionCreators.CART.startAdd);
export const addCart = duck.createAction(actionCreators.CART.add);
export const startRemoveCart = duck.createAction(actionCreators.CART.startRemove);
export const removeCart = duck.createAction(actionCreators.CART.remove);
export const resetCart = duck.createAction(actionCreators.CART.reset);
export const setPreviousSelectionCart = duck.createAction(actionCreators.CART.setPreviousSelectionCart);
export const setCartData = duck.createAction(actionCreators.CART.setCartData);
export const setPreselectError = duck.createAction(actionCreators.CART.setPreselectError);
export const startChooseForMe = duck.createAction(actionCreators.CHOOSE_FOR_ME.start);
export const successChooseForMe = duck.createAction(actionCreators.CHOOSE_FOR_ME.success);
export const startUndoChooseForMe = duck.createAction(actionCreators.CHOOSE_FOR_ME.startUndo);
export const startRefreshChooseForMe = duck.createAction(actionCreators.CHOOSE_FOR_ME.startRefresh);
