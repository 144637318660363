import { isEmpty } from 'ramda'
import { logError } from 'src/utils/logError'

const TASTES = {
  Beef: ['Beef', 'Lamb', 'Pork'],
  Seafood: [
    'Catfish',
    'Cod',
    'Crab',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'Fish',
  ],
  Poultry: ['Chicken', 'Turkey', 'Duck'],
}

const QUIZ_TASTES = {
  ...TASTES,
  Pork: ['Pork'],
  Vegan: ['Vegan'],
  Vegetarian: ['Vegetarian'],
}

const ADDONS_CATEGORY_IDS = [6, 11, 13]

export function filter(data = {}) {
  const { allMeals, type, searchInput } = data
  let result = null

  if (isEmpty(allMeals)) return
  if (type === 'meatType' || type === 'menu') {
    result = filterMeatType(data)
  } else {
    result = filterSpecificationsDetail(data)
  }

  if (searchInput) {
    result.meals = filterSearchInput(result, searchInput)
  }

  return result
}

function filterMeatType({ allMeals, id, searchInput }) {
  if (id === 'all' || id === 'forYou')
    return {
      meals: [...allMeals],
      filters: {
        meatTypeFilter: id,
        specificationsDetailFilter: '',
        searchInput,
      },
    }

  if (id === 'recommendations')
    return {
      meals: filterRecommendedMeals(allMeals),
      filters: {
        meatTypeFilter: id,
        specificationsDetailFilter: '',
        searchInput,
      },
    }

  let filteredMeals = []
  TASTES[id].map(taste => {
    let filtered = allMeals.filter(meal => meal.meat_type === taste)
    return filteredMeals.push(...filtered)
  })

  return {
    meals: [...filteredMeals],
    filters: {
      meatTypeFilter: id,
      specificationsDetailFilter: '',
      searchInput,
    },
  }
}

function filterSpecificationsDetail({ allMeals, id, searchInput }) {
  const filteredMeals = allMeals.filter(meal => {
    let check =
      Array.isArray(meal.specifications_detail) &&
      meal.specifications_detail.find(specification => {
        return specification.label === id
      })

    return !!check
  })

  return {
    meals: filteredMeals,
    filters: {
      specificationsDetailFilter: id,
      meatTypeFilter: '',
      searchInput,
    },
  }
}

function filterSearchInput(result, searchInput) {
  const FILTER_BY_PROPERTIES = [
    'name',
    'short_description',
    'chef_firstname',
    'chef_lastname',
  ]

  return result.meals.filter(item =>
    FILTER_BY_PROPERTIES.some(property =>
      item[property]?.toLowerCase().includes(searchInput.toLowerCase()),
    ),
  )
}

function filterRecommendedMeals(allMeals) {
  return allMeals.filter(meal => meal.matches_preferences)
}

export function matchesWithPreferences(meal = {}, preferences = []) {
  let match = false
  try {
    preferences.forEach(preference => {
      if (QUIZ_TASTES[preference])
        QUIZ_TASTES[preference].forEach(taste => {
          if (meal.meat_type === taste) {
            match = true
          }
        })
      if (Array.isArray(meal.specifications_detail))
        meal.specifications_detail.forEach(specification => {
          if (specification.label === preference) {
            match = true
          }
        })
    })
  } catch (error) {
    logError(error)
  }
  return match
}

export function orderMealSelectedWithFirstPosition(
  selectedMeals = [],
  meals = [],
) {
  if (!selectedMeals || selectedMeals.length === 0) return meals

  return meals.sort((a, b) => {
    const findMealA = selectedMeals.find(item => item.entity_id === a.entity_id)
    const findMealB = selectedMeals.find(item => item.entity_id === b.entity_id)

    if (findMealA !== undefined) return -1
    if (findMealB !== undefined) return 1

    return 0
  })
}

export function keepPreviousSelectionCart(
  meals = [],
  previousSelectionData = [],
) {
  if (!meals.length || !previousSelectionData.length) return []

  return previousSelectionData.reduce((acc, product) => {
    const meal = meals.find(meal => meal.entity_id === product.data.entity_id)

    if (meal && meal.stock >= product.qty) {
      acc.push({ ...product, data: { ...meal } })
    }

    return acc
  }, [])
}

export function transformMenuServiceResponse(meals = []) {
  return meals.map(meal => {
    return {
      isPremium: meal.isPremium,
      premiumFee: meal.premiumFee,
      isIncludedInEconomicPlan: meal.isIncludedInEconomicPlan,
      bannerpic: meal.chef.bannerPic,
      chef_id: meal.chef.id === -1 ? null : meal.chef.id,
      chef_firstname: meal.chef.firstName,
      chef_lastname: meal.chef.lastName,
      is_celebrity_chef: meal.chef.isCelebrityChef,
      primaryImageUrl: meal.primaryImageUrl,
      promotions: meal.promotions,
      ...genericMealObject(meal),
    }
  })
}

export function genericMealObject(meal) {
  return {
    batch_id: meal.batchId,
    entity_id: meal.id,
    image: meal.image,
    image_path: meal?.image_path || meal?.imagePath,
    meat_type: meal.meatType,
    name: meal.name,
    stars: meal.stars,
    stock: meal.stock,
    weight: meal.weight,
    sku: meal.sku,
    premium_fee: meal.premiumFee,
    premium_special: meal.premiumSpecial,
    cuisines: meal.cuisines,
    filter_by: meal.filter_by,
    reviews: meal.reviews,
    specifications_detail: meal.specificationsDetails,
    allergens: meal.allergens,
    price: meal.price,
    sidedish: meal.sideDish,
    short_description: meal.shortDescription,
    category_id: meal.categoryId,
    warning: meal.warning ? meal.warning : '',
    feature: meal.feature ? meal.feature : null,
    ingredients: meal.ingredients,
    calories: +meal.nutritionalFacts.calories,
    carbs: +meal.nutritionalFacts.carbs,
    fat: +meal.nutritionalFacts.fat,
    protein: +meal.nutritionalFacts.protein,
    inventoryId: meal.inventoryId,
  }
}

export function transformMealsWithDetails(
  meals = [],
  proteins = [],
  diets = [],
  useMealServiceAllergens,
  specificationsAvoid = [],
  allergies = [],
) {
  let displayedMealsIds = []

  const mealsFilteredByAddons = filterMealsByAddons(meals)

  const mealsFilteredByAllergies = filterMealsByAllergies(
    mealsFilteredByAddons,
    useMealServiceAllergens,
    specificationsAvoid,
    allergies,
  )

  const mealsMapped = mealsFilteredByAllergies.map((meal, i) => {
    displayedMealsIds.push(meal.entity_id.toString())

    const matchesWithProteins =
      proteins.length > 0 &&
      matchesWithPreferences(
        meal,
        proteins.map(protein => protein.name),
      )

    const matchesWithDiets =
      diets.length > 0 &&
      matchesWithPreferences(
        meal,
        diets.map(diet => diet.name),
      )

    return {
      ...meal,
      position_in_menu: i + 1,
      specifications_detail: meal.specifications_detail || [],
      matches_preferences: matchesWithProteins || matchesWithDiets,
    }
  })
  return { mealsMapped, displayedMealsIds }
}

export function filterMealsByAllergies(
  meals = [],
  useMealServiceAllergens,
  specificationsAvoid = [],
  allergies = [],
) {
  if (!useMealServiceAllergens) {
    const allergiesToWant = specificationsAvoid.filter(
      allergy => allergy.filter,
    )
    const allergiesToAvoid = specificationsAvoid.filter(
      allergy => !allergy.filter,
    )
    return meals.filter(meal => {
      const result =
        isMealAllergiesToWant(meal, allergiesToWant) &&
        isMealAllergiesToAvoid(meal, allergiesToAvoid)
      return result
    })
  }

  const filteredMeals = meals.filter(meal => {
    const hasAllergens = !(meal.allergens || [])
      .map(ma => ma.id)
      .some(mealAllergen =>
        (allergies || []).map(a => a.id).includes(mealAllergen),
      )
    return hasAllergens
  })
  return filteredMeals
}

function isMealAllergiesToWant(meal, allergies) {
  return allergies.every(allergy => {
    return meal.specifications_detail.some(specificationDetail => {
      return (
        specificationDetail.label.toLowerCase() === allergy.name.toLowerCase()
      )
    })
  })
}

function isMealAllergiesToAvoid(meal, allergies) {
  return !allergies.some(allergy => {
    return meal.specifications_detail.some(specificationDetail => {
      return (
        specificationDetail.label.toLowerCase() === allergy.name.toLowerCase()
      )
    })
  })
}

function filterMealsByAddons(meals = []) {
  return meals?.filter((meal) => !ADDONS_CATEGORY_IDS.includes(Number(meal?.category_id)))
}
