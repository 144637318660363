import { IRecord as record } from 'src/redux/helpers/Immutable'

import duck from './duck'
import * as actions from './actions'

import { breadPathList } from 'src/redux/ducks/screens/signup/utils'

const formatError = payload =>
  typeof payload === 'string' ? { error: payload, code: null } : payload

const initialState = record({
  forceSkipQuiz: false,
  experiments: record({
    start: false,
    success: false,
    data: {
      list: [],
      object: {},
    },
    error: null,
  }),

  registerRequestFlow: false,

  showTopBar: true,

  breadPath: breadPathList,
  currentStepName: 'Membership',
  afterElement: null,

  seoData: record({
    title: 'CookUnity',
  }),

  initData: record({
    start: false,
    success: false,
    data: {
      mealPlans: null,
      deliveryDays: [],
      ring: {
        id: null,
        isLocal: false,
        packaging: null,
        storeId: null,
        tax: null,
      },
      upcomingDays: [],
      selectedPlan: null,
      timeslot: {
        start: null,
        end: null,
        label: null,
      },
      timeslots: [],
      days: [],
      startDay: null,
      sharedMealId: null,
    },
    error: null,
    forceLoading: true
  }),

  coupon: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  userSelectedData: record({
    zipcode: '',
    email: '',
    utm: '',
    startDay: {},
    days: [],
    selectedTimesLot: null,
    deliveryOption: null,
    selectedPlan: null,
    flow: 'eat-everything',
    dietaryRestrictions: [],
    summary: {},
    taxesAmount: 0,
    searchMealsIds: [],
    highlightedMealsIds: [],
    isZipcodeNotReached: false,
  }),

  createUser: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  createOrder: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  preferencesQuiz: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  preferencesQuizResponses: {},

  chefsList: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  chefDetail: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  menuSortBy: record({
    start: false,
    success: false,
    data: null,
    error: null,
  }),

  ddoToggles: [],
})

const showTopBarReducer = {
  [actions.SHOW_TOP_BAR.success]: (state, { payload }) => {
    return state.set('showTopBar', payload)
  },
}

const initDataReducer = {
  [actions.INIT_DATA.start]: state => {
    return state
      .setIn(['initData', 'start'], true)
  },
  [actions.INIT_DATA.success]: (state, { payload }) => {
    return state
      .setIn(['initData', 'start'], false)
      .setIn(['initData', 'success'], true)
      .setIn(['initData', 'data'], payload)
  },

  [actions.INIT_DATA.failed]: (state, { payload }) => {
    return state
      .setIn(['initData', 'start'], false)
      .setIn(['initData', 'success'], false)
      .setIn(['initData', 'data'], initialState.getIn(['initData', 'data']))
      .setIn(['initData', 'error'], payload)
  },

  [actions.INIT_DATA.loading]: (state, { payload }) => {
    return state
      .setIn(['initData', 'forceLoading'], payload)
  },
}

const userSelectedDataReducer = {
  [actions.USER_SELECTED_DATA.set]: (state, { payload }) => {
    return state.set(
      'userSelectedData',
      record({
        ...state.get('userSelectedData').toJS(),
        ...payload,
      }),
    )
  },
  [actions.TAX_RATE_ZIP_CODE.set]: (state, { payload }) => {
    return state.setIn(
      ['userSelectedData', 'taxesAmount'], payload
    )
  }
}

const couponReducer = {
  [actions.APPLY_COUPON.start]: state => {
    return state
      .setIn(['coupon', 'error'], null)
      .setIn(['coupon', 'start'], true)
  },
  [actions.APPLY_COUPON.success]: (state, { payload }) => {
    return state
      .setIn(['coupon', 'start'], false)
      .setIn(['coupon', 'success'], true)
      .setIn(['coupon', 'data'], payload)
      .setIn(['coupon', 'error'], null)
  },

  [actions.APPLY_COUPON.failed]: (state, { payload }) => {
    return state
      .setIn(['coupon', 'start'], false)
      .setIn(['coupon', 'success'], false)
      .setIn(['coupon', 'error'], payload)
  },

  [actions.APPLY_COUPON.reset]: state => {
    return state
      .setIn(['coupon', 'start'], initialState.getIn(['coupon', 'start']))
      .setIn(['coupon', 'success'], initialState.getIn(['coupon', 'success']))
      .setIn(['coupon', 'error'], initialState.getIn(['coupon', 'error']))
  },
}

const breadPathReducers = {
  [actions.BREAD_PATH.set]: (state, { payload }) => {
    return state.set('breadPath', payload)
  },
  [actions.BREAD_PATH.setCurrentStepName]: (state, { payload }) => {
    return state.set('currentStepName', payload)
  },
  [actions.BREAD_PATH.setAfterElement]: (state, { payload }) => {
    return state.set('afterElement', payload)
  },
}

const seoDataReducers = {
  [actions.SEO_DATA.addSeoData]: (state, { payload }) => {
    return state.set(
      'seoData',
      record({
        ...state.get('seoData').toJS(),
        ...payload,
      }),
    )
  },
}

const createUserReducer = {
  [actions.CREATE_USER.start]: state => {
    return state.setIn(['createUser', 'start'], true)
  },
  [actions.CREATE_USER.success]: (state, { payload }) => {
    return state
      .setIn(['createUser', 'start'], false)
      .setIn(['createUser', 'success'], true)
      .setIn(['createUser', 'data'], payload)
  },

  [actions.CREATE_USER.failed]: (state, { payload }) => {
    return state
      .setIn(['createUser', 'start'], false)
      .setIn(['createUser', 'success'], false)
      .setIn(['createUser', 'data'], initialState.getIn(['createUser', 'data']))
      .setIn(['createUser', 'error'], payload)
  },

  [actions.CREATE_USER.reset]: state => {
    return state.set('createUser', initialState.getIn(['createUser']))
  },
}

const createOrderReducer = {
  [actions.CREATE_ORDER.start]: state => {
    return state.setIn(['createOrder', 'start'], true)
  },
  [actions.CREATE_ORDER.success]: (state, { payload }) => {
    return state
      .setIn(['createOrder', 'start'], false)
      .setIn(['createOrder', 'success'], true)
      .setIn(['createOrder', 'data'], payload)
  },

  [actions.CREATE_ORDER.failed]: (state, { payload }) => {
    return state
      .setIn(['createOrder', 'start'], false)
      .setIn(['createOrder', 'success'], false)
      .setIn(
        ['createOrder', 'data'],
        initialState.getIn(['createOrder', 'data']),
      )
      .setIn(['createOrder', 'error'], formatError(payload))
  },

  [actions.CREATE_ORDER.reset]: state => {
    return state.set('createOrder', initialState.getIn(['createOrder']))
  },
}

const registerRequestFlowReducer = {
  [actions.REGISTER_REQUEST_FLOW.start]: state => {
    return state.set('registerRequestFlow', true)
  },

  [actions.REGISTER_REQUEST_FLOW.success]: state => {
    return state.set('registerRequestFlow', false)
  },
}

const initPreferencesQuiz = {
  [actions.INIT_PREFERENCES_QUIZ.start]: state => {
    return state.setIn(['preferencesQuiz', 'start'], true)
  },
  [actions.INIT_PREFERENCES_QUIZ.success]: (state, { payload }) => {
    return state
      .setIn(['preferencesQuiz', 'start'], false)
      .setIn(['preferencesQuiz', 'success'], true)
      .setIn(['preferencesQuiz', 'data'], payload)
  },

  [actions.INIT_PREFERENCES_QUIZ.failed]: (state, { payload }) => {
    return state
      .setIn(['preferencesQuiz', 'start'], false)
      .setIn(['preferencesQuiz', 'success'], false)
      .setIn(
        ['preferencesQuiz', 'data'],
        initialState.getIn(['preferencesQuiz', 'data']),
      )
      .setIn(['preferencesQuiz', 'error'], payload)
  },
}

const preferencesQuizReducers = {
  [actions.PREFERENCES_QUIZ_RESPONSE.setResponse]: (state, { payload }) => {
    return state.set('preferencesQuizResponses', {
      ...state.get('preferencesQuizResponses'),
      ...payload,
    })
  },
  [actions.PREFERENCES_QUIZ_RESPONSE.resetResponse]: state => {
    return state.set('preferencesQuizResponses', {})
  },
}

const experimentsReducer = {
  [actions.EXPERIMENTS.start]: state => {
    return state
      .setIn(['experiments', 'error'], null)
      .setIn(['experiments', 'start'], true)
  },
  [actions.EXPERIMENTS.success]: (state, { payload }) => {
    return state
      .setIn(['experiments', 'start'], false)
      .setIn(['experiments', 'success'], true)
      .setIn(['experiments', 'data'], payload)
  },

  [actions.EXPERIMENTS.failed]: (state, { payload }) => {
    return state
      .setIn(['experiments', 'start'], false)
      .setIn(['experiments', 'success'], false)
      .setIn(['experiments', 'error'], payload)
  },

  [actions.EXPERIMENTS.reset]: state => {
    return state
      .setIn(
        ['experiments', 'start'],
        initialState.getIn(['experiments', 'start']),
      )
      .setIn(
        ['experiments', 'success'],
        initialState.getIn(['experiments', 'success']),
      )
      .setIn(
        ['experiments', 'error'],
        initialState.getIn(['experiments', 'error']),
      )
  },
}

const forcedSkipQuizReducer = {
  [actions.FORCED_SKIP_QUIZ.success]: (state, { payload }) => {
    return state.setIn(['forceSkipQuiz'], payload)
  },
}

const chefsListReducer = {
  [actions.CHEFS_LIST.start]: state => {
    return state.setIn(['chefsList', 'start'], true)
  },
  [actions.CHEFS_LIST.success]: (state, { payload }) => {
    return state
      .setIn(['chefsList', 'start'], false)
      .setIn(['chefsList', 'success'], true)
      .setIn(['chefsList', 'data'], {
        ...state.getIn(['chefsList', 'data']),
        ...payload,
      })
      .setIn(['chefsList', 'error'], initialState.getIn(['chefsList', 'error']))
  },
  [actions.CHEFS_LIST.failed]: (state, { payload }) => {
    return state
      .setIn(['chefsList', 'start'], false)
      .setIn(['chefsList', 'success'], false)
      .setIn(['chefsList', 'data'], initialState.getIn(['chefsList', 'data']))
      .setIn(['chefsList', 'error'], payload)
  },
  [actions.CHEFS_LIST.reset]: (state) => {
    return state
      .setIn(['chefsList', 'start'], initialState.getIn(['chefsList', 'start']))
      .setIn(
        ['chefsList', 'success'],
        initialState.getIn(['chefsList', 'success']),
      )
      .setIn(['chefsList', 'data'], initialState.getIn(['chefsList', 'data']))
      .setIn(['chefsList', 'error'], initialState.getIn(['chefsList', 'error']))
  },
}


const chefDetailReducer = {
  [actions.LOAD_CHEF_DETAIL.start]: (state) => {
    return state
      .setIn(["chefDetail", "start"], true);
  },
  [actions.LOAD_CHEF_DETAIL.success]: (state, { payload }) => {
    return state
      .setIn(["chefDetail", "start"], false)
      .setIn(["chefDetail", "success"], true)
      .setIn(["chefDetail", "data"], payload);
  },

  [actions.LOAD_CHEF_DETAIL.failed]: (state, { payload }) => {
    return state
      .setIn(["chefDetail", "start"], false)
      .setIn(["chefDetail", "success"], false)
      .setIn(["chefDetail", "data"], initialState.getIn(["chefDetail", "data"]))
      .setIn(["chefDetail", "error"], payload);
  },

  [actions.LOAD_CHEF_DETAIL.reset]: (state) => {
    return state
      .setIn(["chefDetail"], initialState.getIn(["chefDetail"]));
  },
};

const menuSortBylReducer = {
  [actions.LOAD_MENU_SORT_BY.start]: (state) => {
    return state
      .setIn(["menuSortBy", "start"], true);
  },
  [actions.LOAD_MENU_SORT_BY.success]: (state, {payload}) => {
    return state
      .setIn(["menuSortBy", "start"], false)
      .setIn(["menuSortBy", "success"], true)
      .setIn(["menuSortBy", "data"], payload);
  },

  [actions.LOAD_MENU_SORT_BY.failed]: (state, {payload}) => {
    return state
      .setIn(["menuSortBy", "start"], false)
      .setIn(["menuSortBy", "success"], false)
      .setIn(["menuSortBy", "data"], initialState.getIn(["menuSortBy", "data"]))
      .setIn(["menuSortBy", "error"], payload);
  },

  [actions.LOAD_MENU_SORT_BY.reset]: (state) => {
    return state
      .setIn(["menuSortBy"], initialState.getIn(["menuSortBy"]));
  },
};


const searchMealsIdReducers = {
  [actions.SEARCH_MEALS_IDS.reset]: (state, { payload }) => {
    return state.setIn(['userSelectedData', 'searchMealsIds'], payload)
  },
  [actions.SEARCH_MEALS_IDS.reset]: state => {
    return state.setIn(
      ['userSelectedData', 'searchMealsIds'],
      initialState.getIn(['userSelectedData', 'searchMealsIds']),
    )
  },
}

const ddoTogglesReducers = {
  [actions.DDO_TOGGLES.set]: (state, { payload }) => {
    return state.setIn(['ddoToggles'], payload)
  },
}

export default duck.createReducer(
  {
    ...initDataReducer,
    ...userSelectedDataReducer,
    ...couponReducer,
    ...breadPathReducers,
    ...seoDataReducers,
    ...createUserReducer,
    ...createOrderReducer,
    ...showTopBarReducer,
    ...registerRequestFlowReducer,
    ...initPreferencesQuiz,
    ...preferencesQuizReducers,
    ...experimentsReducer,
    ...forcedSkipQuizReducer,
    ...chefsListReducer,
    ...chefDetailReducer,
    ...menuSortBylReducer,
    ...searchMealsIdReducers,
    ...ddoTogglesReducers,
  },
  initialState,
)
