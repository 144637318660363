import { all } from 'redux-saga/effects'
import rootCommonSaga from 'src/redux/ducks/commons/saga'

import rootModuleMenuListModuleSaga from 'src/redux/ducks/modules/MenuList/saga';
import rootModuleProductDetailModuleSaga from 'src/redux/ducks/modules/ProductDetail/saga';

import rootSignUpScreenSaga from 'src/redux/ducks/screens/signup/commons/saga';
import rootSignUpCheckoutPageSaga from 'src/redux/ducks/screens/signup/pages/Checkout/saga';
import rootSignUpPreOrderPageSaga from 'src/redux/ducks/screens/signup/pages/MealsStep/saga';

import rootDeliverySettingPageSaga from 'src/redux/ducks/screens/signup/pages/DeliverySettings/sagas';
import rootThankYouPageSaga from 'src/redux/ducks/screens/signup/pages/ThankYou/sagas';

export default function* rootSaga() {
  yield all([
    rootCommonSaga(),

    rootModuleMenuListModuleSaga(),
    rootModuleProductDetailModuleSaga(),

    rootSignUpScreenSaga(),
    rootSignUpCheckoutPageSaga(),
    rootSignUpPreOrderPageSaga(),

    rootDeliverySettingPageSaga(),
    rootThankYouPageSaga(),
  ])
}
