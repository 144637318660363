import { isMobileOrTablet } from 'front-lib'
import { subscriptionMutate, subscriptionPublicQuery } from '../../api/client'

const subscriptionOriginType = isMobileOrTablet()
  ? 'customer_web_mobile'
  : 'customer_web_desktop'

const couponQuery = `
query coupon($code: String!) {
  coupon(coupon: $code) {
    source
    expiry
    promotion {
      id
      title
      promotionType
      discount {
        rate
        duration
        type
        typeOfUse
      }
      credit {
        amount
      }
    }
    restrictions {
      notAvailablePlans
      notAvailableDays {
        store
        days
      }
    }
    isEligible {
      isEligible
      message
      type
    }
  }
}
`

const taxRateByZipCode = `
query taxRateByZipCode($zipCode: Int) {
  taxRate: taxrateFromZipcode(zipcode: $zipCode)
}
`

const consumeCouponQuery = `
mutation consumeCoupon($couponCode: String!, $isResurrected: Boolean!) {
    consumeCoupon(coupon: $couponCode, isResurrected: $isResurrected) {
      __typename
      ... on GenericResponse {
        result
      }
      ... on GenericError {
        error
      }
    }
  }
`

const createOrderMutation = `
mutation createOrder($order: CreateOrderInput!, $origin: OperationOrigin) {
  createOrder(order: $order, origin: $origin) {
    __typename
    ... on OrderCreation{
        id
    },
    ... on OrderCreationError {
        error,
        outOfStockIds
    }
  }
}
`

export const validateCoupon = ({ code }) =>
  subscriptionPublicQuery(couponQuery, { code })

export const getTaxRateByZipCode = ({ zipCode }) =>
  subscriptionPublicQuery(taxRateByZipCode, { zipCode })

export const consumeCoupon = ({ couponCode, isResurrected }) =>
  subscriptionMutate(consumeCouponQuery, { couponCode, isResurrected })

export const createOrder = ({ order }) =>
  subscriptionMutate(createOrderMutation, { order, origin: subscriptionOriginType })
