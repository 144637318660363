import React, { useEffect } from 'react'

import './ConfirmOrder.scss'

import { CuiHeader } from 'front-lib'
import { useDispatch, useSelector } from 'react-redux'
import GutterCui from 'src/components/GutterCui/GutterCui'
import {
  addressSelector,
  ringSelector,
  zipCodeSelector,
} from 'src/features/address/addressSlice'
import {
  assignCoupon,
  couponSelector,
  orderCreatedSelector,
  requestCreateOrder,
} from 'src/features/cart/cartSlice'
import {
  deliveryDaySelectedSelector,
  timeSlotSelectedSelector,
  timeSlotsStatusSelector,
} from 'src/features/delivery/deliverySlice'
import { setLoader } from 'src/features/loader/loaderSlice'
import { mealsSelectedSelector } from 'src/features/meals/mealsSlice'
import {
  requestSubscriptionReactivation,
  subscriptionReactivatedSelector,
} from 'src/features/user/userSlice'
import useRouter from 'src/hooks/useRouter'
import useTrackEvent from 'src/hooks/useTrackEvent'
import back from '../../assets/icons/back_arrow.png'
import ConfirmAddressCui from './components/ConfirmAddress'
import ConfirmDeliveryWindowCui from './components/ConfirmDeliveryWindow'
import ConfirmMealsCartCui from './components/ConfirmMealsCart'
import ConfirmSummaryCui from './components/ConfirmSummary'

const ConfirmOrder = () => {
  const dispatch = useDispatch()
  const { nextRoute, previousRoute } = useRouter()
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const timeSlotSelected = useSelector(timeSlotSelectedSelector)
  const timeSlotsStatusLoader = useSelector(timeSlotsStatusSelector)
  const mealsSelected = useSelector(mealsSelectedSelector)
  const subscriptionReactivated = useSelector(subscriptionReactivatedSelector)
  const orderCreated = useSelector(orderCreatedSelector)
  const address = useSelector(addressSelector)
  const ring = useSelector(ringSelector)
  const zipCode = useSelector(zipCodeSelector)
  const coupon = useSelector(couponSelector)
  const trackInformationConfirmed = useTrackEvent({
    eventName: 'Resurrection - Order Confirmed',
  })

  useEffect(() => {
    if (subscriptionReactivated && orderCreated) {
      nextRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionReactivated, orderCreated])

  useEffect(() => {
    dispatch(setLoader(timeSlotsStatusLoader))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlotsStatusLoader])

  return (
    <>
      <CuiHeader
        showNeedHelp
        showNeedHelpTitle
        showLogo
        homeLink={process.env.REACT_APP_SITE_BASE_URL}
      />
      <div className="confirm-order-cui-container">
        <div className="confirm-order-row-cui">
          <div className="left-side-container">
            <h1>Confirm your order for {deliveryDaySelected?.label}</h1>
            <ConfirmSummaryCui />
            <ConfirmDeliveryWindowCui />
            <ConfirmAddressCui />
          </div>

          <div className="right-side-container">
            <ConfirmMealsCartCui />
          </div>
        </div>
      </div>
      <GutterCui
        ctaText="Confirm Order"
        // nextDisabled={buttonNextDisabled}
        onGoNext={async () => {
          await dispatch(
            requestSubscriptionReactivation({
              date: new Date().toISOString().slice(0, 10),
            }),
          )
          await dispatch(
            requestCreateOrder({
              order: {
                deliveryDate: deliveryDaySelected.date,
                end: timeSlotSelected.end,
                products: mealsSelected.map(mealSelected => ({
                  id: mealSelected.id,
                  qty: mealSelected.quantity,
                })),
                start: timeSlotSelected.start,
              },
            }),
          )
          await dispatch(
            assignCoupon({
              couponCode: coupon.code,
              isResurrected: true,
            }),
          )
          trackInformationConfirmed({
            user_address: address.state,
            user_address_city: address.city,
            user_address_state: address.state,
            store_id: ring.storeId,
            ring_id: ring.id,
            user_zipcode: zipCode,
          })
        }}
        // onGoBack={() => stepHandler()}
        className="gutter-confirm-delivery"
      >
        <button
          className="gutter-confirm-delivery__back"
          onClick={previousRoute}
        >
          <img src={back} alt="back" />
          Back
        </button>
        <div className="gutter-confirm-delivery__plan" />
      </GutterCui>
    </>
  )
}

export default ConfirmOrder
