import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { deliveryDaySelectedSelector } from 'src/features/delivery/deliverySlice'
import { mealsSelectedQuantitySelector } from 'src/features/meals/mealsSlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import useRouter from 'src/hooks/useRouter'

const ConfirmOrderGuard = ({ children }) => {
  const { previousRoute } = useRouter()
  const planSelected = useSelector(planSelectedSelector)
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector)

  const isValid =
    deliveryDaySelected &&
    mealsSelectedQuantity ===
      planSelected?.mealsPerDelivery * planSelected?.deliveries

  useEffect(() => {
    if (!isValid) {
      previousRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isValid && (children || <Outlet />)
}

export default React.memo(ConfirmOrderGuard)
