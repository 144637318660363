import React from 'react'
import { CuiButton } from 'front-lib'
import calendar from 'src/assets/img/calendar.png'

const FilterTopBar = ({ startDay, goToSelectDeliveryDateStep }) => {
  const [day, date] = startDay?.label?.split(',') ?? ['', '']

  return (
    <div className="filter-top-bar container-cui">
      <div className="filter-experiment-container">
        <div className="filter-top-bar--title">
          <img src={calendar} alt="calendar" className="filter-top-bar--icon" />
          Choose a date that works best for you
        </div>

        <div className="filter-top-bar--body">
          <span className="filter-top-bar--text">
            {'Delivery on '}
            <span className="filter-top-bar--day-date">
              {day && `${day}, ${date}`}
            </span>
          </span>

          <CuiButton size="small" onClick={() => goToSelectDeliveryDateStep()}>
            Change
          </CuiButton>
        </div>
      </div>
    </div>
  )
}

export default FilterTopBar
