import {
  TOP_RATED_MEALS_MIN_REVIEWS,
  TOP_RATED_MEALS_MIN_STARS,
} from './contants'

export function getProductDataListOfCart(cart = []) {
  if (!cart || cart.length === 0) return []
  const cartMapped = cart.map(c => (c && c.data) || null)
  return cartMapped.filter(c => !!c)
}

export const forYouFilterOption = {
  id: 'forYou',
  name: 'For You',
  image:
    'https://static.cookunity.com/cross/front-lib/icons/your-top-rated.svg',
  type: 'menu',
}

export const recommendationsFilterOption = {
  id: 'recommendations',
  name: 'Recommendations',
  image:
    'https://static.cookunity.com/cross/front-lib/icons/review-stars-3.svg',
  type: 'meatType',
}

export const filtersOptions = [
  {
    id: 'all',
    name: 'All',
    image: 'https://cu-product-media.s3.amazonaws.com/media/menu/all/all.svg',
    type: 'meatType',
  },
  {
    id: 'Seafood',
    name: 'Seafood',
    image: 'https://static.cookunity.com/cross/front-lib/icons/seafood.svg',
    type: 'meatType',
  },
  {
    id: 'Vegetarian',
    name: 'Vegetarian',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/vegetarian-icon.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Beef',
    name: 'Beef',
    image: 'https://static.cookunity.com/cross/front-lib/icons/meat.svg',
    type: 'meatType',
  },
  {
    id: 'Poultry',
    name: 'Poultry',
    image: 'https://static.cookunity.com/cross/front-lib/icons/poultry.svg',
    type: 'meatType',
  },
  {
    id: 'Vegan',
    name: 'Vegan',
    image: 'https://static.cookunity.com/cross/front-lib/icons/vegan.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Less Than 600 calories',
    name: 'Less Than 600 calories',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/low-calories.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Low Carbs',
    name: 'Low Carbs',
    image: 'https://static.cookunity.com/cross/front-lib/icons/low-carbs.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Gluten Free',
    name: 'Gluten Free',
    image: 'https://static.cookunity.com/cross/front-lib/icons/gluten-free.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Dairy Free',
    name: 'Dairy Free',
    image: 'https://static.cookunity.com/growth/media/icons/dairy-free.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Low Sodium',
    name: 'Low Sodium',
    image: 'https://static.cookunity.com/cross/front-lib/icons/low-sodium.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Keto Diet',
    name: 'Keto Diet',
    image: 'https://static.cookunity.com/cross/front-lib/icons/keto.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Paleo',
    name: 'Paleo',
    image: 'https://static.cookunity.com/cross/front-lib/icons/paleo.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'High Protein',
    name: 'High Protein',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/high-protein.svg',
    type: 'specificationsDetail',
  },
  {
    id: 'Low Fat',
    name: 'Low Fat',
    image: 'https://static.cookunity.com/cross/front-lib/icons/low-fat.svg',
    type: 'specificationsDetail',
  },
]

export const getSortedTopRatedMeals = (meals = []) => {
  const { bestMealPerChef, repeatedChefMeals } = [
    ...meals.filter(
      meal =>
        meal.stars >= TOP_RATED_MEALS_MIN_STARS &&
        meal.reviews >= TOP_RATED_MEALS_MIN_REVIEWS,
    ),
  ]
    .sort((a, b) => b.stars - a.stars)
    .reduce(
      ({ bestMealPerChef, repeatedChefMeals }, meal) => {
        if (
          !bestMealPerChef.some(
            m =>
              m.chef_firstname === meal.chef_firstname &&
              m.chef_lastname === meal.chef_lastname,
          )
        ) {
          bestMealPerChef.push(meal)
        } else {
          repeatedChefMeals.push(meal)
        }
        return { bestMealPerChef, repeatedChefMeals }
      },
      { bestMealPerChef: [], repeatedChefMeals: [] },
    )

  return [...bestMealPerChef, ...repeatedChefMeals]
}
