import React from 'react'

import './StepTitleCui.scss'

const StepTitleCui = ({ title, subtitle }) => {
  return (
    <div className="step-title-cui">
      <div className="step-title-cui__title">{title}</div>
      {subtitle && <div className="step-title-cui__subtitle">{subtitle}</div>}
    </div>
  )
}

export default StepTitleCui
