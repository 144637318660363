import {createSelector} from "reselect";

const getMenuListState = state => state.get('moduleMenuList').getIn([0]);

const getMealsMenuState = state => getMenuListState(state).get("products");



export const getSignUpMenuLoading = createSelector(
  getMealsMenuState,
  state => (state.get('start') || !state.get('success'))
);
export const getMenuData = createSelector(
  getMealsMenuState,
  state => state.get('data')
);

const getFilters = state => getMenuListState(state).get("filters");
export const getMenuFilter = createSelector(
  getFilters,
  state => state
);
