import { subscriptionQuery, subscriptionMutate } from '../../api/client'

const usersQuery = `
query user {
  users {
    id
    name
    email
    magento_id
    status
    addresses {
      id
      isActive
      city
      country
      region
      postcode
      telephone
      street
      instruction
    }
    currentPlan {
      id
      title
      price
      mealsPerDelivery
      deliveries
      deliveryFee
    }
    ring {
      id
      isLocal: is_local
      storeId: store_id
    }
    store {
      id
      name
      latitude
      longitude
    }
    paymentProvider: payment_provider
  }
}
`

const updateAddressMutation = `
mutation changeCompleteUserAddress(
  $address: ChangeUserAddressInput
) {
  changeCompleteUserAddress(
    address: $address
  ) {
    ... on GenericResponseType {
      result
    }
    ... on GenericErrorType {
      error
    }
  }
  }
`

const reactivateSubscriptionMutation = `
mutation reactivateSubscription($date: Date!) {
  publicCreateSubscriptionEvent(input: {
      event: subscribe,
      date: $date
  }){
      ... on CreateSubscriptionEventErrorType {
          error
      }
      ... on CreateSubscriptionEventSuccesType {
          id
          userId
          event
          date
      }
  }
}
`

export const getUsers = () => subscriptionQuery(usersQuery)
export const changeUserAddress = ({ address }) =>
  subscriptionMutate(updateAddressMutation, { address })
export const reactivateSubscription = ({ date }) =>
  subscriptionMutate(reactivateSubscriptionMutation, { date })
