import React from 'react'

import GutterCui from '../../../../components/GutterCui'
import DeliveryOptionsCui from './components/DeliveryOptions/DeliveryOptionsCui'
import back from '../../../../assets/icons/caret-black-left.png'

import './DeliveryPageCui.scss'
import ImageLayoutCui from 'src/components/ImageLayoutCui/ImageLayoutCui'
import StepTitleCui from 'src/components/StepTitleCui/StepTitleCui'

import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import classnames from 'classnames'
import { PATHS, pageNames } from '../../constants'

function DeliveryPage(props) {
  const {
    firstSelectionStartDay,
    validDates,
    handleDateChange,
    startDay,
    stepHandler,
    buttonNextDisabled,
    showDateInBar,
    userSelectedData,
  } = props

  const { isTreatment: isDeliveryDayInFunnelTreatment } = useExperiment(
    EXPERIMENTS.deliveryDayInFunnel,
  )

  const { selectedPlan } = userSelectedData

  const DELIVERY_TITLE = isDeliveryDayInFunnelTreatment
    ? 'When do you want to receive your first order?'
    : 'Select your new delivery day'
  const DELIVERY_SUBTITLE =
    'You can always change your delivery day, reschedule or skip upcoming deliveries.'

  return (
    <>
      <ImageLayoutCui>
        {(!isDeliveryDayInFunnelTreatment ||
          (isDeliveryDayInFunnelTreatment && !!selectedPlan)) && (
          <button
            onClick={() => stepHandler()}
            className="delivery-page-cui__back"
          >
            <img src={back} alt="back" />
            Back to Meal selection
          </button>
        )}
        <div className="delivery-page-cui__container">
          <StepTitleCui
            className={classnames({
              'delivery-page-cui__title': isDeliveryDayInFunnelTreatment,
            })}
            title={DELIVERY_TITLE}
            subtitle={DELIVERY_SUBTITLE}
          />

          <DeliveryOptionsCui
            validDates={validDates}
            firstSelectionStartDay={firstSelectionStartDay}
            startDay={startDay}
            onChange={handleDateChange}
          />
        </div>
      </ImageLayoutCui>

      <GutterCui
        ctaText="Confirm"
        nextDisabled={buttonNextDisabled}
        onGoNext={() => {
          stepHandler(PATHS[pageNames.MEALS])
          window.scrollTo(0, 0)
        }}
        onGoBack={() => stepHandler()}
      >
        <div className="gutter-delivery">
          <span className="gutter-delivery__txt">
            First delivery date <span />
          </span>
          <span className="gutter-delivery__p">
            {showDateInBar ? (
              <strong>{startDay.label}</strong>
            ) : (
              <span>Select a delivery day slot</span>
            )}
          </span>
        </div>
      </GutterCui>
    </>
  )
}

export default DeliveryPage
