import React from 'react'
import Modal from 'react-modal'
import classnames from 'classnames'
import './Modal.scss'
import Close from 'src/assets/icons/xclose.png'

const CUModal = props => {
  const {
    overlayClassName,
    className,
    children,
    style = {},
    mediumSize,
    smallSize,
    ...rest
  } = props

  Modal.setAppElement('#root');
  const getParent = () => document.querySelector('#root');

  const contentStyle = style.content || {}
  const overlayStyle = style.overlay || {}

  return (
    <Modal
      parentSelector={getParent}
      overlayClassName={classnames(
        'cookunity__signup_new_modal__overlay',
        overlayClassName,
        {
          medium_size: mediumSize,
          large_size: !mediumSize && !smallSize,
          small_size: smallSize
        }
      )}
      className={classnames('cookunity__new_modal__content', className, {
        medium_size: mediumSize,
        large_size: !mediumSize && !smallSize,
        small_size: smallSize
      })}
      style={{
        content: contentStyle,
        overlay: overlayStyle
      }}
      {...rest}>
      <button
        className="cookunity__new_modal__close"
        onClick={props.onRequestClose}>
        <img src={Close} alt="close" />
      </button>
      {children}
    </Modal>
  )
}

export default CUModal
