import { CuiHeader } from 'front-lib'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GutterCui from 'src/components/GutterCui/GutterCui'
import {
  addressSelector,
  ringSelector,
  zipCodeSelector,
} from 'src/features/address/addressSlice'
import { assignCoupon, couponSelector } from 'src/features/cart/cartSlice'
import {
  deliveryDaySelectedSelector,
  deliveryDaysSelector,
  deliveryDaysStatusSelector,
  setDeliveryDaySelected,
} from 'src/features/delivery/deliverySlice'
import { setLoader } from 'src/features/loader/loaderSlice'
import { paymentMethodsStatusSelector } from 'src/features/payment/paymentSlice'
import {
  planSelectedSelector,
  planStatusSelector,
} from 'src/features/plans/plansSlice'
import {
  requestSubscriptionReactivation,
  subscriptionReactivatedStatusSelector,
  userSelector,
  userStatusSelector,
} from 'src/features/user/userSlice'
import useTrackEvent from 'src/hooks/useTrackEvent'
import {
  formatNumberToCurrency,
  getPlanPriceDiscount,
  getSymbolAmount,
} from 'src/utils/priceUtils'
import './ResurrectionPage.scss'
import Delivery from './components/Delivery/Delivery'
import Payment from './components/Payment/Payment'
import Plan from './components/Plan/Plan'
import { EXPERIMENTS } from 'src/constants/experiments'
import useExperiment from 'src/hooks/useExperiment'
import { useFlag } from '@unleash/proxy-client-react'

const ResurrectionPage = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const userStatusLoader = useSelector(userStatusSelector)
  const deliveryDays = useSelector(deliveryDaysSelector)
  const planSelected = useSelector(planSelectedSelector)
  const planStatusLoader = useSelector(planStatusSelector)
  const paymentMethodsStatusLoader = useSelector(paymentMethodsStatusSelector)
  const subscriptionReactivatedStatusSelectorLoader = useSelector(
    subscriptionReactivatedStatusSelector,
  )
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const deliveryDaysLoader = useSelector(deliveryDaysStatusSelector)
  const coupon = useSelector(couponSelector)
  const discountText = getSymbolAmount(coupon.promotion.discount)
  const address = useSelector(addressSelector)
  const ring = useSelector(ringSelector)
  const zipCode = useSelector(zipCodeSelector)
  const [subsFrontLoader, setSubsFrontLoader] = useState(false)
  const redirectToOrder = useFlag(EXPERIMENTS.redirectToMenu)
  const params = redirectToOrder ? '?redirectToOrder=true' : ''
  const {
    trackExperimentViewed: trackExperimentInfoValidationViewed,
  } = useExperiment(EXPERIMENTS.resurrectionFunnel)

  const trackResurrectionStarted = useTrackEvent({
    eventName: 'Resurrection Funnel Started',
  })
  const trackResurrectionUserInformationConfirmed = useTrackEvent({
    eventName: 'Resurrection - User Information Confirmed',
  })

  useEffect(() => {
    trackExperimentInfoValidationViewed()
    trackResurrectionStarted({
      user_address: address.street,
      user_address_city: address.city,
      user_address_state: address.state,
      store_id: ring.storeId,
      ring_id: ring.id,
      user_zipcode: zipCode,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!deliveryDaySelected && deliveryDays) {
      dispatch(setDeliveryDaySelected(deliveryDays[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDays])

  useEffect(() => {
    dispatch(
      setLoader(
        userStatusLoader ||
          planStatusLoader ||
          paymentMethodsStatusLoader ||
          deliveryDaysLoader ||
          subscriptionReactivatedStatusSelectorLoader ||
          subsFrontLoader,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userStatusLoader,
    planStatusLoader,
    paymentMethodsStatusLoader,
    subscriptionReactivatedStatusSelectorLoader,
  ])

  const handleGoNext = async () => {
    trackResurrectionUserInformationConfirmed({
      user_address: address.street,
      user_address_city: address.city,
      user_address_state: address.state,
      store_id: ring.storeId,
      ring_id: ring.id,
      user_zipcode: zipCode,
    })

    await dispatch(
      requestSubscriptionReactivation({
        date: new Date().toISOString().slice(0, 10),
      }),
    )

    await dispatch(
      assignCoupon({
        couponCode: coupon.code,
        isResurrected: true,
      }),
    )

    window.location.href =
      process.env.REACT_APP_SUBSCRIPTION_URL + '/nextOrder' + params

    setSubsFrontLoader(true)
    return
  }

  const currencyPlanDiscount =
    planSelected &&
    formatNumberToCurrency(
      getPlanPriceDiscount({
        plan: planSelected,
        promotion: coupon.promotion,
      }),
    )

  return (
    <div id="dashboard-container">
      <CuiHeader
        showNeedHelp
        showNeedHelpTitle
        showLogo
        homeLink={process.env.REACT_APP_SITE_BASE_URL}
      />
      <div className="container-cui">
        <div className="hero">
          <div className="hero-img"></div>
          <div className="hero-content">
            <h1 className="title">
              Hey {user?.name}, we missed you!<br></br>
              Rediscover our delicious meals<br></br>
              with{' '}
              <span className="colored-discount">
                {discountText} off your order
              </span>
            </h1>
            <p className="subtitle">
              Please check if your information is still correct.
            </p>
          </div>
        </div>
        <Plan />
        <Delivery />
        <Payment />
      </div>

      <GutterCui
        ctaText="Reactivate plan and select meals"
        onGoNext={handleGoNext}
        className="gutter-dashboard"
      >
        <div className="gutter-confirm-delivery__plan">
          <p>
            {planSelected?.mealsPerDelivery * planSelected?.deliveries} meals
            delivered weekly for ${planSelected?.price}
          </p>
          <p>
            <strong>This week SAVE {currencyPlanDiscount}</strong>
          </p>
        </div>
      </GutterCui>
    </div>
  )
}

export default ResurrectionPage
