import { createSlice, current } from '@reduxjs/toolkit'
import { SESSION_KEY } from 'src/constants'

const initialState = {
  token: null,
  authUser: null,
  isAuthLoading: true,
  authError: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload
    },
    setAuthUser: (state, { payload }) => {
      state.authUser = payload
    },
    setIsAuthLoading: (state, { payload }) => {
      state.isAuthLoading = payload
    },
    setAuthError: (state, { payload }) => {
      state.authError = payload
    },
    saveAuthData: state => {
      window.localStorage.setItem(
        `${SESSION_KEY}_auth_data`,
        JSON.stringify(current(state)),
      )
    },
  },
})

export const {
  setToken,
  setAuthUser,
  setIsAuthLoading,
  setAuthError,
  saveAuthData,
} = authSlice.actions

export const tokenSelector = state => {
  return state.get('auth').token
}
export const authUserSelector = state => state.get('auth').authUser
export const isAuthenticatedSelector = state => {
  return state.get('auth').authUser !== null
}
export const isAuthLoadingSelector = state => state.get('auth').isAuthLoading
export const authErrorSelector = state => state.get('auth').authError

export default authSlice.reducer
