import { getCookie } from './cookieUtils'

const LP_QUERY_COOKIE_NAME = 'cu_lp_query'

function getQueryFromCookie() {
  const queryCookie = getCookie(LP_QUERY_COOKIE_NAME)
  if (queryCookie) {
    return JSON.parse(decodeURIComponent(queryCookie))
  }
  return null
}

export function getParamFromPath(param: string) {
  const queryString = window.location.search
  const searchParams = new URLSearchParams(queryString)
  return searchParams.get(param)
}

export function getChefIdsParam() {
  const path = getParamFromPath('chefIds')
  const query = getQueryFromCookie()
  return path || (query && query.chefIds)
}

export function getMealIdsParam() {
  const path = getParamFromPath('mealIds')
  const query = getQueryFromCookie()
  return path || (query && query.mealIds)
}

export function getNpcParam() {
  const path = getParamFromPath('npc')
  const query = getQueryFromCookie()
  return path || (query && query.npc)
}
