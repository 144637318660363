export function getChefsFromMeals(mealsData) {
  const meals = Array.isArray(mealsData) ? mealsData : []
  const chefs = []

  for (const meal of meals) {
    if (meal.chef_id) {
      const found = chefs.find(c => c.chef_id === meal.chef_id)
      const label = getLabelFromMeatType(meal.meat_type)

      if (!found) {
        chefs.push({
          chef_id: meal.chef_id,
          name: `${meal.chef_firstname} ${meal.chef_lastname}`,
          image: getChefImageUrl(`${meal.chef_firstname} ${meal.chef_lastname}`),
          imageFallback: getChefImageUrl(meal.bannerpic, true),
          meals: [{ ...meal }],
          labels: label ? [label] : [],
        })
      } else {
        found['meals'].push(meal)
        if (label && found['labels'].indexOf(label) === -1) {
          found['labels'].push(label)
        }
      }
    }
  }

  return chefs
}

export function getLabelFromMeatType(meatType) {

  const labels = [
    {
      label: 'Meat',
      types: ['Beef', 'Lamb'],
    },
    {
      label: 'Pork',
      types: ['Pork'],
    },
    {
      label: 'Seafood',
      types: [
        'Catfish',
        'Cod',
        'Crab',
        'Salmon',
        'Scallop',
        'Seafood',
        'Shrimp',
        'Snapper',
        'Sole',
        'Tilapia',
        'Trout',
        'Tuna',
        'Fish',
      ],
    },
    {
      label: 'Poultry',
      types: ['Chicken', 'Turkey', 'Duck'],
    },
    {
      label: 'Vegan',
      types: ['Vegan'],
    },
    {
      label: 'Vegetarian',
      types: ['Vegetarian'],
    },
  ]

  const replaced = labels.find(m => m["types"].indexOf(meatType) !== -1)
  return replaced && replaced.label
}

export function filterChefsListByLabels(chefsList, matchs = []) {
  const chefs = Array.isArray(chefsList) ? [...chefsList] : []

  if (!matchs || !matchs.length) {
    return chefs.map(chef => {
      return { ...chef, labels: [] }
    })
  }

  const filteredChefs = chefs.filter(
    c => c['labels'].find(t => matchs.indexOf(t) !== -1),
  )

  return filteredChefs.map(chef => {
    return {
      ...chef,
      labels: chef.labels.filter(l => matchs.indexOf(l) !== -1)
    }
  })
}

function getChefImageUrl(string, fallback) {
  if (fallback) return `${process.env.REACT_APP_CHEF_IMG_URL_SOURCE}${string}`

  const slug = slugify(string)
  return `https://static.cookunity.com/growth/media/chef_lp/${slug}/images/chef-image1.jpg`
}

function slugify(text) {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
}