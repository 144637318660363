import { subscriptionPublicQuery } from '../../api/client'

const questionsQuery = `
query questions{
  categoryTaste {
      id
      name
      image
      tastes {
          id
          name
          magento_id
      }
  }
}
`

export const getQuestions = async () => {
  const response = await subscriptionPublicQuery(questionsQuery)
  const questions = [
    {
      id: 'categoryTastes',
      name: 'Proteins',
      title: 'What are your go-to protein sources?',
      subtitle:
        'Choose up to 3 so we can give you personalized recommendations.',
      type: 'checkbox',
      max: 3,
      options: response.categoryTaste,
    },
    {
      id: 'allergens',
      name: 'Allergies',
      title: 'Is there anything you would like to avoid?',
      subtitle: 'Please select all that apply.',
      type: 'checkbox',
      options: [
        {
          id: 1,
          name: 'Dairy',
          image: 'meat-icon.png',
        },
        {
          id: 2,
          name: 'Soy',
          image: 'poultry-icon.png',
        },
        {
          id: 3,
          name: 'Gluten',
          image: 'pork-icon.png',
        },
        {
          id: 4,
          name: 'Nut',
          image: 'seafood-icon.png',
        },
        {
          id: 5,
          name: 'Super Foods',
          image: 'vegetarian-icon.png',
        },
        {
          id: 6,
          name: 'Spicy Flavors',
          image: 'vegan-icon.png',
        },
        {
          id: 7,
          name: 'Highly Spicy Flavors',
          image: 'vegan-icon.png',
        },
      ],
    },
  ]
  return { questions }
}
