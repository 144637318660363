import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { zipCodeSelector } from 'src/features/address/addressSlice'
import useRouter from 'src/hooks/useRouter'

const DeliveryDaySelectionGuard = ({ children }) => {
  const { previousRoute } = useRouter()
  const zipCode = useSelector(zipCodeSelector)

  const isValid = zipCode

  useEffect(() => {
    if (!isValid) {
      previousRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isValid && (children || <Outlet />)
}

export default React.memo(DeliveryDaySelectionGuard)
