import { subscriptionPublicQuery } from '../../api/client'

const zipCodeValidationQuery = `
query zipCodeValidation($zipCode: String) {
  isValid: zipcodeValidation(zipcode: $zipCode)
  ring(zipcode: $zipCode) {
    id
    isLocal: is_local
    storeId: store_id
  }
  stores {
    id
    latitude
    longitude
  }
}
`

export const validateZipCode = ({ zipCode }) =>
  subscriptionPublicQuery(zipCodeValidationQuery, { zipCode })
