import React from 'react'
import Modal from '../Modal/Modal'
import { CuiButton } from 'front-lib'
import './ConfirmModal.scss'

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  showCancelButton,
  children,
}) => {
  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel()
    }
    onClose()
  }

  const handleConfirm = () => {
    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm()
    }
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="confirm-content">
        {children}
        <div className="confirm-buttons">
          {showCancelButton && (
            <CuiButton onClick={handleCancel} fill="outline">
              Cancel
            </CuiButton>
          )}
          <CuiButton onClick={handleConfirm}>Continue</CuiButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
