import { useAuth0 } from '@auth0/auth0-react'
import { CuiLink } from 'front-lib'
import * as qs from 'qs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { EXPERIMENTS } from 'src/constants/experiments'
import { authUserSelector } from 'src/features/auth/authSlice'
import useAuth0Api from 'src/features/signIn/hooks/Auth0'
import useExperiment from 'src/hooks/useExperiment'
import { PATHS, pageNames } from 'src/screens/signup/constants'
import { formatEmail } from 'src/utils/user'
import useTrackEvent from 'src/hooks/useTrackEvent';

import './LoggedUser.scss'

const LoggedUser = () => {
  const { search } = useLocation()
  const { loginWithRedirect } = useAuth0()
  const { logout } = useAuth0Api()

  const { isTreatment: isSneakPeekTreatment } = useExperiment(
    EXPERIMENTS.sneakPeek,
  )

  const trackChangeAccount = useTrackEvent({
    eventName: 'CTA Button Clicked',
  })

  const authUser = useSelector(authUserSelector)
  const email = authUser?.email

  const formattedEmail = formatEmail(email)

  const changeAccountHandler = async () => {
    if (isSneakPeekTreatment) {
      logout(`${window.location.origin}${PATHS[pageNames.MEALS]}${search}`)
    }
    else {
      const { zipcode, coupon, cuSession } = qs.parse(search, {
        ignoreQueryPrefix: true,
      })

      const cleanSearch = `?zipcode=${zipcode}${coupon ? `&coupon=${coupon}` : ''
        }&cuSession=${cuSession}`

      trackChangeAccount({cta_text: 'Change account'})

      await loginWithRedirect({
        action: 'signup',
        redirectUri: `${window.location.origin}${PATHS[pageNames.CHECKOUT]}${cleanSearch}`,
        initialEmail: '',
        search: `${cleanSearch}`,
        env: process.env.NODE_ENV,
        max_age: 0,
        prompt: 'login',
      })
    }
  }

  if (!formattedEmail) {
    return null
  }

  return (
    <div className="logged-used-header">
      <p data-testid="userEmail">{formattedEmail ? `Not ${formattedEmail}?` : ''}</p>
      <CuiLink data-testid="changeAccount" fill="clear" size="small" onClick={changeAccountHandler} showUnderline>
        Change account
      </CuiLink>
    </div>
  )
}

export default LoggedUser
