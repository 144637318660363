import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import './InputZipcodeCui.scss'

import iconInput from '../../../../assets/icons/icon-input.png'

function InputZipcode(props) {
  const {
    continueAction,
    isLoading,
    mode = 'header',
    askEmail,
    initialZipcode,
  } = props

  const getClassName = name => `${name}_${mode}`

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        emailValue: '',
        zipCodeValue: initialZipcode || '',
        askEmail: askEmail,
      }}
      onSubmit={values => {
        continueAction(values.zipCodeValue, values.emailValue)
      }}
      validationSchema={Yup.object().shape({
        askEmail: Yup.boolean(),
        emailValue: Yup.string().when('askEmail', {
          is: true,
          then: Yup.string()
            .required('Please enter your email')
            .matches(/^[\w_.+-]+@([\w-]+\.)+[\w-]{2,}$/, 'Please enter a valid email')
        }),
        zipCodeValue: Yup.string().required('Please enter your Zip Code'),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props
        return (
          <form
            className={`${getClassName('form')} ${askEmail && 'withEmail'} input-zipcode`}
          >
            {askEmail && (
              <div className='form-group'>
                <input
                  type="text"
                  className={`input-email rebrand small form-control ${getClassName(
                    'formInputEmail',
                  )} ${errors.emailValue && touched.emailValue
                    ? getClassName('errorInput')
                    : ''
                    }`}
                  id="emailValue"
                  aria-describedby="emailValueHelp"
                  placeholder="Email address"
                  value={values.emailValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.emailValue && touched.emailValue && errors.emailValue && (
                  <p className='error-message'>
                    {errors.emailValue && touched.emailValue && errors.emailValue}
                  </p>
                )}
              </div>
            )}
            <div className='form-group'>
              <div className="input-button">
                <div className={`container-input ${errors.zipCodeValue && touched.zipCodeValue
                  ? getClassName('errorInput')
                  : ''
                  }`}>
                  <img src={iconInput} alt="zip code" />
                  <input
                    type="tel"
                    maxLength={5}
                    className={`rebrand small form-control ${getClassName(
                      'formInputZipCode',
                    )}`}
                    placeholder="Enter your Zip Code"
                    id="zipCodeValue"
                    aria-describedby="zipCodeHelp"
                    value={values.zipCodeValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <button type="submit" className="zip-btn" onClick={handleSubmit} disabled={isLoading}>
                  {!isLoading ? (
                    'Continue'
                  ) : (
                    <i className="spinner fas fa-circle-notch" />
                  )}
                </button>
              </div>
              {errors.zipCodeValue && touched.zipCodeValue && errors.zipCodeValue && (
                <p className='error-message'>
                  {errors.zipCodeValue && touched.zipCodeValue && errors.zipCodeValue}
                </p>
              )}
            </div>

          </form>
        )
      }}
    </Formik>
  )
}

export default InputZipcode
