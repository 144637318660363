import React from 'react'
import GutterCui from '../../../../../../components/GutterCui'
import './Footer.scss'

function Footer({ selectedPlan, onGoNext }) {
  return (
    <GutterCui
      ctaText="Continue"
      nextDisabled={!selectedPlan}
      onGoNext={onGoNext}
    />
  )
}

export default Footer
