import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { CuiHeader } from 'front-lib'

import OrderDetails from './components/OrderDetails'
import NextSteps from './components/NextSteps'
import BottomBanner from './components/BottomBanner'
import { getDateLabel } from 'src/utils/date'
import useExperiment from 'src/hooks/useExperiment'
import useTrackEvent from 'src/hooks/useTrackEvent'
import { isMobile } from 'src/utils/events'
import { EXPERIMENTS } from 'src/constants/experiments'
import { DownloadApp } from './components/DownloadApp/DownloadApp'

import './ThankYouPageOnboarding.scss'
import { EVENTS } from 'src/utils/events'

const ThankYouPageOnboarding = ({
  userSelectedData,
  cartData,
  onboardingURL,
}) => {
  const [showBottomBanner, setShowBottomBanner] = useState(false)
  const trackEvent = useTrackEvent({ eventName: EVENTS.buttonClicked })
  const { isTreatment, isEnabled } = useExperiment(EXPERIMENTS.downloadApp, {
    trackOnMount: true,
  })
  const mobile = isMobile()

  useEffect(() => {
    if (!isEnabled || !mobile) {
      setShowBottomBanner(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])

  const handleDownloadAppClick = () => {
    trackEvent({ cta_action: 'Download App' })
    setShowBottomBanner(true)
  }

  return (
    <>
      <Helmet>
        <title>A Chef Collective | CookUnity | Thank You</title>
      </Helmet>

      <CuiHeader className={'only-desktop'} />
      <div className="rebrand thankyou-container">
        <div className={'confirmation-banner only-mobile'}>
          <div className="confirmation-banner--animation" />
          <p className={'confirmation-banner--text'}>Sweet! Order confirmed.</p>
        </div>

        <div className={'thankyou-container container'}>
          <OrderDetails
            deliveryDate={getDateLabel(
              new Date(userSelectedData?.startDay?.displayDate),
              { weekday: 'short' },
            )}
            deliveryTime={userSelectedData?.selectedTimesLot?.label}
            mealsPerWeek={userSelectedData?.selectedPlan?.mealsPerDelivery}
            products={cartData?.products}
          />
          <div>
            <NextSteps data={userSelectedData?.startDay} />
            {isTreatment && (
              <div className={'download-app__banner'}>
                <DownloadApp onClick={handleDownloadAppClick} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showBottomBanner && <BottomBanner onboardingURL={onboardingURL} />}
    </>
  )
}

export default ThankYouPageOnboarding
