import {createSelector} from 'reselect';

import {duckName} from 'src/redux/ducks/screens/signup/pages/ThankYou/duck';

const thankYouPage = (state) => state.getIn(["signUpScreen", duckName]).get('sendUserSetting');

export const getData = createSelector(thankYouPage, (state) => state.get('data'));

export const getRequestStart = createSelector(thankYouPage, (state) => state.get('start'));

export const getRequestSuccess = createSelector(thankYouPage, (state) => state.get('success'));

export const getRequestError = createSelector(thankYouPage, (state) => state.get('error'));
