import React from 'react'
import styles from './PremiumTag.module.scss'
import premiumTag from '../../assets/svg/premium-tag.svg'
import { CuiImage } from 'front-lib'

const PremiumTag = () => {
  return (
    <div className={styles.cui_meal_card_premium_tag}>
      <CuiImage src={premiumTag} alt="is-premium" />
      <span>Premium</span>
    </div>
  )
}

export default PremiumTag
