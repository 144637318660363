import {
  getRemainingDaysForExpiration,
  getPromotionTypeOfUseLabel,
} from '../../../../../utils/coupons'

export const breadPathList = [
  {
    id: 1,
    label: 'Membership Plans',
    disabled: true,
    step: '/eat-everything/page-1',
  },
  {
    id: 2,
    label: 'Meals',
    disabled: true,
    step: '/eat-everything/page-3',
  },
  {
    id: 3,
    label: 'Checkout',
    disabled: true,
    step: '/eat-everything/page-4',
  },
]

export function sumAllQty(products = []) {
  const localProduct = [...products]
  let qty = 0
  localProduct.forEach(product => (qty = parseInt(product.qty) + parseInt(qty)))
  return qty
}

export const getCouponApplicationEventData = coupon => {
  if (!coupon)
    return {}

  const eventData = {
    promotion_code_source: coupon.source || 'not defined',
  }

  // eventData.promotion_code_initial_use = 'unlimited'
  // eventData.promotion_code_remaining_use = 'unlimited'

  if (coupon.expiry) {
    eventData.promotion_code_expiration_date = coupon.expiry
    eventData.promotion_code_remaining_days = getRemainingDaysForExpiration(coupon)
  }

  if (coupon.promotion) {
    eventData.promotion_id = coupon.promotion.id
    eventData.promotion_name = coupon.promotion.title
    eventData.promotion_code_type = getPromotionTypeOfUseLabel(coupon)
    eventData.promotion_code_description = coupon.promotion.title
  }

  return eventData
}
