import Modal from 'src/components/NewModal'
import { CuiButton } from 'front-lib'
import govxLogo from 'src/assets/img/govx_logo.png'

import 'src/components/GovxComponents/GovxModal.scss'

const LANDING_PAGE_URL = "https://www.cookunity.com/lp/govxverification"

export default function GovxModal({openModal, handleModalState, authenticate, isRedirecting}){

    return (
        <Modal className='govx-modal__component' largeSize isOpen={openModal} onRequestClose={handleModalState}>
        <div className="govx-modal__image"></div>
        <div className="govx-modal">
          <div className="govx-modal__body">
            <img
              style={{ width: '100px' }}
              src={govxLogo}
              alt="GovX"
              className="gox-modal__logo"
            />
            <h2>A special offer for military and teachers</h2>
            <p>
              Create or verify your GovX account to order from a curated
              selection of our menu, featuring restaurant-quality meals at a
              discount:
              <br />
              <b>$8.50 per meal for your first 17 weeks.</b>{' '}
              Add-ons may be available for an additional cost.{' '}
              <a
                href={LANDING_PAGE_URL}
                target="_new"
              >
                Learn more
              </a>
              .
            </p>
            <p>
              For the full menu with standard pricing, please return to select
              a membership plan.
            </p>

            <div className="govx-modal__actions">
              <CuiButton
                style={{ backgroundColor: '#FFC84E', borderColor: '#FFC84E' }}
                disabled={isRedirecting}
                onClick={authenticate}
              >
                Verify your GovX account
              </CuiButton>
              <CuiButton fill="outline" onClick={handleModalState}>
                Return to standard plans
              </CuiButton>
            </div>
          </div>
        </div>
      </Modal>
    )
}

