import React, { useEffect, useMemo } from 'react'
import ToggleButton from '../../../../../../components/ToggleButton'
import ToggleButtonGroup from '../../../../../../components/ToggleButtonGroup'
import './PlanSelection.scss'
import useExperiment from 'src/hooks/useExperiment'
import { EVENTS, isMobile } from 'src/utils/events'
import { pageNames } from 'src/screens/signup/constants'
import { EXPERIMENTS } from 'src/constants/experiments'

const eliminate16MealPlanTrackingConfiguration = variantName => ({
  eventName: EVENTS.experimentViewed,
  eventData: {
    page_name: isMobile() ? null : pageNames.PLANS,
    experiment_id: 'gro-sc-44675',
    experiment_name:
      'Growth - Plan Selection - Eliminate 16 Meal Plan in Regional',
    variation_name: variantName,
  },
})

function PlanSelection({ data, onSelect, selected, sendTracking }) {
  const eliminate16MealPlanExperiment = useExperiment(
    'gro-sc-44803-eliminate-16-meal-plan',
  )

  const { isTreatment: isNo4MealsPlanTreatment } = useExperiment(
    EXPERIMENTS.no4MealsPlan,
    {
      trackOnMount: true,
    },
  )

  useEffect(() => {
    if (eliminate16MealPlanExperiment.isEnabled) {
      sendTracking(
        eliminate16MealPlanTrackingConfiguration(
          eliminate16MealPlanExperiment.variantName,
        ),
      )
    }
  }, [
    eliminate16MealPlanExperiment.isEnabled,
    eliminate16MealPlanExperiment.variantName,
    sendTracking,
  ])

  const dataWithout4MealsPlan = useMemo(
    () =>
      isNo4MealsPlanTreatment
        ? data.filter(({ label }) => label !== 4)
        : data,
    [isNo4MealsPlanTreatment, data],
  )

  const dataWithout16MealsPlan = useMemo(
    () =>
      eliminate16MealPlanExperiment.isTreatment
        ? dataWithout4MealsPlan.filter(({ label }) => label !== 16)
        : dataWithout4MealsPlan,
    [dataWithout4MealsPlan, eliminate16MealPlanExperiment.isTreatment],
  )

  return (
    <ToggleButtonGroup
      value={selected?.id}
      onChange={e => {
        onSelect(+e.currentTarget.value)
      }}
      className="plan-selection"
    >
      {dataWithout16MealsPlan.map(
        ({ id, label, topIcon, topText, bottomIcon }) => (
          <ToggleButton
            key={id}
            value={id}
            className={`plan-selection--label ${topText &&
              'plan-selection--label-top-text'}`
            }
          >
            {topText && (
              <span className="plan-selection__top-text">{topText}</span>
            )}
            {topIcon && (
              <span className="plan-selection__top-icon">{topIcon}</span>
            )}

            <span className={topText && 'plan-selection--number'}>{label}</span>
            {bottomIcon && (
              <span className="plan-selection__bottom-icon">{bottomIcon}</span>
            )}
          </ToggleButton>
        ),
      )}
    </ToggleButtonGroup>
  )
}

export default PlanSelection
