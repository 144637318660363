import React, { useEffect } from 'react'
import { CuiHeader, CuiIcon } from 'front-lib'
import DeliveryDaySelect from '../../features/delivery/DeliveryDaySelect'
import ImageLayoutCui from '../../components/ImageLayoutCui/ImageLayoutCui'
import StepTitleCui from '../../components/StepTitleCui/StepTitleCui'
import GutterCui from '../../components/GutterCui/GutterCui'
import { useSelector } from 'react-redux'
import {
  deliveryDaySelectedSelector,
  deliveryDaysStatusSelector,
} from 'src/features/delivery/deliverySlice'
import useRouter from '../../hooks/useRouter'
import ConfirmChangeDeliveryDay from './components/ConfirmChangeDeliveryDay/ConfirmChangeDeliveryDay'
import './DeliveryDaySelection.scss'
import { setLoader } from 'src/features/loader/loaderSlice'
import { useDispatch } from 'react-redux'
import useTrackEvent from 'src/hooks/useTrackEvent'

const DELIVERY_TITLE = 'Select your new delivery day'
const DELIVERY_SUBTITLE =
  'You can always change your delivery day, reschedule or skip upcoming deliveries.'

const DeliveryDaySelection = () => {
  const dispatch = useDispatch()
  const { nextRoute } = useRouter()
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const deliveryDaysLoader = useSelector(deliveryDaysStatusSelector)

  const trackBackMealsSelected = useTrackEvent({
    eventName: 'Back meals after delivery date changed clicked',
  })
  const trackConfirmDeliveryDateSelected = useTrackEvent({
    eventName: 'Confirm delivery date clicked',
  })

  useEffect(() => {
    dispatch(setLoader(deliveryDaysLoader))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDaysLoader])

  const getTrackingData = () => {
    return {
      delivery_day: {
        label: deliveryDaySelected?.label,
        date: deliveryDaySelected?.date,
        day: deliveryDaySelected?.day,
        cutoff: deliveryDaySelected?.cutoff?.time,
      },
    }
  }

  const handleBackMealsSelected = () => {
    trackBackMealsSelected(getTrackingData())
    nextRoute()
  }

  const handleNextRoute = () => {
    trackConfirmDeliveryDateSelected(getTrackingData())
    nextRoute()
  }

  return (
    <div className="delivery-day-selection">
      <ConfirmChangeDeliveryDay />
      <CuiHeader
        showNeedHelp
        showNeedHelpTitle
        showLogo
        homeLink={process.env.REACT_APP_SITE_BASE_URL}
      />
      <ImageLayoutCui>
        <button
          onClick={handleBackMealsSelected}
          className="delivery-day-selection__back"
        >
          <CuiIcon name="chevronLeft" />
          <p>
            <strong>Back to Meal selection</strong>
          </p>
        </button>
        <div className="delivery-day-selection__container">
          <StepTitleCui title={DELIVERY_TITLE} subtitle={DELIVERY_SUBTITLE} />

          <DeliveryDaySelect />
        </div>
      </ImageLayoutCui>

      <GutterCui
        ctaText="Confirm"
        nextDisabled={!deliveryDaySelected}
        onGoNext={handleNextRoute}
      >
        <div className="delivery-day-selection__gutter">
          <span className="delivery-day-selection__gutter--txt">
            First delivery date <span />
          </span>
          {deliveryDaySelected ? (
            <strong>{deliveryDaySelected.label}</strong>
          ) : (
            <span>Select a delivery day slot</span>
          )}
        </div>
      </GutterCui>
    </div>
  )
}

export default DeliveryDaySelection
