import React, { useEffect } from 'react'
import ToggleButton from '../../components/ToggleButton'
import ToggleButtonGroup from '../../components/ToggleButtonGroup'
import './PlanSelect.scss'
import { useSelector } from 'react-redux'
import {
  fetchPlans,
  planSelectedSelector,
  plansSelector,
  setPlanSelected,
} from './plansSlice'
import { useDispatch } from 'react-redux'

function PlanSelect({ planSelected: planSelectedProp, onSelectPlan }) {
  const dispatch = useDispatch()
  const planSelected = useSelector(planSelectedSelector)
  const plans = useSelector(plansSelector)

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans())
    }
  }, [dispatch, plans])

  const handleSelect = (event, value) => {
    const planFound = plans.find(plan => plan.id === value)

    if (onSelectPlan) {
      onSelectPlan(planFound)
    } else {
      dispatch(setPlanSelected(planFound))
    }
  }

  return (
    plans && (
      <ToggleButtonGroup
        value={planSelectedProp?.id || planSelected?.id}
        onChange={handleSelect}
        className="plan-select-experiment"
      >
        {plans.map(({ id, deliveries, mealsPerDelivery }) => (
          <ToggleButton key={id} value={id} className="plan-select--label">
            {deliveries * mealsPerDelivery}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  )
}

export default PlanSelect
