import { subscriptionPublicQuery, subscriptionQuery } from '../../api/client'

const userDeliveryDaySelectedQuery = `
query user {
  users {
    deliveryDays {
      day
      time_end
      time_start
    }
  }
}
`

const deliveryDaysQuery = `
query deliveryDays($zipCode: String!) {
  deliveryDays: upcomingDays(zipcode: $zipCode, weeks: 3) {
    available
    date
    displayDate
    cutoff {
      time
    }
  }
}
`

const timeSlotsQuery = `
query timeSlots($zipCode: String!) {
  timeSlots: timeslots (zipcode: $zipCode) {
    start
    end
    label
  }
}
`

export const getUserDeliveryDaySelected = (
  { fetchPolicy } = { fetchPolicy: 'cache-first' },
) => subscriptionQuery(userDeliveryDaySelectedQuery, {}, fetchPolicy)

export const getDeliveryDays = ({ zipCode }) =>
  subscriptionPublicQuery(deliveryDaysQuery, { zipCode })

export const getTimeSlots = ({ zipCode }) =>
  subscriptionPublicQuery(timeSlotsQuery, { zipCode })
