import React, { useState, useEffect } from 'react'
import { CuiButton, CuiIcon } from 'front-lib'
import classNames from 'classnames'
import Modal from 'src/components/NewModal'

import './CheckoutCouponOverlay.scss'

function CheckoutCouponOverlay({
  displayOverlay,
  onSubmitOverlay,
  onCloseOverlay,
  couponCode,
  couponError,
}) {
  const [value, setValue] = useState(couponCode)

  /**
   * @description Resets value when Overlay is closed
   */
  useEffect(() => {
    if (!displayOverlay) {
      setValue(couponCode)
    }
  }, [couponCode, displayOverlay])

  const handleSubmit = e => {
    e.preventDefault()
    onSubmitOverlay(value)
  }

  return (
    <>
      <Modal
        className="coupon-modal__component custom-overlay"
        overlayClassName="coupon-modal__component mobile__overlay"
        smallSize
        isOpen={displayOverlay}
        onRequestClose={onCloseOverlay}
      >
        <div className="coupon-modal">
          <div className="coupon-modal__header">
            <h2 className="title">Discount coupon</h2>
          </div>

          <div className="coupon-modal__content">
            <form id="coupon-modal-form" onSubmit={handleSubmit}>
              <input
                className={classNames('input', { 'input--error': couponError })}
                type="text"
                value={value}
                placeholder="Enter coupon code"
                onChange={e => setValue(e.target.value)}
              />
              {couponError && (
                <div className="input-alert">
                  <CuiIcon name="alert" className="alert-icon" />
                  Invalid coupon for this order
                </div>
              )}
            </form>
          </div>
          <div className="coupon-modal__actions">
            <CuiButton
              className="coupon-modal__actions--cancel"
              fill="outline"
              onClick={onCloseOverlay}
            >
              Cancel
            </CuiButton>
            <CuiButton
              className="coupon-modal__actions--submit"
              form="coupon-modal-form"
              type="submit"
              disabled={!value?.trim() || couponCode === value}
            >
              Apply
            </CuiButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CheckoutCouponOverlay
