import { combineReducers } from 'redux-immutable'
import commonsReducer from 'src/redux/ducks/commons'
import moduleMenuList from 'src/redux/ducks/modules/MenuList'
import productDetailModule from 'src/redux/ducks/modules/ProductDetail'
import signUpScreenCommons from 'src/redux/ducks/screens/signup/commons'
import signUpPreOrderPage from 'src/redux/ducks/screens/signup/pages/MealsStep'
import signUpCheckoutPage from 'src/redux/ducks/screens/signup/pages/Checkout'
import deliverySettingPage from 'src/redux/ducks/screens/signup/pages/DeliverySettings'
import thankYouPage from 'src/redux/ducks/screens/signup/pages/ThankYou'
import addressReducer from './address/addressSlice'
import plansReducer from './plans/plansSlice'
import preferencesReducer from './preferences/preferencesSlice'
import deliveryReducer from './delivery/deliverySlice'
import mealsReducer from './meals/mealsSlice'
import paymentReducer from './payment/paymentSlice'
import cartReducer from './cart/cartSlice'
import authReducer from './auth/authSlice'
import userReducer from './user/userSlice'
import loaderReducer from './loader/loaderSlice'

export const rootReducer = combineReducers({
  commons: commonsReducer,
  moduleMenuList,
  productDetailModule,
  signUpScreen: combineReducers({
    commons: signUpScreenCommons,
    preOrderPage: signUpPreOrderPage,
    checkoutPage: signUpCheckoutPage,
    deliverySettingPage: deliverySettingPage,
    thankYouPage: thankYouPage,
  }),
  address: addressReducer,
  plans: plansReducer,
  preferences: preferencesReducer,
  delivery: deliveryReducer,
  meals: mealsReducer,
  payment: paymentReducer,
  cart: cartReducer,
  auth: authReducer,
  user: userReducer,
  loader: loaderReducer,
})
