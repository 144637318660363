import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Formik } from 'formik'
import { CuiButton } from 'front-lib'
import { useState } from 'react'
import * as Yup from 'yup'

import { useFlag } from '@unleash/proxy-client-react'
import { useDispatch, useSelector } from 'react-redux'
import cardsLogos from 'src/assets/img/cards-logos.png'
import { RECAPTCHA_SCRIPT } from 'src/constants'
import { EXPERIMENTS } from 'src/constants/experiments'
import {
  addCard,
  clearAddCardError,
  errorAddCardSelector,
  isLoadingAddCardSelector,
} from 'src/features/payment/paymentSlice'
import { userNameSelector } from 'src/features/user/userSlice'
import useExperiment from 'src/hooks/useExperiment'
import useScript from 'src/hooks/useScript'
import './PaymentForm.scss'

function PaymentForm({ onSave }) {
  const { isTreatment: isPaymentElementEnabled } = useExperiment(EXPERIMENTS.stripePaymentElement);
  const { isTreatment: isApplePayEnabled } = useExperiment(EXPERIMENTS.stripeApplePay);
  const { isTreatment: isGooglePayEnabled } = useExperiment(EXPERIMENTS.stripeGooglePay);

  const dispatch = useDispatch()
  const userName = useSelector(userNameSelector)
  const isLoading = useSelector(isLoadingAddCardSelector)
  const error = useSelector(errorAddCardSelector)
  const elements = useElements()
  const stripe = useStripe()
  const [isValidCard, setIsValidCard] = useState(false)
  const captchaEnabled = useFlag(EXPERIMENTS.recaptchaFeatureFlag)

  useScript(`${RECAPTCHA_SCRIPT + process.env.REACT_APP_GOOGLE_RECAPTCHA}`, captchaEnabled)

  const handleSubmit = async values => {
    if (isPaymentElementEnabled) {
      await elements.submit();
    }

    const { error } = await dispatch(
      addCard({
        stripe: stripe,
        cardInfo: {
          element: elements,
          name: values.cardHolder,
        },
        isPaymentElementEnabled,
        captchaEnabled,
      }),
    )
    if (error) {
      return
    }

    if (onSave && typeof onSave === 'function') {
      onSave()
    }
  }

  return (
    <div className="edit-payment">
      <h4>
        Payment <img src={cardsLogos} alt="" className="cc-logos" />
      </h4>

      <Formik
        enableReinitialize={true}
        initialValues={{
          cardHolder: userName,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          cardHolder: Yup.string()
            .min(3, 'Must be at least 3 characters')
            .max(20, 'Must be at most 20 characters')
            .required('Required'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          } = props
          return (
            <form className="edit-payment__form">
              {isPaymentElementEnabled ? (
                <PaymentElement
                  className='payment-element'
                  options={{
                    value: { postalCode: null },
                    hidePostalCode: true,
                    layout: {
                      type: 'accordion',
                      defaultCollapsed: false,
                      radios: true,
                    },
                    fields: {
                      billingDetails: {
                        address: {
                          country: 'never',
                        },
                      },
                    },
                    wallets: {
                      applePay: isApplePayEnabled ? 'auto' : 'never',
                      googlePay: isGooglePayEnabled ? 'auto' : 'never',
                    }
                  }}
                />
              ) : (
                <div className="edit-payment__form__row edit-payment__form__no-wrap">
                  <div className="col-6">
                    <label htmlFor="cardHolder">Cardholder Name</label>
                    <input
                      type="text"
                      disabled={isLoading}
                      className={
                        errors.cardHolder && touched.cardHolder
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="cardHolder"
                      aria-describedby="cardHolderHelp"
                      placeholder={'Name on card'}
                      value={values.cardHolder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.cardHolder && touched.cardHolder && (
                      <div className="invalid-feedback">
                        {errors.cardHolder ? errors.cardHolder : error}
                      </div>
                    )}
                  </div>
                  <div className="col-6 stripe-resurrection">
                    <label htmlFor="cardHolder">Card number</label>
                    <CardElement
                      options={{
                        value: { postalCode: null },
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: '14px',
                            color: '#707070',
                            letterSpacing: '0.025em',
                            border: '1px solid #707070',
                            'border-radius': '200px',
                            height: '38px',
                            fontFamily: 'Source Code Pro, monospace',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      onFocus={() => {
                        dispatch(clearAddCardError())
                      }}
                      onChange={e => setIsValidCard(e.complete)}
                    />
                  </div>
                </div>
              )}
              {error}
              <div className="edit-payment__form__button">
                <CuiButton
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isLoading || !isValid || !isValidCard}
                  className="cui-button paymentButton"
                >
                  {!isLoading ? (
                    'Save'
                  ) : (
                    <i className="spinner fas fa-circle-notch" />
                  )}
                </CuiButton>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PaymentForm
