import { useEffect } from 'react'
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react'
import { useSelector, connect } from 'react-redux'
import { ringSelector } from 'src/features/address/addressSlice'
import * as signUpCommonSelectors from '../../redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from '../../redux/ducks/screens/signup/commons/creators'
import { getUTMValues } from 'src/utils/utils'
import { cookUnityUuid, getCUCookie } from 'src/utils/cookieUtils'
import { getSearchParam } from 'src/utils/urlUtils'
import { bindActionCreators } from 'redux'
import { filterDDOToggles } from 'src/utils/ddoUtils'
import { DEFAULT_COUPON } from 'src/constants'
import { isMobileOrTablet } from 'front-lib'
import { authUserSelector } from 'src/features/auth/authSlice'

const unleashConfig = {
  url: process.env.REACT_APP_FEATURE_FLAG_PROXY_URL,
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: 120,
  appName: process.env.REACT_APP_FEATURE_FLAG_APP_NAME,
  environment: process.env.REACT_APP_FEATURE_FLAG_ENVIRONMENT,
  context: {
    userId: cookUnityUuid,
    utmSource:
      getSearchParam('utm_source') || getUTMValues().utm_source || 'organic',
    utmCampaign: getSearchParam('utm_campaign') || getUTMValues().utm_campaign,
    initialCouponCode:
      getSearchParam('coupon') || getCUCookie('discountCode') || DEFAULT_COUPON,
    isMobile: isMobileOrTablet().toString(),
  },
}

const client = new UnleashClient(unleashConfig)

const UnleashProvider = ({ children, actions }) => {
  const ring = useSelector(ringSelector)
  const previousStoreRingInformation = useSelector(
    signUpCommonSelectors.getRing,
  )
  const selectedPlan = useSelector(signUpCommonSelectors.getUserSelectedPlan)
  const coupon = useSelector(signUpCommonSelectors.getCoupon)
  const zipCode = useSelector(signUpCommonSelectors.getUserSelectedZipcode)
  const authUser = useSelector(authUserSelector)

  useEffect(() => {
    if (!client) return

    async function run() {
      await client.updateContext({
        ...unleashConfig.context,
        ringId: ring?.storeId || previousStoreRingInformation?.id,
        storeId: ring?.storeId || previousStoreRingInformation?.storeId,
        isLocal: ring?.isLocal || previousStoreRingInformation?.isLocal,
        planId: selectedPlan?.id,
        currentCouponCode: coupon?.couponCode,
        userEmail: authUser?.email ?? '',
        zipcode: zipCode
      })
      const ddoToggles = filterDDOToggles(client.toggles)
      actions.setDDOToggles(ddoToggles)
    }

    run()
  }, [
    ring?.id,
    ring?.storeId,
    ring?.isLocal,
    selectedPlan?.id,
    previousStoreRingInformation?.id,
    previousStoreRingInformation?.storeId,
    previousStoreRingInformation?.isLocal,
    coupon?.couponCode,
    authUser?.email,
    zipCode,
    actions
  ])

  return <FlagProvider unleashClient={client}>{children}</FlagProvider>
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setDDOToggles: signUpCommonActionCreators.setDDOToggles,
    },
    dispatch,
  ),
})

export default connect(null, mapDispatchToProps)(UnleashProvider)
