import React, { useState } from 'react'
import GutterCui from '../../../../../../../../components/GutterCui'
import { CuiLink, CuiModal } from 'front-lib'
import './Footer.scss'
// import PlanSelection from 'src/pages/signup/pages/SelectPlan/components/PlanSelection/PlanSelection.jsx'

function Footer({
  totalProductQty,
  selectedPlan,
  onGoNext,
  onViewSelection,
  displayFooterWarning,
  footerWarningMessage,
}) {
  const [showUpgradePlanModal, setshowUpgradePlanModal] = useState(false)
  return (
    <GutterCui
      ctaText="Continue"
      nextDisabled={(totalProductQty > selectedPlan?.mealsPerDelivery) || totalProductQty < selectedPlan?.mealsPerDelivery}
      onGoNext={onGoNext}
    >
      <div className="pre-order-footer">
        {displayFooterWarning && <div className="pre-order-footer__warning">
          <div className="warning-message">{footerWarningMessage}</div>
        </div>}
        <div className="pre-order-footer__description">
          <span className="pre-order-footer__description--text">
            Meal selected <span />
          </span>
          <span className="pre-order-footer__description--amount">{`${totalProductQty ?? 0}/${selectedPlan.mealsPerDelivery}`}</span>
        </div>
        <CuiLink
          fill="clear"
          showUnderline
          onClick={onViewSelection}
          className="pre-order-footer__button"
        >
          View Selection
        </CuiLink>
      </div>
    
     <CuiModal
      isOpen={showUpgradePlanModal}
      onRequestClose={() => setshowUpgradePlanModal(false)}
      title="Upgrade Plan"
      description="You have selected more meals than your plan allows. Would you like to upgrade your plan?"
      >
        { /* <PlanSelection
                data={options}
                onSelect={onClickPlan}
                selected={selectedPlan}
                sendTracking={sendTracking}
  /> */ }
      </CuiModal>
      </GutterCui>
  )
}

export default Footer
