import Numeral from 'numeral'
import { promotionTypes } from '../screens/signup/constants'
import {
  PROMOTION_TYPES_WITH_DELIVERY_DISCOUNT,
  PROMOTION_TYPES_WITH_FREE_DELIVERY,
} from 'src/constants'

export const roundToTwo = num => +Math.round((num + Number.EPSILON) * 100) / 100
export const getPriceString = num => Numeral(roundToTwo(num)).format('$0.00')
export const getNonNegativeAmount = num => (num > 0 ? num : 0)

export const getDiscount = (coupon, price) => {
  const calculatedDiscount = calculateDiscount(coupon)

  return calculatedDiscount.type === promotionTypes.PERCENTAGE
    ? calculatedDiscount.rate * price
    : calculatedDiscount.discount
}

export const getMealPriceWithoutPromotion = ({
  price,
  deliveries,
  mealsPerDelivery,
}) => price / (deliveries * mealsPerDelivery)

export const getMealPriceWithPromotion = (plan, coupon) => {
  const discount = getDiscount(coupon, plan.price)

  return getNonNegativeAmount(
    roundToTwo(
      (plan.price - discount) / (plan.deliveries * plan.mealsPerDelivery),
    ),
  )
}

export const calculateDiscount = couponData => {
  const isDiscountAvailable = couponData?.promotion?.discount ?? false

  let discount = 0
  if (isDiscountAvailable) {
    if (couponData.promotion.discount.type === promotionTypes.PERCENTAGE) {
      return couponData.promotion.discount
    }

    if (couponData.promotion.discount.type !== promotionTypes.PERCENTAGE) {
      discount = couponData.promotion.discount.rate
    }
  }

  return {
    discount: roundToTwo(discount),
    type: promotionTypes.FIXED_AMOUNT,
  }
}

export const calculateCheckoutAccount = ({
  planPrice,
  deliveryFee,
  coupon,
  taxesAmount: taxrate,
  cartPremiumFeesTotal,
  mealsPerDelivery,
  cartData,
}) => {
  const roundedPlanPrice = roundToTwo(planPrice)
  const premiumFees = cartPremiumFeesTotal
  const totalMealsQuantity =
    cartData && cartData.products
      ? cartData.products
          .map(meal => meal.qty)
          .reduce((acc, curr) => acc + curr)
      : 0
  const extraMealFee = mealsPerDelivery
    ? roundToTwo(
        (totalMealsQuantity - mealsPerDelivery) *
          (planPrice / mealsPerDelivery),
      )
    : 0

  const preTotal = roundedPlanPrice + premiumFees + extraMealFee

  let subTotal = preTotal
  let discount = 0
  let creditAmount = coupon?.promotion?.credit
    ? +coupon.promotion.credit.amount
    : 0

  if (coupon?.promotion) {
    const isPromotionDeliveryDiscount = hasPromotionDeliveryDiscount(
      coupon.promotion,
    )
    const isPromotionFreeDelivery = hasPromotionFreeDelivery(coupon.promotion)
    const preTotalWithDelivery = preTotal + deliveryFee
    discount = getDiscount(
      coupon,
      isPromotionDeliveryDiscount ? preTotalWithDelivery : preTotal,
    )

    subTotal = preTotal - discount
    if (isPromotionFreeDelivery) {
      discount += deliveryFee
    } else if (isPromotionDeliveryDiscount) {
      subTotal += deliveryFee
    }
    subTotal = subTotal > 0 ? subTotal : 0
    subTotal = roundToTwo(subTotal)
    if (!isPromotionDeliveryDiscount && !isPromotionFreeDelivery) {
      subTotal = subTotal + deliveryFee
    }
  } else {
    subTotal = subTotal + deliveryFee
  }

  let taxesAmount = roundToTwo(subTotal * taxrate)
  let total = roundToTwo(subTotal + taxesAmount - creditAmount)

  return {
    planPrice: roundedPlanPrice,
    premiumFees,
    extraMealFee,
    preTotal,
    discount,
    creditAmount,
    deliveryFee,
    subTotal,
    taxesAmount,
    calculatedTotal: total > 0 ? total : roundToTwo(0),
    showPremiumFees: true,
    freePremiumFees: false,
  }
}

export const formatCheckoutAccount = checkoutAccount => ({
  ...checkoutAccount,
  planPrice: getPriceString(checkoutAccount.planPrice),
  premiumFees: getPriceString(checkoutAccount.premiumFees),
  extraMealFee: getPriceString(checkoutAccount.extraMealFee),
  deliveryFee: getPriceString(checkoutAccount.deliveryFee),
  discount: getPriceString(checkoutAccount.discount),
  taxesAmount: getPriceString(checkoutAccount.taxesAmount),
  calculatedTotal: getPriceString(checkoutAccount.calculatedTotal),
})

export const calculateSummary = ({
  plan,
  meals,
  coupon,
  taxRate,
}) => {
  const roundedPlanPrice = plan?.price
  let deliveryFee = plan?.deliveryFee
  const mealPremiumFees = meals
    ?.map(meal => meal.premiumFee)
    .reduce((acc, curr) => acc + curr)

  let preTotal = roundedPlanPrice + mealPremiumFees
  let extraMealsFee = 0

  let subTotal = preTotal
  let discount = 0
  const creditAmount = +coupon?.promotion?.credit?.amount || 0

  if (coupon?.promotion) {
    const isPromotionDeliveryDiscount = hasPromotionDeliveryDiscount(
      coupon.promotion,
    )
    const isPromotionFreeDelivery = hasPromotionFreeDelivery(coupon.promotion)
    const preTotalWithDelivery = preTotal + deliveryFee
    discount = roundToTwo(
      getDiscount(
        coupon,
        isPromotionDeliveryDiscount ? preTotalWithDelivery : preTotal,
      ),
    )

    if (isPromotionFreeDelivery) {
      discount += deliveryFee
    }

    subTotal = roundToTwo(preTotal - discount)
    subTotal = subTotal > 0 ? subTotal : 0
    if (!isPromotionDeliveryDiscount && !isPromotionFreeDelivery) {
      subTotal = subTotal + deliveryFee
    }
  } else {
    subTotal = subTotal + deliveryFee
  }

  const taxesAmount = roundToTwo(subTotal * taxRate)
  const total = roundToTwo(subTotal + taxesAmount - creditAmount)

  return {
    planPrice: roundedPlanPrice,
    mealPremiumFees,
    extraMealsFee,
    discount,
    creditAmount,
    deliveryFee,
    subTotal,
    taxesAmount,
    total: total > 0 ? total : 0,
  }
}

const hasPromotionDeliveryDiscount = ({ promotionType }) => {
  return PROMOTION_TYPES_WITH_DELIVERY_DISCOUNT.includes(promotionType)
}

const hasPromotionFreeDelivery = ({ promotionType }) => {
  return PROMOTION_TYPES_WITH_FREE_DELIVERY.includes(promotionType)
}
