import { SIGN_UP_PATHS, RESURRECTION_PATHS } from './routes'

const PAGE_NAMES = {
  HOME: 'Home',
  PLAN_SELECTION: 'Plan Selection',
  DELIVERY_DAY_SELECTION: 'Delivery Date Selection',
  PREFERENCE_QUIZ: 'Preference Selection',
  CHECKOUT: 'Checkout',
  DASHBOARD: 'Dashboard',
  MEAL_SELECTION: 'Meal Selection',
  CONFIRM_ORDER: 'Confirm Order',
  THANK_YOU: 'Thank You',
}

const SIGN_UP_NAME = 'Sign Up'
const RESURRECTION_NAME = 'Resurrection'

const SIGN_UP_PAGE_NAMES = {
  PLAN_SELECTION: `${SIGN_UP_NAME} - ${PAGE_NAMES.PLAN_SELECTION}`,
  DELIVERY_DAY_SELECTION: `${SIGN_UP_NAME} - ${PAGE_NAMES.DELIVERY_DAY_SELECTION}`,
  PREFERENCE_QUIZ: `${SIGN_UP_NAME} - ${PAGE_NAMES.PREFERENCE_QUIZ}`,
  MEAL_SELECTION: `${SIGN_UP_NAME} - ${PAGE_NAMES.MEAL_SELECTION}`,
  CHECKOUT: `${SIGN_UP_NAME} - ${PAGE_NAMES.CHECKOUT}`,
  THANK_YOU: `${SIGN_UP_NAME} - ${PAGE_NAMES.THANK_YOU}`,
}

const RESURRECTION_PAGE_NAMES = {
  DASHBOARD: `${RESURRECTION_NAME} - ${PAGE_NAMES.DASHBOARD}`,
  MEAL_SELECTION: `${RESURRECTION_NAME} - ${PAGE_NAMES.MEAL_SELECTION}`,
  DELIVERY_DAY_SELECTION: `${RESURRECTION_NAME} - ${PAGE_NAMES.DELIVERY_DAY_SELECTION}`,
  CONFIRM_ORDER: `${RESURRECTION_NAME} - ${PAGE_NAMES.CONFIRM_ORDER}`,
  THANK_YOU: `${RESURRECTION_NAME} - ${PAGE_NAMES.THANK_YOU}`,
}

export const PAGE_INFORMATION = {
  '/': {
    name: PAGE_NAMES.HOME,
  },
  [SIGN_UP_PATHS.PLAN_SELECTION]: {
    name: SIGN_UP_PAGE_NAMES.PLAN_SELECTION,
  },
  [SIGN_UP_PATHS.DELIVERY_DAY_SELECTION]: {
    name: SIGN_UP_PAGE_NAMES.DELIVERY_DAY_SELECTION,
  },
  [SIGN_UP_PATHS.PREFERENCE_QUIZ]: {
    name: SIGN_UP_PAGE_NAMES.PREFERENCE_QUIZ,
  },
  [SIGN_UP_PATHS.MEAL_SELECTION]: {
    name: SIGN_UP_PAGE_NAMES.MEAL_SELECTION,
  },
  [SIGN_UP_PATHS.CHECKOUT]: {
    name: SIGN_UP_PAGE_NAMES.CHECKOUT,
  },
  [SIGN_UP_PATHS.THANK_YOU]: {
    name: SIGN_UP_PAGE_NAMES.THANK_YOU,
  },
  [RESURRECTION_PATHS.DASHBOARD]: {
    name: RESURRECTION_PAGE_NAMES.DASHBOARD,
  },
  [RESURRECTION_PATHS.MEAL_SELECTION]: {
    name: RESURRECTION_PAGE_NAMES.MEAL_SELECTION,
  },
  [RESURRECTION_PATHS.DELIVERY_DAY_SELECTION]: {
    name: RESURRECTION_PAGE_NAMES.DELIVERY_DAY_SELECTION,
  },
  [RESURRECTION_PATHS.CONFIRM_ORDER]: {
    name: RESURRECTION_PAGE_NAMES.CONFIRM_ORDER,
  },
  [RESURRECTION_PATHS.THANK_YOU]: {
    name: RESURRECTION_PAGE_NAMES.THANK_YOU,
  },
  default: {
    name: 'default',
  },
}
