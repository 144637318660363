import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  identifyFailed,
  identifySuccess,
  sessionSuccess,
  trackingFailed,
  trackingSuccess,
  validZipCodeFailed,
  validZipCodeSuccess,
} from './creators'

import { IDENTIFY, LOGGING, TRACKING, VALID_ZIP_CODE } from './actions'

import { cookUnityUuid } from 'src/utils/cookieUtils'
import {
  isExperimentStartedSet,
  setExperimentStarted,
} from 'src/utils/sessionStorage'

import { GQL } from 'src/api/services'
import { userPreferences } from 'src/features/user/userSlice'
import * as commonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as checkoutSelectors from 'src/redux/ducks/screens/signup/pages/Checkout/selectors'
import { getCustomAttributeValue } from 'src/utils/auth0'
import { EVENTS, getUserOs, isMobile } from 'src/utils/events'
import { logError } from 'src/utils/logError'
import Segment from 'src/utils/segment'
import { getUTMValues, sanitizeGA4EventName } from 'src/utils/utils'
import * as selectors from './selectors'
import { authUserSelector } from 'src/features/auth/authSlice'

const getApplicationState = () => select(state => state)

export function* checkValidZipCode({ payload }) {
  try {
    const { zipcode, email } = payload,
      validateZipCodeResponse = yield call(GQL.validateZipCode, zipcode)

    if (!validateZipCodeResponse) throw Error('Failed to validate zip code')

    if (!validateZipCodeResponse.zipcodeValidation) {
      logError("Zip code not covered", { zipcode, email })

      const errorCode = 'ZIP_CODE_DO_NOT_COVER'
      yield put(
        validZipCodeFailed({
          isValid: validateZipCodeResponse.zipcodeValidation,
          errorCode,
          data: { zipcode, email },
        }),
      )
    } else {
      yield put(
        validZipCodeSuccess({
          isValid: validateZipCodeResponse.zipcodeValidation,
          zipcode,
          email,
        }),
      )
    }
  } catch (error) {
    const { zipcode, email } = payload
    logError("Failed to validate zip code", { error, zipcode, email })
    const errorCode = 'UNEXPECTED_ERROR'
    yield put(
      validZipCodeFailed({
        isValid: false,
        errorCode,
        data: { zipcode, email },
      }),
    )
  }
}

export function* logging({ payload }) {
  try {
    yield put(sessionSuccess(cookUnityUuid))

    const category = payload.data ? payload.data.category : null
    const label = payload.data ? payload.data.label : null
    const action = payload.data ? payload.data.action : null
    const value = payload.data ? payload.data.value : null

    if (category && label && action) {
      yield ReactGA.ga('trackNewFunnel.send', 'event', {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: value,
      })
      yield ReactGA.ga('trackNewFunnelForTesting.send', 'event', {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: value,
      })

      yield ReactGA4.event({
        category,
        label,
        action: sanitizeGA4EventName(action),
        value,
      })
    }

    const toAnalytics = payload?.toAnalytics?.sendEvent || false
    const toAnalyticsAdapter = payload?.toAnalytics?.adapter || null
    const toAnalyticsData = payload?.toAnalytics?.data || []
    if (toAnalytics && toAnalyticsAdapter === 'bulkEvents') {
      if (!Array.isArray(toAnalyticsData)) return

      yield Promise.all(
        toAnalyticsData.map(async row => {
          await ReactGA.ga('trackNewFunnel.send', 'event', {
            eventCategory: 'funnelExperiment',
            eventLabel: row.currentVariant,
            eventAction: row.name,
          })
          await ReactGA.ga('trackNewFunnelForTesting.send', 'event', {
            eventCategory: 'funnelExperiment',
            eventLabel: row.currentVariant,
            eventAction: row.name,
          })

          await ReactGA4.event({
            category: 'funnelExperiment',
            label: row.currentVariant,
            action: sanitizeGA4EventName(row.name),
          })
        }),
      )
    }
  } catch (error) {
    logError(error)
  }
}

export function* tracking({ payload }) {
  try {
    if (!payload.eventName) throw Error('Event name missing')

    const applicationState = yield getApplicationState()
    const signUpInitData = commonSelectors.getSignUpInitData(applicationState)
    const userSelectedData = commonSelectors.getUserSelectedData(
      applicationState,
    )

    const user = authUserSelector(applicationState) || {}

    const eventName = payload.eventName
    const userEmail =
      user.email || userSelectedData.email || signUpInitData.email || null
    const userMagentoId = getCustomAttributeValue(user, 'magento_id') || null
    const device = isMobile() ? ' Mobile Web' : 'Web Desktop'
    const deviceOs = getUserOs()

    const event = {
      user_id: userMagentoId,
      user_email: userEmail,
      device: device,
      device_os: deviceOs,
      ...payload.eventData,
    }

    Segment.track(eventName, event)

    // Experiment event that only tracks the first time a user sees an experiment
    if (eventName === EVENTS.experimentViewed) {
      try {
        if (event.experiment_id && event.variation_name) {
          const anonymousId = cookUnityUuid
          if (!isExperimentStartedSet(event.experiment_id, anonymousId)) {
            setExperimentStarted(
              event.experiment_id,
              anonymousId,
              event.variation_name,
            )
            Segment.track(EVENTS.experimentStarted, event)
          }
        } else {
          throw new Error(
            'Invalid event validation in experiment tracking data',
          )
        }
      } catch (error) {
        logError(error)
      }
    }

    yield put(trackingSuccess())
  } catch (error) {
    logError(error)
    yield put(trackingFailed({ error }))
  }
}

export function* identify() {
  try {
    const applicationState = yield getApplicationState()
    const zipCodeData = selectors.getZipCode(applicationState)
    const signUpInitData = commonSelectors.getSignUpInitData(applicationState)
    const couponData = commonSelectors.getCoupon(applicationState)
    const userSelectedData = commonSelectors.getUserSelectedData(
      applicationState,
    )
    const preferenceQuizResponseData = commonSelectors.preferencesQuizResponses(
      applicationState,
    )
    const checkoutAddressData = checkoutSelectors.getAddressData(
      applicationState,
    )
    const createUserData = commonSelectors.getCreateUserData(applicationState)

    const utmValues = getUTMValues()
    const user = authUserSelector(applicationState)
    const userStatePreferences = userPreferences(applicationState)

    const zipCode =
      checkoutAddressData.zipcode || signUpInitData.zipcode || zipCodeData || userSelectedData.zipcode
    const isZipcodeNotReached = userSelectedData.isZipcodeNotReached
    const userEmail =
      user?.email || userSelectedData.email || signUpInitData.email
    const userMagentoId = user && getCustomAttributeValue(user, 'magento_id')

    const utmData = {
      utm_campaign: utmValues.utm_campaign || undefined,
      utm_source: utmValues.utm_source || undefined,
      utm_medium: utmValues.utm_medium || undefined,
      utm_content: utmValues.utm_content || undefined,
    }

    const initData = {
      user_address_delivery_zone: signUpInitData.ring?.is_local
        ? 'local'
        : 'regional',
      user_address_ring_id: signUpInitData.ring?.id,
      user_address_zipcode: zipCode,
      user_store_id: signUpInitData.ring?.store_id,
      user_store_name: signUpInitData.store?.name,
      user_store_timezone: signUpInitData.store?.timezone,
    }

    const referralData = {
      referral_code: couponData?.isEligible
        ? couponData?.couponCode
        : undefined,
      user_is_referral: couponData?.isEligible ? true : false,
    }

    const userData = {
      anonymous_id: cookUnityUuid,
      user_id: userMagentoId,
      subscriber_id: createUserData?.id,
      email: userEmail,
      first_name: user?.given_name,
      last_name: user?.family_name,
      name: user?.given_name && `${user?.given_name} ${user?.family_name}`,
      user_creation_date: user?.updated_at,
      has_sms_enabled: userStatePreferences?.hasSmsEnable,
      is_zipcode_not_reached: isZipcodeNotReached,
    }

    const planData = {
      plan_id: userSelectedData.selectedPlan?.id,
      plan_name: userSelectedData.selectedPlan?.title,
      plan_billing_amount: userSelectedData.selectedPlan?.price,
      plan_size: userSelectedData.selectedPlan?.mealsPerDelivery,
      plan_weekly_deliveries: userSelectedData.selectedPlan?.deliveries,
      plan_status: createUserData?.id && 'active',
      plan_trial: createUserData?.id ? true : false,
    }

    const deliveryData = {
      user_delivery_day: userSelectedData.startDay.label?.split(',')[0],
      user_cutoff_day:
        userSelectedData.startDay.cutoff &&
        new Date(userSelectedData.startDay.cutoff.time).toLocaleString(
          'en-us',
          { weekday: 'long' },
        ),
    }

    const quizData = {
      preferences_selected: preferenceQuizResponseData.proteins?.map(
        answer => answer?.name,
      ),
    }

    const addressData = {
      user_phone: checkoutAddressData.phone,
      user_address:
        checkoutAddressData.address &&
        checkoutAddressData.address +
          (checkoutAddressData.apt ? ', ' + checkoutAddressData.apt : ''),
      user_address_city: checkoutAddressData.city,
      user_address_state: checkoutAddressData.state,
      user_delivery_instructions: userSelectedData.deliveryOption
        ? userSelectedData.deliveryOption +
          (checkoutAddressData.deliveryInstructions
            ? ' ' + checkoutAddressData.deliveryInstructions
            : '')
        : undefined,
      user_delivery_window: userSelectedData.selectedTimesLot?.label,
    }

    Segment.identify(
      userMagentoId,
      Object.assign(
        {},
        utmData,
        initData,
        referralData,
        userData,
        planData,
        deliveryData,
        quizData,
        addressData,
      ),
    )

    yield put(identifySuccess())
  } catch (error) {
    logError(error)
    yield put(identifyFailed({ error }))
  }
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(VALID_ZIP_CODE.start, checkValidZipCode),
    takeEvery(LOGGING.start, logging),
    takeEvery(TRACKING.start, tracking),
    takeEvery(IDENTIFY.start, identify),
  ])
}
