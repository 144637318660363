import React from 'react'
import { useSelector } from 'react-redux'
import { CuiImage, CuiMealImage, CuiQuantitySelector } from 'front-lib'
import {
  addMeal,
  mealsSelectedQuantitySelector,
  mealsSelectedSelector,
  removeMeal,
} from 'src/features/meals/mealsSlice'
import './CartContent.scss'
import { useDispatch } from 'react-redux'
import { planSelectedSelector } from 'src/features/plans/plansSlice'
import { getMealPlanSize } from 'src/utils/mealUtils'
import { useVariant } from '@unleash/proxy-client-react'
import { logError } from 'src/utils/logError'

const basePathImg = process.env.REACT_APP_IMG_STORAGE
const ErrorImagePath = 'https://static.cookunity.com/cross/front-lib/images/no-image.png'

const CartContent = () => {
  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');
  const dispatch = useDispatch()
  const mealsSelected = useSelector(mealsSelectedSelector)
  const mealsSelectedQuantity = useSelector(mealsSelectedQuantitySelector);
  const planSelected = useSelector(planSelectedSelector)

  const handleAddMeal = meal => {
    dispatch(addMeal(meal))
  }

  const handleRemoveMeal = meal => {
    dispatch(removeMeal(meal))
  }

  const trackMealErrorImage = (imgError) => {
    const error = new Error(`Meal image id: ${mealsSelected.entity_id}`)
    const context = { message: 'error on CartContent', mealsSelected, ...imgError }
    logError(error, context)
  }

  return (
    <div className="cart-content">
      {mealsSelected?.map(mealSelected => (
        <div key={mealSelected.id} className="cart-content__product">
          <CuiMealImage className="cart-content__product__image">
            {isTreatment ? (
              <CuiImage
                basePath={basePathImg}
                relativePath={mealSelected.image_path + mealSelected.image}
                noImageSrc={ErrorImagePath}
                alt={mealSelected.name}
                width="80"
                height="80"
                loading="lazy"
                onError={trackMealErrorImage}
                config={{ width: 80, height: 80, aspectRatio: '1:1'}}
              />
            ) : (
              <CuiImage
                src={mealSelected.imageFullPath}
                alt={mealSelected.name}
                width="80"
                height="80"
                loading="lazy"
                onError={trackMealErrorImage}
              />
            )}
          </CuiMealImage>

          <p className="cart-content__product__description">
            <b>{mealSelected.name}</b>
            {` ${mealSelected.shortDescription}`}
          </p>

          <CuiQuantitySelector
            value={mealSelected.quantity}
            onAddItem={() => handleAddMeal(mealSelected)}
            onRemoveItem={() => handleRemoveMeal(mealSelected)}
            max={getMealPlanSize(planSelected) - mealsSelectedQuantity + mealSelected.quantity}
            showBorder
            className="cart-content__product__actions"
          />
        </div>
      ))}
    </div>
  )
}

export default CartContent
