import { createSelector } from 'reselect'

const getCheckoutPageState = state =>
  state.getIn(['signUpScreen', 'checkoutPage'])

export const checkoutPage = createSelector(getCheckoutPageState, state => state)

const getFormDataState = state => getCheckoutPageState(state).get('formData')
export const getFormData = createSelector(getFormDataState, state => state)

const getAddressState = state => getCheckoutPageState(state).get('address')
export const getAddressStatus = createSelector(getAddressState, state =>
  state.get('start'),
)
export const getAddressData = createSelector(getAddressState, state =>
  state.get('data'),
)
export const getAddressError = createSelector(getAddressState, state =>
  state.get('error'),
)

export const isAddressSuccess = createSelector(getAddressState, state =>
  state.get('success'),
)

const getCardState = state => getCheckoutPageState(state).get('card')
export const getCardStatus = createSelector(getCardState, state =>
  state.get('start'),
)
export const getCardData = createSelector(getCardState, state =>
  state.get('data'),
)
export const getCardError = createSelector(getCardState, state =>
  state.get('error'),
)

const getFormStepState = state => getCheckoutPageState(state).get('formSteps')
export const getSteps = createSelector(getFormStepState, state => state)

const getCouponDataState = state => getCheckoutPageState(state).get('couponData')
export const getCouponStatus = createSelector(getCouponDataState, state =>
  state.get('start'),
)
export const getCouponData = createSelector(getCouponDataState, state =>
  state.get('data'),
)
export const getCouponError = createSelector(getCouponDataState, state =>
  state.get('error'),
)
export const getCouponSuccess = createSelector(getCouponDataState, state =>
  state.get('success'),
)

const getGiftCardState = state =>
  getCheckoutPageState(state).get('validateGiftCard')
export const getGiftcardData = createSelector(getGiftCardState, state =>
  state.get('data'),
)
export const getGiftcardCredit = createSelector(
  getGiftcardData,
  data => (data && data.amount) || 0,
)
