import React from 'react'
import { CuiLink } from 'front-lib'
import Accordion from './Accordion'
import SlickTitleCui from '../SlickTitleCui'

import './common-questions-cui.scss'

const COMMON_QUESTIONS_TITLE = 'Most Common Questions'
const CTA_TEXT = 'Go to FAQs'

const CommonQuestionsCui = ({ options, title = COMMON_QUESTIONS_TITLE }) => (
  <section id="common-questions-cui" className="container">
    <div className="container">
      <SlickTitleCui title={title} center />
      <Accordion className="common-questions-accordion" options={options} />
      <div className="button">
        <CuiLink
          size="large"
          color="tertiary"
          href="https://support.cookunity.com"
          onClick={e => {
            e.preventDefault()
            window.location.href = e.currentTarget.href
          }}
        >
          {CTA_TEXT}
        </CuiLink>
      </div>
    </div>
  </section>
)

export default CommonQuestionsCui
