import {createSelector} from "reselect";

const getPreOrderPageState = state => state.getIn(["signUpScreen", "preOrderPage"]);

const getCartState = state => getPreOrderPageState(state).get("cart");
export const getCart = createSelector(
  getCartState,
  state => state
);

export const getCartPremiumFeesTotal = createSelector(getCartState, state => {
  const products = state.products

  if (!products) return 0

  return products.reduce((tot, product) => {
    const premiumFee = product.data.premium_fee || 0
    const quantity = product.qty || 1
    return tot + (premiumFee * quantity)
  }, 0)
})

export const getSomeProductChoseForMe = createSelector(getCartState, state => {
  const products = state.products

  return products.some(product => product.data.choseForMe)
})

const getChooseForMeState = state => getPreOrderPageState(state).get("chooseForMe");
export const getChooseForMeStatus =
  createSelector(getChooseForMeState, state => state.get('start') && !state.get('success'));