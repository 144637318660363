import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { validateZipCode } from './addressAPI'

const initialState = {
  zipCode: null,
  isZipCodeValid: null,
  street: null,
  apt: null,
  city: null,
  state: null,
  ring: null,
  store: null,
  status: 'idle',
  phone: null,
}

export const fetchZipCodeValidation = createAsyncThunk(
  'address/fetchZipCodeValidation',
  async ({ zipCode }) => {
    const response = await validateZipCode({ zipCode })
    const { stores, ...restResponse } = response
    const store = stores.find(store => store.id === response.ring.storeId)
    return { store, ...restResponse }
  },
)

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setZipCode: (state, { payload }) => {
      state.zipCode = payload
    },
    setAddress: (state, { payload }) => {
      state.zipCode = payload.zipCode
      state.street = payload.street
      state.apt = payload.apt
      state.city = payload.city
      state.state = payload.state
      state.phone = payload.phone
    },
    setZipCodeValidation: (state, { payload }) => {
      state.isZipCodeValid = true
      state.ring = payload.ring
      state.store = payload.store
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchZipCodeValidation.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchZipCodeValidation.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.isZipCodeValid = payload.isValid
        state.ring = payload.ring
        state.store = payload.store
      })
  },
})

export const {
  setZipCode,
  setAddress,
  setZipCodeValidation,
} = addressSlice.actions

export const addressSelector = state => ({
  zipCode: state.get('address').zipCode,
  street: state.get('address').street,
  apt: state.get('address').apt,
  city: state.get('address').city,
  state: state.get('address').state,
  phone: state.get('address').phone,
})
export const zipCodeSelector = state => state.get('address').zipCode
export const isValidZipCodeSelector = state =>
  state.get('address').isValidZipCode
export const ringSelector = state => state.get('address').ring
export const storeSelector = state => state.get('address').store

export default addressSlice.reducer
