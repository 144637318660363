import React from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { datadogRum } from '@datadog/browser-rum'

import { unregister } from './serviceWorker'
import UnleashProvider from './app/providers/UnleashProvider'
import StoreProvider from './app/providers/StoreProvider'
import StripeProvider from './app/providers/StripeProvider'
import RouterProvider from './app/providers/RouterProvider'
import LoaderProvider from './app/providers/LoaderProvider'
import MaintenanceModeProvider from './app/providers/MaintenanceModeProvider'
import GovXOAuthProvider from './app/providers/GovXOAuthProvider'

const environment = process.env.REACT_APP_ENVIRONMENT || 'dev'

if (process.env.NODE_ENV !== 'development' || process.env.NODE_ENV !== 'dev') {
  datadogRum.init({
    applicationId: 'c944ab5f-f0d0-414b-a489-133bf3ae7c16',
    clientToken: 'pub92f288450306414789443215735f39b7',
    site: 'datadoghq.com',
    service: 'acquisition-web',
    env: environment,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    trackSessionAcrossSubdomains: true,
    // trackUserInteractions: true,
    // allowedTracingUrls: [/https:\/\/.*\.cookunity\.com/],
  })
}

ReactGA.initialize(
  [
    {
      trackingId: process.env.REACT_APP_GA_ID,
      gaOptions: {
        name: 'trackNewFunnel',
        cookieDomain: 'cookunity.com',
      },
    },
    {
      trackingId: process.env.REACT_APP_GA_ID_FOR_TESTING,
      gaOptions: {
        name: 'trackNewFunnelForTesting',
        cookieDomain: 'cookunity.com',
      },
    },
  ],
  {
    debug: process.env.NODE_ENV !== 'production',
    alwaysSendToDefaultTracker: false,
  },
)

ReactGA4.initialize(process.env.REACT_APP_GA4_ID)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StoreProvider>
    <LoaderProvider>
      <UnleashProvider>
        <GovXOAuthProvider>
          <MaintenanceModeProvider>
            <StripeProvider>
              <RouterProvider />
            </StripeProvider>
          </MaintenanceModeProvider>
        </GovXOAuthProvider>
      </UnleashProvider>
    </LoaderProvider>
  </StoreProvider>,
)

unregister()
