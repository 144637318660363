import duck from "./duck";

export const LOAD_PRODUCT = {
  start: duck.defineType('LOAD_PRODUCT_START'),
  success: duck.defineType('LOAD_PRODUCT_SUCCESS'),
  failed: duck.defineType('LOAD_PRODUCT_FAILED'),
  reset: duck.defineType('LOAD_PRODUCT_RESET'),

  startFilter: duck.defineType('START_PRODUCT_FILTER'),
  startFilterBy: duck.defineType('START_PRODUCT_FILTER_BY'),
  filter: duck.defineType('FILTER_PRODUCT_FILTER'),
  filterReset: duck.defineType('FILTER_PRODUCT_RESET'),
  resetFilterBy: duck.defineType('FILTER_BY_PRODUCT_RESET'),
};
