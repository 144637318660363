
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import ToggleButtonGroup from 'src/components/ToggleButtonGroup'
import { zipCodeSelector } from 'src/features/address/addressSlice'
import { fetchTimeSlots, setTimeSlotSelected, timeSlotSelectedSelector, timeSlotsSelector } from 'src/features/delivery/deliverySlice'
import ToggleButton from 'src/components/ToggleButton'

const ConfirmDeliveryWindow = () => {
    const dispatch = useDispatch()
    const zipCode = useSelector(zipCodeSelector)
    const timeSlots = useSelector(timeSlotsSelector)
    const timeSlotSelected = useSelector(timeSlotSelectedSelector)

    const handleSelect = (e, value) => {
        const optionSelected = timeSlots.find(timeSlot => timeSlot.label === value)
        dispatch(setTimeSlotSelected(optionSelected))
    }

    useEffect(() => {
        if (!timeSlots && zipCode) {
            dispatch(fetchTimeSlots({ zipCode }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCode])

    useEffect(() => {
        if (timeSlots && !timeSlotSelected) {
            dispatch(setTimeSlotSelected(timeSlots[0]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlots])

    return (
        <div className="delivery-window__container-cui">
            <h3 className="rebrand">Delivery Window</h3>
            <div className='delivery-window__options-row'>

                <ToggleButtonGroup
                    value={timeSlotSelected?.label}
                    onChange={handleSelect}
                    className="plan-selection"
                >
                    {timeSlots?.map(({ label }) => (
                        <ToggleButton key={label} value={label}       className="plan-selection--label" >
                            {label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </div>
    )
}


export default ConfirmDeliveryWindow