import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './Fade.scss'

const Fade = ({ in: inProp, timeout = 200, children, ...rest }) => {
  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames="fade"
      unmountOnExit
      {...rest}
    >
      {children}
    </CSSTransition>
  )
}

export default Fade
