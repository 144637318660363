import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from '../../../components/Loader'
import { Outlet, useSearchParams } from 'react-router-dom'

const AuthenticationGuard = ({ children }) => {
  let [searchParams] = useSearchParams()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return <Loader />
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
        couponCode: searchParams.get('coupon'),
      },
    })
    return <Loader />
  }

  return children || <Outlet />
}

export default React.memo(AuthenticationGuard)
