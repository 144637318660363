import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import './EditDelivery.scss'
import AutocompleteAddress from 'src/screens/signup/components/shared/AutocompleteAddress'
import { CuiButton } from 'front-lib'
import { useSelector } from 'react-redux'
import {
  addressSelector,
  ringSelector,
  setAddress,
  storeSelector,
} from 'src/features/address/addressSlice'
import { useDispatch } from 'react-redux'
import { updateAddress, userSelector } from 'src/features/user/userSlice'
import useTrackEvent from 'src/hooks/useTrackEvent'

const EditDelivery = ({ error, loading, onSubmit }) => {
  const dispatch = useDispatch()
  const address = useSelector(addressSelector)
  const store = useSelector(storeSelector)
  const ring = useSelector(ringSelector)
  const user = useSelector(userSelector)
  const phone = user.addresses[0].telephone
  const trackDeliveryAddressChanged = useTrackEvent({
    eventName: 'Resurrection - Delivery Address Changed',
  })

  const coordinates = { lat: store?.latitude, lng: store?.longitude }
  const getFormValidationSchema = Yup.object().shape({
    address: Yup.string()
      .required('Required')
      .test('address', 'Invalid Address,', value => {
        if (value) {
          const regex = /(P\s?.*O\s?.*BOX\s?.*)/gi
          return !!!regex.test(value)
        } else {
          return true
        }
      }),
    apt: Yup.string().test('apt', 'Invalid Address,', value => {
      if (value) {
        const regex = /(P\s?.*O\s?.*BOX\s?.*)/gi
        return !!!regex.test(value)
      } else {
        return true
      }
    }),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipcode: Yup.number()
      .typeError('Invalid zipcode')
      .required('Required'),
  })

  const formInitValues = {
    address: address?.street || '',
    apt: address?.apt || '',
    city: address?.city || '',
    state: address?.state || '',
    zipcode: address?.zipCode || '',
  }

  const handleSubmit = data => {
    dispatch(
      updateAddress({
        address: {
          city: data.city,
          floor: data.apt,
          state: data.state,
          street: data.address,
          zipcode: data.zipcode,
          phone,
        },
      }),
    )
    dispatch(
      setAddress({
        street: data.address,
        apt: data.apt,
        city: data.city,
        state: data.state,
        zipCode: data.zipcode,
        phone,
      }),
    )
    trackDeliveryAddressChanged({
      user_address: data.address,
      user_address_city: data.city,
      user_address_state: data.state,
      store_id: store?.id,
      ring_id: ring?.id,
      user_zipcode: data.zipcode,
    })
    onSubmit()
  }

  return (
    <div className="edit-delivery">
      <h2>Delivery Address</h2>
      <Formik
        enableReinitialize={true}
        initialValues={formInitValues}
        onSubmit={handleSubmit}
        validationSchema={getFormValidationSchema}
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          const hasError = fieldName => errors[fieldName] && touched[fieldName]

          return (
            <form className="edit-delivery__form" onSubmit={handleSubmit}>
              {error && <div className="error-container">{error}</div>}
              <div className="edit-delivery__form__row edit-delivery__form__no-wrap">
                <div className="col-6">
                  <label htmlFor="address">Address</label>
                  <AutocompleteAddress
                    value={values.address}
                    disabled={loading}
                    className={
                      hasError('address')
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="address"
                    error={errors.address}
                    touched={touched.address}
                    aria-describedby="addressHelp"
                    placeholder="Eg: 2354 54th street"
                    coordinates={coordinates}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    tempResurrectionZipCode={values.zipcode}
                  />
                  {hasError('address') && (
                    <div className="invalid-feedback">{errors.address}</div>
                  )}
                </div>
                <div className="col-6">
                  <label htmlFor="apt">Apt / Suite / Office</label>
                  <input
                    disabled={loading}
                    id="apt"
                    type="text"
                    className={
                      hasError('apt')
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    aria-describedby="aptHelp"
                    placeholder="Eg: 3A"
                    value={values.apt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {hasError('apt') && (
                    <div className="invalid-feedback">{errors.apt}</div>
                  )}
                </div>
              </div>
              <div className="edit-delivery__form__row">
                <div className="col-8">
                  <div className="col-6">
                    <label htmlFor="city">City</label>
                    <input
                      disabled={loading}
                      type="text"
                      className={
                        hasError('city')
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="city"
                      aria-describedby="cityHelp"
                      placeholder="Eg: New York"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('city') && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div>

                  <div className="col-6">
                    <label htmlFor="state">State</label>
                    <input
                      disabled={loading}
                      type="text"
                      id="state"
                      className={
                        hasError('state')
                          ? 'form-control is-invalid'
                          : 'form-control '
                      }
                      placeholder="Eg: New York"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {hasError('state') && (
                      <div className="invalid-feedback">{errors.state}</div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="zipcode">Zipcode</label>
                  <input
                    type="text"
                    id="zipcode"
                    className={
                      hasError('zipcode')
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    placeholder="Eg: 10012"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {hasError('zipcode') && (
                    <div className="invalid-feedback">{errors.zipcode}</div>
                  )}
                </div>
              </div>

              <div className="edit-delivery__form__button">
                <CuiButton
                  type="submit"
                  disabled={loading}
                  className="cui-button"
                >
                  {!loading ? (
                    'Save'
                  ) : (
                    <i className="spinner fas fa-circle-notch" />
                  )}
                </CuiButton>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default EditDelivery
