import React, { useEffect, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Loader from 'src/components/Loader'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import useExperiment from 'src/hooks/useExperiment'
import { formatQuestionsMap, formatResponseByQueryString } from './utils'
import _questions from './questions'
import { autoSelectDeliveryDate } from 'src/utils/utils'
import QuizCui from './QuizCui'
import { EXPERIMENTS } from 'src/constants/experiments'

const PreferencesQuizContainer = props => {
  const {
    actions,
    isLoading,
    preferencesQuizData,
    setShowQuiz,
    validDates,
    preferencesQuizResponses,
  } = props
  const location = useLocation()

  const { isTreatment: simplifiedDietsQuizExperiment } = useExperiment(
    EXPERIMENTS.simplifiedDietsQuizExperiment,
    {
      trackOnMount: true,
    },
  )

  const { isTreatment: eatEverythingAtTopOfQuizExperiment } = useExperiment(
    EXPERIMENTS.eatEverythingAtTopOfQuiz,
    {
      trackOnMount: true,
    },
  )

  useEffect(() => {
    actions.initPreferencesQuiz()
  }, []) // eslint-disable-line

  const [questionsMap, setQuestionsMap] = useState([])
  const [questions, setQuestions] = useState([])
  useEffect(() => {
    if (preferencesQuizData) {
      const dietsQuestionIndex = _questions.findIndex(
        question => question.name === 'diets',
      )
      const proteinsQuestionIndex = _questions.findIndex(
        question => question.name === 'proteins',
      )
      const allergensQuestionIndex = _questions.findIndex(
        question => question.name === 'allergens',
      )

      const questions = _questions.slice(
        dietsQuestionIndex,
        proteinsQuestionIndex + 1,
      )

      const eatEverythingOption = {
        id: -2,
        name: 'None',
        label: 'I eat everything!',
      }

      const allergenOptions = _questions[allergensQuestionIndex].options

      if (!allergenOptions.find(diet => diet.id === -2)) {
        if (eatEverythingAtTopOfQuizExperiment) {
          allergenOptions.unshift(eatEverythingOption)
        } else {
          allergenOptions.push(eatEverythingOption)
        }
      }

      questions.push(_questions[allergensQuestionIndex])

      setQuestions(questions)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    const formattedQuestionsMap = formatQuestionsMap(
      preferencesQuizData,
      questions,
      simplifiedDietsQuizExperiment,
      eatEverythingAtTopOfQuizExperiment,
    )
    const response = formatResponseByQueryString(
      location.search,
      formattedQuestionsMap,
    )

    setQuestionsMap(formattedQuestionsMap)
    const hasPreferences =
      preferencesQuizResponses.diets ||
      preferencesQuizResponses.proteins ||
      preferencesQuizResponses.allergens
    if (response && !hasPreferences) {
      actions.setResponse(response)
      const autoSelectedDate = autoSelectDeliveryDate(validDates)

      actions.setUserSelectedDataStart({
        startDay: autoSelectedDate,
        days: [autoSelectedDate.day],
      })
      setShowQuiz(false)
    }
  }, [isLoading, questions]) // eslint-disable-line

  const setShowQuizCallback = useCallback(() => {
    setShowQuiz()
  }, [setShowQuiz]) // eslint-disable-line

  if (isLoading || questionsMap.length === 0) return <Loader />

  return (
    <QuizCui
      setShowQuiz={setShowQuizCallback}
      questions={questionsMap}
      {...props}
    />
  )
}

const mapStateToProps = state => ({
  isLoading: signUpCommonSelectors.getPreferencesQuizStatus(state),
  signUpInitData: signUpCommonSelectors.getSignUpInitData(state),
  preferencesQuizData: signUpCommonSelectors.getPreferencesQuizData(state),
  validDates: signUpCommonSelectors.getDeliveryDays(state),
  preferencesQuizResponses: signUpCommonSelectors.preferencesQuizResponses(
    state,
  ),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initPreferencesQuiz: signUpCommonActionCreators.startPreferencesQuiz,
      setResponse: signUpCommonActionCreators.preferencesQuizSetResponse,
      resetResponse: signUpCommonActionCreators.preferencesQuizResetResponse,
      initChefsList: signUpCommonActionCreators.initChefsList,
      updateChefsList: signUpCommonActionCreators.updateChefsList,
      sendTracking: creatorCommons.trackingStart,
      sendIdentify: creatorCommons.identifyStart,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreferencesQuizContainer)
