import { useVariant } from '@unleash/proxy-client-react'
import React from 'react'
import noMealPlaceholder from 'src/assets/no-image.jpg'
import ImageWithDefaultPlaceHolder from 'src/components/ImageWithDefaultPlaceHolder'


const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const IMG_STORAGE = process.env.REACT_APP_IMG_STORAGE

const ConfirmMeals = ({
  orderProductQty,
  image,
  name,
  shortDescription
}) => {
  const variant = useVariant('use-imgix');
  const isTreatment = (variant && variant.enabled && variant.name === 'TREATMENT');

  const title = [
    name.trim(),
    ...(shortDescription ? [shortDescription.trim()] : []),
  ].join(' ')

  return (
    <div className="cart_check__container">
      <ImageWithDefaultPlaceHolder
        src={`${(isTreatment ? IMG_STORAGE : IMG_URL_SOURCE)}${image}`}
        srcDefault={noMealPlaceholder}
        alt="meal"
        className="meal-image"
      />
      <div className='cart_check__container-name'>
        <p className="rebrand title">{title}</p>
        <p className="rebrand circle"> {orderProductQty} </p>
      </div>
    </div>
  )
}

export default ConfirmMeals
