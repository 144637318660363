export const DELIVERY_DAY_LOGIC_ID = 'gro-sc-24261'
export const DELIVERY_DAY_LOGIC_NAME = 'Growth - Delivery Date Selection Page - Holiday Logic'
export const DELIVERY_DAY_LOGIC_VARIANTS = {
  CONTROL: 'control',
  TREATMENT: 'treatment-1',
}

export const USE_MENU_SERVICE_ID = 'gro-sc-25624'
export const USE_MENU_SERVICE_NAME = 'Growth - Funnel - Meals from Menu Service'
export const USE_MENU_SERVICE_VARIANTS = {
  CONTROL: 'control',
  TREATMENT: 'treatment-1',
}

export const experimentData = [
  {
    active: false,
    name: 'example',
    date: '2020-10-28',
    variants: ['A', 'B'],
  },
  {
    active: false,
    name: 'paypal',
    date: '2021-04-14',
    variants: ['A'],
  },
  {
    active: false,
    name: DELIVERY_DAY_LOGIC_ID,
    date: '2022-06-22',
    variants: [
      DELIVERY_DAY_LOGIC_VARIANTS.CONTROL,
      DELIVERY_DAY_LOGIC_VARIANTS.TREATMENT,
    ],
    period: {
      start: '2022-06-23',
      end: '2022-07-04'
    }
  },
  {
    active: false,
    name: USE_MENU_SERVICE_ID,
    date: '2022-08-08',
    variants: [
      USE_MENU_SERVICE_VARIANTS.CONTROL,
      USE_MENU_SERVICE_VARIANTS.TREATMENT,
    ],
  }
]
