import { useVariant } from '@unleash/proxy-client-react'
import { useCallback, useEffect } from 'react'
import useTrackEvent from './useTrackEvent'
import { EVENTS } from 'src/utils/events'
import {
  checkVariantIsTreatment,
  parseVariantPayload,
  trackExperimentWithData,
} from 'src/utils/experiments'

const useExperiment = (experimentName, { trackOnMount } = {}) => {
  const { enabled, name, payload } = useVariant(experimentName)
  const trackEvent = useTrackEvent({
    eventName: EVENTS.experimentViewed,
  })

  const trackExperimentViewed = useCallback(
    extraData => {
      trackExperimentWithData(trackEvent, experimentName, {
        variation_name: name,
        ...extraData,
      })
    },
    [experimentName, name, trackEvent],
  )

  useEffect(() => {
    if (trackOnMount && enabled && name !== 'disabled') {
      trackExperimentViewed({ variation_name: name })
    }
  }, [enabled, name, trackExperimentViewed, trackOnMount])

  const isTreatment = checkVariantIsTreatment(name)
  const parsedPayload = parseVariantPayload(payload)

  return {
    isEnabled: enabled,
    variantName: name,
    isTreatment,
    payload: parsedPayload,
    trackExperimentViewed,
  }
}

export default useExperiment
