import React from 'react'
import Portal from 'src/components/Portal/Portal'
import Backdrop from 'src/components/Backdrop/Backdrop'
import Drawer from 'src/components/Drawer/Drawer'
import CartHeader from './CartHeader/CartHeader'
import CartContent from './CartContent/CartContent'
import CartFooter from './CartFooter/CartFooter'
import './Cart.scss'

const Cart = ({ isOpen, onClose, onContinue }) => {
  return (
    <Portal>
      <Backdrop open={isOpen} onClick={onClose} />
      <Drawer
        open={isOpen}
        onClose={onClose}
        className={'cart'}
        direction="right"
      >
        <CartHeader />
        <CartContent />
        <CartFooter onClick={onContinue} />
      </Drawer>
    </Portal>
  )
}

export default Cart
