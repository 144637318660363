import React from 'react';

import Alert from '../../../../../../assets/icons/alert-rebrand.png';
import blueAlert from '../../../../../../assets/icons/alert-rebrand-blue.png'

export const MessageContainerCui = ({ mobile, message, blue }) => (
  <div className={`message-container-cui ${mobile ? 'mobile' : ''} ${blue? 'blue' : ''}`}>
    <span className={blue ? 'blue' : ''} />
    <img src={blue ? blueAlert : Alert} alt="alert" />
    <p className='rebrand'>
      {message}
    </p>
  </div>
);
