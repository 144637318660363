import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal'
import {
  changeDeliveryDaySelected,
  confirmationNeededSelector,
  setConfirmationNeeded,
  setDeliveryDaySelected,
  unconfirmedDeliveryDaySelector,
} from 'src/features/delivery/deliverySlice'

const ConfirmChangeDeliveryDay = () => {
  const dispatch = useDispatch()
  const confirmationNeeded = useSelector(confirmationNeededSelector)
  const unconfirmedDeliveryDay = useSelector(unconfirmedDeliveryDaySelector)

  const handleClose = () => {
    dispatch(setConfirmationNeeded(false))
  }

  const handleConfirm = () => {
    dispatch(setDeliveryDaySelected(unconfirmedDeliveryDay))
    dispatch(changeDeliveryDaySelected({ deliveryDay: unconfirmedDeliveryDay }))
  }

  return (
    <ConfirmModal
      isOpen={confirmationNeeded}
      onClose={handleClose}
      onConfirm={handleConfirm}
      showCancelButton
    >
      <p>
        <strong>
          Please note that some of the meals you selected may not be available
          on the new delivery day
        </strong>
      </p>
    </ConfirmModal>
  )
}

export default ConfirmChangeDeliveryDay
