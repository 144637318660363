import { useState } from 'react'
import { CuiIcon } from 'front-lib'
import Coupon from '../Coupon'
import './Header.scss'

import GovxLogo from 'src/assets/img/govx_small_logo.png'

function Header({ coupon, onChange, onSubmit, govx }) {
  const [showCoupon, setShowCoupon] = useState(false)

  function toggleShowCoupon() {
    setShowCoupon(!showCoupon)
  }

  return (
    <header className="select-plan__header">
      <div
        className={`select-plan__header__container  ${
          showCoupon ? 'header_open' : 'header_close'
        }`}
        onClick={toggleShowCoupon}
        style={{ cursor: 'pointer ' }}
      >
        <div className={`select-plan__header__container__content`}>
          <div className="select-plan__header__container__content__icon-container">
            <CuiIcon
              name="percent"
              className="select-plan__header__container__content__icon-container--icon"
            />
          </div>
          <p className="select-plan__header__container__content--text">
            Would you like to use another discount?
          </p>
        </div>
        <CuiIcon
          className="select-plan__header__container__content__icon-container__chevron-icon"
          name="chevronRight"
        />
      </div>

      <div className={`select-plan__header__container__content__couponinput ${showCoupon ? 'couponinput_opened' : 'couponinput_closed' }`}>
        <Coupon data={coupon} onChange={onChange} onSubmit={onSubmit} />
      </div>

      {govx && govx.isFlagEnabled && !govx.isAuthenticated && (
        <div
          className="select-plan__header__govx"
          onClick={govx.handleModalState}
          style={{ cursor: 'pointer' }}
        >
          <div className="select-plan__header__govx__row">
            <div className="select-plan__header__govx__row__col">
              <img
                src={GovxLogo}
                alt="GovX"
                className="select-plan__header__govx__logo"
              />
              <p className="select-plan__header__container__content--text">
                See special offer for military and teachers
              </p>
            </div>
            <div className="select-plan__header__govx__row__col">
              <CuiIcon name="chevronRight" />
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
