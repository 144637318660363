import { cuEatApiClient } from './client'
import gqlClient from './gqlClient'
import { applyHooks } from './hooks'
import { isMobileOrTablet } from 'front-lib'
// import queryString from 'query-string'
const applyFilter = call => call.then(res => applyHooks(res))

const subscriptionOriginType = isMobileOrTablet()
  ? 'customer_web_mobile'
  : 'customer_web_desktop'

export const saveEmailAndZip = (zip, email) =>
  applyFilter(
    cuEatApiClient(`/api/saveEmailAndZip`, 'post', {
      zipcode: zip,
      email: email,
    }),
  )

/**
 * Create an order.
 * @param {string} payload.customer_id
 * @param {string} payload.date date in format YYYY/MM/DD
 * @param {string} payload.start time in format HH:mm
 * @param {string} payload.end time in format HH:mm
 * @param {string} payload.dorman define
 * @param {string} payload.special special
 */
export const createOrder = payload =>
  gqlClient.mutation(
    `
    mutation createOrder($order: CreateOrderInput!, $origin: OperationOrigin) {
      createOrder(order: $order, origin: $origin) {
        __typename
        ... on OrderCreation{
            id
        },
        ... on OrderCreationError {
            error,
            code
        }
      }
    }
  `,
    {
      order: payload,
      origin: subscriptionOriginType,
    },
  )

export const getMealDetail = id =>
  gqlClient.publicQuery(
    `
    query meal ($id: Int!){
      meal(id: $id) {
        batch_id
        entity_id
        name
        chef_firstname
        chef_lastname
        is_celebrity_chef
        category_id
        chef_id
        logopic
        calories
        price
        image
        image_path
        stock
        inStock
        meat_type
        user_rating
        rating
        stars
        weight
        reviews_count
        sku
        premium_fee
        cooking_steps {
            oven_steps
            microwave_steps
        }
        reviews_data {
            review_id
            created_at
            entity_id
            entity_pk_value
            status_id
            detail_id
            title
            detail
            nickname
            customer_id
            customer_name
            reviews
            rating
            stars
            image
        }
        specifications_detail {
            id
            label
            image
            image_png
        }
        nutritional_facts {
            calories
            servingsize
            fat
            carbs
            fiber
            protein
            sodium
            saturated_fat
            sugar
        }
        meat_category
        feature {
            name
            description
            icon
            color
            background
        }
        image_full_url
        image_key
        url_path
        chef_image_url
        chef_logo_pic_url
        meal_story
        ingredients_data {
            entity_id
            name
            value
            componentCode
            description
            filename
            image
            manufacturer
        }
      }
    }
  `,
    { id },
  )

export const getMealDetailPublic = inventoryId =>
  gqlClient.menuServiceQuery(
    `
    query getMealPublic($inventoryId: String!) {
      mealPublic(id: $inventoryId) {
        id
        inventoryId
        name
        stars
        userRating
        image
        primaryImageUrl
        mealStory
        reviews
        mealExternalId
        categoryId
        price
        sku
        premiumFee
        ingredients {
          id
          name
        }
        cookingSteps {
          microwaveSteps
          ovenSteps
        }
        nutritionalFacts {
          calories
          carbs
          fat
          fiber
          protein
          saturatedFat
          servingSize
          sodium
          sugar
        }
        specificationsDetails {
          image
          label
        }
        userReviews {
          customerFirstName
          customerLastName
          rating
          feedback
          createdAt
        }
        chef {
          id
          labelImageUrl
          firstName
          lastName
        }
      }
    }
  `,
    { inventoryId },
  )

export const setSetting = ({ key, value }) =>
  gqlClient.mutation(
    `
  mutation setSetting($key: String!, $value: String!) {
    setSetting(key: $key, value: $value) {
        ... on SetSetting {
            key
            value
        }
        ... on GenericError {
            error
        }
    }
  }
`,
    {
      key,
      value,
    },
  )

export const getChefDetail = id =>
  gqlClient.publicQuery(
    `
    query getChef($id: Int!) {
      chef(id: $id) {
        stars
        reviews
        compdesi
      }
    }
    `,
    { id },
  )
