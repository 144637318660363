/* eslint-disable jsx-a11y/anchor-is-valid */
import { CardElement, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { Formik } from 'formik'
import { isEmpty } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { Collapse } from 'reactstrap'
import * as Yup from 'yup'

import cardsLogos from 'src/assets/img/cards-logos.png'
import payPalLogo from 'src/assets/img/paypalLogoFull.png'
import payPalLogoIcon from 'src/assets/img/paypalLogoIcon.png'

import './PaymentFormCui.scss'

import Coupon from '../../../SelectPlans/components/Coupon'
import { MessageContainerCui } from '../MessageContainerCui'
import PaypalButton from './PaypalButton'

import { useFlag } from '@unleash/proxy-client-react'
import classnames from 'classnames'
import { CuiCheckbox } from 'front-lib'
import { RECAPTCHA_SCRIPT } from 'src/constants'
import { EXPERIMENTS } from 'src/constants/experiments'
import useExperiment from 'src/hooks/useExperiment'
import useScript from 'src/hooks/useScript'
import { EVENTS } from 'src/utils/events'
import { logError } from 'src/utils/logError'
import { useSelector } from 'react-redux'
import { authUserSelector } from 'src/features/auth/authSlice'
import CheckoutCoupon from '../CheckoutCoupon'

const payPalSecretToken = process.env.REACT_APP_PAYPAL_SECRET_TOKEN
const payPalEnv = process.env.REACT_APP_PAYPAL_ENV

function PaymentFormCui({
  applyCoupon,
  eraseCouponError,
  error,
  paymentOpen,
  loading,
  card,
  address,
  userFullName,
  stripe,
  elements,
  setGiftCardCode,
  coupon,
  giftcardCode: giftcardCodeFromCookie,
  addCard,
  sendTracking,
  validateCoupon,
  resetCouponValidation,
  resetGiftcardValidation,
}) {
  const authUser = useSelector(authUserSelector)
  const stripePaymentElementExperiment = useExperiment(EXPERIMENTS.stripePaymentElement);
  const applePayExperiment = useExperiment(EXPERIMENTS.stripeApplePay);
  const googlePayExperiment = useExperiment(EXPERIMENTS.stripeGooglePay);
  const { isTreatment: changeCouponInChoExperiment } = useExperiment(EXPERIMENTS.changeCouponInCho, {
    trackOnMount: true,
  })

  const formikRef = useRef()
  const paymentFormRef = useRef()

  const [payPalInfo, setPayPalInfo] = useState(null)
  const [paymentMethodType, setPaymentMethodType] = useState('creditCard')

  const paypalExp = useExperiment(
    'gro-sc-46129-growth-checkout-paypal-integration',
  )

  const captchaEnabled = useFlag(EXPERIMENTS.recaptchaFeatureFlag)

  useScript(`${RECAPTCHA_SCRIPT + process.env.REACT_APP_GOOGLE_RECAPTCHA}`, captchaEnabled)

  useEffect(() => {
    if (paypalExp && paypalExp.isEnabled) {
      sendTracking({
        eventName: EVENTS.experimentViewed,
        eventData: {
          page_name: 'Checkout Page',
          experiment_id: 'gro-sc-46129',
          experiment_name: 'Growth - Checkout - Paypal Integration',
          variation_name: paypalExp.variantName,
        },
      })
    }
  }, [paypalExp.isEnabled, paypalExp.variantName]) // eslint-disable-line

  useEffect(() => {
    if (stripePaymentElementExperiment.isEnabled) {
      sendTracking({
        eventName: EVENTS.experimentViewed,
        eventData: {
          page_name: 'Checkout Page',
          experiment_id: 'stripe-payment-element',
          experiment_name: 'Growth - Checkout - PaymentElement Integration',
          variation_name: stripePaymentElementExperiment.variantName,
        },
      })
    }
  }, [
    sendTracking,
    stripePaymentElementExperiment.isEnabled,
    stripePaymentElementExperiment.variantName,
  ])

  useEffect(() => {
    if (applePayExperiment.isEnabled) {
      sendTracking({
        eventName: EVENTS.experimentViewed,
        eventData: {
          page_name: 'Checkout Page',
          experiment_id: 'stripe-apple-pay',
          experiment_name: 'Growth - Checkout - Apple Pay Integration',
          variation_name: applePayExperiment.variantName,
        },
      })
    }
  }, [
    sendTracking,
    applePayExperiment.isEnabled,
    applePayExperiment.variantName
  ])

  useEffect(() => {
    if (googlePayExperiment.isEnabled) {
      sendTracking({
        eventName: EVENTS.experimentViewed,
        eventData: {
          page_name: 'Checkout Page',
          experiment_id: 'stripe-apple-pay',
          experiment_name: 'Growth - Checkout - Google Pay Integration',
          variation_name: googlePayExperiment.variantName,
        },
      })
    }
  }, [
    sendTracking,
    googlePayExperiment.isEnabled,
    googlePayExperiment.variantName
  ])

  useEffect(() => {
    if (error) {
      const email = authUser?.email
      const newError = new Error(`Checkout Error ${error}`)
      const context = { message: `Error: ${error}`, email }
      logError(newError, context)
    }
  }, [authUser?.email, error])

  useEffect(() => {
    eraseCouponError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (payPalInfo && formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }, [payPalInfo])

  const setPromoHandler = e => {
    e.preventDefault()
    const { value } = e.target.dataset

    if (!isEmpty(value)) {
      applyCoupon(value)
      eraseCouponError()
    }
  }

  const isValid = !isEmpty(card)

  const creditCardContainerOpen = paymentMethodType === 'creditCard'
  const payPalContainerOpen = paymentMethodType === 'paypal'

  const handleTogglePaymentMethod = () => {
    if (paymentMethodType === "creditCard") {
      setPaymentMethodType("paypal");
    } else {
      setPaymentMethodType("creditCard");
    }
  }

  return (
    <div
      className={classnames('form-container-cui', {
        active: paymentOpen,
        inactive: !paymentOpen,
        valid: isValid,
        invalid: !isValid,
      })}
    >
      <div
        id="payment"
        className="header-container"
        ref={paymentFormRef}
        tabIndex={0}
      >
        <h2 className="rebrand">
          <span
            className={classnames('step', {
              'current-step': paymentOpen,
            })}
          >
            {paymentOpen || !isValid ? '2' : '2'}
          </span>
          Payment
        </h2>
      </div>
      <Collapse
        isOpen={paymentOpen}
        onEntered={() => {
          paymentFormRef.current?.scrollIntoView({ behavior: 'smooth' })
        }}
      >
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            cardHolder: userFullName,
            couponCode: '',
            giftCardCode: giftcardCodeFromCookie || '',
            acceptTC: true,
            isCreditCard: paymentMethodType === 'creditCard',
          }}
          onSubmit={async values => {
            if (isEmpty(address) || !stripe || !elements) return

            setGiftCardCode(values.giftCardCode)

            if (stripePaymentElementExperiment.isTreatment) {
              await elements.submit();
            }

            addCard({
              stripe: stripe,
              cardInfo:
                paymentMethodType === 'creditCard'
                  ? {
                    element: stripePaymentElementExperiment.isTreatment ? elements : elements.getElement(CardElement),
                    name: values.cardHolder,
                  }
                  : null,
              paymentMethodType: paymentMethodType,
              token: payPalInfo && payPalInfo.token,
              device: payPalInfo && payPalInfo.device,
              isPaymentElementEnabled: stripePaymentElementExperiment.isTreatment,
              captchaEnabled,
            })

            return
          }}
          validationSchema={Yup.object().shape({
            isCreditCard: Yup.boolean(),
            cardHolder: Yup.string().when('isCreditCard', {
              is: true,
              then: Yup.string()
                .required('Required')
                .matches(/.*\s/, 'First and last name required.'),
            }),
            acceptTC: Yup.bool(),
            couponCode: Yup.string(),
            giftCardCode: Yup.string(),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form className="form">

                {stripePaymentElementExperiment.isTreatment && (
                  <>
                    <div
                      className={classnames({
                        'disabled-payment-element': paymentMethodType === 'paypal'
                      })}
                    >
                      <PaymentElement
                        className={classnames('payment-element')}
                        options={{
                          value: { postalCode: null },
                          hidePostalCode: true,
                          layout: {
                            type: 'accordion',
                            defaultCollapsed: false,
                            radios: false,
                          },
                          fields: {
                            billingDetails: {
                              address: {
                                country: 'never',
                              },
                            },
                          },
                          wallets: {
                            applePay: applePayExperiment.isTreatment ? 'auto' : 'never',
                            googlePay: googlePayExperiment.isTreatment ? 'auto' : 'never',
                          }
                        }}
                      />
                    </div>

                    {paypalExp && paypalExp.isEnabled && paypalExp.isTreatment && (
                      <div className={`paymentMethodOptionContainer newPaymentElement ${payPalContainerOpen ? 'selected' : ''}`}>
                        <div
                          className="radio-container"
                          onClick={handleTogglePaymentMethod}
                        >
                          <div className='paymentLabel'>
                            <img src={payPalLogoIcon} alt="" className="payPalLogoIcon" />
                            <span className="text">PayPal</span>{' '}
                          </div>
                          <CuiCheckbox
                            name="paypal_payment"
                            checked={payPalContainerOpen}
                            onChange={handleTogglePaymentMethod}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}


                {
                  !stripePaymentElementExperiment.isTreatment && (
                    <>
                      <div className="paymentMethodOptionContainer">
                        {paypalExp && paypalExp.isEnabled && paypalExp.isTreatment && (
                          <div
                            className={"radio-container"}
                            onClick={() => setPaymentMethodType('paypal')}
                          >
                            <div>
                              <span
                                className={`checkradio ${payPalContainerOpen ? 'checked' : ''
                                  }`}
                              />
                              <span className="text">PayPal</span>{' '}
                            </div>
                            <img src={payPalLogo} alt="" className="payPalLogo" />
                          </div>
                        )}

                        <div
                          className="radio-container"
                          onClick={() => {
                            setPaymentMethodType('creditCard')
                          }}
                        >
                          <div>
                            <span
                              className={`checkradio ${creditCardContainerOpen ? 'checked' : ''
                                }`}
                            />
                            <span className="text">Credit Card</span>{' '}
                          </div>
                          <img src={cardsLogos} alt="" className="cc-logos" />
                        </div>
                      </div>
                      <Collapse isOpen={creditCardContainerOpen}>
                        <div className="form-row form-group">
                          <div className="col-md-6">
                            <label htmlFor="cardHolder">Cardholder Name</label>
                            <input
                              type="text"
                              disabled={loading}
                              className={
                                errors.cardHolder && touched.cardHolder
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              id="cardHolder"
                              aria-describedby="cardHolderHelp"
                              placeholder={'Name on card'}
                              value={values.cardHolder}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.cardHolder && touched.cardHolder && (
                              <div className="invalid-feedback">
                                {errors.cardHolder ? errors.cardHolder : error}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="cardHolder">Card number</label>
                            <CardElement
                              className='card-element'
                              options={{
                                value: { postalCode: null },
                                hidePostalCode: true,
                                style: {
                                  base: {
                                    fontSize: '14px',
                                    color: '#707070',
                                    letterSpacing: '0.025em',
                                    border: '1px solid #707070',
                                    'border-radius': '200px',
                                    height: '38px',
                                    fontFamily: 'Source Code Pro, monospace',
                                    '::placeholder': {
                                      color: '#aab7c4',
                                    },
                                  },
                                  invalid: {
                                    color: '#9e2146',
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Collapse>
                    </>
                  )
                }
                <div className="form-row form-group">
                  <div className="col-md-6">
                    <label htmlFor="promoCode">Promotions</label>
                    {!changeCouponInChoExperiment ? (
                      <Coupon
                        data={coupon}
                        onChange={handleChange}
                        onSubmit={setPromoHandler}
                        disabled
                      />
                    ) : (
                      <CheckoutCoupon
                        couponData={coupon}
                        validateCoupon={validateCoupon}
                        resetCouponValidation={resetCouponValidation}
                      />
                    )}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="promoCode">GiftCard</label>
                    <input
                      disabled={loading}
                      type="text"
                      id="giftCardCode"
                      className={
                        errors.createAccountError && touched.createAccountError
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      placeholder="Add giftcard code"
                      value={(values.giftCardCode || '').toUpperCase()}
                      onChange={(event) => {
                        resetGiftcardValidation()
                        handleChange(event)
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                {
                  !loading && (error || isEmpty(address)) && (
                    <div className="error-container">
                      {error && error}
                      {isEmpty(address) && 'Complete your address data first.'}
                    </div>
                  )
                }

                <MessageContainerCui
                  blue
                  message="By confirming this order, you agree to sign up for the CookUnity service. See details at our Terms and Conditions page."
                />

                <div className="button-container">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    disabled={loading}
                    className={classnames(
                      'cui-button',
                      'paymentButton',
                      'disabled-payment-button',
                      {
                        'display-payment-button':
                          paymentMethodType === 'creditCard',
                      },
                    )}
                  >
                    {!loading ? (
                      'Confirm Order'
                    ) : (
                      <i className="spinner fas fa-circle-notch" />
                    )}
                  </button>

                  <button
                    disabled={loading}
                    className={classnames(
                      'cui-button',
                      'paymentButton',
                      'disabled-payment-button',
                      {
                        'display-payment-button':
                          paymentMethodType === 'paypal' && loading,
                      },
                    )}
                  >
                    <i className="spinner fas fa-circle-notch" />
                  </button>

                  <div
                    className={classnames('disabled-payment-button', {
                      'display-payment-button':
                        paymentMethodType === 'paypal' && !loading,
                    })}
                  >
                    <PaypalButton
                      id="paypal-button-container"
                      env={payPalEnv}
                      client={payPalSecretToken}
                      onSubmit={(token, device) => {
                        setPayPalInfo({ token, device })
                      }}
                      onCancel={data => {
                        setPayPalInfo(null)
                        console.log('onCancel: ', data)
                      }}
                      onError={error => {
                        setPayPalInfo(null)
                        logError(error)
                      }}
                      description={'CookUnity'}
                    />
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </Collapse>
    </div >
  )
}

export default function InjectedCardSetupForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <PaymentFormCui {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}
