import { CuiLoader } from 'front-lib'
import React from 'react'
import './Loader.scss'

const Loader = () => {
  return (
    <div className="loader-holder">
      <CuiLoader />
    </div>

  )
}

export default Loader
