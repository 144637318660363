import { useVariant } from '@unleash/proxy-client-react'
import React, { useMemo } from 'react'
import Slider from 'src/components/Slider/Slider'
import { Lazy, Navigation } from 'swiper'
import 'swiper/scss/lazy'
import 'swiper/scss/navigation'
import MealCardFromApp from '../../../components/MealCardPremiumFee'
import classnames from 'classnames'
import MealCardColour from 'src/components/MealCardColour/MealCardColour'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import { useFlag } from '@unleash/proxy-client-react'
import { getChefIdsParam, getParamFromPath } from 'src/utils/queryCookie'

const sliderConfigs = {
  loop: false,
  loopedSlides: 4,
  spaceBetween: 10,
  slidesOffsetBefore: 10,
  slidesPerView: 4,
  modules: [Lazy, Navigation],
  centeredSlides: false,
  allowTouchMove: false,
  preloadImages: false,
  lazy: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
  },
  breakpoints: {
    1200: {
      slidesPerView: 'auto',
      centeredSlides: false,
      allowTouchMove: true,
    },
    768: {
      slidesPerView: 'auto',
      allowTouchMove: true,
    },
    1: {
      slidesPerView: 1.3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      spaceBetween: 10,
      centeredSlides: true,
      allowTouchMove: true,
      loop: false,
      centeredSlidesBounds: true,
    },
  },
}

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const IMG_STORAGE = process.env.REACT_APP_IMG_STORAGE
const TASTY_CAMPAIGN_PARAM_VALUE = '1'
const TASTY_CAMPAIGN_PARAM_KEY = 'tasty'
const DEFAULT_TITLE = 'Try these best-selling meals for your first order'
const DISCOUNTED_PRODUCTS_TITLE = 'Enjoy weekly savings on select meals!'
const DISCOUNTED_PRODUCTS_DESCRIPTION =
  'Get up to $2.5 OFF every week on select meals!'

function HighlightedRow({
  addProduct,
  removeProduct,
  onMealClick,
  maxProductToCart,
  totalOrderedProductsQty,
  actions,
  getCategory,
  meals,
  getOrderQty,
  title,
  displayedMeals = [],
  onlyProductsWithDiscount = false,
}) {
  const variant = useVariant('use-imgix')
  const isTreatment = variant && variant.enabled && variant.name === 'TREATMENT'
  const tastyCampaign = getParamFromPath(TASTY_CAMPAIGN_PARAM_KEY)

  const { isTreatment: highlightedMealCard } = useExperiment(
    EXPERIMENTS.highlightedMealCard,
    {
      trackOnMount: true,
    },
  )

  const isQueryPublicEnabled = useFlag(
    'gro-gtt-4387-enable-meal-public-query-funnel',
  )

  const trackSlideChange = (swiper, position) => {
    const { previousIndex, allowTouchMove } = swiper
    if (previousIndex > 0) {
      const trackEvent = {
        eventName: 'Sign Up - Meals Selection - Content Module Clicked',
        eventData: {
          module_name: 'Highlighted Row',
          module_type: 'carrousel',
          module_click_description: allowTouchMove ? 'swipe' : 'arrow',
          module_click_action: 'scroll-horizontal',
          page_name: 'Meals Selection',
          page_position_row: position,
        },
      }

      actions.sendTracking(trackEvent)
    }
  }

  const MealCardExp = highlightedMealCard ? MealCardColour : MealCardFromApp

  const highlightedMeal = useMemo(() => {
    const displayedMealsSet = new Set(
      displayedMeals.map(meal => meal.entity_id),
    )

    const filteredMeals = meals.filter(meal =>
      displayedMealsSet.has(meal.entity_id),
    )

    if (filteredMeals.length < 4 && !onlyProductsWithDiscount) {
      const uniqueFilteredMealsSet = new Set(
        filteredMeals.map(meal => meal.entity_id),
      )

      const additionalMeals = displayedMeals
        .filter(meal => !uniqueFilteredMealsSet.has(meal.entity_id))
        .slice(0, 4 - filteredMeals.length)

      return filteredMeals.concat(additionalMeals)
    }

    return filteredMeals
  }, [displayedMeals, meals, onlyProductsWithDiscount])

  const chefTitle = useMemo(() => {
    const withChefTitle = !!getChefIdsParam()
    if (!meals?.length || !withChefTitle) {
      return null
    }
    const chefName = `${meals[0].chef_firstname} ${meals[0].chef_lastname}`
    return `Meals Sponsored By Chef ${chefName}`
  }, [meals])

  if (highlightedMeal?.length < 1) {
    return null
  }

  return (
    <div
      className={classnames('highlighted-row', {
        'highlighted-row-exp': highlightedMealCard,
        'tasty-campaign': tastyCampaign === TASTY_CAMPAIGN_PARAM_VALUE,
      })}
    >
      <div className="container-cui slider-container">
        <h2 className="rebrand">
          {onlyProductsWithDiscount
            ? DISCOUNTED_PRODUCTS_TITLE
            : chefTitle || title || DEFAULT_TITLE}
        </h2>
        {onlyProductsWithDiscount && (
          <p className="rebrand title-description">{DISCOUNTED_PRODUCTS_DESCRIPTION}</p>
        )}
        <Slider
          showArrows={false}
          configs={{
            ...sliderConfigs,
            onSlideChange: swiper => trackSlideChange(swiper, 0),
          }}
        >
          {highlightedMeal.map(meal => (
            <MealCardExp
              highlightedMeal
              key={`mealCard-recommended-${meal.entity_id}`}
              meal={{
                ...meal,
                premium_fee: meal.premium_fee,
                protein_type: getCategory(meal),
                full_path_meal_image: isTreatment
                  ? IMG_STORAGE + meal.image_path + meal.image
                  : `${IMG_URL_SOURCE}${meal.image}`,
                full_path_chef_image: `${IMG_URL_SOURCE}${meal.bannerpic}`,
              }}
              onAddItem={() => {
                addProduct(meal)
              }}
              onRemoveItem={() => {
                removeProduct(meal)
              }}
              onMealClick={() => {
                onMealClick({
                  entity_id: meal.entity_id,
                  stock: meal.stock,
                  quizRecommendation: meal.quizRecommendation || false,
                  position_in_menu: meal.position_in_menu || 0,
                  isQueryPublicEnabled,
                  inventoryId: meal.inventoryId,
                })
              }}
              disableAddItem={totalOrderedProductsQty >= maxProductToCart}
              quantity={getOrderQty(meal.entity_id)}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default HighlightedRow
