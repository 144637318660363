import duck from "./duck";

export const VALID_ZIP_CODE = {
  start: duck.defineType('VALID_ZIP_CODE_START'),
  success: duck.defineType('VALID_ZIP_CODE_SUCCESS'),
  failed: duck.defineType('VALID_ZIP_CODE_FAILED'),
  reset: duck.defineType('VALID_ZIP_CODE_RESET'),
};

export const LOGGING = {
  start: duck.defineType('LOGGING_START'),
  success: duck.defineType('LOGGING_SUCCESS'),
  failed: duck.defineType('LOGGING_FAILED'),
  reset: duck.defineType('LOGGING_RESET'),
};

export const SESSION = {
  start: duck.defineType('SESSION_START'),
  success: duck.defineType('SESSION_SUCCESS'),
  failed: duck.defineType('SESSION_FAILED'),
  reset: duck.defineType('SESSION_RESET'),
};

export const TRACKING = {
  start: duck.defineType('TRACKING_START'),
  success: duck.defineType('TRACKING_SUCCESS'),
  failed: duck.defineType('TRACKING_FAILED'),
  reset: duck.defineType('TRACKING_RESET'),
};

export const IDENTIFY = {
  start: duck.defineType('IDENTIFY_START'),
  success: duck.defineType('IDENTIFY_SUCCESS'),
  failed: duck.defineType('IDENTIFY_FAILED'),
  reset: duck.defineType('IDENTIFY_RESET'),
};