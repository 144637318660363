import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CuiIcon } from 'front-lib'
import { getCouponError } from '../../../../../../redux/ducks/screens/signup/commons/selectors'
import { changeCouponOnSearch, validateCoupon } from 'src/utils/coupons'
import './Coupon.scss'

function Coupon({ data, className, disabled }) {
  const [value, setValue] = useState('')
  const { pathname, search } = useLocation()
  const [couponError, setCouponError] = useState(
    useSelector(getCouponError) || (!data?.promotion ? 'Invalid coupon' : ''),
  )
  useEffect(() => {
    if (!couponError) {
      setValue(data.userCoupon)
    }
  }, [data.userCoupon, couponError])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      const error = validateCoupon(value)
      if (!error) {
        window.location = pathname + changeCouponOnSearch(search, value)
      } else {
        setCouponError(error)
      }
    },
    [pathname, search, value],
  )

  const handleChange = useCallback(e => {
    e.preventDefault()
    setValue(e.target.value)
  }, [])

  return (
    <form onSubmit={handleSubmit} className="coupon-form">
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className={classNames('coupon-input', className)}
        disabled={disabled}
      />
      {data?.promotion ? (
        <button
          className="coupon-button coupon-button__success"
          disabled={disabled}
        >
          <CuiIcon
            name="checkmarkCircle2"
            className="coupon-button__success--icon"
          />
        </button>
      ) : (
        <button
          className="coupon-button coupon-button__plus"
          disabled={disabled}
        >
          <CuiIcon name="plus" className="coupon-button__plus--icon" />
        </button>
      )}
    </form>
  )
}

export default Coupon
