import React, { useState }  from 'react'
import PlanPage from './PlanPage'

const Plan = () => {
  const [showModal, setShowModal] = useState(false)


  const handleToggleModal = () => {
    setShowModal(prev => !prev)
  }
  return <PlanPage showModal={showModal} toggleModal={handleToggleModal} />
}

export default Plan
