export const getRecaptcha = (action = 'default') => {
  return new Promise((resolve, reject) => {
    try {
      window.grecaptcha.enterprise.ready(
        async () => {
          resolve(
            await window.grecaptcha.enterprise.execute(
              process.env.REACT_APP_GOOGLE_RECAPTCHA,
              { action },
            ),
          )
        },
        error => reject(error),
      )
    } catch (error) {
      reject(error)
    }
  })
}
