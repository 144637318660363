import { getCUCookie, createCUCookie } from './cookieUtils'
import { experimentData } from '../config/experiment'
import { logError } from './logError'
import { EXPERIMENTS_DATA } from 'src/constants/experiments'

export const generateRandom = (max = 2, min = 0) =>
  Math.floor(Math.random() * (max - min)) + min

const convertToLowerCase = string => {
  if (typeof string !== 'string') return null
  return string.toLowerCase()
}

export const getAllActives = (userData = {}) => {
  const currentDate = new Date().toISOString().slice(0, 10)

  return experimentData.reduce((experimentsActive, currentExperiment) => {
    if (
      currentExperiment.active &&
      (!currentExperiment.stores ||
        currentExperiment.stores.indexOf(userData.store) !== -1) &&
      (!currentExperiment.rings ||
        currentExperiment.rings.indexOf(userData.ring) !== -1) &&
      (!currentExperiment.skip ||
        currentExperiment.skip.utmSource !==
          convertToLowerCase(userData.utmSource)) &&
      (!currentExperiment.period ||
        currentExperiment.period.start <= currentDate) &&
      (!currentExperiment.period || currentExperiment.period.end >= currentDate)
    ) {
      experimentsActive.push({
        ...currentExperiment,
        currentVariant: getVariant(currentExperiment),
      })
    }

    return experimentsActive
  }, [])
}

export function getByName(name) {
  const object = experimentData.find(item => item.name === name)

  return object && object.active
    ? { ...object, variant: getVariant(object) }
    : null
}

export function getVariant(experiment, callback) {
  if (!experiment || typeof experiment !== 'object') return null

  const expCookieName = experiment.name

  let variant = getCUCookie(expCookieName)
  if (!variant) {
    if (experiment.variants.length === 1) {
      variant = experiment.variants[0]
    } else {
      const random = generateRandom(experiment.variants.length)
      variant = experiment.variants[random]
    }
    createCUCookie(expCookieName, variant, {
      expirationDays: 28,
      includeSubdomains: true,
    })
  }

  if (typeof callback === 'function')
    callback({ ...experiment, currentVariant: variant })

  return variant
}

export const composeObjectWithVariant = attrs =>
  getAllActives(attrs).reduce(
    (ac, experiment) => ({
      ...ac,
      [experiment.name]: experiment.currentVariant,
    }),
    {},
  )

export const parseVariantPayload = payload => {
  try {
    if (!payload) {
      return payload
    }

    if (payload.type === 'string') {
      return payload.value
    }

    if (payload.type === 'json') {
      const parsedValue = JSON.parse(payload.value)
      return parsedValue
    }
  } catch (error) {
    logError(error)
    return payload
  }
}

export const checkVariantIsTreatment = variantName => {
  return variantName.toLowerCase().includes('treatment')
}

export const trackExperimentWithData = (
  trackEvent,
  experimentName,
  extraData,
) => {
  const experimentData = EXPERIMENTS_DATA[experimentName]

  if (!experimentData) {
    logError(`Experiment ${experimentName} is not defined in EXPERIMENTS_DATA`)
    return
  }

  trackEvent({
    experiment_id: experimentData?.id,
    experiment_name: experimentData?.name,
    ...extraData,
  })
}
