import classNames from 'classnames'
import { CuiButton, CuiIcon } from 'front-lib'

const getBaseImage = url => {
  if (!url) {
    return undefined
  }

  const filenameWithExtension = url.split('/').pop()
  const filename = filenameWithExtension.split('.')[0]
  const baseFilename = filename.split('-')[0]

  return baseFilename
}

const QuizPreferencesSelected = ({ preferencesToShow, setShowQuiz }) => {
  const allPreferences = [
    ...(preferencesToShow.diets || []),
    ...(preferencesToShow.proteins || []),
    ...(preferencesToShow.allergens || []),
  ]

  const filteredPreferences = allPreferences.filter(
    preference => preference.name !== 'None',
  )

  const filterAllergens = ['sesameSeeds', 'eggs', 'peanuts', 'fish']

  return (
    <div
      className={classNames('cui-quiz-preferences-selected', {
        'cui-quiz-preferences-selected--none': filteredPreferences.length === 0,
      })}
    >
      {filteredPreferences.length > 0 && (
        <ul>
          {filteredPreferences.map(preference => (
            <li
              key={preference.name}
              className={`quiz-cui__checkbox__option--img preference-${preference.name}`}
            >
              <CuiIcon
                path={preference.path}
                name={getBaseImage(preference.image)}
              />
              <span>
                {(preference.filter ||
                  filterAllergens.includes(preference.name)) &&
                  'No '}
                {preference.label}
              </span>
            </li>
          ))}
        </ul>
      )}
      <CuiButton
        onClick={() => setShowQuiz(true)}
        className="cui-quiz-preferences-selected__button"
        size="medium"
        fill="outline"
        color="dark"
      >
        Update preferences
      </CuiButton>
    </div>
  )
}

export default QuizPreferencesSelected
