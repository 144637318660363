import { useAuth0 } from '@auth0/auth0-react'
import { parse } from 'qs'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import ScrollDownTracking from 'src/components/ScrollDownTracking'
import { SESSION_KEY } from 'src/constants'
import {
  DELIVERY_DAY_LOGIC_ID,
  DELIVERY_DAY_LOGIC_NAME,
} from 'src/config/experiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import { isAuthLoadingSelector, isAuthenticatedSelector } from 'src/features/auth/authSlice'
import { EVENTS, isMobile } from 'src/utils/events'
import { PATHS, pageNames } from 'src/screens/signup/constants'
import { removeUrlParameter } from 'src/utils/urlUtils'
import useExperiment from 'src/hooks/useExperiment'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import * as selectorMenuList from 'src/redux/ducks/modules/MenuList/selectors'
import * as productDetailModuleCreators from 'src/redux/ducks/modules/ProductDetail/creators'
import { showProductDetail } from 'src/redux/ducks/modules/ProductDetail/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as preOrderActionCreators from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as preOrderPageSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as deliverySettingCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'
import Analytics from 'src/utils/analytics'
import * as checkoutPageActionCreators from '../../../../../../redux/ducks/screens/signup/pages/Checkout/creators'
import { useAuthData } from '../../../Checkout/hooks'
import PreOrderPageCui from './PreOrderPageCui'
import useAuth0Api from 'src/features/signIn/hooks/Auth0'
import Loader from 'src/components/Loader'

function PreOrderPageContainer({
  cartData,
  experimentData,
  userSelectedData,
  setShowQuiz,
  menuFilters,
  showProductDetail,
  actions,
  searchMealsIds,
  signUpInitData,
  coupon,
  validDates
}) {
  const { isTreatment: isSneakPeekTreatment } = useExperiment(
    EXPERIMENTS.sneakPeek,
  )

  const {
    loginWithRedirect,
    isAuthenticated: isAuthenticatedAuth0,
  } = useAuth0()
  const isAuthenticatedSneakPeek = useSelector(isAuthenticatedSelector)
  const isAuthenticated = isSneakPeekTreatment
    ? isAuthenticatedSneakPeek
    : isAuthenticatedAuth0

  const location = useLocation()
  const navigate = useNavigate()
  const [openSelectedMeals, setOpenSelectedMeals] = useState(false)
  const [cartUpdated, setCartUpdated] = useState(false)
  const { checkSession } = useAuth0Api()
  const [checkSneakPeekFlag, setCheckSneakPeekFlag] = useState(false)
  const isAuthLoading = useSelector(isAuthLoadingSelector)

  useAuthData(location.search, actions, location.pathname, isSneakPeekTreatment)

  useEffect(() => {
    if (typeof actions.addSeoData === 'function')
      actions.addSeoData({
        title: 'A Chef Collective | CookUnity | Meal Selection',
      })

    Analytics.trackRealLoad('Meals Page Load')

    actions.sendLogging({
      event: 'preOrderPage',
      warning: false,
      data: {
        category: 'new-funnel',
        label: 'test-new-funnel',
        action: 'meals-page',
      },
    })

    if (experimentData[DELIVERY_DAY_LOGIC_ID]) {
      actions.sendTracking({
        eventName: EVENTS.experimentViewed,
        eventData: {
          page_name: pageNames.MEALS,
          experiment_id: DELIVERY_DAY_LOGIC_ID,
          experiment_name: DELIVERY_DAY_LOGIC_NAME,
          variation_name: experimentData[DELIVERY_DAY_LOGIC_ID],
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    actions.sendIdentify()
  }, [isAuthenticated]) // eslint-disable-line

  useEffect(() => {
    if (isSneakPeekTreatment) checkSession()

    if (!isSneakPeekTreatment && !isAuthenticated) {
      ;(async () => {
        //* Flag is changing its value causing re-renders, this timeout ensure to get the last value
        //* this should be removed once sneak peek experiment is completed
        await new Promise(resolve => setTimeout(resolve, 1000))
        setCheckSneakPeekFlag(true)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSneakPeekTreatment])

  useEffect(() => {
    if (checkSneakPeekFlag && !isSneakPeekTreatment && !isAuthenticated) {
      const { email, cuSession, utm_source } = parse(location.search, {
        ignoreQueryPrefix: true,
      })
      const searchForAuth0 = !cuSession ? `&cuSession=${SESSION_KEY}` : ''
      const search = removeUrlParameter(location.search, [
        'planSize',
        'meals',
        'noSkip',
      ])

      loginWithRedirect({
        action: 'signup',
        redirectUri: `${window.location.origin}${
          PATHS[pageNames.MEALS]
        }${search}${searchForAuth0}`,
        initialEmail: email,
        search: `${search}${searchForAuth0}`,
        env: process.env.NODE_ENV,
        isDemo: utm_source === 'demo',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSneakPeekFlag])

  const toggleSelectedMeals = () => {
    setOpenSelectedMeals(prev => !prev)
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection - Cart Viewed',
      eventData: {
        [openSelectedMeals ? 'open_cart_view' : 'close_cart_view']: true,
      },
    })
  }

  const trackScrolling = (args = {}) => {
    const obj = {
      event: 'ScrollMenu',
      warning: false,
      data: {
        action: `${args.maxScroll}%`,
        value: args.elementHeight,
        category: 'ScrollMenu',
        label: args.activeFilter,
      },
    }

    actions.sendLogging(obj)
  }

  const stepHandler = () => {
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection Completed',
      eventData: {
        products: cartData.products.map(product => ({
          product_id: product.id,
          sku: product.data.sku,
          category: product.data.category_id,
          name: product.data.name,
          chef: product.data.chef_firstname + product.data.chef_lastname,
          price: product.data.price,
          quantity: product.qty,
        })),
      },
    })

    actions.setBreadPathStart(PATHS[pageNames.CHECKOUT])
    actions.persistState({ sessionId: SESSION_KEY })

    const { cuSession } = parse(location.search, {
      ignoreQueryPrefix: true,
    })
    const searchForAuth0 = !cuSession ? `&cuSession=${SESSION_KEY}` : ''

    const search = removeUrlParameter(location.search, [
      'planSize',
      'meals',
      'noSkip',
    ])
    navigate(`${PATHS[pageNames.CHECKOUT]}${search}${searchForAuth0}`)
  }

  const addProduct = product => {
    actions.addProductCart(product)
  }

  const removeProduct = product => {
    actions.removeProductCart(product)
  }

  const handleClickChooseForMe = () => {
    actions.chooseForMe()
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection - Choose for Me Clicked',
      eventData: {
        page_name: isMobile() && 'Sign Up - Meals Selection',
        count_products_in_cart_before_action: cartData.totalProductQty,
        count_products_in_cart_after_action:
          userSelectedData.selectedPlan?.mealsPerDelivery,
        count_products_added_after_action:
          userSelectedData.selectedPlan?.mealsPerDelivery -
          cartData.totalProductQty,
        action: 'choose_for_me',
      },
    })
    toggleSelectedMeals()
  }

  const handleClickUndoChooseForMe = () => {
    const choseForMeMeals = cartData.products.filter(
      product => product.data.choseForMe,
    )

    actions.undoChooseForMe()
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection - Undo Choose for Me',
      eventData: {
        page_name: isMobile() && 'Sign Up - Meals Selection',
        count_products_in_cart_before_action: cartData.totalProductQty,
        count_products_in_cart_after_action:
          cartData.totalProductQty - choseForMeMeals.length,
        count_products_erased_after_action: choseForMeMeals.length,
        action: 'undo_choose_for_me',
      },
    })
  }

  const handleClickRefreshChooseForMe = () => {
    const choseForMeMeals = cartData.products.filter(
      product => product.data.choseForMe,
    )

    actions.refreshChooseForMe()
    actions.sendTracking({
      eventName: 'Sign Up - Meals Selection - Refresh Choose for Me',
      eventData: {
        page_name: isMobile() && 'Sign Up - Meals Selection',
        count_products_in_cart_before_action: cartData.totalProductQty,
        count_products_in_cart_after_action:
          userSelectedData.selectedPlan?.mealsPerDelivery,
        count_products_erased_after_action: choseForMeMeals.length,
        action: 'refresh_choose_for_me',
      },
    })
  }

  const activeFilter =
    menuFilters.meatTypeFilter ||
    menuFilters.specificationsDetailFilter ||
    'all'

  useEffect(() => {
    if (!cartUpdated) {
      setCartUpdated(true)
      return
    }

    if (
      cartData &&
      cartData.totalProductQty &&
      Array.isArray(searchMealsIds) &&
      searchMealsIds.length > 0 &&
      cartUpdated
    ) {
      actions.resetSearchMealsIds()
      if (
        cartData.totalProductQty ===
        userSelectedData.selectedPlan?.mealsPerDelivery
      ) {
        stepHandler()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData])

  if(isSneakPeekTreatment && isAuthLoading) return <Loader />

  return (
    <ScrollDownTracking
      callback={output => {
        trackScrolling({ ...output, activeFilter: activeFilter })
      }}
      reset={activeFilter}
    >
      <PreOrderPageCui
        activeFilter={activeFilter}
        toggleSelectedMeals={toggleSelectedMeals}
        openSelectedMeals={openSelectedMeals}
        zipcode={userSelectedData.zipcode}
        stepHandler={stepHandler}
        addProduct={addProduct}
        removeProduct={removeProduct}
        selectedPlan={userSelectedData.selectedPlan}
        startDay={userSelectedData.startDay}
        selectedProducts={cartData.products.map(cartProduct => ({
          ...cartProduct,
        }))}
        totalProductQty={cartData.totalProductQty}
        productDetailHandler={actions.productDetail}
        setShowQuiz={setShowQuiz}
        resetProductDetail={actions.resetProductDetail}
        showProductDetail={showProductDetail}
        diet={userSelectedData.diet}
        sendTracking={actions.sendTracking}
        onClickChooseForMe={handleClickChooseForMe}
        onClickUndoChooseForMe={handleClickUndoChooseForMe}
        onClickRefreshChooseForMe={handleClickRefreshChooseForMe}
        actions={actions}
        signUpInitData={signUpInitData}
        coupon={coupon}
        validDates={validDates}
      />
    </ScrollDownTracking>
  )
}

const mapStateToProps = state => ({
  userSelectedData: signUpCommonSelectors.getUserSelectedData(state),
  cartData: preOrderPageSelectors.getCart(state),
  menuFilters: selectorMenuList.getMenuFilter(state),
  showProductDetail: showProductDetail(state),
  experimentData: signUpCommonSelectors.getExperimentData(state).object,
  menuSortBy: signUpCommonSelectors.menuSortBy(state),
  searchMealsIds: signUpCommonSelectors.getSearchMealsIds(state),
  signUpInitData: signUpCommonSelectors.getSignUpInitData(state),
  coupon: signUpCommonSelectors.getCoupon(state),
  validDates: signUpCommonSelectors.getDeliveryDays(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addProductCart: preOrderActionCreators.startAddCart,
      removeProductCart: preOrderActionCreators.startRemoveCart,
      addSeoData: signUpCommonActionCreators.startAddSeoData,
      productDetail: productDetailModuleCreators.startProductDetail,
      sendLogging: creatorCommons.loggingStart,
      resetProductDetail: productDetailModuleCreators.loadProductDetailReset,
      persistState: signUpCommonActionCreators.persistState,
      setUserSelectedDataStart:
        signUpCommonActionCreators.setUserSelectedDataStart,
      setBreadPathStart: signUpCommonActionCreators.setBreadPathStart,
      setForcedSkipQuiz: signUpCommonActionCreators.forcedSkipQuizStart,
      sendTracking: creatorCommons.trackingStart,
      chooseForMe: preOrderActionCreators.startChooseForMe,
      undoChooseForMe: preOrderActionCreators.startUndoChooseForMe,
      refreshChooseForMe: preOrderActionCreators.startRefreshChooseForMe,
      setLoading: signUpCommonActionCreators.setForceLoading,
      resetSearchMealsIds: signUpCommonActionCreators.resetSearchMealsIds,
      setFormData: checkoutPageActionCreators.setFormData,
      applyCoupon: signUpCommonActionCreators.applyCouponStart,
      sendIdentify: creatorCommons.identifyStart,
      setDeliveryOptions: deliverySettingCreators.deliveryOptionCreators.start,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreOrderPageContainer)
