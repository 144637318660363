const Box = ({ title, content }) => {
  return (
    <div>
      <div className="stepper--box">
        <p className={'rebrand bold stepper--box--title'}>{title}</p>
        <div className={'stepper--box--content'}>{content}</div>
      </div>
      <div className={'stepper--line'} />
    </div>
  )
}

export default Box
