import React from 'react'
import classNames from 'classnames'
import styles from './FormCuiInput.module.scss'

const FormCuiInput = ({
  fill = 'solid',
  loading = false,
  size = 'small',
  color = 'white',
  className,
  ...props
}: any) => {
  return (
    <input
      type="text"
      className={classNames(
        styles['cui-input'],
        styles[`cui-input-${color}`],
        styles[`cui-input-${size}`],
        styles[`cui-input-${color}-${fill}`],
        loading && styles[`cui-input-${color}-${fill}-loading`],
        className,
      )}
      {...props}
    />
  )
}

export default FormCuiInput
