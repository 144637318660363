import React from 'react'
import { CuiIcon } from 'front-lib'
import { useSelector } from 'react-redux'
import {
  deliveryDaySelectedSelector,
  timeSlotSelectedSelector,
} from 'src/features/delivery/deliverySlice'
import { planSelectedSelector } from 'src/features/plans/plansSlice'

const SubDetails = () => {
  const deliveryDaySelected = useSelector(deliveryDaySelectedSelector)
  const planSelected = useSelector(planSelectedSelector)
  const timeSlotSelected = useSelector(timeSlotSelectedSelector)

  return (
    <>
        <div className="thank-row margin-0">
          <CuiIcon name="layers" />
          <p>
            <b>
              {planSelected?.mealsPerDelivery * planSelected?.deliveries} Weekly
              Meals Plan
            </b>
          </p>
        </div>
        <div className="thank-row">
          <CuiIcon name="calendar" />
          <div>
            <p>First delivery will arrive on</p>
            <p className="margin-8">
              <b>{deliveryDaySelected?.label}</b>
            </p>
          </div>
        </div>
        <div className="thank-row margin-0">
          <CuiIcon name="clock" />
          <p>
            <b>{timeSlotSelected?.label}</b>
          </p>
        </div>
    </>
  )
}

export default SubDetails
