import React, { useState } from 'react'
import './AccordionItem.scss'

const AccordionItem = ({ trigger, content }) => {
  const [showContent, changeShowContent] = useState(false)

  return (
    <div className="accordion-item-container">
      <div
        role="button"
        className="trigger-wrapper"
        onClick={() => changeShowContent(!showContent)}
      >
        <p className="rebrand trigger">{trigger}</p>
        <span
          className={`trigger-arrow ${showContent ? 'visible' : 'hidden'}`}
        />
      </div>
      <p
        className={`rebrand accordion-content${showContent ? ' visible' : ''}`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  )
}

export default AccordionItem
