export const NPC_CONFIG = {
  '0': {
    noPopulateCart: false,
    sortMenu: true,
    displayHighlightedRow: 'default',
    onlyProductsWithDiscount: false,
  },
  '1': {
    noPopulateCart: true,
    sortMenu: true,
    displayHighlightedRow: 'default',
    onlyProductsWithDiscount: false,
  },
  '2': {
    noPopulateCart: true,
    sortMenu: false,
    displayHighlightedRow: 'always',
    onlyProductsWithDiscount: false,
  },
  '3': {
    noPopulateCart: true,
    sortMenu: true,
    displayHighlightedRow: 'never',
    onlyProductsWithDiscount: false,
  },
  '4': {
    noPopulateCart: true,
    sortMenu: false,
    displayHighlightedRow: 'always',
    onlyProductsWithDiscount: true,
  },
}
