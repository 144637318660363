import { PropTypes } from 'prop-types'
import { CuiIcon } from 'front-lib'

const DetailWithIcon = ({ color = 'black-1', icon, title, children }) => {
  return (
    <div className={'detail-with-icon'}>
      {icon && (
        <CuiIcon
          name={icon}
          className={`detail-with-icon--icon color-${color}`}
        />
      )}
      <div className={'detail-with-icon--content'}>
        <h3 className={`order-details--subtitle color-${color}`}>{title}</h3>
        {children}
      </div>
    </div>
  )
}

const COLORS = [
  'green-1',
  'green-2',
  'green-3',
  'green-4',
  'yellow-1',
  'yellow-2',
  'wine-1',
  'wine-2',
  'wine-3',
  'blue-1',
  'blue-2',
  'blue-3',
  'pink-1',
  'pink-2',
  'pink-3',
  'gold-1',
  'orange-1',
  'orange-2',
  'red-1',
  'black-1',
  'white-1',
  'gray-1',
  'gray-2',
  'gray-3',
  'gray-4',
  'gray-5',
  'gray-6',
  'gray-7',
  'gray-8',
  'gray-9',
]

DetailWithIcon.propTypes = {
  color: PropTypes.oneOf(COLORS),
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default DetailWithIcon
