import React from 'react'
import classnames from 'classnames'
import './GutterCui.scss'
import { CuiButton } from 'front-lib'

const GutterCui = props => {
  const { onGoNext, className, style, nextDisabled, children, ctaText } = props

  return (
    <div className={classnames('gutter-cui', className)} style={style}>
      <div className="gutter-cui__container">
        <div className="gutter-cui__cta_text_container">{children}</div>
        <div className="gutter-cui__buttons_container">
          <CuiButton
            disabled={nextDisabled}
            className="gutter-cui__cta_button"
            onClick={onGoNext}
            data-testid="next-step"
          >
            {ctaText}
          </CuiButton>
        </div>
      </div>
    </div>
  )
}

export default GutterCui
